import React, { useEffect, useRef, useState } from 'react';

import * as Constants from '../../../../helpers/Constants';
import { aviatorStore } from '../../Store';

import { DrawCircle } from './Circles';
import { DrawCurveLine } from './DrawCurveLine';
import canvasStyle from './PlaneAnimation.module.scss';
import { PlaneImageWithAnimation } from './PlaneImageWithAnimation';

export default function PlaneAnimation() {
  const { planeAnimation } = aviatorStore();
  const cnv = useRef<any>();
  const [ctx, setCtx] = useState<any>();
  const _targetPointCoef = Math.pow(planeAnimation.width, 2) / planeAnimation.height;
  let _isPlaneInCorner = false;
  const [planePointX, setPlanePointX] = useState<any>(-500);
  const [planePointY, setPlanePointY] = useState<any>(-500);
  const planeRef = useRef<any>();

  useEffect(() => {
    if (planeRef) {
      plane.targetPlanePoint.x = planeAnimation.width - planeRef.current.clientWidth - 20;
      plane.targetPlanePoint.y = planeAnimation.height - planeRef.current.clientHeight - 20;
    }
  });
  const plane = {
    planePoint: {
      // x: 10,
      // y: planeAnimation.height - 10,
      x: 0,
      y: planeAnimation.height,
    },
    targetPlanePoint: {
      x: planeAnimation.width - 150, // indent from right
      y: 150, // indent from top
    },
    speed: {
      x: 0.7,
      y: 0.7,
    },
    image: null,
  };

  const targetPlanePoints = [
    {
      x: planeAnimation.width - 100, // indent from right
      y: 60, // indent from top
    },
    {
      x: planeAnimation.width - 200, // indent from right
      y: 150, // indent from top
    },
    {
      x: planeAnimation.width - 250, // indent from right
      y: 60, // indent from top
    },
    {
      x: planeAnimation.width - 100, // indent from right
      y: 150, // indent from top
    },
  ];

  const circles = {
    speed: 0.3,
    horizontal: {
      x: 0,
      y: planeAnimation.height - 3, // indent from bottom
      // y: planeAnimation.height - 10, // indent from bottom
    },
    vertical: {
      x: 3, // indent from left
      // x: 10, // indent from left
      y: planeAnimation.height,
    },
    groupLength: 100,
  };

  useEffect(() => {
    if (cnv.current.getContext) {
      setCtx(cnv.current.getContext('2d'));
    }
  }, [cnv]);

  useEffect(() => {
    if (ctx != null) {
      animate();
      const handleResize = () => {
        ctx.canvas.width = planeAnimation.width;
        ctx.canvas.height = planeAnimation.height;

        cnv.current.width = planeAnimation.width;
        cnv.current.height = planeAnimation.height;

        circles.vertical.y = planeAnimation.height;
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [ctx]);

  function calculatePlanePoint() {
    if (plane.planePoint.x.toFixed() === plane.targetPlanePoint.x.toFixed()) {
      _isPlaneInCorner = true;
      plane.speed = { x: 0.2, y: 0.2 };
      plane.targetPlanePoint = targetPlanePoints[Math.floor(Math.random() * 3) + 1];
    }

    if (!_isPlaneInCorner) {
      plane.planePoint.x += plane.speed.x;
      plane.planePoint.y = -Math.pow(plane.planePoint.x, 2) / _targetPointCoef + planeAnimation.height - 10;
    } else {
      plane.planePoint.x = calculateOffsetPoint(plane.planePoint.x, plane.targetPlanePoint.x, plane.speed.x);
      plane.planePoint.y = calculateOffsetPoint(plane.planePoint.y, plane.targetPlanePoint.y, plane.speed.y);
    }
    setPlanePointX(plane.planePoint.x);
    setPlanePointY(plane.planePoint.y - planeRef.current.clientHeight);
  }

  function calculateCirclesPoint() {
    if (circles.horizontal.x.toFixed() === (-circles.groupLength).toFixed()) {
      circles.horizontal.x = 0;
    }
    circles.horizontal.x = calculateOffsetPoint(circles.horizontal.x, -circles.groupLength, circles.speed);

    if (circles.vertical.y.toFixed() === (planeAnimation.height + circles.groupLength).toFixed()) {
      circles.vertical.y = planeAnimation.height;
    }
    circles.vertical.y = calculateOffsetPoint(
      circles.vertical.y,
      planeAnimation.height + circles.groupLength,
      circles.speed,
    );
  }

  const animate = () => {
    if (cnv.current) {
      ctx.clearRect(0, 0, cnv.current.width, cnv.current.height);

      calculatePlanePoint();
      // ctx.drawImage(plane.image, plane.planePoint.x - 15, plane.planePoint.y - 55);
      if (aviatorStore.getState().aviatorStateMachine.state === Constants.aviator_state_machine_states.IN_PLAY) {
        DrawCurveLine(cnv, ctx, plane.planePoint.x, plane.planePoint.y);
        if (_isPlaneInCorner) {
          calculateCirclesPoint();
        }
        for (let i = 1; i < planeAnimation.width + circles.groupLength / (circles.groupLength / 5); i++) {
          DrawCircle(ctx, circles.horizontal.x + i * (circles.groupLength / 5), circles.horizontal.y, !(i % 5));
        }
        for (let i = 1; i < planeAnimation.height + circles.groupLength / (circles.groupLength / 5); i++) {
          DrawCircle(ctx, circles.vertical.x, circles.vertical.y - i * (circles.groupLength / 5), !(i % 5));
        }
      } else if (
        aviatorStore.getState().aviatorStateMachine.state === Constants.aviator_state_machine_states.END_FLYING
      ) {
        plane.targetPlanePoint.x = planeAnimation.width * 100;
        plane.targetPlanePoint.y = -planeAnimation.height * 100;
        plane.speed.x = plane.speed.y = 2;
      }
      requestAnimationFrame(animate);
    }
  };

  return (
    <div>
      <div style={{ width: '80px', position: 'absolute', left: planePointX, top: planePointY }} ref={planeRef}>
        <PlaneImageWithAnimation style={{ transform: 'rotate(10deg)' }} />
      </div>

      <canvas id={'plane'} ref={cnv} className={canvasStyle.myCanvas} />
      {/*<img src={planeImage} id={'planeImg'} alt={'123'} hidden={true} />*/}
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getRandomIntInclusive(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}

function calculateOffsetPoint(currentPoint: number, targetPoint: number, speed: number) {
  let result = 0;
  if (currentPoint < targetPoint) {
    result = currentPoint + speed;
  } else if (currentPoint > targetPoint) {
    result = currentPoint - speed;
  }
  return result;
}
