import React from 'react';

import { stateStore } from '../../../Store';
import './TeenPattiModal.scss';

interface TeenPattiModalProps {
  onYes: () => void;
  onNo: () => void;
}

export const TeenPattiModal = ({ onYes, onNo }: TeenPattiModalProps) => {
  const [teenPattiModal] = stateStore((state) => [state.teenPattiModal]);

  const handleYesClick = () => {
    onYes();
  };

  const handleNoClick = () => {
    onNo();
  };

  return (
    <div style={{ display: teenPattiModal.isOpen ? 'block' : 'none' }} className="fold-modal-container">
      <div className="modal">
        <p className="question">
          If you feel confident in your hand, click &apos;Play&apos; to place a bet equivalent to your Ante bet.
        </p>
        <div className="modal-buttons">
          <button className="fold-btn" onClick={handleYesClick}>
            PLAY
          </button>
          <button className="go-on-btn" onClick={handleNoClick}>
            FOLD
          </button>
        </div>
      </div>
    </div>
  );
};
