import * as amplitude from '@amplitude/analytics-browser';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as CloseButton } from '../../assets/closeButton.svg';
import '../../panels/About.scss';
import { ClickSound } from '../../helpers/AudioHelper';
import { stateStore } from '../../Store';

export default function About() {
  const [about, aboutState] = stateStore((state: any) => [state.about, state.aboutState]);
  useEffect(() => {
    if (about.isOpen) amplitude.track('Open about game Dragon Tiger');
  }, [about.isOpen]);

  return (
    <div className={'about'} style={{ display: about.isOpen ? 'flex' : 'none' }}>
      <div className={'background'}></div>
      <div className={'aboutHeader'}>
        <div
          className={'close'}
          onClick={() => {
            ClickSound();
            aboutState({ ...about, isOpen: false });
          }}
        >
          <CloseButton />
        </div>

        <p className={'text headerText'}>
          <FormattedMessage id={'welcome_to'} /> <FormattedMessage id={'dragon'} /> <FormattedMessage id={'tiger'} /> !
        </p>
      </div>
      <div className={'aboutContent newAboutContent'}>
        <div className={'rulesBlock'}>
          <a className={'text rulesBlockHeader'}>
            <FormattedMessage id={'objective'} />:
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'dragon_objective'} />
          </a>
        </div>
        <div className={'rulesBlock'}>
          <a className={'text rulesBlockHeader'}>
            <FormattedMessage id={'card_rankings'} />:
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'card_rankings_AK'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'suits_dont_matter'} />
          </a>
        </div>
        <div className={'rulesBlock'}>
          <a className={'text rulesBlockHeader'}>
            <FormattedMessage id={'gameplay'} />:
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'standard_deck'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'dragon_gameplay_3'} />
          </a>
        </div>
        <div className={'rulesBlock'}>
          <a className={'text rulesBlockHeader'}>
            <FormattedMessage id={'dealing'} />:
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'dragon_dealing'} />
          </a>
        </div>
        <div className={'rulesBlock'}>
          <a className={'text rulesBlockHeader'}>
            <FormattedMessage id={'determining_the_winner'} />:
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'dragon_determining_the_winner_1'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'dragon_determining_the_winner_2'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'dragon_determining_the_winner_3'} />
          </a>
        </div>
        <div className={'rulesBlock'}>
          <a className={'text rulesBlockHeader'}>
            <FormattedMessage id={'payouts'} />:
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'dragon_payouts_1'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'dragon_payouts_2'} />
          </a>
        </div>
      </div>
    </div>
  );
}
