import * as amplitude from '@amplitude/analytics-browser';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';

import * as Constants from '../../helpers/Constants';
import { socket } from '../../helpers/socket/SocketManager';
import { usePlayerState } from '../../Store';

import styles from './Game.module.scss';
import { placeBetLogic } from './modules/BetPanel';
import { fetchBets, getCoefColor } from './modules/BetsHistory';
import { PlaneImageWithAnimation } from './modules/planeAnimation/PlaneImageWithAnimation';
import { aviatorStore } from './Store';

export default function GameLogic() {
  const { player, changeBalance } = usePlayerState();
  const {
    aviatorStateMachine,
    setAviatorStateMachine,
    playState,
    setPlayState,
    planeAnimation,
    setPlaneAnimation,
    setDisplayState,
    addToAllBets,
    removeFromAllBets,
    changeBetFromAllBets,
    moveAllBetsToPreviousRound,
    lastIndexes,
    addLastIndex,
    setCashOutMessage,
    setMyBets,
    betPanelOne,
    setBetPanelOne,
    setBetPanelOneStatus,
    betPanelTwo,
    setBetPanelTwo,
    setBetPanelTwoStatus,
  } = aviatorStore();

  function stateMachine() {
    switch (aviatorStateMachine.state) {
      case Constants.aviator_state_machine_states.START_ROUND:
        moveAllBetsToPreviousRound(lastIndexes[0]);
        setPlayState({ coef: null });
        setPlaneAnimation({
          ...planeAnimation,
          visible: false,
        });
        break;

      case Constants.aviator_state_machine_states.PENDING_FOR_START: {
        setDisplayState({
          obj: (
            <>
              <PlaneImageWithAnimation
                height={'20%'}
                style={{ transform: 'rotate(23deg)', position: 'absolute', top: '20px' }}
              />
              <a className={styles.state} style={{ position: 'initial' }}>
                Waiting for next round
              </a>
              <div className={styles.progressBar}>
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: '100%' }}
                  transition={{ duration: 5, ease: 'linear' }}
                  className={styles.percents}
                ></motion.div>
              </div>
            </>
          ),
        });

        // // If auto bet enabled, send place_bet event
        // if (betPanelOne.autoBet && betPanelOne.status === Constants.aviator_bet_panel_states.default) {
        //   placeBetLogic(betPanelOne, setBetPanelOne, betPanelOne.autoBet);
        // }
        // if (betPanelTwo.autoBet && betPanelTwo.status === Constants.aviator_bet_panel_states.default) {
        //   placeBetLogic(betPanelTwo, setBetPanelTwo, betPanelTwo.autoBet);
        // }

        // If bet panel in pending status change it to place_bet
        if (betPanelOne.status === Constants.aviator_bet_panel_states.pending) {
          setBetPanelOne({ ...betPanelOne, status: Constants.aviator_bet_panel_states.bet_placed });
        }
        if (betPanelTwo.status === Constants.aviator_bet_panel_states.pending) {
          setBetPanelTwo({ ...betPanelTwo, status: Constants.aviator_bet_panel_states.bet_placed });
        }
        break;
      }
      case Constants.aviator_state_machine_states.START_FLYING:
        // Change Bet Panels states to cash out enabled
        if (betPanelOne.status === Constants.aviator_bet_panel_states.bet_placed) {
          setBetPanelOne({ ...betPanelOne, status: Constants.aviator_bet_panel_states.cash_out });
        }
        if (betPanelTwo.status === Constants.aviator_bet_panel_states.bet_placed) {
          setBetPanelTwo({ ...betPanelTwo, status: Constants.aviator_bet_panel_states.cash_out });
        }
        break;

      case Constants.aviator_state_machine_states.IN_PLAY:
        setPlaneAnimation({
          ...planeAnimation,
          visible: true,
          isEndFlying: false,
        });

        break;

      case Constants.aviator_state_machine_states.END_FLYING:
        setPlaneAnimation({
          ...planeAnimation,
          isEndFlying: true,
        });
        setDisplayState({
          obj: (
            <>
              <a className={styles.state}>Flew away!</a>
              <a className={styles.coef} style={{ color: 'var(--accent-system-pink)' }}>
                {playState.coef}x
              </a>
            </>
          ),
        });
        break;

      case Constants.aviator_state_machine_states.FINAL:
        if (betPanelOne.status === Constants.aviator_bet_panel_states.cash_out) {
          if (betPanelOne.autoBet && betPanelOne.bet < player.balance) {
            placeBetLogic(betPanelOne, setBetPanelOne, betPanelOne.autoBet);
          } else {
            setBetPanelOne({ ...betPanelOne, status: Constants.aviator_bet_panel_states.default });
          }
        }
        if (betPanelTwo.status === Constants.aviator_bet_panel_states.cash_out) {
          if (betPanelTwo.autoBet && betPanelTwo.bet < player.balance) {
            placeBetLogic(betPanelTwo, setBetPanelTwo, betPanelTwo.autoBet);
          } else {
            setBetPanelTwo({ ...betPanelTwo, status: Constants.aviator_bet_panel_states.default });
          }
        }
        break;

      case Constants.aviator_state_machine_states.END_ROUND:
        fetchBets(player.token!, setMyBets);
        playState.coef && addLastIndex(playState.coef);
        break;

      default:
        // console.warn('something wrong in state machine');
        break;
    }
  }

  useEffect(() => {
    stateMachine();
  }, [aviatorStateMachine]);

  useEffect(() => {
    const stateMachineListener = (roomState: any) => {
      setAviatorStateMachine({ state: roomState });
    };
    if (socket) socket.on('room_state', stateMachineListener);

    const matchListener = async (coef: any) => {
      await setPlayState({ coef: coef });
      setDisplayState({
        obj: (
          <>
            <motion.div className={styles.coefBg} animate={{ background: getCoefColor(coef) }} />
            <a className={styles.coef}>{coef}x</a>
          </>
        ),
      });
    };
    if (socket) socket.on('play', matchListener);

    const placeBet = (args: any) => {
      const data = tryToParseJSON(args);
      addToAllBets(data);
    };
    if (socket) socket.on('place_bet', placeBet);

    const placeBetError = async (args: any) => {
      const data = tryToParseJSON(args);
      console.log(data);
      if (Number(data.stake_id) === 1) {
        await changeBalance('+', data.stake);
        setBetPanelOneStatus({ status: Constants.aviator_bet_panel_states.default });
      } else if (Number(data.stake_id) === 2) {
        await changeBalance('+', data.stake);
        setBetPanelTwoStatus({ status: Constants.aviator_bet_panel_states.default });
      }
    };
    if (socket) socket.on('place_bet_error', placeBetError);

    const removeBet = (args: any) => {
      const data = tryToParseJSON(args);
      removeFromAllBets(data);
    };
    if (socket) socket.on('cancel_bet', removeBet);

    const cashOut = (args: any) => {
      const data = tryToParseJSON(args);
      changeBetFromAllBets(data);
    };
    if (socket) socket.on('cash_out', cashOut);

    const cashOutPrivate = async (args: any) => {
      const data = tryToParseJSON(args);
      setCashOutMessage(data);
      // changeBalance('set', data.balance);
      if (
        Number(data.stake_id) === 1
        // && aviatorStore.getState().betPanelOne.status === Constants.aviator_bet_panel_states.cash_out
      ) {
        setBetPanelOneStatus({ status: Constants.aviator_bet_panel_states.default });
        changeBalance('+', aviatorStore.getState().betPanelOne.bet * data.coef);
      } else if (
        Number(data.stake_id) === 2
        // && aviatorStore.getState().betPanelTwo.status === Constants.aviator_bet_panel_states.cash_out
      ) {
        setBetPanelTwoStatus({ status: Constants.aviator_bet_panel_states.default });
        changeBalance('+', aviatorStore.getState().betPanelTwo.bet * data.coef);
      }
      fetchBets(player.token!, setMyBets);
      amplitude.track('Cash_out_result', data);
    };
    if (socket) socket.on('cash_out_result', cashOutPrivate);
  }, [socket]);
}

export function StateMachineDevButtons() {
  const [setAviatorStateMachine] = aviatorStore((state: any) => [state.setAviatorStateMachine]);

  function changeState(state: any) {
    setAviatorStateMachine({
      state: state,
    });
  }

  return (
    <div
      style={{
        left: 0,
        // right: 0,
        justifyContent: 'center',
        display: 'flex',
        position: 'absolute',
        zIndex: 999,
        flexDirection: 'column',
      }}
    >
      <button onClick={() => changeState(Constants.aviator_state_machine_states.PENDING_FOR_START)}>
        PENDING_FOR_START
      </button>
      <button onClick={() => changeState(Constants.aviator_state_machine_states.START_FLYING)}>START_FLYING</button>
      <button onClick={() => changeState(Constants.aviator_state_machine_states.IN_PLAY)}>IN_PLAY</button>
      <button onClick={() => changeState(Constants.aviator_state_machine_states.END_FLYING)}>END_FLYING</button>
    </div>
  );
}

function tryToParseJSON(data: any) {
  try {
    data = JSON.parse(data.replace(/'/g, '"'));
    return data;
  } catch (e) {
    return data;
  }
}
