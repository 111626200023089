import * as amplitude from '@amplitude/analytics-browser';
import React, { useEffect, useState } from 'react';

import {
  BetButton,
  ButtonWithValue,
  Display,
  InitBetCalculator,
  SwitchButton,
  calculateMultiplyingCoefficient,
} from '../../../helpers/BetCalculator.new2';
import * as Constants from '../../../helpers/Constants';
import loader from '../../../helpers/dotsLoader.module.scss';
import { sendRequest, socket } from '../../../helpers/socket/SocketManager';
import { ModalWindowReasons } from '../../../panels/ModalWindow';
import { stateStore, usePlayerState } from '../../../Store';
import { aviatorStore } from '../Store';

import styles from './BetPanel.module.scss';

export async function placeBetLogic(store: any, setStore: any, autoBet?: boolean) {
  const _store = store;
  _store.loader = true;
  setStore(_store);

  if (usePlayerState.getState().player.balance < store.bet) {
    // lowBalanceStore.getState().setIsLowBalance(true); // TODO: ????disable low balance here and display another panel
    stateStore.getState().modalWindowState({
      isOpen: true,
      reason: ModalWindowReasons.info,
      infoTitle: 'Attention',
      infoMessage: 'Your balance is too low. Change bet please',
      infoButtonText: 'Close',
    });
    _store.loader = false;
    setStore(_store);
    return;
  }
  const socketParams = aviatorStore.getState().socketParams;
  const data = {
    stake: store.bet,
    stake_id: store.panelId,
    player_id: socketParams.player_id,
    room: socketParams.room,
    name: socketParams.name,
    auto_cash_out: store.autoCashOut.enable ? store.autoCashOut.value : 0,
  };
  const amplitude_data = { ...data, auto_bet: _store.autoBet };
  socket.emit('place_bet', JSON.stringify(data), async (callbackStatus: any) => {
    if (callbackStatus !== Constants.socket_events_callback_statuses.error) {
      _store.status =
        callbackStatus === Constants.socket_events_callback_statuses.success
          ? Constants.aviator_bet_panel_states.bet_placed
          : Constants.aviator_bet_panel_states.pending;
      _store.autoBet = autoBet ? autoBet : store.autoBet;
      await setStore(_store);
      await usePlayerState.getState().changeBalance('-', store.bet);
      amplitude.track('Place_bet', amplitude_data);
    } else {
      amplitude.track('Place_bet_error', amplitude_data);
    }

    _store.loader = false;
    setStore(_store);
  });
}

export async function cancelBetLogic(store: any, setStore: any) {
  const _store = store;
  _store.loader = true;
  setStore(_store);

  const socketParams = aviatorStore.getState().socketParams;
  const data = {
    stake: store.bet,
    stake_id: store.panelId,
    player_id: socketParams.player_id,
    room: socketParams.room,
    name: socketParams.name,
    auto_cash_out: store.autoCashOut.enable ? store.autoCashOut.value : 0,
  };
  socket.emit('cancel_bet', JSON.stringify(data), async (callbackStatus: any) => {
    if (callbackStatus !== Constants.socket_events_callback_statuses.error) {
      _store.status = Constants.aviator_bet_panel_states.default;
      _store.autoBet = false;
      setStore(_store);
      await usePlayerState.getState().changeBalance('+', store.bet);
      amplitude.track('Cancel_bet', data);
    } else if (aviatorStore.getState().aviatorStateMachine.state === Constants.aviator_state_machine_states.IN_PLAY) {
      _store.status = Constants.aviator_bet_panel_states.cash_out;
      amplitude.track('Cancel_bet_error', data);
    }

    _store.loader = false;
    setStore(_store);
  });
}

export async function cashOutBetLogic(store: any, setStore: any, playState: any, needSendEvent?: boolean) {
  const _store = store;
  _store.loader = true;
  setStore(_store);

  const socketParams = aviatorStore.getState().socketParams;
  const data = {
    coef: playState.coef ? playState.coef : '1.00',
    stake: store.bet,
    stake_id: store.panelId,
    player_id: socketParams.player_id,
    room: socketParams.room,
    name: socketParams.name,
  };
  if (needSendEvent) {
    await sendRequest('cash_out', data);
  }
  if (!store.autoBet && store.status !== Constants.aviator_bet_panel_states.default) {
    _store.status = Constants.aviator_bet_panel_states.default;
  }

  _store.loader = false;
  setStore(_store);

  amplitude.track('Cash_out_bet', data);
}

interface BetPanelPropsInterface {
  store: any;
  setStore: any;
  params: any;
  playState: any;
  betPanelId: number;
}

export default function BetPanel(betPanelProps: BetPanelPropsInterface) {
  useEffect(() => {
    InitBetCalculator({
      min_bet: betPanelProps.params.min_bet,
      max_bet: betPanelProps.params.max_bet,
      step: betPanelProps.params.step,
      currency: betPanelProps.params.currency,
    });
  }, [betPanelProps.params]);

  const [_betButton, set_betButton] = useState<React.JSX.Element>();
  const [_buttonsStyle, set_buttonsStyle] = useState<React.CSSProperties | undefined>();
  const [_switchStyle, set_switchStyle] = useState<React.CSSProperties | undefined>();
  const [_isDisplayNumPadOn, _setIsDisplayNumPadOn] = useState(false);
  const [_isSwitchNumPadOn, _setIsSwitchNumPadOn] = useState(false);
  const _unavailableStyle: React.CSSProperties = { opacity: 0.2, pointerEvents: 'none' };

  useEffect(() => {
    if (
      betPanelProps.store.status === Constants.aviator_bet_panel_states.bet_placed ||
      betPanelProps.store.status === Constants.aviator_bet_panel_states.pending ||
      betPanelProps.store.status === Constants.aviator_bet_panel_states.cash_out
    ) {
      _setIsDisplayNumPadOn(false);
      _setIsSwitchNumPadOn(false);
      _setAutoCashOutValue(betPanelProps.store.autoCashOut.value, true);
    } else if (
      betPanelProps.store.status === Constants.aviator_bet_panel_states.default &&
      betPanelProps.store.autoBet
    ) {
      if (betPanelProps.store.bet < usePlayerState.getState().player.balance) {
        placeBetLogic(betPanelProps.store, betPanelProps.setStore, betPanelProps.store.autoBet);
      } else {
        betPanelProps.setStore({ ...betPanelProps.store, bet: betPanelProps.params.min_bet, autoBet: false });
      }
    }
  }, [betPanelProps.store.status]);

  useEffect(() => {
    switch (betPanelProps.store.status) {
      case Constants.aviator_bet_panel_states.default:
        set_betButton(
          <BetButton
            text={'BET'}
            styles={`${styles['betButton']} ${styles['defaultBetButton']}`}
            betValue={betPanelProps.store.bet}
            onClick={() => {
              placeBetLogic(betPanelProps.store, betPanelProps.setStore);
            }}
          />,
        );
        set_buttonsStyle(undefined);
        set_switchStyle(undefined);
        break;

      case Constants.aviator_bet_panel_states.bet_placed:
      case Constants.aviator_bet_panel_states.pending:
        set_betButton(
          <BetButton
            text={'CANCEL'}
            styles={`${styles['betButton']} ${styles['cancelBetButton']}`}
            onClick={() => {
              cancelBetLogic(betPanelProps.store, betPanelProps.setStore);
            }}
          />,
        );
        set_switchStyle({ opacity: 0.2, pointerEvents: 'none' });
        break;

      case Constants.aviator_bet_panel_states.cash_out:
        set_betButton(
          <BetButton
            text={'CASH OUT'}
            styles={`${styles['betButton']} ${styles['cashOutBetButton']}`}
            betValue={betPanelProps.store.bet * betPanelProps.playState.coef}
            onClick={() => {
              cashOutBetLogic(betPanelProps.store, betPanelProps.setStore, betPanelProps.playState, true);
            }}
          />,
        );
        // _buttonsStyle = _switchStyle = _unavailableStyle;
        set_buttonsStyle(_unavailableStyle);
        set_switchStyle(_unavailableStyle);
        break;
    }
  }, [betPanelProps, betPanelProps.store.status]);

  function _setBet(value: number) {
    betPanelProps.setStore({ ...betPanelProps.store, bet: value });
  }

  function _setAutoBet(value: boolean) {
    if (value && betPanelProps.store.status === Constants.aviator_bet_panel_states.default) {
      placeBetLogic(betPanelProps.store, betPanelProps.setStore, value);
    } else {
      betPanelProps.setStore({ ...betPanelProps.store, autoBet: value });
    }
  }

  function _setAutoCashOut(value: boolean) {
    betPanelProps.setStore({
      ...betPanelProps.store,
      autoCashOut: { ...betPanelProps.store.autoCashOut, enable: value },
    });
    _setIsSwitchNumPadOn(false);
  }

  function _setAutoCashOutValue(value: any, isDoneClick?: boolean) {
    let _value = value;
    if (isDoneClick) {
      if (_value < betPanelProps.params.min_coef) {
        _value = betPanelProps.params.min_coef;
      }
      if (_value > betPanelProps.params.max_coef) {
        _value = betPanelProps.params.max_coef;
      }
      if (_value.toString().includes('.')) {
        _value = _value.toString().slice(0, _value.toString().indexOf('.') + 3);
      }
      _value = Number(_value).toFixed(2);
    }

    betPanelProps.setStore({
      ...betPanelProps.store,
      autoCashOut: { ...betPanelProps.store.autoCashOut, value: _value.toString() },
    });
  }

  function TextUnderButtons() {
    return (
      <div className={styles.betButtons}>
        <a className={styles.ButtonsUnderText}>Waiting for next round</a>
      </div>
    );
  }

  return (
    <>
      <div className={styles.betPanel}>
        {betPanelProps.store.loader && (
          <div className={styles.loader}>
            <div className={loader.loader} />
          </div>
        )}
        <div className={styles.header}>
          <Display
            balance={betPanelProps.params.balance}
            bet={betPanelProps.store.bet}
            setBet={_setBet}
            isButtonsHidden={betPanelProps.store.status !== Constants.aviator_bet_panel_states.default}
            needNumPad={betPanelProps.store.status === Constants.aviator_bet_panel_states.default}
            needNumPadDot={false}
            isNumPadOn={_isDisplayNumPadOn}
            setIsNumpadOn={_setIsDisplayNumPadOn}
          />
          <div style={_isDisplayNumPadOn || _isSwitchNumPadOn ? _unavailableStyle : {}} className={styles.betBtn}>
            {_betButton}
          </div>
        </div>
        <div className={styles.body}>
          {betPanelProps.store.status !== Constants.aviator_bet_panel_states.bet_placed &&
          betPanelProps.store.status !== Constants.aviator_bet_panel_states.pending ? (
            <Buttons buttonsStyle={_buttonsStyle} betPanelProps={betPanelProps} setBet={_setBet} />
          ) : (
            <TextUnderButtons />
          )}
        </div>
        <div className={styles.footer}>
          <SwitchButton
            isOn={betPanelProps.store.autoBet}
            onSwitchChange={_setAutoBet}
            id={Math.random().toString()}
            name={'Auto bet'}
            needDisplay={false}
          />
          <div style={_switchStyle}>
            <SwitchButton
              isOn={betPanelProps.store.autoCashOut.enable}
              onSwitchChange={_setAutoCashOut}
              id={Math.random().toString()}
              name={'Auto cash out'}
              needDisplay={true}
              displayValue={betPanelProps.store.autoCashOut.value}
              setDisplayValue={_setAutoCashOutValue}
              numPadNeedDot={true}
              isNumPadOn={_isSwitchNumPadOn}
              setIsNumpadOn={_setIsSwitchNumPadOn}
            />
          </div>
        </div>
      </div>
    </>
  );
}

interface btn {
  buttonsStyle: any;
  betPanelProps: any;
  setBet: any;
}

function Buttons(params: btn) {
  return (
    <div className={styles.betButtons} style={params.buttonsStyle}>
      <ButtonWithValue
        operation={'specific-bet'}
        value={10 * calculateMultiplyingCoefficient(params.betPanelProps.params.max_bet)}
        bet={params.betPanelProps.store.bet}
        setBet={params.setBet}
        balance={params.betPanelProps.params.balance}
      />
      <ButtonWithValue
        operation={'specific-bet'}
        value={20 * calculateMultiplyingCoefficient(params.betPanelProps.params.max_bet)}
        bet={params.betPanelProps.store.bet}
        setBet={params.setBet}
        balance={params.betPanelProps.params.balance}
      />
      <ButtonWithValue
        operation={'specific-bet'}
        value={50 * calculateMultiplyingCoefficient(params.betPanelProps.params.max_bet)}
        bet={params.betPanelProps.store.bet}
        setBet={params.setBet}
        balance={params.betPanelProps.params.balance}
      />
      <ButtonWithValue
        operation={'specific-bet'}
        value={100 * calculateMultiplyingCoefficient(params.betPanelProps.params.max_bet)}
        bet={params.betPanelProps.store.bet}
        setBet={params.setBet}
        balance={params.betPanelProps.params.balance}
      />
    </div>
  );
}
