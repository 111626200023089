import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import jokerPlaceholder from '../../assets/jokerPlaceholder.png';
import { ReactComponent as StarsSvg } from '../../assets/stars.svg';
import { BackgroundSound, ClickSound, LoseSound, WinSound } from '../../helpers/AudioHelper';
import { cardMap } from '../../helpers/CardsPreloader';
import { useClockState } from '../../helpers/Clock';
import { lucky_sides } from '../../helpers/Constants';
import * as Constants from '../../helpers/Constants';
import Firework from '../../helpers/Fireworks.module.scss';
import {
  activeFreeBetStore,
  luckyStatisticsStore,
  playRequestStore,
  stateStore,
  useGameParams,
  usePlayerState,
} from '../../Store';

import gameStyle from './Game.module.scss';

export default function Game() {
  const [playRequest, setPlayRequest] = playRequestStore((state: any) => [
    state.playLuckyRequest,
    state.setPlayLuckyRequest,
  ]);
  const [setLuckyStats] = luckyStatisticsStore((state: any) => [state.setLuckyStats]);
  const [luckyGame, luckyGameState] = stateStore((state: any) => [state.luckyGame, state.luckyGameState]);
  const luckyParams = useGameParams((state: any) => state.luckyParams);
  const [player, setPlayer] = usePlayerState((state: any) => [state.player, state.playerState]);
  const [sideColor, setSideColor] = useState('');
  const [sideName, setSideName] = useState('null');
  const clockState = useClockState((state: any) => state.clockState);

  function setBottomPanel(side: number) {
    switch (side) {
      case lucky_sides.seven_down:
        setSideColor('var(--button-system-orange)');
        setSideName('down');
        break;
      case lucky_sides.seven_up:
        setSideColor('var(--button-system-pink)');
        setSideName('up');
        break;
      case lucky_sides.seven:
        setSideColor('var(--button-system-blue)');
        setSideName('null');
        break;
    }
  }

  useEffect(() => {
    setBottomPanel(playRequest.side);

    firstCard = cards.shift();
    if (luckyGame.isOpen) {
      BackgroundSound('play');
      cardsDistributionAnimation().then(() => null);
    }
  }, [luckyGame.isOpen]);

  const [playLuckyResponse] = playRequestStore((state: any) => [state.playLuckyResponse]);

  const [finishGame, setFinishGame] = useState(false);
  const cards = playLuckyResponse.cards;
  const win = playLuckyResponse.win;
  const winSide = playLuckyResponse.win_side;
  let profit = Number(playLuckyResponse.profit);
  const status = playLuckyResponse.status;
  const balance = playLuckyResponse.balance;
  const currency = luckyParams.currency;
  const intl = useIntl();

  const [retrieveActiveFreeBets] = activeFreeBetStore((state: any) => [state.retrieveActiveFreeBets]);
  useEffect(() => {
    if (finishGame) {
      retrieveActiveFreeBets(player.token);
    }
  }, [finishGame]);

  const sleep = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));

  const [jokerCard, setJokerCard] = useState(null);

  async function jokerDistribution(joker: any) {
    setJokerAnimation(jokerAnimationVariants.rotateAgain);
    setJokerPlaceholderAnimation(jokerAnimationVariants.rotate);
    await sleep(800);
    setJokerAnimation(jokerAnimationVariants.rotate);
    setJokerPlaceholderAnimation(jokerAnimationVariants.rotateAgain);
    setJokerCard(cardMap().get(joker));
  }

  let firstCard = '';

  async function cardsDistributionAnimation() {
    await jokerDistribution(firstCard);
    await sleep(1000);
    setJokerAnimation(jokerAnimationVariants.opacity);
    await winAnimation();
    setFinishGame(true);
    await clockState({ visible: false });
    await setPlayer({ ...player, balance: balance });
    setLuckyStats({ winner: winSide });
    await sleep(2000);
    moveToLobby();
  }

  const [luckyLobby, luckyLobbyState] = stateStore((state: any) => [state.luckyLobby, state.setLuckyLobbyState]);

  function moveToLobby() {
    // ClickSound();
    // if (finishGame) {
    setFinishGame(false);
    clockState({ visible: true });
    setWinAnimationState(false);
    setPlayRequest({
      demo: false,
      side: -1,
      stake: 0,
    }).then(() => {
      luckyLobbyState({
        ...luckyLobby,
        isOpen: true,
        isStatisticsVisible: true,
      });
      luckyGameState({ ...luckyGame, isOpen: false });
      // BackgroundSound("stop");
    });
    // }
  }

  const [winAnimationState, setWinAnimationState] = useState(false);

  const [firstWinText, setFirstWinText] = useState<any>(null);
  const [secondWinText, setSecondWinText] = useState<any>(null);
  const [winBannerColor, setWinBannerColor] = useState('');

  async function winAnimation() {
    if (win) {
      setSecondWinText(
        <>
          <StarsSvg /> <FormattedMessage id={'you_win'} />{' '}
          {intl.formatNumber(Math.trunc(profit * 100) / 100, {
            style: 'currency',
            currency: currency ? currency : 'INR',
            minimumFractionDigits: 0,
          })}{' '}
          <StarsSvg />
        </>,
      );
      WinSound();
    } else {
      setSecondWinText('');
      LoseSound();
    }
    switch (winSide) {
      case lucky_sides.seven_down:
        setFirstWinText(
          <>
            {/*{win ? "" : "🫧"}*/}
            7 <FormattedMessage id={'down'} /> <FormattedMessage id={'won'} />
          </>,
        );
        if (win) {
          setWinBannerColor(
            'linear-gradient(90deg, color-mix(in srgb,var(--button-system-orange),#0000 100%) 0%, var(--button-system-orange) 50.52%, color-mix(in srgb,var(--button-system-orange),#0000 100%) 100%)',
          );
        } else {
          setWinBannerColor(
            'linear-gradient(90deg, rgba(26, 26, 26, 0.00) 0%, #1A1A1A 50.52%, rgba(26, 26, 26, 0.00) 100%)',
          );
        }
        break;
      case lucky_sides.seven_up:
        setFirstWinText(
          <>
            7 <FormattedMessage id={'up'} /> <FormattedMessage id={'won'} />
          </>,
        );
        if (win) {
          setWinBannerColor(
            'linear-gradient(90deg, color-mix(in srgb,var(--button-system-pink),#0000 100%) 0%, var(--button-system-pink) 50.52%, color-mix(in srgb,var(--button-system-pink),#0000 100%) 100%)',
          );
        } else {
          setWinBannerColor(
            'linear-gradient(90deg, rgba(26, 26, 26, 0.00) 0%, #1A1A1A 50.52%, rgba(26, 26, 26, 0.00) 100%)',
          );
        }
        break;
      case lucky_sides.seven:
        setFirstWinText(
          <>
            7 <FormattedMessage id={'won'} />
          </>,
        );
        if (win) {
          setWinBannerColor(
            'linear-gradient(90deg, color-mix(in srgb,var(--button-system-blue),#0000 100%) 0%, var(--button-system-blue) 50.52%, color-mix(in srgb,var(--button-system-blue),#0000 100%) 100%)',
          );
        } else {
          setWinBannerColor(
            'linear-gradient(90deg, rgba(26, 26, 26, 0.00) 0%, #1A1A1A 50.52%, rgba(26, 26, 26, 0.00) 100%)',
          );
        }
        break;
    }
    setWinAnimationState(true);
    // await sleep(800);
  }

  const [jokerAnimation, setJokerAnimation] = useState<any>();
  const [jokerPlaceholderAnimation, setJokerPlaceholderAnimation] = useState<any>();

  const jokerAnimationVariants = {
    rotate: {
      rotateY: 0,
      opacity: 1,
      transition: { duration: 0.4 },
    },
    rotateAgain: {
      rotateY: -180,
      opacity: 0,
      transition: { duration: 0.4 },
    },
    scaling: {
      rotateY: 0,
      opacity: 1,
      scale: [1, 1.1, 1, 1.1, 1],
      transition: { duration: 1.5 },
    },
    opacity: {
      opacity: 0.4,
      rotateY: 0,
      transition: { duration: 0.4 },
    },
  };

  // freebet block

  const [currentFreeBet] = activeFreeBetStore((state: any) => [state.currentActiveFreeBet]);

  // find freebet id in url
  const urlParams = new URLSearchParams(window.location.search);
  const freebet_id = urlParams.get('freebet_id');
  // param to freebet play
  const [isFreeBetPlay, setIsFreeBetPlay] = stateStore((state: any) => [
    state.freebetLobbyState,
    state.setFreebetLobbyState,
  ]);
  const setIsSowClaim = useState(false)[1];

  if (isFreeBetPlay.status === Constants.freebetPlay.play || isFreeBetPlay.status === Constants.freebetPlay.claim) {
    profit = playLuckyResponse.profit;
  }

  const isFreeBetPlayHandler = () => {
    setIsSowClaim(false);
    const freeBet = currentFreeBet;
    const hasBet = Number(freeBet.freebets_amount) - Number(freeBet.freebets_played);
    if (freeBet.freebet_id && hasBet > 0) {
      setIsFreeBetPlay({ status: Constants.freebetPlay.play });
    } else if (freeBet.freebet_id && hasBet === 0) {
      setIsFreeBetPlay({ status: Constants.freebetPlay.claim });
    } else if (freeBet.freebet_id === undefined) {
      setIsFreeBetPlay({ status: Constants.freebetPlay.load });
    } else {
      setIsFreeBetPlay({ status: Constants.freebetPlay.notPlay });
    }

    if (status === Constants.freeBetStatus.claimed) {
      setIsFreeBetPlay({ status: Constants.freebetPlay.claim });
    }
  };

  useEffect(() => {
    if (freebet_id) {
      isFreeBetPlayHandler();
    }
  }, [currentFreeBet]);
  // end freebet block

  return (
    // <div className={gameStyle.game} style={{ display: luckyGame.isOpen ? "flex" : "none" }}>
    <motion.div
      className={gameStyle.game}
      // style={{ display: dragonGame.isOpen ? "flex" : "none" }}
      // initial={{y: window.innerHeight}}
      // animate={luckyGame.isOpen ? {y: 0} : {y: window.innerHeight}}
      // transition={{duration: 0.3}}
    >
      <div className={gameStyle.container}>
        <div className={gameStyle.gameFieldPosition}>
          {/*<Statistics isLobby={true} />*/}
          <div className={gameStyle.cardBg}>
            <div className={gameStyle.jokerFieldPosition}>
              <motion.img
                src={jokerCard as any}
                className={gameStyle.jokerCard}
                initial={{ opacity: 0, rotateY: -180 }}
                animate={jokerAnimation}
                style={{ opacity: finishGame ? 0.4 : 1 }}
              />
              <motion.img
                src={jokerPlaceholder}
                className={gameStyle.jokerPlaceholder}
                animate={jokerPlaceholderAnimation}
              />
            </div>
            <motion.div
              className={gameStyle.winBanner}
              style={{ background: winBannerColor }}
              initial={{ opacity: 0 }}
              animate={
                winAnimationState
                  ? {
                      opacity: 1,
                      transition: { duration: 0.4 },
                    }
                  : undefined
              }
            >
              {win ? (
                <div className={Firework.pyro} style={{ width: '100%' }}>
                  <div className={Firework.before}></div>
                  <div className={Firework.after}></div>
                </div>
              ) : (
                ''
              )}
              <a className={gameStyle.winText}>{firstWinText}</a>
              <a className={gameStyle.winTextSecond}>{secondWinText}</a>
              {/* {isFreeBetPlay.status == Constants.freebetPlay.play ||
                            isFreeBetPlay.status == Constants.freebetPlay.claim ? (
                                <FreeBetsLobby
                                    currentFreeBet={currentFreeBet}
                                    openLobby={moveToLobby}
                                    style={"gameStyle"}
                                />
                            ) : (
                                ""
                            )} */}
            </motion.div>
          </div>
        </div>

        <div className={gameStyle.changeBetButtons}>
          {/* TODO: FIX BUTTON. JIRA: https://bolo-bhai.atlassian.net/browse/BOL-294?atlOrigin=eyJpIjoiMzdkOGM0OGI0ZDZmNDZmMTliOGQ5MDNiZWE4MmMyOGQiLCJwIjoiaiJ9*/}
          <motion.div
            className={gameStyle.rebetButton}
            style={{
              background: 'var(--button-system-light-green)',
              visibility: 'hidden',
            }}
            animate={{ scale: [1, 0.98, 1] }}
            transition={{ duration: 1, repeat: Infinity }}
            onClick={async () => {
              ClickSound();
            }}
          >
            <div className={gameStyle.rebetButtonTextPosition}>
              <a className={gameStyle.rebetButtonText}>
                <FormattedMessage id={'continue'} />
              </a>
            </div>
          </motion.div>
        </div>
        {/* TODO: FIRST VARIANT OF REBET BUTTONS */}
        {/*<div className={gameStyle.changeBetButtons}>*/}
        {/*    <div*/}
        {/*        className={gameStyle.rebetButton}*/}
        {/*        style={{ background: "var(--button-system-light-green)" }}*/}
        {/*        onClick={() => {*/}
        {/*            isFreeBetPlay.status === Constants.freebetPlay.notPlay*/}
        {/*                ? rebetGame(lucky_sides.seven_up)*/}
        {/*                : isFreeBetPlay.status === Constants.freebetPlay.claim*/}
        {/*                ? setIsSowClaim(true)*/}
        {/*                : rebetFreeBetGame(lucky_sides.seven_up);*/}
        {/*        }}*/}
        {/*    >*/}
        {/*        <div className={gameStyle.rebetButtonTextPosition}>*/}
        {/*            <a className={`${gameStyle["text"]} ${gameStyle["rebetButtonCoefTextPosition"]}`}>*/}
        {/*                {luckyParams.seven_up_coef}:1*/}
        {/*            </a>*/}
        {/*            <a className={gameStyle.rebetButtonText}>*/}
        {/*                7 <FormattedMessage id="up" />*/}
        {/*            </a>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*        className={gameStyle.rebetButton}*/}
        {/*        style={{ background: "var(--button-system-blue)" }}*/}
        {/*        onClick={() => {*/}
        {/*            isFreeBetPlay.status === Constants.freebetPlay.notPlay*/}
        {/*                ? rebetGame(lucky_sides.seven)*/}
        {/*                : isFreeBetPlay.status === Constants.freebetPlay.claim*/}
        {/*                ? setIsSowClaim(true)*/}
        {/*                : rebetFreeBetGame(lucky_sides.seven);*/}
        {/*        }}*/}
        {/*    >*/}
        {/*        <div className={gameStyle.rebetButtonTextPosition}>*/}
        {/*            <a className={`${gameStyle["text"]} ${gameStyle["rebetButtonCoefTextPosition"]}`}>*/}
        {/*                {luckyParams.seven_coef}:1*/}
        {/*            </a>*/}
        {/*            <a className={gameStyle.rebetButtonText}>7</a>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*        className={gameStyle.rebetButton}*/}
        {/*        style={{ background: "var(--button-system-pink)" }}*/}
        {/*        onClick={() => {*/}
        {/*            isFreeBetPlay.status === Constants.freebetPlay.notPlay*/}
        {/*                ? rebetGame(lucky_sides.seven_down)*/}
        {/*                : isFreeBetPlay.status === Constants.freebetPlay.claim*/}
        {/*                ? setIsSowClaim(true)*/}
        {/*                : rebetFreeBetGame(lucky_sides.seven_down);*/}
        {/*        }}*/}
        {/*    >*/}
        {/*        <div className={gameStyle.rebetButtonTextPosition}>*/}
        {/*            <a className={`${gameStyle["text"]} ${gameStyle["rebetButtonCoefTextPosition"]}`}>*/}
        {/*                {luckyParams.seven_down_coef}:1*/}
        {/*            </a>*/}
        {/*            <a className={gameStyle.rebetButtonText}>*/}
        {/*                7 <FormattedMessage id="down" />*/}
        {/*            </a>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}

        <motion.div
          className={gameStyle.betBg}
          style={{ background: sideColor }}
          initial={{ y: 0 }}
          animate={
            finishGame
              ? {
                  y: 84,
                  transition: { duration: 0.5 },
                }
              : undefined
          }
        >
          <a
            className={gameStyle.cardText}
            style={{
              margin: 'inherit',
              color: 'var(--text-uninvertible-white)',
              textTransform: 'capitalize',
            }}
          >
            <FormattedMessage id="you_bet_on" />: 7 <FormattedMessage id={sideName} />
          </a>
          <div className={gameStyle.betTextPosition}>
            <a className={gameStyle.placedBetText}>{/* <FormattedMessage id={'amount'} /> */}Total bet:</a>
            <a className={gameStyle.placedBetText} style={{ fontWeight: '700' }}>
              {intl.formatNumber(Math.trunc(playRequest.stake * 100) / 100, {
                style: 'currency',
                currency: currency ? currency : 'INR',
                minimumFractionDigits: 0,
              })}
              {isFreeBetPlay.status === Constants.freebetPlay.play ||
              isFreeBetPlay.status === Constants.freebetPlay.claim
                ? ' Free Bet '
                : ''}
            </a>
            {/* <a className={gameStyle.placedBetText}>
              <FormattedMessage id="to_win" />
            </a>
            <a className={gameStyle.placedBetText} style={{ fontWeight: '700' }}>
              {isFreeBetPlay.status === Constants.freebetPlay.play ||
              isFreeBetPlay.status === Constants.freebetPlay.claim
                ? intl.formatNumber(
                    playRequest.side === lucky_sides.seven_up
                      ? Math.trunc(playRequest.stake * luckyParams.seven_up_coef * 100) / 100
                      : playRequest.side === lucky_sides.seven_down
                        ? Math.trunc(playRequest.stake * luckyParams.seven_down_coef * 100) / 100
                        : Math.trunc(playRequest.stake * luckyParams.seven_coef * 100) / 100,
                    {
                      style: 'currency',
                      currency: currency ? currency : 'INR',
                      minimumFractionDigits: 0,
                    },
                  )
                : intl.formatNumber(
                    playRequest.side === lucky_sides.seven_up
                      ? Math.trunc((playRequest.stake + playRequest.stake * luckyParams.seven_up_coef) * 100) / 100
                      : playRequest.side === lucky_sides.seven_down
                        ? Math.trunc((playRequest.stake + playRequest.stake * luckyParams.seven_down_coef) * 100) / 100
                        : Math.trunc((playRequest.stake + playRequest.stake * luckyParams.seven_coef) * 100) / 100,
                    {
                      style: 'currency',
                      currency: currency ? currency : 'INR',
                      minimumFractionDigits: 0,
                    },
                  )}
            </a> */}
          </div>
        </motion.div>
        {/* TODO: FIRST VARIANT WITH CHANGE BET BUTTON IN HEADER*/}
        {/*{isFreeBetPlay.status !== Constants.freebetPlay.play ? (*/}
        {/*    <motion.div*/}
        {/*        className={gameStyle.headerChangeBetButton}*/}
        {/*        animate={*/}
        {/*            !finishGame*/}
        {/*                ? {*/}
        {/*                      display: "none",*/}
        {/*                      opacity: 0,*/}
        {/*                  }*/}
        {/*                : {*/}
        {/*                      display: "flex",*/}
        {/*                      opacity: 1,*/}
        {/*                  }*/}
        {/*        }*/}
        {/*    >*/}
        {/*        <div*/}
        {/*            onClick={() => {*/}
        {/*                isFreeBetPlay.status == Constants.freebetPlay.claim*/}
        {/*                    ? setIsSowClaim(true)*/}
        {/*                    : moveToLobby();*/}
        {/*            }}*/}
        {/*            className={gameStyle.changeBetButton}*/}
        {/*        >*/}
        {/*            <a className={"text"}>*/}
        {/*                <FormattedMessage id={"change_bet"} />*/}
        {/*            </a>*/}
        {/*        </div>*/}
        {/*    </motion.div>*/}
        {/*) : (*/}
        {/*    ""*/}
        {/*)}*/}
      </div>
    </motion.div>
  );
}
