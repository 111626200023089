import { motion } from 'framer-motion';
import React from 'react';

import { ReactComponent as FilterArrowDown } from '../../../assets/filterArrowUp.svg';
import { BaccaratStateStore, useBaccaratParams } from '../BaccaratStore';
import styles2 from '../Game.module.scss';
import styles1 from '../Lobby.module.scss';

interface PlayerColumnProps {
  activeColumn: any;
  activePair: any;
  playerStake?: any;
  onClick?: () => void;
  totalStake: number;
}

export const PlayerColumn: React.FC<PlayerColumnProps> = ({
  activeColumn,
  activePair,
  playerStake,
  onClick,
  totalStake,
}) => {
  const styles = { ...styles1, ...styles2 };
  const isActive = activeColumn === 'player' || activePair === 'playerTie';
  const isBetPlaced = playerStake > 0;
  const baccaratGame = BaccaratStateStore((state: any) => state.baccaratGame);
  const isGameOpen = baccaratGame.isOpen;
  const [baccaratParams] = useBaccaratParams((state: any) => [state.baccaratParams]);

  return (
    <div className={styles.betColumn + ' ' + `${isActive ? styles.activePlayer : ''}`} onClick={onClick}>
      <div className={styles.betTitle}>player</div>
      {(isActive || isBetPlaced) && (
        <div
          className={styles.betValue}
          style={{
            color: !isActive && isBetPlaced ? 'var(--button-system-pink)' : 'white',
          }}
        >
          {playerStake >= 0 && <div className={styles.placeBetWithStake}>PLACED BET</div>} ₹{playerStake}{' '}
        </div>
      )}
      {!isActive && !isBetPlaced && (
        <div className={styles.placeBet + ' ' + `${playerStake > 0 || isGameOpen ? styles.placeBetWithStak : ''}`}>
          {!totalStake &&
            !isActive &&
            !playerStake &&
            !isBetPlaced &&
            activeColumn !== 'tie' &&
            activeColumn !== 'banker' && (
              <motion.div
                className={styles.arrows}
                animate={{
                  x: [-5, 1, -5],
                  y: 0,
                  transition: {
                    duration: 1,
                    repeat: Infinity,
                  },
                }}
              >
                <FilterArrowDown
                  width={'14px'}
                  height={'14px'}
                  fill={'var(--button-system-pink)'}
                  style={{ transform: 'rotate(90deg)' }}
                />
              </motion.div>
            )}
          {isGameOpen ? 'No Bet' : 'Place Bet'}
          {!totalStake &&
            !isActive &&
            !playerStake &&
            !isBetPlaced &&
            activeColumn !== 'tie' &&
            activeColumn !== 'banker' && (
              <motion.div
                className={styles.arrows}
                animate={{
                  x: [5, -1, 5],
                  y: 0,
                  transition: {
                    duration: 1,
                    repeat: Infinity,
                  },
                }}
              >
                <FilterArrowDown
                  width={'14px'}
                  height={'14px'}
                  fill={'var(--button-system-pink)'}
                  style={{ transform: 'rotate(-90deg)' }}
                />
              </motion.div>
            )}
        </div>
      )}
      <div className={styles.odds}>{baccaratParams.player_coef}:1</div>
    </div>
  );
};
