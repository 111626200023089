const card_2C = require('../assets/cards/2C.png');
const card_2D = require('../assets/cards/2D.png');
const card_2H = require('../assets/cards/2H.png');
const card_2S = require('../assets/cards/2S.png');
const card_3C = require('../assets/cards/3C.png');
const card_3D = require('../assets/cards/3D.png');
const card_3H = require('../assets/cards/3H.png');
const card_3S = require('../assets/cards/3S.png');
const card_4C = require('../assets/cards/4C.png');
const card_4D = require('../assets/cards/4D.png');
const card_4H = require('../assets/cards/4H.png');
const card_4S = require('../assets/cards/4S.png');
const card_5C = require('../assets/cards/5C.png');
const card_5D = require('../assets/cards/5D.png');
const card_5H = require('../assets/cards/5H.png');
const card_5S = require('../assets/cards/5S.png');
const card_6C = require('../assets/cards/6C.png');
const card_6D = require('../assets/cards/6D.png');
const card_6H = require('../assets/cards/6H.png');
const card_6S = require('../assets/cards/6S.png');
const card_7C = require('../assets/cards/7C.png');
const card_7D = require('../assets/cards/7D.png');
const card_7H = require('../assets/cards/7H.png');
const card_7S = require('../assets/cards/7S.png');
const card_8C = require('../assets/cards/8C.png');
const card_8D = require('../assets/cards/8D.png');
const card_8H = require('../assets/cards/8H.png');
const card_8S = require('../assets/cards/8S.png');
const card_9C = require('../assets/cards/9C.png');
const card_9D = require('../assets/cards/9D.png');
const card_9H = require('../assets/cards/9H.png');
const card_9S = require('../assets/cards/9S.png');
const card_AC = require('../assets/cards/AC.png');
const card_AD = require('../assets/cards/AD.png');
const card_AH = require('../assets/cards/AH.png');
const card_AS = require('../assets/cards/AS.png');
const card_JC = require('../assets/cards/JC.png');
const card_JD = require('../assets/cards/JD.png');
const card_JH = require('../assets/cards/JH.png');
const card_JS = require('../assets/cards/JS.png');
const card_KC = require('../assets/cards/KC.png');
const card_KD = require('../assets/cards/KD.png');
const card_KH = require('../assets/cards/KH.png');
const card_KS = require('../assets/cards/KS.png');
const card_QC = require('../assets/cards/QC.png');
const card_QD = require('../assets/cards/QD.png');
const card_QH = require('../assets/cards/QH.png');
const card_QS = require('../assets/cards/QS.png');
const card_TC = require('../assets/cards/TC.png');
const card_TD = require('../assets/cards/TD.png');
const card_TH = require('../assets/cards/TH.png');
const card_TS = require('../assets/cards/TS.png');

export const cardMap = () => {
  const cardsMap = new Map();
  cardsMap.set('AC', card_AC);
  cardsMap.set('AD', card_AD);
  cardsMap.set('AH', card_AH);
  cardsMap.set('AS', card_AS);
  cardsMap.set('2C', card_2C);
  cardsMap.set('2D', card_2D);
  cardsMap.set('2H', card_2H);
  cardsMap.set('2S', card_2S);
  cardsMap.set('3C', card_3C);
  cardsMap.set('3D', card_3D);
  cardsMap.set('3H', card_3H);
  cardsMap.set('3S', card_3S);
  cardsMap.set('4C', card_4C);
  cardsMap.set('4D', card_4D);
  cardsMap.set('4H', card_4H);
  cardsMap.set('4S', card_4S);
  cardsMap.set('5C', card_5C);
  cardsMap.set('5D', card_5D);
  cardsMap.set('5H', card_5H);
  cardsMap.set('5S', card_5S);
  cardsMap.set('6C', card_6C);
  cardsMap.set('6D', card_6D);
  cardsMap.set('6H', card_6H);
  cardsMap.set('6S', card_6S);
  cardsMap.set('7C', card_7C);
  cardsMap.set('7D', card_7D);
  cardsMap.set('7H', card_7H);
  cardsMap.set('7S', card_7S);
  cardsMap.set('8C', card_8C);
  cardsMap.set('8D', card_8D);
  cardsMap.set('8H', card_8H);
  cardsMap.set('8S', card_8S);
  cardsMap.set('9C', card_9C);
  cardsMap.set('9D', card_9D);
  cardsMap.set('9H', card_9H);
  cardsMap.set('9S', card_9S);
  cardsMap.set('TC', card_TC);
  cardsMap.set('TD', card_TD);
  cardsMap.set('TH', card_TH);
  cardsMap.set('TS', card_TS);
  cardsMap.set('JC', card_JC);
  cardsMap.set('JD', card_JD);
  cardsMap.set('JH', card_JH);
  cardsMap.set('JS', card_JS);
  cardsMap.set('QC', card_QC);
  cardsMap.set('QD', card_QD);
  cardsMap.set('QH', card_QH);
  cardsMap.set('QS', card_QS);
  cardsMap.set('KC', card_KC);
  cardsMap.set('KD', card_KD);
  cardsMap.set('KH', card_KH);
  cardsMap.set('KS', card_KS);
  return cardsMap;
};

export const cardsList = [
  // card_AC, card_AD, card_AH, card_AS,
  // card_2C, card_2D, card_2H, card_2S,
  // card_3C, card_3D, card_3H, card_3S,
  // card_4C, card_4D, card_4H, card_4S,
  // card_5C, card_5D, card_5H, card_5S,
  // card_2C, card_2D, card_2H, card_2S,
  // card_2C, card_2D, card_2H, card_2S,
  // card_2C, card_2D, card_2H, card_2S,
  // card_2C, card_2D, card_2H, card_2S,
  // card_2C, card_2D, card_2H, card_2S,

  card_2D,
  card_3D,
  card_4D,
  card_5D,
  card_6D,
  card_7D,
  card_8D,
  card_9D,
  card_TD,
  card_JD,
  card_QD,
  card_KD,
  card_AD,

  card_2H,
  card_3H,
  card_4H,
  card_5H,
  card_6H,
  card_7H,
  card_8H,
  card_9H,
  card_TH,
  card_JH,
  card_QH,
  card_KH,
  card_AH,

  card_2S,
  card_3S,
  card_4S,
  card_5S,
  card_6S,
  card_7S,
  card_8S,
  card_9S,
  card_TS,
  card_JS,
  card_QS,
  card_KS,
  card_AS,

  card_2C,
  card_3C,
  card_4C,
  card_5C,
  card_6C,
  card_7C,
  card_8C,
  card_9C,
  card_TC,
  card_JC,
  card_QC,
  card_KC,
  card_AC,
];
