/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';

import { ReactComponent as StarsSvg } from '../../assets/stars.svg';
import { BackgroundSound, ClickSound, LoseSound, WinSound } from '../../helpers/AudioHelper';
import { baccarat_side } from '../../helpers/Constants';
import { usePlayerState } from '../../Store';

import useAutoplayStore, {
  BaccaratStateStore,
  CardsStore,
  baccaratBetsStore,
  baccaratPlayRequestStore,
  useColumnState,
  useFireworkStore,
  useSideValueStore,
} from './BaccaratStore';
import { BankerColumn } from './Columns/BankerColumn';
import { PlayerColumn } from './Columns/PlayerColumn';
import TieColumn from './Columns/TieColumn';
import styles from './Game.module.scss';
import { ResultAnimation } from './ResultAnimation';

type Prop = {
  startGame?: any;
  playButtonDisabled: boolean;
  setPlayButtonDisabled: (value: boolean) => void;
};

export const Game = (props: Prop) => {
  const [defaultGameNumbers, remainingGames, setRemainingGames, lossLimitValue, startingBalance] = useAutoplayStore(
    (state: any) => [
      state.defaultGameNumbers,
      state.remainingGames,
      state.setRemainingGames,
      state.lossLimitValue,
      state.startingBalance,
    ],
  );

  const [winAnimationState, setWinAnimationState] = useState(false);
  const [firstWinText, setFirstWinText] = useState<any>();
  const [secondWinText] = useState<any>();
  const [winBannerColor, setWinBannerColor] = useState('');
  const [setIsFireworkShown] = useFireworkStore((state: any) => [state.setIsFireworkShown]);
  const [baccaratGame, setBaccaratGame] = BaccaratStateStore((state: any) => [
    state.baccaratGame,
    state.setBaccaratGame,
  ]);
  const [playBaccaratResponse] = baccaratPlayRequestStore((state: any) => [state.playBaccaratResponse]);
  const [player, setPlayer] = usePlayerState((state: any) => [state.player, state.playerState]);

  const [clearCards, cardsBottomDistribution, cardsTopDistribution] = CardsStore((state: any) => [
    state.clearCards,
    state.cardsBottomDistribution,
    state.cardsTopDistribution,
  ]);

  const [activeColumn, activePair, setActiveColumn, setActivePair] = useColumnState((state: any) => [
    state.activeColumn,
    state.activePair,
    state.setActiveColumn,
    state.setActivePair,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [baccaratBetss, clearStakes] = baccaratBetsStore((state: any) => [state.baccaratBetss, state.clearStakes]);

  const totalStake = baccaratBetss[0].stake + baccaratBetss[1].stake + baccaratBetss[2].stake;
  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  const balance = playBaccaratResponse.balance;
  const win = playBaccaratResponse.total_win;
  const [isAutoplayStopped, setIsAutoplayStopped] = useAutoplayStore((state: any) => [
    state.isAutoplayStopped,
    state.setIsAutoplayStopped,
  ]);

  const [sideValue] = useSideValueStore((state: any) => [state.sideValue]);

  const handleStopAutoplay = () => {
    ClickSound();
    setIsAutoplayStopped(true);
  };

  const [countBottom, setCountBottom] = useState(0);
  const [countTop, setCountTop] = useState(0);

  async function countTopValue(cards: any) {
    for (let i = 0; i < cards.length; i++) {
      const cardValue = cards[i][0];

      let valueToAdd = 0;
      if (!isNaN(cardValue)) {
        valueToAdd = parseInt(cardValue);
      } else if (cardValue === 'A') {
        valueToAdd = 1;
      } else if (!['K', 'T', 'Q', 'J'].includes(cardValue)) {
        valueToAdd = 0;
      }
      setCountTop((prevCountTop: any) => (prevCountTop + valueToAdd) % 10);
    }
  }

  async function countBottomValue(cards: any) {
    for (let i = 0; i < cards.length; i++) {
      const cardValue = cards[i][0];

      let valueToAdd = 0;
      if (!isNaN(cardValue)) {
        valueToAdd = parseInt(cardValue);
      } else if (cardValue === 'A') {
        valueToAdd = 1;
      } else if (!['K', 'T', 'Q', 'J'].includes(cardValue)) {
        valueToAdd = 0;
      }
      setCountBottom((prevCountBottom: any) => (prevCountBottom + valueToAdd) % 10);
    }
  }

  async function cardsDistributionAnimation(cards: any) {
    const hasPlayerThirdCard = cards[0].length >= 3;
    const hasDealerThirdCard = cards[1].length >= 3;
    await cardsBottomDistribution(cards[0].slice(0, 1));
    await countBottomValue(cards[0].slice(0, 1));
    await cardsBottomDistribution(cards[0].slice(1, 2));
    await countBottomValue(cards[0].slice(1, 2));
    await cardsTopDistribution(cards[1].slice(0, 1));
    await countTopValue(cards[1].slice(0, 1));
    await cardsTopDistribution(cards[1].slice(1, 2));
    await countTopValue(cards[1].slice(1, 2));
    await cardsBottomDistribution(cards[0].slice(2, hasPlayerThirdCard ? 3 : 2));
    await countBottomValue(cards[0].slice(2, hasPlayerThirdCard ? 3 : 2));
    await cardsTopDistribution(cards[1].slice(2, hasDealerThirdCard ? 3 : 2));
    await countTopValue(cards[1].slice(2, hasDealerThirdCard ? 3 : 2));
    await winAnimation();
    await setPlayer({ ...player, balance: balance });
    await sleep(3000);
    moveToLobby();
  }

  function moveToLobby() {
    setCountTop(0);
    setCountBottom(0);
    setWinAnimationState(false);
    setActiveColumn(null);
    setActivePair(null);
    clearCards();
    setBaccaratGame({
      ...baccaratGame,
      isOpen: false,
      isAutoBet: false,
    });
    // clearStakes();
    props.setPlayButtonDisabled(false);
    setIsFireworkShown(false);
  }

  useEffect(() => {
    setRemainingGames(defaultGameNumbers);
  }, [defaultGameNumbers]);

  useEffect(() => {
    if (baccaratGame.isOpen && !baccaratGame.isAutoBet) {
      BackgroundSound('play');
      cardsDistributionAnimation(playBaccaratResponse.cards).then(() => {
        return;
      });
    }
  }, [baccaratGame.isOpen]);

  useEffect(() => {
    if (baccaratGame.isOpen && baccaratGame.isAutoBet && !isAutoplayStopped) {
      BackgroundSound('play');
      handleAutoBet(playBaccaratResponse.cards);
    }
  }, [baccaratGame.isOpen, playBaccaratResponse.cards, isAutoplayStopped]);

  useEffect(() => {
    if (
      baccaratGame.isOpen &&
      baccaratGame.isAutoBet &&
      !isAutoplayStopped &&
      remainingGames > 0 &&
      startingBalance - lossLimitValue < player.balance
    ) {
      if (!isAutoplayStopped) {
        props.startGame(sideValue);
      }
    } else if (remainingGames < 1 || isAutoplayStopped || startingBalance - lossLimitValue >= player.balance) {
      moveToLobby();
      setIsAutoplayStopped(false);
      setRemainingGames(defaultGameNumbers);
    }
  }, [remainingGames]);

  const handleAutoBet = async (cards: any) => {
    const hasPlayerThirdCard = cards[0].length >= 3;
    const hasDealerThirdCard = cards[1].length >= 3;
    await cardsBottomDistribution(cards[0].slice(0, 1));
    await countBottomValue(cards[0].slice(0, 1));
    await cardsBottomDistribution(cards[0].slice(1, 2));
    await countBottomValue(cards[0].slice(1, 2));
    await cardsTopDistribution(cards[1].slice(0, 1));
    await countTopValue(cards[1].slice(0, 1));
    await cardsTopDistribution(cards[1].slice(1, 2));
    await countTopValue(cards[1].slice(1, 2));
    await cardsBottomDistribution(cards[0].slice(2, hasPlayerThirdCard ? 3 : 2));
    await countBottomValue(cards[0].slice(2, hasPlayerThirdCard ? 3 : 2));
    await cardsTopDistribution(cards[1].slice(2, hasDealerThirdCard ? 3 : 2));
    await countTopValue(cards[1].slice(2, hasDealerThirdCard ? 3 : 2));
    await winAnimation();
    await setPlayer({ ...player, balance: balance });
    await sleep(5000);
    setIsFireworkShown(false);
    setWinAnimationState(false);
    setActiveColumn(null);
    setActivePair(null);
    clearCards();
    setRemainingGames(remainingGames - 1);
    setCountTop(0);
    setCountBottom(0);
  };

  async function winAnimation() {
    const totalAmount = parseInt(playBaccaratResponse.total_profit);

    if (win && playBaccaratResponse.win_side == baccarat_side.player) {
      setIsFireworkShown(true);
      setFirstWinText(
        <>
          <StarsSvg />
          {/* <FormattedMessage id={'Player'} />{' '} */}
          {/* {intl.formatNumber(Math.trunc(totalAmount), {
            style: 'currency',
            currency: currency ? currency : 'INR',
            minimumFractionDigits: 2,
          })}{' '} */}{' '}
          You Won ₹{totalAmount}
          ! <StarsSvg />
        </>,
      );
      setWinBannerColor(
        'linear-gradient(90deg, color-mix(in srgb,var(--button-system-blue),#0000 100%) 0%, var(--button-system-blue) 50.52%, color-mix(in srgb,var(--button-system-blue),#0000 100%) 100%)',
      );
      WinSound();
    } else if (win && playBaccaratResponse.win_side == baccarat_side.banker) {
      setIsFireworkShown(true);
      setFirstWinText(
        <>
          <StarsSvg />
          {/* <FormattedMessage id={'Banker'} />{' '} */}
          {/* {intl.formatNumber(Math.trunc(totalAmount), {
            style: 'currency',
            currency: currency ? currency : 'INR',
            minimumFractionDigits: 2,
          })}{' '} */}
          You Won ₹{totalAmount}
          ! <StarsSvg />
        </>,
      );
      setWinBannerColor(
        'linear-gradient(90deg, color-mix(in srgb,var(--button-system-blue),#0000 100%) 0%, var(--button-system-blue) 50.52%, color-mix(in srgb,var(--button-system-blue),#0000 100%) 100%)',
      );
      WinSound();
    } else if (win && playBaccaratResponse.win_side == baccarat_side.tie) {
      setIsFireworkShown(true);
      setFirstWinText(
        <>
          <StarsSvg />
          {/* <FormattedMessage id={'tie'} />{' '} */}
          {/* {intl.formatNumber(Math.trunc(totalAmount), {
            style: 'currency',
            currency: currency ? currency : 'INR',
            minimumFractionDigits: 2,
          })}{' '} */}
          You Won ₹{totalAmount}
          ! <StarsSvg />
        </>,
      );
      setWinBannerColor(
        'linear-gradient(90deg, color-mix(in srgb,var(--button-system-blue),#0000 100%) 0%, var(--button-system-blue) 50.52%, color-mix(in srgb,var(--button-system-blue),#0000 100%) 100%)',
      );
      WinSound();
    }

    if (!win && playBaccaratResponse.win_side == baccarat_side.player) {
      setFirstWinText(
        <>
          Player Won
          {/* {intl.formatNumber(Math.trunc(totalAmount), {
            style: 'currency',
            currency: currency ? currency : 'INR',
            minimumFractionDigits: 2,
          })}{' '} */}
        </>,
      );
      setWinBannerColor(
        'linear-gradient(90deg, rgba(26, 26, 26, 0.00) 0%, #1A1A1A 50.52%, rgba(26, 26, 26, 0.00) 100%)',
      );
      LoseSound();
    } else if (!win && playBaccaratResponse.win_side == baccarat_side.banker) {
      setFirstWinText(
        <>
          Banker won
          {/* {intl.formatNumber(Math.trunc(totalAmount), {
            style: 'currency',
            currency: currency ? currency : 'INR',
            minimumFractionDigits: 2,
          })}{' '} */}
        </>,
      );
      setWinBannerColor(
        'linear-gradient(90deg, rgba(26, 26, 26, 0.00) 0%, #1A1A1A 50.52%, rgba(26, 26, 26, 0.00) 100%)',
      );
      LoseSound();
    } else if (!win && playBaccaratResponse.win_side == baccarat_side.tie) {
      setFirstWinText(
        <>
          Tie won
          {/* {intl.formatNumber(Math.trunc(totalAmount), {
            style: 'currency',
            currency: currency ? currency : 'INR',
            minimumFractionDigits: 2,
          })}{' '} */}
        </>,
      );
      setWinBannerColor(
        'linear-gradient(90deg, rgba(26, 26, 26, 0.00) 0%, #1A1A1A 50.52%, rgba(26, 26, 26, 0.00) 100%)',
      );
      LoseSound();
    }
    setWinAnimationState(true);
    await setPlayer({ ...player });
  }

  const [isWidthSmall, setIsWidthSmall] = useState(false);
  const [isHeightSmall, setIsHeightSmall] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsWidthSmall(window.innerWidth < 982);
      setIsHeightSmall(window.innerHeight <= 590);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={{
        ...(isWidthSmall && { flexGrow: baccaratGame.isOpen ? '1' : '0', transition: 'flex-grow 0.2s ease-in-out' }),
        paddingLeft: isWidthSmall ? '0' : '40px',
        paddingRight: isWidthSmall ? '0' : '40px',
      }}
      className={styles.gameBaccaratContainer}
    >
      <div
        className={styles.gameBaccaratFrame}
        style={{
          ...(isWidthSmall && {
            transition: 'padding 0.2s ease',
            backgroundPosition: 'center',
          }),
        }}
      >
        <ResultAnimation
          winBannerColor={winBannerColor}
          winAnimationState={winAnimationState}
          firstWinText={firstWinText}
          secondWinText={secondWinText}
          win={win}
          totalStake={totalStake}
          countTop={countTop}
          countBottom={countBottom}
        />

        {baccaratGame.isOpen && isWidthSmall && (
          <div
            className={styles.baccaratColumns + ' ' + `${baccaratGame.isOpen ? styles.visible : ''}`}
            style={{
              paddingTop: !isHeightSmall && baccaratGame.isOpen ? '25px' : '',
              transform: baccaratGame.isOpen ? 'translateY(0px)' : 'translateY(500px)',
              opacity: 1,
              height: baccaratGame.isOpen ? '32px' : '20px',
              transition: '0.2s ease-out',
            }}
          >
            <div className={styles.gameMainBetsContent + ' ' + `${baccaratGame.isOpen ? styles.visible : ''}`}>
              <PlayerColumn
                activeColumn={activeColumn}
                activePair={activePair}
                playerStake={baccaratBetss[0].stake}
                totalStake={totalStake}
              />
              <TieColumn
                activeColumn={activeColumn}
                activePair={activePair}
                tieStake={baccaratBetss[2].stake}
                totalStake={totalStake}
              />
              <BankerColumn
                activeColumn={activeColumn}
                activePair={activePair}
                bankerStake={baccaratBetss[1].stake}
                totalStake={totalStake}
              />
            </div>
            {baccaratGame.isOpen && baccaratGame.isAutoBet && isWidthSmall && isHeightSmall && (
              <div className={styles.autoBetCounter}>
                <span className={styles.remainingGame}>
                  {isAutoplayStopped ? (
                    <span className={styles.autoPlayStopped}>AutoPlay stopped</span>
                  ) : (
                    <span className={styles.autoplay}>
                      Autoplay <span className={styles.remainingGames}>{remainingGames}</span> Games
                    </span>
                  )}
                </span>{' '}
                {!isAutoplayStopped && (
                  <button className={styles.autobetContainer} onClick={handleStopAutoplay}>
                    Stop
                  </button>
                )}
              </div>
            )}
          </div>
        )}
        {baccaratGame.isOpen && baccaratGame.isAutoBet && isWidthSmall && !isHeightSmall && (
          <div
            className={
              styles.autoBetCounter + ' ' + `${!isHeightSmall ? styles.positioned : styles.positionedForSmall}`
            }
          >
            <span className={styles.remainingGame}>
              {isAutoplayStopped ? (
                <span className={styles.autoPlayStopped}>AutoPlay stopped</span>
              ) : (
                <span className={styles.autoplay}>
                  Autoplay <span className={styles.remainingGames}>{remainingGames}</span> Games
                </span>
              )}
            </span>{' '}
            {!isAutoplayStopped && (
              <button className={styles.autobetContainer} onClick={handleStopAutoplay}>
                Stop
              </button>
            )}
          </div>
        )}
      </div>
      <div className={styles.betColumnContainer}></div>
    </div>
  );
};
