import { motion } from 'framer-motion';
import * as React from 'react';
import { SVGProps } from 'react';

export const PlaneImageWithAnimation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width={66}
    // height={41}
    viewBox="0 0 66 41"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_1212_7667)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.3962 9.09308C53.3965 7.17693 52.5082 5.54935 52.4221 5.47867C52.2124 5.31825 51.8705 5.21012 51.6047 5.2274C51.3257 5.24052 47.0622 7.44683 46.8585 7.67924C46.6441 7.92274 46.5175 8.34603 46.5678 8.6443C46.6208 8.97963 53.123 21.5339 53.3755 21.7875C53.619 22.0238 54.0204 22.1725 54.3579 22.1344C54.6649 22.1012 58.9194 19.8776 59.0906 19.6566C59.2576 19.4488 59.3658 19.1065 59.3553 18.8425C59.347 18.7314 58.9144 17.8126 58.3997 16.81C57.0652 14.2377 55.7307 11.6654 54.3962 9.09308ZM51.9553 8.50935C51.9273 8.52943 51.3387 8.83557 50.6375 9.20023L49.365 9.86202L49.0798 9.32296C48.927 9.02849 48.803 8.75758 48.8155 8.71809C48.8259 8.68518 49.4076 8.35514 50.1088 7.99048L51.3899 7.32419L51.691 7.90447C51.8573 8.22493 51.9747 8.49376 51.9553 8.50935Z"
        fill="#FF375F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2639 28.2989L11.0241 22.8471L7.42096 19.8545L10.1011 17.5152C10.1011 17.5152 11.6987 16.2742 14.8061 16.4773L33.9142 17.8688C35.1761 17.1998 36.7169 16.3903 38.286 15.5742C41.6707 13.8139 44.456 12.3709 44.4758 12.3771C44.4955 12.3834 45.5601 14.4142 46.8452 16.8912L49.1817 21.3949L43.3798 24.4178C37.8289 27.3102 35.9411 28.248 33.9669 29.0933C32.5279 29.7097 30.1359 30.6183 28.7967 31.0563C27.6615 31.4212 15.6101 35.5662 15.4869 35.6358C14.9799 35.8138 14.5275 35.9717 14.1188 36.1144L14.1169 36.1151C11.3122 37.0941 10.5616 37.3561 8.28638 38.4843L5.85391 39.7493C3.24381 41.0957 3.10498 41.146 2.60364 40.8788C2.1573 40.6435 1.71696 39.7947 1.78136 39.2938C1.85134 38.7295 1.9722 38.6447 4.57331 37.2809L7.00786 36.0093L6.58793 35.6448C5.14446 34.3774 2.89616 31.8129 1.83817 30.2186C0.501171 28.203 -0.271639 25.707 0.0881423 24.5465C0.240097 24.0661 0.44478 23.8991 0.986865 23.8317C1.37214 23.7798 1.48808 23.802 2.17187 24.0182C3.49134 24.4428 4.92404 25.2869 7.53522 27.1842C8.94459 28.2164 11.9902 30.5697 12.0597 30.693C12.1022 30.7644 10.7937 32.7976 9.9885 33.9258C9.73211 34.2791 9.53598 34.579 9.54946 34.605C9.57193 34.6483 12.1073 32.8294 14.0764 31.3598C14.6644 30.921 16.399 29.6533 18.2639 28.2989ZM19.842 29.4873C13.6646 32.9681 8.61442 35.8919 8.8301 36.1012C10.4183 37.6167 44.7169 21.4745 45.2753 20.3911C46.8746 17.3254 43.4117 15.454 40.8772 16.4372C40.6205 16.5369 39.0554 17.3055 37.4169 18.1238L38.5177 18.204C39.9081 17.6928 40.8843 17.3764 41.2635 17.3196C41.4262 17.2959 40.8581 17.6574 39.768 18.295L41.416 18.415C41.416 18.415 44.0767 19.008 42.1008 20.886L24.0668 29.8754C24.0668 29.8754 21.954 31.2592 20.8389 30.2379L19.842 29.4873Z"
        fill="#FF375F"
      />
      <motion.g animate={{ scale: [1, 0.7, 1] }} transition={{ repeat: Infinity, times: [0, 1], duration: 1 }}>
        <path
          d="M53.4375 0.865773C53.3796 0.499955 53.4529 0.153902 53.6257 0.0420396C54.1247 -0.277966 55.7878 1.23264 58.4887 4.44706L59.0084 5.06757L59.2333 8.10701L57.4877 7.94158C57.4767 7.94597 57.4592 7.9441 57.4356 7.93664L57.4877 7.94158C57.5443 7.91904 57.4314 7.73162 57.1052 7.28915C55.1944 4.68661 53.6125 1.913 53.4375 0.865773Z"
          fill="#FF375F"
        />
        <path
          d="M59.9496 12.8663C59.9694 12.8725 60.0099 13.2474 60.0408 13.6987L60.0924 14.5187L60.5347 15.064C62.6321 17.6025 64.4165 19.347 65.1529 19.5799C65.5737 19.713 65.7882 19.3319 65.6582 18.6682C65.4476 17.5735 63.8622 14.8567 61.7374 11.9476L61.0448 11.0045L59.9496 12.8663Z"
          fill="#FF375F"
        />
      </motion.g>

      <path
        d="M61.0376 8.90526C59.5499 6.82963 56.5771 7.99041 56.3108 8.18836C55.637 8.68937 56.8832 14.5122 58.3515 14.2123C59.9042 13.8953 62.0447 10.3105 61.0376 8.90526Z"
        fill="#FF375F"
      />
    </g>
    <defs>
      <clipPath id="clip0_1212_7667">
        <rect width={65.6961} height={41.0072} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
