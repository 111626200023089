import { motion } from 'framer-motion';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { clarity } from 'react-microsoft-clarity';
import { create } from 'zustand';

import { ReactComponent as StarsSvg } from '../../assets/stars.svg';
import { BackgroundSound, CardSound, ClickSound, LoseSound, WinSound } from '../../helpers/AudioHelper';
import { cardMap } from '../../helpers/CardsPreloader';
import { useClockState } from '../../helpers/Clock';
import * as Constants from '../../helpers/Constants';
import { dragon_sides } from '../../helpers/Constants';
import Firework from '../../helpers/Fireworks.module.scss';
import {
  activeFreeBetStore,
  dragonStatisticsStore,
  playRequestStore,
  stateStore,
  useGameParams,
  usePlayerState,
} from '../../Store';

import gameStyle from './Game.module.scss';

interface card {
  id: number;
  card: string;
  name: string;
}

export const CardsStore = create((set: any) => ({
  cardsLeft: [],
  addLeftCard: (card: card) =>
    set((state: any) => ({
      cardsLeft: [...state.cardsLeft, { id: card.id, card: card.card, name: card.name }],
    })),
  removeLeftCards: () =>
    set(() => ({
      cardsLeft: [],
    })),

  cardsRight: [],
  addRightCard: (card: card) =>
    set((state: any) => ({
      cardsRight: [...state.cardsRight, { id: card.id, card: card.card, name: card.name }],
    })),
  removeRightCards: () =>
    set(() => ({
      cardsRight: [],
    })),
}));

export default function Game() {
  const [playRequest, setPlayRequest] = playRequestStore((state: any) => [
    state.playDragonRequest,
    state.setPlayDragonRequest,
  ]);
  const [setDragonStats] = dragonStatisticsStore((state: any) => [state.setDragonStats]);
  const [dragonGame, dragonGameState] = stateStore((state: any) => [state.dragonGame, state.dragonGameState]);
  const dragonParams = useGameParams((state: any) => state.dragonParams);
  const [player, setPlayer] = usePlayerState((state: any) => [state.player, state.playerState]);
  const [sideColor, setSideColor] = useState('');
  const [sideName, setSideName] = useState('null');
  const clockState = useClockState((state: any) => state.clockState);

  function setBottomPanel(side: number) {
    switch (side) {
      case dragon_sides.tiger:
        setSideColor('var(--button-system-orange)');
        setSideName('tiger');
        break;
      case dragon_sides.dragon:
        setSideColor('var(--button-system-pink)');
        setSideName('dragon');
        break;
      case dragon_sides.tie:
        setSideColor('var(--button-system-blue)');
        setSideName('tie');
        break;
    }
  }

  useEffect(() => {
    setBottomPanel(playRequest.side);

    if (dragonGame.isOpen) {
      BackgroundSound('play');
      cardsDistributionAnimation().then(() => null);
    }
  }, [dragonGame.isOpen]);

  const [playDragonResponse] = playRequestStore((state: any) => [state.playDragonResponse]);

  const [finishGame, setFinishGame] = useState(false);
  const cards = playDragonResponse.cards;
  const win = playDragonResponse.win;
  const winSide = playDragonResponse.win_side;
  let profit = Number(playDragonResponse.profit);
  const status = playDragonResponse.status;
  const balance = playDragonResponse.balance;
  const currency = dragonParams.currency;
  const intl = useIntl();

  const [leftCardsArray, setLeftCardsArray, removeLeftCards] = CardsStore((state: any) => [
    state.cardsLeft,
    state.addLeftCard,
    state.removeLeftCards,
  ]);
  const [rightCardsArray, setRightCardsArray, removeRightCards] = CardsStore((state: any) => [
    state.cardsRight,
    state.addRightCard,
    state.removeRightCards,
  ]);

  const [retrieveActiveFreeBets] = activeFreeBetStore((state: any) => [state.retrieveActiveFreeBets]);
  useEffect(() => {
    if (finishGame) {
      retrieveActiveFreeBets(player.token);
    }
  }, [finishGame]);

  let isLeft = false;

  const sleep = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));

  async function cardsDistribution(cards: any) {
    await sleep(700);

    for (let i = 0; i < cards.length; i++) {
      if (isLeft) {
        setLeftCardsArray({
          id: i,
          card: cardMap().get(cards[i]),
          name: cards[i],
        });
      } else {
        setRightCardsArray({
          id: i,
          card: cardMap().get(cards[i]),
          name: cards[i],
        });
      }
      isLeft = !isLeft;
      CardSound();
      await sleep(700);
    }
    await setDragonStats({ winner: winSide });
  }

  async function cardsDistributionAnimation() {
    await cardsDistribution(cards);
    await winAnimation();
    setFinishGame(true);
    await clockState({ visible: false });
    await setPlayer({ ...player, balance: balance });
    await sleep(2000);
    moveToLobby();
  }

  const [dragonLobby, dragonLobbyState] = stateStore((state: any) => [state.dragonLobby, state.setDragonLobbyState]);

  function moveToLobby() {
    // if (finishGame) {
    setFinishGame(false);
    clockState({ visible: true });
    setWinAnimationState(false);
    setPlayRequest({
      demo: false,
      side: -1,
      stake: 0,
    });
    // .then(() => {
    dragonLobbyState({
      ...dragonLobby,
      isOpen: true,
      isStatisticsVisible: true,
    });
    dragonGameState({ ...dragonGame, isOpen: false });
    removeLeftCards();
    removeRightCards();
    // BackgroundSound("stop");
    // });
    // }
  }

  const [winAnimationState, setWinAnimationState] = useState(false);

  const [firstWinText, setFirstWinText] = useState<any>(null);
  const [secondWinText, setSecondWinText] = useState<any>(null);
  const [winBannerColor, setWinBannerColor] = useState('');

  async function winAnimation() {
    if (win) {
      setSecondWinText(
        <>
          <StarsSvg /> <FormattedMessage id={'you_win'} />{' '}
          {intl.formatNumber(Math.trunc(profit * 100) / 100, {
            style: 'currency',
            currency: currency ? currency : 'INR',
            minimumFractionDigits: 0,
          })}{' '}
          <StarsSvg />
        </>,
      );
      WinSound();
    } else {
      setSecondWinText('');
      LoseSound();
    }
    switch (winSide) {
      case dragon_sides.tiger:
        setFirstWinText(
          <>
            <FormattedMessage id={'tiger'} /> <FormattedMessage id={'won'} />
          </>,
        );
        if (win) {
          setWinBannerColor(
            'linear-gradient(90deg, color-mix(in srgb,var(--button-system-orange),#0000 100%) 0%, var(--button-system-orange) 50.52%, color-mix(in srgb,var(--button-system-orange),#0000 100%) 100%)',
          );
        } else {
          setWinBannerColor(
            'linear-gradient(90deg, rgba(26, 26, 26, 0.00) 0%, #1A1A1A 50.52%, rgba(26, 26, 26, 0.00) 100%)',
          );
        }
        break;
      case dragon_sides.dragon:
        setFirstWinText(
          <>
            <FormattedMessage id={'dragon'} /> <FormattedMessage id={'won'} />
          </>,
        );
        if (win) {
          setWinBannerColor(
            'linear-gradient(90deg, color-mix(in srgb,var(--button-system-pink),#0000 100%) 0%, var(--button-system-pink) 50.52%, color-mix(in srgb,var(--button-system-pink),#0000 100%) 100%)',
          );
        } else {
          setWinBannerColor(
            'linear-gradient(90deg, rgba(26, 26, 26, 0.00) 0%, #1A1A1A 50.52%, rgba(26, 26, 26, 0.00) 100%)',
          );
        }
        break;
      case dragon_sides.tie:
        setFirstWinText(
          <>
            <FormattedMessage id={'tie'} /> <FormattedMessage id={'won'} />
          </>,
        );
        if (win) {
          setWinBannerColor(
            'linear-gradient(90deg, color-mix(in srgb,var(--button-system-blue),#0000 100%) 0%, var(--button-system-blue) 50.52%, color-mix(in srgb,var(--button-system-blue),#0000 100%) 100%)',
          );
        } else {
          setWinBannerColor(
            'linear-gradient(90deg, rgba(26, 26, 26, 0.00) 0%, #1A1A1A 50.52%, rgba(26, 26, 26, 0.00) 100%)',
          );
        }
        break;
    }
    setWinAnimationState(true);
    clarity.setTag('DT_win_side', String(winSide));
    clarity.upgrade('upgradeReason');
    // await sleep(1200);
  }

  // freebet block

  const [currentFreeBet] = activeFreeBetStore((state: any) => [state.currentActiveFreeBet]);

  // find freebet id in url
  const urlParams = new URLSearchParams(window.location.search);
  const freebet_id = urlParams.get('freebet_id');
  // param to freebet play
  const [isFreeBetPlay, setIsFreeBetPlay] = stateStore((state: any) => [
    state.freebetLobbyState,
    state.setFreebetLobbyState,
  ]);
  const setIsSowClaim = useState(false)[1];

  if (isFreeBetPlay.status === Constants.freebetPlay.play || isFreeBetPlay.status === Constants.freebetPlay.claim) {
    profit = playDragonResponse.profit;
  }

  const isFreeBetPlayHandler = () => {
    setIsSowClaim(false);
    const freeBet = currentFreeBet;
    const hasBet = Number(freeBet.freebets_amount) - Number(freeBet.freebets_played);
    if (freeBet.freebet_id && hasBet > 0) {
      setIsFreeBetPlay({ status: Constants.freebetPlay.play });
    } else if (freeBet.freebet_id && hasBet === 0) {
      setIsFreeBetPlay({ status: Constants.freebetPlay.claim });
    } else if (freeBet.freebet_id === undefined) {
      setIsFreeBetPlay({ status: Constants.freebetPlay.load });
    } else {
      setIsFreeBetPlay({ status: Constants.freebetPlay.notPlay });
    }

    if (status === Constants.freeBetStatus.claimed) {
      setIsFreeBetPlay({ status: Constants.freebetPlay.claim });
    }
  };

  useEffect(() => {
    if (freebet_id) {
      isFreeBetPlayHandler();
    }
  }, [currentFreeBet]);

  // end freebet block

  return (
    <motion.div
      className={gameStyle.game}
      // style={{ display: dragonGame.isOpen ? "flex" : "none" }}
      // initial={{y: window.innerHeight}}
      // animate={dragonGame.isOpen ? {y: 0} : {y: window.innerHeight}}
      // transition={{duration: 0.3}}
    >
      <div className={gameStyle.container}>
        <div className={gameStyle.gameFieldPosition}>
          {/*<Statistics isLobby={true} />*/}
          <div className={gameStyle.cardBg}>
            <div className={gameStyle.cardTextFieldPosition}>
              <div className={gameStyle.cardTextPosition}>
                <a className={gameStyle.cardText}>
                  <FormattedMessage id={'dragon'} />
                </a>
              </div>
              <div className={gameStyle.cardTextPosition}>
                <a className={gameStyle.cardText}>
                  <FormattedMessage id={'tiger'} />
                </a>
              </div>
            </div>
            <div className={gameStyle.cardsField}>
              <motion.div
                className={gameStyle.winBanner}
                style={{ background: winBannerColor }}
                initial={{ opacity: 0 }}
                animate={
                  winAnimationState
                    ? {
                        opacity: 1,
                        transition: { duration: 0.4 },
                      }
                    : undefined
                }
              >
                {win ? (
                  <div className={Firework.pyro} style={{ width: '100%' }}>
                    <div className={Firework.before}></div>
                    <div className={Firework.after}></div>
                  </div>
                ) : (
                  ''
                )}
                <a className={gameStyle.winText}>{firstWinText}</a>
                <a className={gameStyle.winTextSecond}>{secondWinText}</a>
                {/* {isFreeBetPlay.status == Constants.freebetPlay.play ||
                                isFreeBetPlay.status == Constants.freebetPlay.claim ? (
                                    <FreeBetsLobby
                                        currentFreeBet={currentFreeBet}
                                        openLobby={moveToLobby}
                                        style={"gameStyle"}
                                    />
                                ) : (
                                    ""
                                )} */}
              </motion.div>
              <motion.div
                animate={
                  winAnimationState
                    ? {
                        opacity: 0.4,
                        transition: { duration: 0.4 },
                      }
                    : undefined
                }
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div className={gameStyle.cardFieldPosition}>
                  <div className={gameStyle.cardColumn}>
                    <div className={`${gameStyle['card']} ${gameStyle['cardPlaceholder']}`} />
                    {(() => {
                      const right = [];
                      for (let i = 0; i < rightCardsArray.length; i++) {
                        right.push(
                          <motion.img
                            className={gameStyle.card}
                            src={rightCardsArray[i].card}
                            alt={i.toString()}
                            style={{
                              rotate: -15,
                              opacity: finishGame ? 0.4 : 1,
                              boxShadow: '-4px 4px 4px 0px rgba(39, 39, 41, 0.25)',
                            }}
                            initial={{ x: -200 }}
                            animate={{
                              opacity: 1,
                              rotate: 0,
                              x: 0,
                            }}
                            transition={{ duration: 0.4 }}
                            key={i.toString()}
                          />,
                        );
                      }
                      return right;
                    })()}
                  </div>
                  <div className={gameStyle.cardColumn}>
                    <div className={`${gameStyle['card']} ${gameStyle['cardPlaceholder']}`}></div>
                    {(() => {
                      const left = [];
                      for (let i = 0; i < leftCardsArray.length; i++) {
                        left.push(
                          <motion.img
                            className={gameStyle.card}
                            src={leftCardsArray[i].card}
                            alt={i.toString()}
                            style={{
                              rotate: 15,
                              opacity: finishGame ? 0.4 : 1,
                              boxShadow: '4px 4px 4px 0px rgba(39, 39, 41, 0.25)',
                            }}
                            initial={{ x: 200 }}
                            animate={{
                              opacity: 1,
                              rotate: 0,
                              x: 0,
                            }}
                            transition={{ duration: 0.4 }}
                            key={i.toString()}
                          />,
                        );
                      }
                      return left;
                    })()}
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>

        <div className={gameStyle.changeBetButtons}>
          {/* TODO: FIX BUTTON. JIRA: https://bolo-bhai.atlassian.net/browse/BOL-294?atlOrigin=eyJpIjoiMzdkOGM0OGI0ZDZmNDZmMTliOGQ5MDNiZWE4MmMyOGQiLCJwIjoiaiJ9*/}
          <motion.div
            className={gameStyle.rebetButton}
            style={{
              background: 'var(--button-system-light-green)',
              visibility: 'hidden',
            }}
            animate={{ scale: [1, 0.98, 1] }}
            transition={{ duration: 1, repeat: Infinity }}
            onClick={async () => {
              ClickSound();
            }}
          >
            <div className={gameStyle.rebetButtonTextPosition}>
              <a className={gameStyle.rebetButtonText}>
                <FormattedMessage id={'continue'} />
              </a>
            </div>
          </motion.div>
        </div>
        {/* TODO: FIRST VARIANT OF REBET BUTTONS */}
        {/*<div className={gameStyle.changeBetButtons}>*/}
        {/*    <div*/}
        {/*        className={gameStyle.rebetButton}*/}
        {/*        style={{ background: "var(--button-system-orange)" }}*/}
        {/*        onClick={() => {*/}
        {/*            isFreeBetPlay.status === Constants.freebetPlay.notPlay*/}
        {/*                ? rebetGame(dragon_sides.tiger)*/}
        {/*                : isFreeBetPlay.status === Constants.freebetPlay.claim*/}
        {/*                ? setIsSowClaim(true)*/}
        {/*                : rebetFreeBetGame(dragon_sides.tiger);*/}
        {/*        }}*/}
        {/*    >*/}
        {/*        <div className={gameStyle.rebetButtonTextPosition}>*/}
        {/*            <a className={`${gameStyle["text"]} ${gameStyle["rebetButtonCoefTextPosition"]}`}>*/}
        {/*                {dragonParams.tiger_coef}:1*/}
        {/*            </a>*/}
        {/*            <a className={gameStyle.rebetButtonText}>*/}
        {/*                <FormattedMessage id="tiger" />*/}
        {/*            </a>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*        className={gameStyle.rebetButton}*/}
        {/*        style={{ background: "var(--button-system-blue)" }}*/}
        {/*        onClick={() => {*/}
        {/*            isFreeBetPlay.status === Constants.freebetPlay.notPlay*/}
        {/*                ? rebetGame(dragon_sides.tie)*/}
        {/*                : isFreeBetPlay.status === Constants.freebetPlay.claim*/}
        {/*                ? setIsSowClaim(true)*/}
        {/*                : rebetFreeBetGame(dragon_sides.tie);*/}
        {/*        }}*/}
        {/*    >*/}
        {/*        <div className={gameStyle.rebetButtonTextPosition}>*/}
        {/*            <a className={`${gameStyle["text"]} ${gameStyle["rebetButtonCoefTextPosition"]}`}>*/}
        {/*                {dragonParams.tie_coef}:1*/}
        {/*            </a>*/}
        {/*            <a className={gameStyle.rebetButtonText}>*/}
        {/*                <FormattedMessage id="tie" />*/}
        {/*            </a>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*        className={gameStyle.rebetButton}*/}
        {/*        style={{ background: "var(--button-system-light-green)" }}*/}
        {/*        onClick={() => {*/}
        {/*            isFreeBetPlay.status === Constants.freebetPlay.notPlay*/}
        {/*                ? rebetGame(dragon_sides.dragon)*/}
        {/*                : isFreeBetPlay.status === Constants.freebetPlay.claim*/}
        {/*                ? setIsSowClaim(true)*/}
        {/*                : rebetFreeBetGame(dragon_sides.dragon);*/}
        {/*        }}*/}
        {/*    >*/}
        {/*        <div className={gameStyle.rebetButtonTextPosition}>*/}
        {/*            <a className={`${gameStyle["text"]} ${gameStyle["rebetButtonCoefTextPosition"]}`}>*/}
        {/*                {dragonParams.dragon_coef}:1*/}
        {/*            </a>*/}
        {/*            <a className={gameStyle.rebetButtonText}>*/}
        {/*                <FormattedMessage id="dragon" />*/}
        {/*            </a>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}

        <motion.div
          className={gameStyle.betBg}
          style={{ background: sideColor }}
          initial={{ y: 0 }}
          animate={
            finishGame
              ? {
                  y: 84,
                  transition: { duration: 0.5 },
                }
              : undefined
          }
        >
          <a
            className={gameStyle.cardText}
            style={{
              margin: 'inherit',
              color: 'var(--text-uninvertible-white)',
              textTransform: 'capitalize',
            }}
          >
            <FormattedMessage id="you_bet_on" />: <FormattedMessage id={sideName} />
          </a>
          <div className={gameStyle.betTextPosition}>
            <a className={gameStyle.placedBetText}>{/* <FormattedMessage id={'amount'} /> */}Total Bet:</a>
            <a className={gameStyle.placedBetText} style={{ fontWeight: '700' }}>
              {intl.formatNumber(Math.trunc(playRequest.stake * 100) / 100, {
                style: 'currency',
                currency: currency ? currency : 'INR',
                minimumFractionDigits: 0,
              })}
              {isFreeBetPlay.status === Constants.freebetPlay.play ||
              isFreeBetPlay.status === Constants.freebetPlay.claim
                ? ' Free Bet '
                : ''}
            </a>
            {/* <a className={gameStyle.placedBetText}>
              <FormattedMessage id="to_win" />
            </a>
            <a className={gameStyle.placedBetText} style={{ fontWeight: '700' }}>
              {isFreeBetPlay.status === Constants.freebetPlay.play ||
              isFreeBetPlay.status === Constants.freebetPlay.claim
                ? intl.formatNumber(
                    playRequest.side === dragon_sides.dragon
                      ? Math.trunc(playRequest.stake * dragonParams.dragon_coef * 100) / 100
                      : playRequest.side === dragon_sides.tiger
                        ? Math.trunc(playRequest.stake * dragonParams.tiger_coef * 100) / 100
                        : Math.trunc(playRequest.stake * dragonParams.tie_coef * 100) / 100,
                    {
                      style: 'currency',
                      currency: currency ? currency : 'INR',
                      minimumFractionDigits: 0,
                    },
                  )
                : intl.formatNumber(
                    playRequest.side === dragon_sides.dragon
                      ? Math.trunc((playRequest.stake + playRequest.stake * dragonParams.dragon_coef) * 100) / 100
                      : playRequest.side === dragon_sides.tiger
                        ? Math.trunc((playRequest.stake + playRequest.stake * dragonParams.tiger_coef) * 100) / 100
                        : Math.trunc((playRequest.stake + playRequest.stake * dragonParams.tie_coef) * 100) / 100,
                    {
                      style: 'currency',
                      currency: currency ? currency : 'INR',
                      minimumFractionDigits: 0,
                    },
                  )}
            </a> */}
          </div>
        </motion.div>
        {/* TODO: FIRST VARIANT WITH CHANGE BET BUTTON IN HEADER*/}
        {/*{isFreeBetPlay.status !== Constants.freebetPlay.play ? (*/}
        {/*    <motion.div*/}
        {/*        className={gameStyle.headerChangeBetButton}*/}
        {/*        animate={*/}
        {/*            !finishGame*/}
        {/*                ? {*/}
        {/*                      display: "none",*/}
        {/*                      opacity: 0,*/}
        {/*                  }*/}
        {/*                : {*/}
        {/*                      display: "flex",*/}
        {/*                      opacity: 1,*/}
        {/*                  }*/}
        {/*        }*/}
        {/*    >*/}
        {/*        <div*/}
        {/*            onClick={() => {*/}
        {/*                isFreeBetPlay.status == Constants.freebetPlay.claim*/}
        {/*                    ? setIsSowClaim(true)*/}
        {/*                    : moveToLobby();*/}
        {/*            }}*/}
        {/*            className={gameStyle.changeBetButton}*/}
        {/*        >*/}
        {/*            <a className={gameStyle.text}>*/}
        {/*                <FormattedMessage id={"change_bet"} />*/}
        {/*            </a>*/}
        {/*        </div>*/}
        {/*    </motion.div>*/}
        {/*) : (*/}
        {/*    ""*/}
        {/*)}*/}
      </div>
    </motion.div>
  );
}
