import * as amplitude from '@amplitude/analytics-browser';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as CloseButton } from '../../assets/closeButton.svg';
import { ClickSound } from '../../helpers/AudioHelper';
import { stateStore } from '../../Store';
import '../../panels/About.scss';

export const About = () => {
  const [aboutState, setAboutState] = stateStore((state: any) => [state.about, state.aboutState]);
  useEffect(() => {
    if (aboutState.isOpen) amplitude.track('Open about game Baccarat');
  }, [aboutState.isOpen]);
  return (
    <div className={'about'} style={{ display: aboutState.isOpen ? 'flex' : 'none' }}>
      <div className={'background'}></div>
      <div className={'aboutHeader'}>
        <div
          className={'close'}
          onClick={() => {
            ClickSound();
            setAboutState({ ...aboutState, isOpen: false });
          }}
        >
          <CloseButton />
        </div>

        <p className={'text headerText'}>
          <FormattedMessage id={'welcome_to'} /> <FormattedMessage id={'baccarat'} /> !
        </p>
      </div>
      <div className={'aboutContent newAboutContent'}>
        <div className={'rulesBlock'}>
          <a className={'text rulesBlockHeader'}>
            <FormattedMessage id={'objective'} />:
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'baccarat_objective'} />
          </a>
        </div>
        <div className={'rulesBlock'}>
          <a className={'text rulesBlockHeader'}>
            <FormattedMessage id={'gameplay'} />:
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'baccarat_gameplay1'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'baccarat_gameplay2'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'baccarat_gameplay3'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'baccarat_gameplay4'} />
          </a>
        </div>
        <div className={'rulesBlock'}>
          <a className={'text rulesBlockHeader'}>
            <FormattedMessage id={'card_value'} />:
          </a>
          <table style={{ margin: '0 auto', width: '100%' }}>
            <tbody>
              <tr>
                <td
                  className={'text aboutText'}
                  style={{
                    textAlign: 'left',
                    border: '1px solid var(--icon-uninvertible-white)',
                  }}
                >
                  Ace
                </td>
                <td
                  className={'text aboutText'}
                  style={{
                    textAlign: 'center',
                    border: '1px solid var(--icon-uninvertible-white)',
                  }}
                >
                  1
                </td>
              </tr>
              <tr>
                <td
                  className={'text aboutText'}
                  style={{
                    textAlign: 'left',
                    border: '1px solid var(--icon-uninvertible-white)',
                  }}
                >
                  Face cards and tens
                </td>
                <td
                  className={'text aboutText'}
                  style={{
                    textAlign: 'center',
                    border: '1px solid var(--icon-uninvertible-white)',
                  }}
                >
                  0
                </td>
              </tr>
              <tr>
                <td
                  className={'text aboutText'}
                  style={{
                    textAlign: 'left',
                    border: '1px solid var(--icon-uninvertible-white)',
                  }}
                >
                  All other cards
                </td>
                <td
                  className={'text aboutText'}
                  style={{
                    textAlign: 'center',
                    border: '1px solid var(--icon-uninvertible-white)',
                  }}
                >
                  Value stated on the card
                </td>
              </tr>
            </tbody>
          </table>
          <a className={'text aboutText'}>
            <FormattedMessage id={'baccarat_card_values1'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'baccarat_card_values2'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'baccarat_card_values3'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'baccarat_card_values4'} />
          </a>
          <a className={'text rulesBlockHeader'}>
            <FormattedMessage id={'baccarat_player_rules'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'baccarat_player_rule1'} />
          </a>
          <a className={'text rulesBlockHeader'}>
            <FormattedMessage id={'baccarat_banker_rules'} />
          </a>
          <ul>
            <li className={'text aboutText'}>
              <FormattedMessage id={'baccarat_banker_rule1'} />
            </li>
            <li className={'text aboutText'}>
              <FormattedMessage id={'baccarat_banker_rule2'} />
            </li>
            <ul>
              <li className={'text aboutText'}>
                <FormattedMessage id={'baccarat_banker_rule3'} />
              </li>
              <li className={'text aboutText'}>
                <FormattedMessage id={'baccarat_banker_rule4'} />
              </li>
              <li className={'text aboutText'}>
                <FormattedMessage id={'baccarat_banker_rule5'} />
              </li>
              <li className={'text aboutText'}>
                <FormattedMessage id={'baccarat_banker_rule6'} />
              </li>
              <li className={'text aboutText'}>
                <FormattedMessage id={'baccarat_banker_rule7'} />
              </li>
            </ul>
          </ul>
          <a className={'text aboutText'}>
            At the end of the game, winnings are paid according to the table below:
            {/* <FormattedMessage id={'baccarat_card_values3'} /> */}
          </a>
          <table style={{ margin: '0 auto', width: '100%', paddingBottom: '20px' }}>
            <tbody>
              <tr>
                <td
                  className={'text aboutText'}
                  style={{
                    textAlign: 'left',
                    border: '1px solid var(--icon-uninvertible-white)',
                  }}
                >
                  Player
                </td>
                <td
                  className={'text aboutText'}
                  style={{
                    textAlign: 'center',
                    border: '1px solid var(--icon-uninvertible-white)',
                  }}
                >
                  1:1
                </td>
              </tr>
              <tr>
                <td
                  className={'text aboutText'}
                  style={{
                    textAlign: 'left',
                    border: '1px solid var(--icon-uninvertible-white)',
                  }}
                >
                  Banker
                </td>
                <td
                  className={'text aboutText'}
                  style={{
                    textAlign: 'center',
                    border: '1px solid var(--icon-uninvertible-white)',
                  }}
                >
                  0.95:1
                </td>
              </tr>
              <tr>
                <td
                  className={'text aboutText'}
                  style={{
                    textAlign: 'left',
                    border: '1px solid var(--icon-uninvertible-white)',
                  }}
                >
                  Tie
                </td>
                <td
                  className={'text aboutText'}
                  style={{
                    textAlign: 'center',
                    border: '1px solid var(--icon-uninvertible-white)',
                  }}
                >
                  8:1
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
