import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import jokerPlaceholder from '../../../../assets/jokerPlaceholder.png';
import './Card.scss';

type CardProps = {
  card: {
    id: number;
    card: string;
    name: string;
  };
  isTop: boolean;
  index: number;
};

export const Card = ({ card, isTop, index }: CardProps) => {
  const xPositionValues = [-119, 0, 119];
  const xSmallPositionValue = [-86, 0, 86];
  const [layCardFaceDown, setLayCardFaceDown] = useState(true);
  const [isWidthSmall, setIsWidthSmall] = useState(false);
  const [isHeightSmall, setIsHeightSmall] = useState(false);
  const xPosition = isWidthSmall && isHeightSmall ? xSmallPositionValue : xPositionValues;

  useEffect(() => {
    const timer = setTimeout(() => {
      setLayCardFaceDown(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsWidthSmall(window.innerWidth <= 668);
      setIsHeightSmall(window.innerHeight <= 400);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {!isTop && (
        <motion.img
          initial={{ opacity: 1, x: -1000, rotate: -25, y: 100 }}
          animate={{
            y: 0,
            rotate: 0,
            opacity: 1,
            x: isTop ? 250 : xPosition[card.id] || 0,
          }}
          exit={{ opacity: 0, x: 300 }}
          transition={{ duration: 0.4, ease: 'easeOut' }}
          className={'teen-card'}
          src={card.card}
          alt={card.name}
        />
      )}
      {layCardFaceDown && isTop && (
        <motion.img
          initial={{ opacity: 0, x: -800, rotate: -25, y: 100 }}
          animate={{ rotate: 0, y: 0, opacity: 1, x: xPosition[index] || 0 }}
          exit={{ opacity: 0, x: 100, rotateY: -180 }}
          transition={{ duration: 0.4, ease: 'easeOut' }}
          src={jokerPlaceholder}
          className={'jkPlaceholder'}
        />
      )}
      {!layCardFaceDown && isTop && (
        <motion.img
          initial={{ opacity: 0, rotateY: 180, x: xPosition[index] || 0 }}
          animate={{
            x: xPosition[index],
            rotateY: 0,
            opacity: 1,
            transition: { duration: 0.3 },
          }}
          exit={{ opacity: 0, x: 100 }}
          className={'teen-card'}
          style={{ width: '109px', height: '145px' }}
          src={card.card}
          alt={card.name}
        />
      )}
    </>
  );
};
