import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as CloseButton } from '../assets/closeButton.svg';
import { ReactComponent as EmptyCart } from '../assets/emptyCart.svg';
import { andarPlayRequestStore } from '../games/andarNewVision/AndarStore';
import { baccaratPlayRequestStore } from '../games/baccarat/BaccaratStore';
import * as API from '../helpers/API';
import { ClickSound } from '../helpers/AudioHelper';
import * as Constants from '../helpers/Constants';
import loader from '../helpers/loaderSpinner.module.scss';
import WinLoseLabel from '../helpers/WinLoseLabel';
import { playRequestStore, stateStore, useBuildType, usePlayerState } from '../Store';

import styles from './PlayerHistory.module.scss';

export default function PlayerHistory() {
  const buildType = useBuildType((state: any) => state.buildType);
  const [gamesList, setGamesList] = useState<any>([]);
  const [playerHistory, playerHistoryState] = stateStore((state: any) => [
    state.playerHistory,
    state.playerHistoryState,
  ]);

  const [playAndarResponseNew] = andarPlayRequestStore((state: any) => [state.playAndarResponse]);
  const player = usePlayerState((state: any) => state.player);
  const [playAndarResponse, playDragonResponse, playLuckyResponse, playTeenPattiResponse] = playRequestStore(
    (state: any) => [
      state.playAndarResponse,
      state.playDragonResponse,
      state.playLuckyResponse,
      state.playTeenPattiResponse,
    ],
  );
  const [playBaccaratResponse] = baccaratPlayRequestStore((state: any) => [state.playBaccaratResponse]);

  const [historyList, setHistoryList] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    API.get(Constants.games_list, '').then((json) => {
      setGamesList(json);
    });
  }, []);

  useEffect(() => {
    if (player.token != null) {
      API.get(
        buildType === Constants.build_types.Lobby
          ? Constants.history_all + '?limit=' + Constants.history_limit
          : Constants.history + '?limit=' + Constants.history_limit,

        player.token,
      ).then((json) => {
        setHistoryList(json.results);
        setIsLoading(false);
      });
    }
  }, [
    player.token,
    playAndarResponse,
    playDragonResponse,
    playLuckyResponse,
    playTeenPattiResponse,
    playBaccaratResponse,
    playAndarResponseNew,
  ]);

  const intl = useIntl();

  return (
    <div className={styles['playerHistory']} style={{ display: playerHistory.isOpen ? 'flex' : 'none' }}>
      <div className={styles['background']}></div>
      <div className={styles['playerHistoryHeader']}>
        <CloseButton
          style={{ position: 'absolute' }}
          onClick={() => {
            ClickSound();
            playerHistoryState({ ...playerHistory, isOpen: false });
          }}
        />
        <p className={styles['text'] + ' ' + styles['headerText']}>
          <FormattedMessage id="player_history" />
        </p>
      </div>

      <div className={styles['playerHistoryFilter']}>
        <div className={styles['playerHistoryFilterItem']}>
          <p className={styles['playerHistoryFilterText']}>
            <FormattedMessage id="date" /> & <FormattedMessage id="time" />
          </p>
          {/*<div className={"playerHistoryFilterItemArrows"}>*/}
          {/*    <FilterArrowUp height={"5px"} fill={"var(--icon-tertiary)"} />*/}
          {/*    <FilterArrowDown height={"5px"} fill={"var(--icon-tertiary)"} />*/}
          {/*</div>*/}
        </div>
        <div className={styles['playerHistoryFilterItem']}>
          <p className={styles['playerHistoryFilterText']}>
            <FormattedMessage id="bets" />
          </p>
          {/*<div className={"playerHistoryFilterItemArrows"}>*/}
          {/*    <FilterArrowUp height={"5px"} fill={"var(--icon-tertiary)"} />*/}
          {/*    <FilterArrowDown height={"5px"} fill={"var(--icon-tertiary)"} />*/}
          {/*</div>*/}
        </div>
        <div className={styles['playerHistoryFilterItem']}>
          <p className={styles['playerHistoryFilterText']}>
            <FormattedMessage id="payout" />
          </p>
          {/*<div className={"playerHistoryFilterItemArrows"}>*/}
          {/*    <FilterArrowUp height={"5px"} fill={"var(--icon-tertiary)"} />*/}
          {/*    <FilterArrowDown height={"5px"} fill={"var(--icon-tertiary)"} />*/}
          {/*</div>*/}
        </div>
        <div className={styles['playerHistoryFilterItem']}>
          <p className={styles['playerHistoryFilterText']}>
            <FormattedMessage id="status" />
          </p>
        </div>
      </div>
      <div className={styles['playerHistoryContent']}>
        {(() => {
          if (isLoading) {
            return <span className={loader.loader}></span>;
          } else if (historyList.length <= 0) {
            return (
              <div className={styles.emptyHistoryWrapper}>
                <EmptyCart />
                <div className={styles.emptyHistoryText}>
                  <p>Nothing!</p>
                  <p>Your history is empty...</p>
                </div>
              </div>
            );
          } else {
            const displayedHistoryList = [];

            for (let i = 0; i < historyList.length; i++) {
              if (historyList[i].play_status === 'WIN' || historyList[i].play_status === 'LOSE') {
                const date = new Date(historyList[i].created);
                displayedHistoryList.push(
                  <div className={styles['playerHistoryContentRow']} key={historyList[i].id}>
                    <div className={styles['playerHistoryContentRowDateColumn']}>
                      <p className={styles['playerHistoryContentRowText']} style={{ margin: '0px' }}>
                        {/*{date.getDate().toString().padStart(2, "0")}.{date.getMonth().toString().padStart(2, "0")}.{date.getFullYear()}*/}
                        {intl.formatDate(date)}
                      </p>
                      <p
                        className={styles['playerHistoryContentRowText']}
                        style={{
                          fontSize: '12px',
                          fontWeight: '400',
                          opacity: 0.5,
                          margin: '0px',
                        }}
                      >
                        {intl.formatTime(date)}

                        {/*{date.getHours().toString().padStart(2, "0")}:{date.getMinutes().toString().padStart(2, "0")} PM*/}
                      </p>
                    </div>
                    <div className={styles['playerHistoryContentRowMoneyColumn']}>
                      <p
                        className={styles['playerHistoryContentRowText']}
                        data-contentlength={Math.floor(
                          intl.formatNumber(historyList[i].stake, {
                            style: 'currency',
                            currency: player.currency ? player.currency : 'INR',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 5,
                          }).length / 7,
                        )}
                      >
                        {intl.formatNumber(historyList[i].stake, {
                          style: 'currency',
                          currency: player.currency ? player.currency : 'INR',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 5,
                        })}
                      </p>
                      {buildType === Constants.build_types.Lobby ? (
                        <p
                          className={styles['playerHistoryContentRowText'] + ' ' + styles['gameName']}
                          style={{
                            fontSize: '12px',
                            fontWeight: '400',
                            opacity: 0.5,
                            margin: '0px',
                          }}
                        >
                          {gamesList.filter((item: any) => item.id === historyList[i].game_id)[0]?.name}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className={styles['playerHistoryContentRowMoneyColumn']}>
                      <p
                        data-contentlength={Math.floor(
                          intl.formatNumber(historyList[i].profit, {
                            style: 'currency',
                            currency: player.currency ? player.currency : 'INR',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 5,
                          }).length / 7,
                        )}
                        className={styles['playerHistoryContentRowText']}
                      >
                        {intl.formatNumber(historyList[i].profit, {
                          style: 'currency',
                          currency: player.currency ? player.currency : 'INR',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 5,
                        })}
                      </p>
                    </div>
                    <WinLoseLabel isWin={historyList[i].play_status === 'WIN'} />
                  </div>,
                );
              }
            }
            return displayedHistoryList;
          }
        })()}
      </div>
    </div>
  );
}
