import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { create } from 'zustand';

import { ReactComponent as ClockIcon } from '../assets/clockIcon.svg';

export const useClockState = create((set: any) => ({
  clock: {
    visible: true,
  },
  clockState: async (params: any) =>
    await set((state: any) => {
      state.clock = {
        visible: params.visible,
      };
      return state.clock;
    }),
}));

export default function Clock() {
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(0);
  const clock = useClockState((state: any) => state.clock);

  function refreshClock() {
    setDate(new Date());
    setTime(time + 100);
  }
  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, [date]);

  const hours = Math.floor(time / 360000);
  const minutes = Math.floor((time % 360000) / 6000);
  const seconds = Math.floor((time % 6000) / 100);

  return (
    <motion.div
      className={'clockBlock'}
      animate={
        clock.visible
          ? {
              visibility: 'visible',
              opacity: 1,
            }
          : {
              visibility: 'hidden',
              opacity: 0,
            }
      }
    >
      <ClockIcon opacity={0.5} style={{ fill: 'var(--icon-tertiary)' }} />
      {/*<p className="text">{date.getHours().toString().padStart(2, "0")}:{date.getMinutes().toString().padStart(2, "0")}</p>*/}
      <p className="text">{useIntl().formatTime(Date.now())}</p>
      <p className="text">
        {hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
      </p>
    </motion.div>
  );
}
