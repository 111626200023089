import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useIntl } from 'react-intl';

import './Header.css';

// import { ReactComponent as ActivityIcon } from '../assets/Activity.svg';
// import { ReactComponent as NewActivityIcon } from '../assets/ActivityRed.svg';
// import { ReactComponent as BoxIcon } from '../assets/iconBox.svg';
// import { ReactComponent as BoxIconGreen } from '../assets/iconBoxGreen.svg';
import { ReactComponent as MenuButton } from '../assets/menuButton.svg';
import { ReactComponent as SoundOffIcon } from '../assets/soundOffIcon.svg';
import { ReactComponent as SoundOnIcon } from '../assets/soundOnIcon.svg';
// import { ClickSound, MuteBackground, MuteSound, UnMuteBackground, UnMuteSound } from '../helpers/AudioHelper';
// import { useWindowSize } from '../helpers/WindowSize';
import { useSoundContext } from '../helpers/SoundContext';
import { stateStore, usePlayerState } from '../Store';

// import * as Constants from './../helpers/Constants';
// import { activityStore } from './activity/activityStore';

export default function Header() {
  const { ClickSound, MuteBackground, MuteSound, UnMuteBackground, UnMuteSound } = useSoundContext();
  const player = usePlayerState((state: any) => state.player);
  // const buildType = useBuildType((state: any) => state.buildType);
  // const [freeBetsManagement, setFreeBetsManagementOpen] = stateStore((state: any) => [
  //   state.freeBetsManagement,
  //   state.setFreeBetsManagementOpen,
  // ]);

  // const [activity, setActivity] = activityStore((state: any) => [state.activityState, state.setActivityState]);

  const intl = useIntl();

  const [balance, setBalance] = useState(player.balance);
  const [prevBalance, setPrevBalance] = useState(player.balance);
  const [balanceClass, setBalanceClass] = useState('accountBalance');

  useEffect(() => {
    if (player.balance > balance && balance != null) {
      setBalanceClass('accountBalance colored');
      setBalance(player.balance);
      setTimeout(() => {
        setPrevBalance(player.balance);
      }, 1000);
    } else {
      setPrevBalance(player.balance);
      setBalance(player.balance);
      setBalanceClass('accountBalance');
    }
  }, [player.balance]);

  const [menu, menuState] = stateStore((state: any) => [state.menu, state.menuState]);
  const [setModalWindow] = stateStore((state: any) => [state.modalWindowState]);

  function balanceClick() {
    ClickSound();
    setModalWindow({ reason: 'balance', isOpen: true });
  }

  // async function muteButtonClick() {
  //     ClickSound();
  //     menu.isSoundOn ? MuteSound() : UnMuteSound();
  //     await menuState({ ...menu, isSoundOn: !menu.isSoundOn });
  //     localStorage.setItem("isSoundOn", String(!menu.isSoundOn));
  // }

  async function muteButtonClick() {
    ClickSound();
    if (menu.isSoundOn || menu.isMusicOn) {
      MuteSound();
      MuteBackground();
    } else {
      UnMuteSound();
      UnMuteBackground();
    }

    await menuState({
      ...menu,
      isSoundOn: !menu.isSoundOn,
      isMusicOn: !menu.isSoundOn,
    });
    localStorage.setItem('isSoundOn', String(!menu.isSoundOn));

    localStorage.setItem('isMusicOn', String(!menu.isSoundOn));
  }

  // const size = useWindowSize();

  return (
    <div className={'header'} id={'header'}>
      <div className={'headerContent'}>
        <div className="leftBlock">
          <div
            onClick={() => {
              ClickSound();
              menuState({ ...menu, isOpen: true });
              const body = document.getElementById('body');
              if (body) body.style.overflow = 'hidden';
            }}
            style={{
              padding: '12px',
              display: 'flex',
              fill: 'var(--icon-secondary)',
              cursor: 'pointer',
            }}
          >
            <MenuButton />
          </div>
          <div
            onClick={muteButtonClick}
            style={{
              padding: '12px',
              display: 'flex',
              fill: 'var(--icon-secondary)',
              cursor: 'pointer',
            }}
          >
            {menu.isSoundOn || menu.isMusicOn ? (
              <SoundOnIcon className={'icon'} />
            ) : (
              <SoundOffIcon className={'icon'} />
            )}
          </div>
        </div>
        <div className="balanceWrapper">
          <div className={'balance'} onClick={balanceClick}>
            {/*<p className={"text"}>Balance: </p>*/}
            {/*<BalanceIcon style={{ opacity: 0.5, fill: "var(--icon-tertiary)" }} />*/}
            <p className={'text'}>
              <CountUp
                className={balanceClass}
                start={prevBalance}
                end={balance}
                duration={1.5}
                decimals={9}
                preserveValue={true}
                formattingFn={(value: number) =>
                  intl
                    .formatNumberToParts(value, {
                      style: 'currency',
                      currency: player.currency ? player.currency : 'INR',
                      // maximumFractionDigits: 9,
                    })
                    .map(({ type, value }) => {
                      switch (type) {
                        case 'currency':
                          return `<span style="font-weight: 400">${value}</span>`;
                        default:
                          return value;
                      }
                    })
                    .reduce((string, part) => string + part)
                }
              />
            </p>
          </div>
        </div>
        {/*<div className="rightBlock">*/}
        {/*  <div*/}
        {/*    key={`${activity.isNewActivity + activity.isOpen}`}*/}
        {/*    id={`${'Notification' + activity.isNewActivity + activity.isOpen}`}*/}
        {/*    className="activityWrapper"*/}
        {/*    onClick={() => {*/}
        {/*      ClickSound();*/}
        {/*      setActivity({ ...activity, isOpen: true });*/}
        {/*    }}*/}
        {/*    style={*/}
        {/*      (buildType === Constants.build_types.Andar || buildType === Constants.build_types.Baccarat) &&*/}
        {/*      size.width < 650*/}
        {/*        ? { marginRight: 97 }*/}
        {/*        : buildType === Constants.build_types.Baccarat && size.width < 981*/}
        {/*          ? { marginRight: 117 }*/}
        {/*          : buildType === Constants.build_types.Andar && size.width < 900*/}
        {/*            ? { marginRight: 117 }*/}
        {/*            : {}*/}
        {/*    }*/}
        {/*  >*/}
        {/*    {activity.isNewActivity ? (*/}
        {/*      <div className="bell">*/}
        {/*        <NewActivityIcon />{' '}*/}
        {/*      </div>*/}
        {/*    ) : (*/}
        {/*      <ActivityIcon />*/}
        {/*    )}*/}
        {/*  </div>*/}

        {/*  /!*----Free Bets----*!/*/}
        {/*  /!*TODO: ENABLE WHEN FREE BETS WILL DONE*!/*/}
        {/*  /!*{freeBetsManagement.activeFreeBetsGameId.length > 0 ? (*!/*/}
        {/*  /!* <div*/}
        {/*    className="freebetButton"*/}
        {/*    onClick={async () => {*/}
        {/*      ClickSound();*/}
        {/*      await setFreeBetsManagementOpen({*/}
        {/*        ...freeBetsManagement,*/}
        {/*        isOpen: true,*/}
        {/*      });*/}
        {/*    }}*/}
        {/*    style={{*/}
        {/*      borderColor:*/}
        {/*        freeBetsManagement.activeFreeBetsGameId.length > 0*/}
        {/*          ? 'var(--accent-system-light-green)'*/}
        {/*          : 'var(--button-invertible-primary)',*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {freeBetsManagement.activeFreeBetsGameId.length > 0 ? <BoxIconGreen /> : <BoxIcon />}*/}
        {/*    <span>*/}
        {/*      <FormattedMessage id="free_bets" />*/}
        {/*    </span>*/}
        {/*  </div> *!/*/}
        {/*  /!*) : (*!/*/}
        {/*  /!*    ""*!/*/}
        {/*  /!*)}*!/*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
