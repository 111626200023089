import { usePlayerState } from '../Store';

import { displayError } from './ErrorWindow';

let player: any;
let my_headers: Headers;

export default function InitAPI() {
  player = usePlayerState((state: any) => state.player);
  setupHeaders();
}

function setupHeaders() {
  my_headers = new Headers();
  my_headers.set('Access-Control-Allow-Headers', '*');
  my_headers.set('Access-Control-Allow-Origin', '*');
  my_headers.set('Accept', 'application/json');

  my_headers.set('Content-Type', 'application/json');
  my_headers.set('Authorization', player.token);

  my_headers.set('Session', player.session_uuid);
}

export async function post(url: string, data: any, token: string): Promise<any | null> {
  my_headers.get('Authorization') || my_headers.set('Authorization', token);

  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    headers: my_headers,
    redirect: 'follow', // manual, *follow, error
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
    .then(
      (response) => response.json(),
      () => {
        displayError(response.error_code, response.error_detail);
        return null;
      },
    )
    .catch((error) => {
      displayError(error.error_code, error.error_detail);
      return null;
    });
  if (response != null) {
    if (response.error_code !== undefined) {
      displayError(response.error_code, response.error_detail);
      return null;
    }
    return response;
  }
  return response;
}

export async function get(url: string, token: string): Promise<any | null> {
  my_headers.get('Authorization') || my_headers.set('Authorization', token);

  const response = await fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    headers: my_headers,
    redirect: 'follow', // manual, *follow, error
  })
    .then(
      (response) => response.json(),
      () => {
        displayError(response.error_code, response.error_detail);
        return null;
      },
    )
    .catch((error) => {
      displayError(error.error_code, error.error_detail);
      return null;
    });
  if (response != null) {
    if (response.error_code !== undefined) {
      displayError(response.error_code, response.error_detail);
      return null;
    }
    return response;
  }
  return response;
}
