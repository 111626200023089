import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { create } from 'zustand';

import './CardsSlider.css';

interface config {
  width: number;
  height: number;
  isLeft: boolean;
}

interface card {
  id: number;
  card: string;
}

export const CardsSliderStore = create((set: any) => ({
  sliderCardsLeft: [],
  sliderAddLeftCard: (card: card) =>
    set((state: any) => ({
      sliderCardsLeft: [...state.sliderCardsLeft, { id: card.id, card: card.card }],
    })),
  sliderCardsRight: [],
  sliderAddRightCard: (card: card) =>
    set((state: any) => ({
      sliderCardsRight: [...state.sliderCardsRight, { id: card.id, card: card.card }],
    })),
  clearSlider: () =>
    set(() => ({
      sliderCardsLeft: [],
      sliderCardsRight: [],
    })),
}));

export default function CardsSlider(config: config) {
  const cardsLeft = CardsSliderStore((state: any) => state.sliderCardsLeft);
  const cardsRight = CardsSliderStore((state: any) => state.sliderCardsRight);

  let arrayOfCards = [];

  if (config.isLeft) {
    arrayOfCards = cardsLeft;
  } else {
    arrayOfCards = cardsRight;
  }

  const shiftCoef = config.height / 60;

  const [isDrag, setIsDrag] = useState(false);

  return (
    <div className={'cardsSlider'} style={{ width: config.width, height: config.height }}>
      <div
        className={'leftBlur'}
        style={
          config.width - 10 - arrayOfCards.length * 25 * shiftCoef <= 20
            ? { height: config.height + 10 + 'px' }
            : { height: config.height + 10 + 'px', boxShadow: 'unset' }
        }
      ></div>
      <motion.div
        drag="x"
        dragConstraints={{
          left: config.width - 10 - arrayOfCards.length * 25 * shiftCoef,
          right: 30,
        }}
        transition={{ ease: 'easeIn' }}
        dragElastic={0}
        className={'cardsSliderComponent'}
        onDrag={() => setIsDrag(true)}
        onDragEnd={() => setIsDrag(false)}
        animate={{
          x: !isDrag ? config.width - 10 - arrayOfCards.length * 25 * shiftCoef : undefined,
        }}
      >
        {(() => {
          const displayedCards = [];

          for (let i = 0; i < arrayOfCards.length; i++) {
            displayedCards.push(
              <motion.img
                src={arrayOfCards[i].card}
                height={'100%'}
                alt={i.toString()}
                style={{ zIndex: i, marginLeft: -20 * shiftCoef + 'px' }}
                initial={{ opacity: 0, filter: undefined }}
                animate={{
                  opacity: 1,
                  // filter: (i == arrayOfCards.length - 1 ? undefined : "brightness(50%)")
                }}
                key={i.toString()}
              />,
            );
          }
          return displayedCards;
        })()}
      </motion.div>
      <div
        className={'rightBlur'}
        style={
          config.width - 10 - arrayOfCards.length * 25 * shiftCoef <= 20
            ? {
                margin: '-5px 0px 0px ' + (config.width - 14) + 'px',
                height: config.height + 10 + 'px',
              }
            : {
                margin: '-5px 0px 0px ' + (config.width - 14) + 'px',
                height: config.height + 10 + 'px',
                boxShadow: 'unset',
              }
        }
      ></div>
    </div>
  );
}
