import { create } from 'zustand';

import * as API from '../../helpers/API';
import { CardSound } from '../../helpers/AudioHelper';
import { cardMap } from '../../helpers/CardsPreloader';
import * as Constants from '../../helpers/Constants';

// import AutoplayWindow from './autoplay/AutoplayWindow';

export const useSideValueStore = create((set) => ({
  sideValue: 0,
  setSideValue: (newValue: number) => set({ sideValue: newValue }),
}));

export const useFireworkStore = create((set) => ({
  isFireworkShown: false,
  setIsFireworkShown: (value: any) => set({ isFireworkShown: value }),
}));

export const useCalculatorStore = create((set) => ({
  isCalculatorShown: undefined,
  setIsCalculatorShown: (value: any) => set({ isCalculatorShown: value }),
}));

const useAutoplayStore = create((set) => ({
  hasAutoPlayPressed: undefined,
  isAutoplayStopped: undefined,
  howManyTimesChanged: 1,
  defaultGameNumbers: 5,
  remainingGames: 5,
  lossLimitValue: 0,
  startingBalance: 0,
  setHasAutoPlayPressed: (value: any) => set({ hasAutoPlayPressed: value }),
  setIsAutoplayStopped: (value: any) => set({ isAutoplayStopped: value }),
  setDefaultGameNumbers: (value: any) => set({ defaultGameNumbers: value }),
  setRemainingGames: (value: any) => set({ remainingGames: value }),
  setHowManyTimesChanged: (value: any) => set({ howManyTimesChanged: value }),
  setLossLimitValue: (value: any) => {
    set({ lossLimitValue: value });
  },
  setStartingBalance: (value: any) => set({ startingBalance: value }),
}));

export default useAutoplayStore;

export const useBaccaratParams = create((set: any) => ({
  baccaratParams: {
    id: 5,
    name: '',
    currency: '',
    min_bet: 20,
    max_bet: 1000,
    step: 1,
    balance: 0,
    player_coef: 1,
    banker_coef: 0,
    tie_coef: 8,
  },

  setBaccaratParams: (props: any) =>
    set((state: any) => {
      state.baccaratParams = {
        id: props.id,
        name: props.name,
        currency: Constants.currency(props.currency),
        min_bet: props.min_bet,
        max_bet: props.max_bet,
        step: props.step,
        balance: props.balance,
        player_coef: (props.player_coef - 1).toFixed(0),
        banker_coef: (props.banker_coef - 1).toFixed(2),
        tie_coef: (props.tie_coef - 1).toFixed(0),
      };

      return state.baccaratParams;
    }),
}));

export const baccaratPlayRequestStore = create((set: any) => ({
  playBaccaratRequest: {
    demo: false,
    bets: [{}],
  },

  setPlayBaccaratRequest: async (request: any) =>
    await set((state: any) => {
      state.playBaccaratRequest = {
        demo: request.demo,
        bets: Array.isArray(request.bets) ? request.bets : [request.bets],
      };
      return state.playBaccaratRequest;
    }),

  playBaccaratResponse: {
    win_side: 0,
    total_win: false,
    total_profit: 0,
    balance: 0,
    cards: [],
  },

  retrieveBaccaratResponse: async (data: any, token: any): Promise<any> => {
    const responseData = await API.post(Constants.play, data, token).then((json) => {
      return json;
    });

    await set((state: any) => {
      state.historyFreeBets = {
        bet_id: responseData.bet_id,
        win: responseData.win,
        win_side: responseData.win_side,
        cards: responseData.cards,
        balance: responseData.balance,
        profit: responseData.profit,
        mini_game: responseData.mini_game,
        mini_game_bet: responseData.mini_game_bet,
        status: responseData.status ? responseData.status : null,
        total_gain: responseData.total_gain ? responseData.total_gain : null,
      };
      return state.historyFreeBets;
    });
  },

  setPlayBaccaratResponse: async (response: any) =>
    await set((state: any) => {
      state.playBaccaratResponse = {
        win_side: response.win_side,
        total_win: response.total_win,
        total_profit: response.total_profit,
        balance: response.balance,
        cards: response.cards,
        bets: response.bets,
      };
      return state.playBaccaratResponse;
    }),
}));

interface card {
  id: number;
  card: string;
  name: string;
}

export const BaccaratStateStore = create((set: any) => ({
  baccaratLobby: {
    isOpen: true,
  },
  setBaccaratLobbyState: (params: any) =>
    set((state: any) => {
      state.dragonLobby = {
        isOpen: params.isOpen,
      };
      return state.baccaratLobbys;
    }),

  baccaratGame: {
    isOpen: false,
    isAutoBet: false,
    isCalculatorShown: false,
    hasAutoStarted: false,
    isFirstAutoPlay: false,
    isAutoplayStopped: false,
    isBlocked: false,
    isQuickMode: !getInitialGameMode(),
  },

  setBaccaratGame: (props: any) => {
    set((state: any) => {
      state.baccaratGame = {
        isOpen: props.isOpen === undefined ? state.isOpen : props.isOpen,
        isAutoBet: props.isAutoBet === undefined ? state.isAutoBet : props.isAutoBet,
        isCalculatorShown: props.isCalculatorShown === undefined ? state.isCalculatorShown : props.isCalculatorShown,
        hasAutoStarted: props.hasAutoStarted === undefined ? state.hasAutoStarted : props.hasAutoStarted,
        isFirstAutoPlay: props.isFirstAutoPlay === undefined ? state.isFirstAutoPlay : props.isFirstAutoPlay,
        isAutoplayStopped: props.isAutoplayStopped === undefined ? state.isAutoplayStopped : props.isAutoplayStopped,
        isBlocked: props.isBlocked === undefined ? state.isBlocked : props.isBlocked,
        isQuickMode: props.isQuickMode === undefined ? state.isQuickMode : props.isQuickMode,
      };
      return state.baccaratGame;
    });
  },

  isBetButtonClicked: {
    isClicked: false,
  },
  setIsBetButtonClicked: async (params: any) =>
    set((state: any) => {
      state.isBetButtonClicked = {
        isClicked: params.isClicked,
      };
      return state.isBetButtonClicked;
    }),

  isCancelButtonClicked: {
    isClicked: false,
  },
  setIsCancelButtonClicked: async (params: any) =>
    set((state: any) => {
      state.isCancelButtonClicked = {
        isClicked: params.isClicked,
      };
      return state.isCancelButtonClicked;
    }),
}));
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const CardsStore = create((set: any) => ({
  currentCard: null as card | null,
  setCard: (card: card) => set({ currentCard: card }),
  clearCard: () => set({ currentCard: null }),

  cardsTop: [],
  addTopCard: (card: card) =>
    set((state: any) => ({
      cardsTop: [...state.cardsTop, { id: card.id, card: card.card }],
    })),

  cardsBottom: [],
  addBottomCard: (card: card) =>
    set((state: any) => ({
      cardsBottom: [...state.cardsBottom, { id: card.id, card: card.card }],
    })),

  clearCards: () =>
    set(() => ({
      cardsTop: [],
      cardsBottom: [],
    })),

  cardsTopDistribution: async (cards: any) => {
    // await sleep(500);
    for (let i = 0; i < cards.length; i++) {
      set((state: any) => ({
        cardsTop: [...state.cardsTop, { id: i, card: cardMap().get(cards[i]) }],
      }));
      await CardSound();
      await sleep(750);
    }
  },

  cardsBottomDistribution: async (cards: any) => {
    // await sleep(500);
    for (let i = 0; i < cards.length; i++) {
      set((state: any) => ({
        cardsBottom: [...state.cardsBottom, { id: i, card: cardMap().get(cards[i]) }],
      }));
      await CardSound();
      await sleep(750);
    }
  },
}));

export const useColumnState = create((set) => ({
  activeColumn: null,
  activePair: null,
  setActiveColumn: (column: string) => set({ activeColumn: column }),
  setActivePair: (pair: string) => set({ activePair: pair }),
}));

export const baccaratBetsStore = create((set: any) => ({
  currentBet: { side: null, stake: 0 },

  setCurrentBet: (props: { side: number | null; stake: number | null }) => {
    set((state: any) => {
      state.currentBet = { side: props.side, stake: props.stake };
      return state.currentBet;
    });
  },

  // theCurrentBet: { side: null, stake: 0 },

  // setTheCurrentBet: (props: { side: number | null; stake: number | null }) => {
  //   set((state: any) => {
  //     state.theCurrentBet = { side: props.side, stake: props.stake };
  //     return state.theCurrentBet;
  //   });
  // },

  baccaratBetss: [
    { side: 0, stake: 0 },
    { side: 1, stake: 0 },
    { side: 2, stake: 0 },
  ],
  clearStakes: () => {
    set((state: any) => ({
      baccaratBetss: state.baccaratBetss.map((bet: any) => ({
        ...bet,
        stake: 0,
      })),
    }));
  },

  setBaccaratBetStakes: (side: any, newStake: any, changeAmount: any) => {
    set((state: any) => ({
      baccaratBetss: state.baccaratBetss.map((bet: any) =>
        bet.side === side ? { ...bet, stake: bet.stake + changeAmount } : bet,
      ),
    }));
  },

  baccaratBets: [
    { side: 0, coef: 1, stake: 0, name: 'player' },
    { side: 1, coef: 0.95, stake: 0, name: 'banker' },
    { side: 2, coef: 8.0, stake: 0, name: 'tie' },
  ],

  setBaccaratBets: (props: []) => {
    set((state: any) => {
      state.baccaratBets = props;
      return state.baccaratBets;
    });
  },

  setBaccaratBetStake: (props: { betName: string; stake: any; maxBet: any }) => {
    set((state: any) => {
      state.baccaratBets = state.baccaratBets.map((element: any) => {
        if (element.name === props.betName) {
          return {
            ...element,
            stake: props.stake,
          };
        } else {
          return { ...element };
        }
      });
      return state.baccaratBets;
    });
  },
}));

export const baccaratStatisticsStore = create((set: any) => ({
  baccaratStats: {
    winner: [],
    last_winner: [],
    player_percent: 0,
    banker_percent: 0,
    tie_percent: 0,
  },

  setBaccaratStats: (stats: any) =>
    set((state: any) => {
      state.baccaratStats = {
        winner: stats.winner,
        last_winner: stats.last_winner,
        player_percent: stats.player_percent,
        banker_percent: stats.banker_percent,
        tie_percent: stats.tie_percent,
      };
      return state.baccaratStats;
    }),
  retrieveBaccaratStats: async (params: any): Promise<any> => {
    const data = await API.get(Constants.statistics + params.id, params.token).then((json) => {
      return json;
    });

    await set(async (state: any) => {
      state.baccaratStats = {
        ...state.baccaratStats,
        winner: data.winner,
        last_winner: data.last_winner,
        player_percent: data.player_percent,
        banker_percent: data.banker_percent,
        tie_percent: data.tie_percent,
      };

      return state.baccaratStats;
    });
  },
}));

function getInitialGameMode() {
  try {
    const isMultiMode = sessionStorage.getItem('isMultiMode');
    if (isMultiMode) {
      return isMultiMode.toLowerCase() === 'true';
    } else return false;
  } catch (e) {
    return false;
  }
}
