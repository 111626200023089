import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as StatisticIcon } from '..//../assets/statisticIcon.svg';
import { ReactComponent as SettingButtonAuto } from '../../assets/autoPlaySettingBtn.svg';
import { ClickSound } from '../../helpers/AudioHelper';
import { calculateMultiplyingCoefficient } from '../../helpers/BetCalculator.new';
import FireworkComponent from '../../helpers/FireWorkComponet';
import Switch from '../../helpers/Switch';
import { useWindowSize } from '../../helpers/WindowSize';
import { lowBalanceStore, usePlayerState } from '../../Store';

import AutoplayWindow from './autoplay/AutoplayWindow';
import useAutoplayStore, {
  BaccaratStateStore,
  baccaratBetsStore,
  useBaccaratParams,
  useCalculatorStore,
  useColumnState,
  useFireworkStore,
  useSideValueStore,
} from './BaccaratStore';
import Calculator from './calculator/Calculator';
import { BankerColumn } from './Columns/BankerColumn';
import { PlayerColumn } from './Columns/PlayerColumn';
import { TieColumn } from './Columns/TieColumn';
import InfoModal from './infoModal/InfoModal';
import styles from './Lobby.module.scss';
import QuickModeBetsPanel from './quickModeLobby/QuickModeBetsPanel';
import { StartBtn } from './startButton';
import Statistic from './Statistics';

type Prop = {
  startGame?: any;
  playButtonDisabled: boolean;
};

export const Lobby = (props: Prop) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isBetPlaced, setIsBetPlaced] = useState<boolean>(false);
  const [activeTab] = useState<string>('main-bets');
  const [isCalculatorShow, setIsCalculatorShow] = useCalculatorStore((state: any) => [
    state.isCalculatorShown,
    state.setIsCalculatorShown,
  ]);
  const [isDesktopCalculatorShow] = useState<boolean>(true);
  const [isPlayerCalculatorShow, setIsPlayerCalculatorShow] = useState<boolean>(false);
  const [isBankerCalculatorShow, setIsBankerCalculatorShow] = useState<boolean>(false);
  const [isTieCalculatorShow, setIsTieCalculatorShow] = useState<boolean>(false);
  const [baccaratParams] = useBaccaratParams((state: any) => [state.baccaratParams]);
  const [activeColumn, activePair, setActiveColumn] = useColumnState((state: any) => [
    state.activeColumn,
    state.activePair,
    state.setActiveColumn,
  ]);
  const [isFireworkShown] = useFireworkStore((state: any) => [state.isFireworkShown]);
  const [baccaratBetss, setCurrentBet] = baccaratBetsStore((state: any) => [state.baccaratBetss, state.setCurrentBet]);
  const [player] = usePlayerState((state: any) => [state.player]);
  const [baccaratGame, setBaccaratGame] = BaccaratStateStore((state: any) => [
    state.baccaratGame,
    state.setBaccaratGame,
  ]);

  const [extendedWindow, setExtendedWindow] = useState(0);
  const [setIsLowBalance] = lowBalanceStore((state: any) => [state.setIsLowBalance]);

  const [remainingGames, setHasAutoPlayPressed, isAutoplayStopped, setIsAutoplayStopped] = useAutoplayStore(
    (state: any) => [
      state.remainingGames,
      state.setHasAutoPlayPressed,
      state.isAutoplayStopped,
      state.setIsAutoplayStopped,
    ],
  );

  const [bet, setBet] = useState(20);
  const totalStake = baccaratBetss[0].stake + baccaratBetss[1].stake + baccaratBetss[2].stake;

  const [sideValue, setSideValue] = useSideValueStore((state: any) => [state.sideValue, state.setSideValue]);

  const [isShowModal, setIsShowModal] = useState(false);

  const handleColumnClick = (column: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let stakeValue = 0;
    if (props.playButtonDisabled) {
      return;
    }
    const betIndex = baccaratBetss.findIndex((bet: any) => bet.side === sideValue);

    const isPlayerBetPlaced = baccaratBetss[0].stake > 0;
    const isBankerBetPlaced = baccaratBetss[1].stake > 0;

    if ((column === 'banker' && isPlayerBetPlaced) || (column === 'player' && isBankerBetPlaced)) {
      return;
    }

    if (betIndex !== -1) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      stakeValue = baccaratBetss[betIndex].stake;
    }

    if (column === 'player') {
      setSideValue(0);
      ClickSound();
      setIsPlayerCalculatorShow(true);
      setIsBankerCalculatorShow(false);
      setIsTieCalculatorShow(false);
    } else if (column === 'banker') {
      setSideValue(1);
      ClickSound();
      setIsPlayerCalculatorShow(false);
      setIsBankerCalculatorShow(true);
      setIsTieCalculatorShow(false);
    } else if (column === 'tie') {
      setSideValue(2);
      ClickSound();
      setIsPlayerCalculatorShow(false);
      setIsBankerCalculatorShow(false);
      setIsTieCalculatorShow(true);
    }

    if (column === 'player' && isPlayerBetPlaced) {
      setActiveColumn(null);
    }

    setActiveColumn(column === activeColumn ? null : column);
    setIsBetPlaced(true);
    setIsCalculatorShow(true);
  };

  useEffect(() => {
    if (sideValue !== null) {
      setCurrentBet({
        side: sideValue,
        stake: baccaratBetss[sideValue].stake,
      });
    }
  }, [sideValue, setCurrentBet, baccaratBetss]);

  const [isWidthSmall, setIsWidthSmall] = useState(false);
  const [isHeightSmall, setIsHeightSmall] = useState(false);

  const [isWidthSmallest, setIsWidthSmallest] = useState(false);
  const [isHeightSmallest, setIsHeightSmallest] = useState(false);

  const size = useWindowSize();

  const handleResize = () => {
    if (!baccaratGame.isOpen) {
      setIsWidthSmall(size.width < 982);
      setIsHeightSmall(size.height <= 699);
      setIsWidthSmallest(size.width <= 700);
      setIsHeightSmallest(size.height <= 410);
    }
  };

  useEffect(() => {
    handleResize();
  }, [size.width < 982, size.height <= 699, size.width <= 700, size.height <= 410, baccaratGame.isOpen]);

  useEffect(() => {
    setActiveColumn(null);
  }, [baccaratBetss, baccaratGame.isOpen]);

  useEffect(() => {
    if (activeColumn === null && !baccaratGame.isOpen) {
      setIsCalculatorShow(false);
    } else {
      setIsCalculatorShow(true);
    }
  }, [!baccaratGame.isOpen, activeColumn]);

  const handleStopAutoplay = () => {
    ClickSound();
    setIsAutoplayStopped(true);
    // setBaccaratGame({
    //   ...baccaratGame,
    //   isAutoplayStopped: true,
    // });
  };

  const betChecker = () => {
    const defaultStake =
      totalStake >= 0 && bet >= baccaratParams.min_bet
        ? bet
        : 2 * calculateMultiplyingCoefficient(baccaratParams.max_bet) > baccaratParams.min_bet
          ? 2 * calculateMultiplyingCoefficient(baccaratParams.max_bet)
          : baccaratParams.min_bet;

    if (player.balance > defaultStake + totalStake) {
      setBet(defaultStake);
    } else {
      if (player.balance > baccaratParams.min_bet + totalStake) {
        setBet(player.balance - totalStake);
      } else {
        if (player.balance < baccaratParams.min_bet) {
          setBet(0);
        } else if (player.balance === totalStake) {
          setBaccaratGame({ ...baccaratGame, isAutoBet: false });
          setBet(0);
          setIsShowModal(true);
        } else if (player.balance < totalStake) {
          setBet(0);
          setIsShowModal(true);
        } else if (player.balance < baccaratParams.min_bet + totalStake) {
          setBet(0);
          setIsShowModal(true);
        } else {
          setBet(baccaratParams.min_bet);
        }
      }
    }
  };

  const calculatorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isWidthSmall && isCalculatorShow && !baccaratGame.isOpen && calculatorRef.current) {
      const heightToScroll = calculatorRef.current.getBoundingClientRect();
      const baccaratCont = document.getElementById('baccaratContainer');
      baccaratCont?.scrollTo({ top: heightToScroll.y, behavior: 'smooth' });
    }
  }, [isCalculatorShow]);

  useEffect(() => {
    setIsLowBalance(false);
    betChecker();
  }, [baccaratParams, player.balance, totalStake]);

  const lobbyAnimation = {
    closed: {
      transform: 'translateY(100px)',
      opacity: 0,
      display: 'none',
    },
    closing: {
      transform: 'translateY(100px)',
      opacity: 0,
      display: 'none',
    },
    open: {
      transform: 'translateY(0px)',
      opacity: 1,
      display: 'flex',
    },
  };
  const [startBtnAnim, setStartBtnAnim] = useState(lobbyAnimation.open);

  const QuickBtnAnimationHandler = async () => {
    if (baccaratGame.isOpen) {
      setStartBtnAnim(lobbyAnimation.closing);
      setStartBtnAnim(lobbyAnimation.closed);
    } else {
      setStartBtnAnim(lobbyAnimation.closing);
      setStartBtnAnim(lobbyAnimation.open);
    }
  };

  useEffect(() => {
    if (isWidthSmall) {
      QuickBtnAnimationHandler();
    }
  }, [baccaratGame.isOpen]);

  useEffect(() => {
    if (isWidthSmall) {
      QuickBtnAnimationHandler();
    }
  }, [baccaratGame.isOpen]);

  const [QuickMode, setQuickMode] = useState(false);

  const changeModeHandler = () => {
    ClickSound();
    setBaccaratGame({ ...baccaratGame, isQuickMode: !baccaratGame.isQuickMode });
    sessionStorage.setItem('isMultiMode', JSON.stringify(baccaratGame.isQuickMode));
  };

  const ModeChangeHandler = async () => {
    setQuickMode(baccaratGame.isQuickMode);

    // if (player.balance - totalStake < defaultStake && totalStake > 0) {
    //   andarBets.forEach((el: any) => {
    //     setAndarBetStake({ betName: el.side, stake: 0 });
    //   });
    // }
  };

  useEffect(() => {
    ModeChangeHandler();
  }, [baccaratGame.isQuickMode]);

  return (
    <>
      <InfoModal
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        applyHandler={() => {
          ClickSound();
          setExtendedWindow(0);
        }}
      />
      <Statistic isOpen={extendedWindow === 2 ? true : false} setExtendedWindow={setExtendedWindow} reason="Desk" />
      {!isWidthSmall && totalStake > 0 && (
        <AutoplayWindow
          playButtonDisabled={props.playButtonDisabled}
          isOpen={extendedWindow === 3 ? true : false}
          setExtendedWindow={setExtendedWindow}
          totalStake={totalStake}
          currency={baccaratParams.currency}
          baccaratBetss={baccaratBetss}
          startGame={props.startGame}
          sideValue={sideValue}
          reason="Desk"
        />
      )}

      {!baccaratGame.isQuickMode ? (
        <motion.div
          className={styles.baccaratLobby}
          style={{ display: 'none' }}
          animate={startBtnAnim}
          transition={{ duration: 0.3 }}
          initial={{ y: 0 }}
        >
          {!baccaratGame.isOpen && (
            <div className={styles.switchModeWrapper}>
              <span className={styles.switchModeText}>Multi Bet Mode</span>
              <Switch value={!QuickMode} id={'switchAndarGameMode'} onChange={changeModeHandler} />
            </div>
          )}
          {!isWidthSmall && (
            <div className={styles.desktopContentContainer}>
              <div className={styles.desktopBccrtMainBetsContent}>
                <div className={styles.topDesktopContent}></div>
                <div className={styles.bottomMainBetsContent}>
                  {isWidthSmall && (
                    <>
                      <Statistic isOpen={extendedWindow === 2 ? true : false} setExtendedWindow={setExtendedWindow} />
                      <div className={styles.statDesktopContainer}>
                        <div
                          className={styles.statDesktopBtn}
                          style={{ opacity: baccaratGame ? '0.2' : '1' }}
                          onClick={() => {
                            ClickSound();
                            setExtendedWindow(2);
                          }}
                        >
                          <StatisticIcon />
                          Statistics
                        </div>
                      </div>
                    </>
                  )}
                  <div className={styles.desktopBetColumnWrapper}>
                    {!isWidthSmall && (
                      <>
                        <div className={styles.statDesktopContainer}>
                          <div
                            className={styles.statDesktopBtn}
                            style={{ opacity: baccaratGame.isOpen ? '0.2' : '2' }}
                            onClick={() => {
                              if (!baccaratGame.isOpen) {
                                ClickSound();
                              }
                              if (props.playButtonDisabled) {
                                setExtendedWindow(0);
                              } else {
                                setExtendedWindow(2);
                              }
                            }}
                          >
                            <StatisticIcon />
                            Statistics
                          </div>
                        </div>
                      </>
                    )}
                    <div className={styles.desktopBetColumnContainer}>
                      <PlayerColumn
                        activeColumn={activeColumn}
                        activePair={activePair}
                        playerStake={baccaratBetss[0].stake}
                        onClick={!baccaratGame.isOpen ? () => handleColumnClick('player') : undefined}
                        totalStake={totalStake}
                      />
                      <TieColumn
                        activeColumn={activeColumn}
                        activePair={activePair}
                        tieStake={baccaratBetss[2].stake}
                        onClick={!baccaratGame.isOpen ? () => handleColumnClick('tie') : undefined}
                        totalStake={totalStake}
                      />
                      <BankerColumn
                        activeColumn={activeColumn}
                        activePair={activePair}
                        bankerStake={baccaratBetss[1].stake}
                        onClick={!baccaratGame.isOpen ? () => handleColumnClick('banker') : undefined}
                        totalStake={totalStake}
                      />
                    </div>
                  </div>
                  <div className={styles.desktopCalcContainer}>
                    {!isWidthSmall && !baccaratGame.isOpen && (
                      <div
                        className={
                          styles.desktopAutoplayBtnContainer +
                          ' ' +
                          `${totalStake > 0 && !baccaratGame.isOpen ? styles.show : styles.hide}`
                        }
                      >
                        <div
                          className={styles.autoplayBtn}
                          onClick={() => {
                            ClickSound();
                            setExtendedWindow(3);
                            if (props.playButtonDisabled) {
                              setExtendedWindow(0);
                            }
                          }}
                        >
                          <SettingButtonAuto />
                          Autoplay
                        </div>
                      </div>
                    )}
                    {baccaratGame.isOpen && baccaratGame.isAutoBet && !isWidthSmall ? (
                      <div className={styles.autoBetCounter}>
                        <span className={styles.remainingGame}>
                          {isAutoplayStopped ? (
                            <span className={styles.autoPlayStopped}>AutoPlay stopped</span>
                          ) : (
                            <span className={styles.autoplay}>
                              Autoplay <span className={styles.remainingGames}>{remainingGames}</span> Games
                            </span>
                          )}
                        </span>{' '}
                        {!isAutoplayStopped && (
                          <button className={styles.autobetContainer} onClick={handleStopAutoplay}>
                            Stop
                          </button>
                        )}
                      </div>
                    ) : baccaratGame.isOpen && !isWidthSmall ? (
                      <div className={styles.autoBetCounter} style={{ height: 32 }}></div>
                    ) : (
                      ''
                    )}
                    {!isWidthSmall && isDesktopCalculatorShow && (
                      <div className={styles.calculatorWrapper}>
                        <Calculator
                          bet={bet}
                          setBet={setBet}
                          totalStake={totalStake}
                          isCalculatorShow={isDesktopCalculatorShow}
                          isFreebetPlay={true}
                        />
                        <StartBtn
                          playButtonDisabled={props.playButtonDisabled}
                          totalStake={totalStake}
                          currency={baccaratParams.currency}
                          baccaratBetss={baccaratBetss}
                          startGame={props.startGame}
                        />
                      </div>
                    )}
                  </div>
                  {/* {!isWidthSmall && (
                  <StartBtn
                    playButtonDisabled={props.playButtonDisabled}
                    totalStake={totalStake}
                    currency={baccaratParams.currency}
                    baccaratBetss={baccaratBetss}
                    startGame={props.startGame}
                  />
                )} */}
                  {isWidthSmall && (
                    <div
                      className={styles.calculatorWrapper}
                      style={{ height: !isCalculatorShow ? '0' : '100%' }}
                      ref={calculatorRef}
                    >
                      {isPlayerCalculatorShow && (
                        <Calculator bet={bet} setBet={setBet} totalStake={totalStake} isFreebetPlay={true} />
                      )}
                      {isBankerCalculatorShow && (
                        <Calculator bet={bet} setBet={setBet} totalStake={totalStake} isFreebetPlay={true} />
                      )}
                      {isTieCalculatorShow && (
                        <Calculator bet={bet} setBet={setBet} totalStake={totalStake} isFreebetPlay={true} />
                      )}
                    </div>
                  )}
                </div>
                {/* {!isWidthSmall && (
                <StartBtn
                  playButtonDisabled={props.playButtonDisabled}
                  totalStake={totalStake}
                  currency={baccaratParams.currency}
                  baccaratBetss={baccaratBetss}
                  startGame={props.startGame}
                />
              )} */}
              </div>
              <div style={{ zIndex: '9' }}>{isFireworkShown && <FireworkComponent />}</div>
            </div>
          )}
          {isWidthSmall && (
            <div className={styles.contentContainer}>
              {activeTab === 'main-bets' && (
                <div className={styles.bccrtMainBetsContent}>
                  <div className={styles.betColumnContainer}>
                    <PlayerColumn
                      activeColumn={activeColumn}
                      activePair={activePair}
                      playerStake={baccaratBetss[0].stake}
                      onClick={() => handleColumnClick('player')}
                      totalStake={totalStake}
                    />
                    <TieColumn
                      activeColumn={activeColumn}
                      activePair={activePair}
                      tieStake={baccaratBetss[2].stake}
                      onClick={() => handleColumnClick('tie')}
                      totalStake={totalStake}
                    />
                    <BankerColumn
                      activeColumn={activeColumn}
                      activePair={activePair}
                      bankerStake={baccaratBetss[1].stake}
                      onClick={() => handleColumnClick('banker')}
                      totalStake={totalStake}
                    />
                  </div>
                  {isWidthSmall && (
                    <div
                      className={styles.calculatorWrapper}
                      style={{ height: !isCalculatorShow ? '0' : '100%' }}
                      ref={calculatorRef}
                    >
                      {isPlayerCalculatorShow && (
                        <Calculator bet={bet} setBet={setBet} totalStake={totalStake} isFreebetPlay={true} />
                      )}
                      {isBankerCalculatorShow && (
                        <Calculator bet={bet} setBet={setBet} totalStake={totalStake} isFreebetPlay={true} />
                      )}
                      {isTieCalculatorShow && (
                        <Calculator bet={bet} setBet={setBet} totalStake={totalStake} isFreebetPlay={true} />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          <div
            className={isWidthSmallest && isHeightSmallest ? styles.relativeFooter : styles.footerBaccarat}
            style={{
              ...(isWidthSmall &&
                isHeightSmall &&
                isCalculatorShow && {
                  transform: totalStake <= 0 ? 'translateY(50px)' : 'translateY(0px)',
                  opacity: 1,
                  height: totalStake <= 0 ? '32px' : '20px',
                  transition: '0.2s ease-in-out',
                  ...(isCalculatorShow &&
                    isHeightSmall &&
                    totalStake >= 0 && {
                      transform: '',
                      height: '32px',
                      transition: '0.2s ease-out',
                    }),
                }),
            }}
          >
            {totalStake > 0 && isWidthSmall && !isCalculatorShow && (
              <div className={styles.autoplayBtnContainer}>
                <div
                  className={styles.autoplayBtn}
                  onClick={() => {
                    ClickSound();
                    setHasAutoPlayPressed(true);
                    if (props.playButtonDisabled) {
                      setHasAutoPlayPressed(false);
                    }
                  }}
                >
                  <SettingButtonAuto />
                  Autoplay
                </div>
              </div>
            )}
            {isWidthSmall && (
              <StartBtn
                playButtonDisabled={props.playButtonDisabled}
                totalStake={totalStake}
                currency={baccaratParams.currency}
                baccaratBetss={baccaratBetss}
                startGame={props.startGame}
              />
            )}
          </div>
        </motion.div>
      ) : (
        <motion.div
          className={styles.baccaratLobby}
          style={{ display: 'none' }}
          animate={startBtnAnim}
          transition={{ duration: 0.3 }}
          initial={{ y: 0 }}
        >
          <QuickModeBetsPanel
            bet={bet}
            setBet={setBet}
            totalStake={totalStake}
            currency={player.currency}
            startGame={props.startGame}
          />{' '}
          <div className={styles.contentContainer + ' ' + styles.contentContainerQuick}>
            {!isWidthSmall && (
              <>
                <div
                  className={styles.statDesktopBtn + ' ' + styles.statDesktopBtnQuick}
                  style={{ opacity: baccaratGame.isOpen ? '0.2' : '2' }}
                  onClick={() => {
                    if (!baccaratGame.isOpen) {
                      ClickSound();
                    }
                    if (props.playButtonDisabled) {
                      setExtendedWindow(0);
                    } else {
                      setExtendedWindow(2);
                    }
                  }}
                >
                  <StatisticIcon />
                  Statistics
                </div>
              </>
            )}
            <div className={styles.calculatorPrevText}>Select amount</div>
            <div className={styles.calculatorWrapper} style={{ height: '100%' }} ref={calculatorRef}>
              <Calculator bet={bet} setBet={setBet} totalStake={totalStake} isFreebetPlay={true} />
            </div>
          </div>
          {!baccaratGame.isOpen && (
            <div className={styles.switchModeWrapper}>
              <span className={styles.switchModeText}>Multi Bet Mode</span>
              <Switch value={!QuickMode} id={'switchAndarGameMode'} onChange={changeModeHandler} />
            </div>
          )}
          {!isWidthSmall && <div style={{ zIndex: '9' }}>{isFireworkShown && <FireworkComponent />}</div>}
        </motion.div>
      )}
    </>
  );
};
