import * as amplitude from '@amplitude/analytics-browser';
import React, { useEffect, useState } from 'react';

import { ReactComponent as StatisticIcon } from '..//../assets/statisticIcon.svg';
import * as API from '../../helpers/API';
import { ClickSound, lose_sound, win_sound } from '../../helpers/AudioHelper';
import * as Constants from '../../helpers/Constants';
import { lowBalanceStore, stateStore, usePlayerState } from '../../Store';

import { About } from './About';
import AutoplayWindow from './autoplay/AutoplayWindow';
import styles from './Baccarat.module.scss';
import useAutoplayStore, {
  BaccaratStateStore,
  baccaratBetsStore,
  baccaratPlayRequestStore,
  useBaccaratParams,
} from './BaccaratStore';
import { Game } from './Game';
import InfoModal from './infoModal/InfoModal';
import { Lobby } from './Lobby';
import Statistic from './Statistics';

export const Baccarat = () => {
  const [extendedWindow, setExtendedWindow] = useState(0);
  const [setBaccaratParams] = useBaccaratParams((state: any) => [state.setBaccaratParams]);
  const [player, setPlayer] = usePlayerState((state: any) => [state.player, state.playerState]);
  const [setIsLowBalance] = lowBalanceStore((state: any) => [state.setIsLowBalance]);

  const sleep = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));
  const [baccaratBetss, setBaccaratBets] = baccaratBetsStore((state: any) => [
    state.baccaratBetss,
    state.setBaccaratBets,
  ]);
  const [baccaratParams] = useBaccaratParams((state: any) => [state.baccaratParams]);
  const [setPlayBaccaratResponse] = baccaratPlayRequestStore((state: any) => [state.setPlayBaccaratResponse]);
  const [setPlayBaccaratRequest] = baccaratPlayRequestStore((state: any) => [state.setPlayBaccaratRequest]);
  const [baccaratGame, setBaccaratGame] = BaccaratStateStore((state: any) => [
    state.baccaratGame,
    state.setBaccaratGame,
  ]);
  const [hasAutoPlayPressed] = useAutoplayStore((state: any) => [state.hasAutoPlayPressed]);
  const totalStake = baccaratBetss[0].stake + baccaratBetss[1].stake + baccaratBetss[2].stake;
  const [isShowModal, setIsShowModal] = useState(false);

  const [playButtonDisabled, setPlayButtonDisabled] = useState(false);

  async function startGame(params: any) {
    win_sound.load();
    lose_sound.load();
    amplitude.track('Play', {
      side: params.side,
      stake: totalStake,
      game: 'Baccarat',
      auto_play: baccaratGame.isAutoBet,
      quick_mode: baccaratGame.isQuickMode,
    });
    if (!baccaratGame.isAutoBet) {
      ClickSound();
    }
    setPlayButtonDisabled(true);
    if (player.balance >= totalStake && player.balance >= baccaratParams.min_bet) {
      setIsLowBalance(false);
      const selectedBets = baccaratBetss.filter((el: any) => el.stake > 0);
      const data = {
        demo: false,
        bets: selectedBets,
      };

      API.post(Constants.play, data, player.token).then((json) => {
        if (json) {
          amplitude.track('Play response', {
            ...json,
            game_name: baccaratParams.name,
            quick_mode: baccaratGame.isQuickMode,
          });
          setPlayBaccaratResponse(json);
          setPlayer({ ...player, balance: player.balance - totalStake });
          setBaccaratGame({
            ...baccaratGame,
            isOpen: true,
            side: params.side,
          });
        }
      });

      setPlayBaccaratRequest(data);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const baccarat = document.getElementById('baccarat');

      await sleep(300);
    } else {
      setIsLowBalance(true);
    }
  }

  const [error, errorState] = stateStore((state: any) => [state.error, state.errorState]);
  useEffect(() => {
    if (player.token != null) {
      API.post(Constants.game_params, { demo: false }, player.token).then((json) => {
        if (json.id !== Number(process.env.REACT_APP_BUILD_ID)) {
          errorState({ ...error, isOpen: true, messageId: 0, message: 'Wrong token' });
        }
        setBaccaratParams(json);
        setBaccaratBets([
          { side: 0, coef: (json.player_coef - 1).toFixed(0), stake: 0, name: 'player' },
          { side: 1, coef: (json.banker_coef - 1).toFixed(2), stake: 0, name: 'banker' },
          { side: 2, coef: (json.tie_coef - 1).toFixed(0), stake: 0, name: 'tie' },
        ]);
        setPlayer({ ...player, balance: json.balance });
        amplitude.track('Join game', {
          game: 'Baccarat',
        });
      });
    }
  }, [player.token]);

  const [isWidthSmall, setIsWidthSmall] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsWidthSmall(window.innerWidth <= 981);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      key={'baccarat'}
      id={'baccarat'}
      style={{
        width: '100%',
        display: 'contents',
        overflowY: 'auto',
        height: '100%',
      }}
    >
      {isWidthSmall && totalStake > 0 && (
        <AutoplayWindow
          playButtonDisabled={playButtonDisabled}
          isOpen={hasAutoPlayPressed}
          setExtendedWindow={setExtendedWindow}
          totalStake={totalStake}
          currency={baccaratParams.currency}
          baccaratBetss={baccaratBetss}
          startGame={startGame}
        />
      )}
      <InfoModal
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        applyHandler={() => {
          ClickSound();
          setExtendedWindow(0);
        }}
      />
      {isWidthSmall && (
        <>
          <Statistic isOpen={extendedWindow === 2 ? true : false} setExtendedWindow={setExtendedWindow} />
          <div className={styles.staiscticContainer} style={{ opacity: baccaratGame.isOpen ? '0.2' : '2' }}>
            <div
              className={styles.statisticBtn}
              onClick={() => {
                if (!baccaratGame.isOpen) {
                  ClickSound();
                }
                if (playButtonDisabled) {
                  setExtendedWindow(0);
                } else {
                  setExtendedWindow(2);
                }
              }}
            >
              <StatisticIcon />
              Statistics
            </div>
          </div>
        </>
      )}
      <About />
      <div
        id={'baccaratContainer'}
        className={styles.baccaratContainer}
        style={{
          overflowY: !baccaratGame.isOpen && isWidthSmall ? 'auto' : 'unset',
        }}
      >
        <Game
          startGame={startGame}
          playButtonDisabled={playButtonDisabled}
          setPlayButtonDisabled={setPlayButtonDisabled}
        />
        <Lobby startGame={startGame} playButtonDisabled={playButtonDisabled} />
      </div>
    </div>
  );
};
