import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import styles from './Game.module.scss';
import GameLogic from './GameLogic';
import BetPanel from './modules/BetPanel';
import BetsHistory from './modules/BetsHistory';
import CashOutPanel from './modules/CashOutPanel';
import PlaneAnimation from './modules/planeAnimation/PlaneAnimation';
import { PlaneBackgroundWithAnimation } from './modules/planeAnimation/PlaneBackgroundWithAnimation';
import { aviatorStore } from './Store';

export default function Game() {
  const { aviatorParams } = aviatorStore();
  const { playState } = aviatorStore();
  const { planeAnimation, setPlaneAnimationSize } = aviatorStore();
  const { displayState, lastIndexes, cashOutMessage } = aviatorStore();

  const { betPanelOne, setBetPanelOne, betPanelTwo, setBetPanelTwo } = aviatorStore();
  const gameStateBlock = useRef<any>();
  const animRef = useRef<any>();
  const [planeBgStyle, setPlaneBgStyle] = useState({});

  useEffect(() => {
    if (gameStateBlock) {
      setPlaneAnimationSize({
        width: gameStateBlock.current.clientWidth,
        height: gameStateBlock.current.clientHeight,
      });
    }
  }, [gameStateBlock.current?.clientWidth]);

  GameLogic();

  const [cashOutBanners, setCashOutBanners] = useState<any>([]);
  useEffect(() => {
    if (cashOutMessage.coef > 0) {
      const newElement = (
        <CashOutPanel
          coef={cashOutMessage.coef}
          profit={cashOutMessage.profit}
          stake_id={cashOutMessage.stake_id}
          key={uuidv4()}
        />
      );
      setCashOutBanners((prevElements: any) => [newElement, ...prevElements]);
    }
  }, [cashOutMessage]);

  useEffect(() => {
    if (animRef.current) {
      setPlaneBgStyle({
        width: animRef.current.clientWidth * 4,
        top: -animRef.current.clientWidth * 2 + animRef.current.clientHeight,
        left: -animRef.current.clientWidth * 2,
      });
    }
  }, [animRef, gameStateBlock.current?.clientWidth]);

  const [planeObject, setPlaneObject] = useState<React.JSX.Element>();
  useEffect(() => {
    setPlaneObject(planeAnimation.visible ? <PlaneAnimation /> : undefined);
  }, [planeAnimation.visible]);
  const [displayObject, setDisplayObject] = useState<any>();
  useEffect(() => {
    setDisplayObject(displayState.obj);
  }, [displayState.obj]);

  const [previousLastIndexes, setPreviousLastIndexes] = useState([]);
  useEffect(() => {
    setPreviousLastIndexes(lastIndexes.slice(1));
  }, [lastIndexes]);

  const ref = useRef<any>();
  const [leftPadding, setLeftPadding] = useState(0);
  useEffect(() => {
    if (ref.current) {
      setLeftPadding(ref.current.clientWidth + 8);
    }
  }, [lastIndexes]);
  return (
    <div className={styles.game}>
      <div className={styles.container}>
        <div className={styles.lastIndexes}>
          <div className={styles.last} ref={ref}>
            <a>Last price:</a>
            <a
              style={{
                color: 'var(--accent-system-pink)',
              }}
            >
              {lastIndexes[0] && lastIndexes[0] + 'x'}
            </a>
          </div>
          <div className={styles.previous} style={{ paddingLeft: leftPadding + 'px' }}>
            {previousLastIndexes.map((value, index) => {
              return (
                <a
                  style={{
                    color: 'var(--text-secondary)',
                  }}
                  key={index}
                >
                  {value && value + 'x'}
                </a>
              );
            })}
          </div>
        </div>

        <div className={styles.gameContainer} style={{ zIndex: 100, position: 'sticky', top: '25px' }}>
          <div className={styles.animation} ref={animRef}>
            <div className={styles.planeAnimationBackgroundLines} style={planeBgStyle}>
              {PlaneBackgroundWithAnimation({ isRotate: planeAnimation.visible })}
            </div>
            <div className={styles.gameState} ref={gameStateBlock}>
              {planeObject}
              {displayObject}
            </div>
            <div className={styles.cashOutMessages}>{cashOutBanners}</div>
          </div>
        </div>
        <div className={styles.gameContainer}>
          <div className={styles.stakes}>
            <BetPanel
              store={betPanelOne}
              setStore={setBetPanelOne}
              params={aviatorParams}
              playState={playState}
              betPanelId={1}
            />
            <BetPanel
              store={betPanelTwo}
              setStore={setBetPanelTwo}
              params={aviatorParams}
              playState={playState}
              betPanelId={2}
            />
          </div>
        </div>
        <BetsHistory />
      </div>
    </div>
  );
}
