import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { BaccaratStateStore, CardsStore } from '../BaccaratStore';

import { Card } from './Card';

import './Row.scss';

interface RowProps {
  isTop: boolean;
  numSlots: number;
  countTop?: number;
  countBottom?: number;
}
export const Row = ({ isTop, numSlots, countTop, countBottom }: RowProps) => {
  const cards = isTop ? CardsStore((state) => state.cardsTop) : CardsStore((state) => state.cardsBottom);

  const emptySlots = Array.from({ length: numSlots });

  const [isWidthSmall, setIsWidthSmall] = useState(false);
  const [isHeightSmall, setIsHeightSmall] = useState(false);
  const [baccaratGame] = BaccaratStateStore((state: any) => [state.baccaratGame]);

  useEffect(() => {
    const handleResize = () => {
      setIsWidthSmall(window.innerWidth <= 991);
      setIsHeightSmall(window.innerWidth <= 450);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={{
        paddingRight: isHeightSmall && isWidthSmall && !isTop ? '23px' : undefined,
      }}
    >
      {isTop ? (
        <a className="baccarat-position-dealer">
          <FormattedMessage id="banker" />
          <span>: {countTop}</span>
        </a>
      ) : (
        <a className="baccarat-position-player">
          <FormattedMessage id="player" />
          <span>: {countBottom}</span>
        </a>
      )}
      <div className="baccarat-row">
        {cards.map((card: { card: string; name: string }, index: number) => (
          <Card key={index} card={card} isTop={isTop} index={index} />
        ))}
        {emptySlots.map((_, index) => (
          <motion.div
            key={index}
            className={` ${isTop && index === 0 ? 'rotated-top-card' : 'baccarat-placeholder'} 
          ${!isTop && index === 2 ? 'rotate-button-card' : 'baccarat-placeholder'} 
          ${baccaratGame.isOpen ? 'baccarat-placeholder-open' : 'baccarat-placeholder-closed'}`}
          />
        ))}
      </div>
    </div>
  );
};
