import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import useSound from 'use-sound'; // Импортируем PlayOptions

// Импортируем все звуковые файлы
import aviatorBackground from '../assets/sounds/aviatorBackgroundNew.mp3';
import background from '../assets/sounds/background.mp3';
import background2 from '../assets/sounds/background2.mp3';
import bezel from '../assets/sounds/bezelSound.mp3';
import card from '../assets/sounds/card.mp3';
import click from '../assets/sounds/click.mp3';
import click2 from '../assets/sounds/click2.mp3';
// import jazzRoulette from '../assets/sounds/jazzRoulette.mp3';
import lose from '../assets/sounds/lose.mp3';
import negativeBeeps from '../assets/sounds/negative_beeps.mp3';
import ruletkaEnd from '../assets/sounds/ruletka-sound_end.mp3';
import ruletkaBall from '../assets/sounds/ruletka_ball_sound.mp3';
import win from '../assets/sounds/win.mp3';
import winUpBalance from '../assets/sounds/winUpBalance.mp3';
import { stateStore } from '../Store';

import * as Constants from './Constants';

interface MenuState {
  isSoundOn: boolean;
  isMusicOn: boolean;
}

// Интерфейс для определения звуковых контролов
interface SoundControls {
  InitAudioProvider: () => void;
  MuteSound: () => void;
  UnMuteSound: () => void;
  MuteBackground: () => void;
  UnMuteBackground: () => void;
  ClickSound: () => void;
  ChipSound: () => void;
  RouletteSound: (type: 'play' | 'stop') => void;
  RouletteSoundEnd: (type: 'play' | 'stop') => void;
  BackgroundSound: (type: 'play' | 'stop') => void;
  WinSound: () => void;
  LoseSound: () => void;
  CardSound: () => void;
}

interface SoundProviderProps {
  children: ReactNode;
}

const SoundContext = createContext<SoundControls | undefined>(undefined);

const buildType = Constants.Select_build_types(process.env.REACT_APP_BUILD_ID);

// Определение переменных для звуков
let clickSound: string = click;
let backgroundSound: string = background;
let cardSound: string = card;
let winSound: string = win;
let loseSound: string = lose;
const chipSound: string = bezel;
const rouletteSound: string = ruletkaBall;
const rouletteSoundEnd: string = ruletkaEnd;

switch (buildType) {
  case Constants.build_types.Andar:
    break;
  case Constants.build_types.Baccarat:
    clickSound = click2;
    backgroundSound = background2;
    cardSound = card;
    winSound = winUpBalance;
    loseSound = negativeBeeps;
    break;

  // case Constants.build_types.Roulette:
  //   clickSound = click;
  //   backgroundSound = jazzRoulette;
  //   cardSound = card;
  //   winSound = winUpBalance;
  //   loseSound = negativeBeeps;
  //   chipSound = bezel;
  //   rouletteSound = ruletkaBall;
  //   rouletteSoundEnd = ruletkaEnd;
  //   break;

  case Constants.build_types.Aviator:
    clickSound = click;
    backgroundSound = aviatorBackground;
    break;

  default:
    clickSound = click;
    backgroundSound = background;
    cardSound = card;
    winSound = win;
    loseSound = lose;
    break;
}

export const SoundProvider: React.FC<SoundProviderProps> = ({ children }) => {
  const [menu, setMenu] = useState<MenuState>({
    isSoundOn: true,
    isMusicOn: true,
  });

  const [playClickSound] = useSound(clickSound, { volume: 0.3, playbackRate: 1 });
  const [playBackgroundSound, { sound: bgSound, stop: stopBgSound }] = useSound(backgroundSound, {
    volume: 0.2,
    playbackRate: 1.05,
    loop: true,
  });
  const [playCardSound, { sound: cSound }] = useSound(cardSound);
  const [playWinSound, { sound: wSound }] = useSound(winSound);
  const [playLoseSound, { sound: lSound }] = useSound(loseSound);
  const [playChipSound] = useSound(chipSound);
  const [playRouletteSound, { sound: roulSound, stop: stopRoulSound }] = useSound(rouletteSound);
  const [playRouletteSoundEnd, { sound: roulSoundEnd, stop: stopRoulSoundEnd }] = useSound(rouletteSoundEnd);

  const state = stateStore((state: any) => state.menu);

  useEffect(() => {
    setMenu(state);
  }, [state]);

  const InitAudioProvider = useMemo(
    () => async (): Promise<void> => {
      !menu.isSoundOn ? MuteSound() : UnMuteSound();
      !menu.isMusicOn ? MuteBackground() : UnMuteBackground();
    },
    [menu.isSoundOn, menu.isMusicOn],
  );

  useEffect(() => {
    InitAudioProvider();
  }, [menu, InitAudioProvider]);

  const MuteSound = (): void => {
    roulSound?.mute(true);
    roulSoundEnd?.mute(true);
    wSound?.mute(true);
    lSound?.mute(true);
    cSound?.mute(true);
  };

  const UnMuteSound = (): void => {
    roulSound?.mute(false);
    roulSoundEnd?.mute(false);
    wSound?.mute(false);
    lSound?.mute(false);
    cSound?.mute(false);
  };

  const MuteBackground = (): void => {
    bgSound?.mute(true);
  };

  const UnMuteBackground = (): void => {
    bgSound?.mute(false);
  };

  const ClickSound = (): void => {
    if (menu.isSoundOn) playClickSound();
  };

  const ChipSound = (): void => {
    if (menu.isSoundOn) playChipSound();
  };

  const CardSound = (): void => {
    if (menu.isSoundOn) playCardSound();
  };

  const RouletteSound = (type: 'play' | 'stop'): void => {
    switch (type) {
      case 'play':
        if (menu.isSoundOn) playRouletteSound();
        break;
      case 'stop':
        stopRoulSound?.();
        break;
      default:
        stopRoulSound?.();
    }
  };

  const RouletteSoundEnd = (type: 'play' | 'stop'): void => {
    switch (type) {
      case 'play':
        if (menu.isSoundOn) {
          playRouletteSoundEnd();
        }
        break;
      case 'stop':
        stopRoulSoundEnd?.();
        break;
      default:
        stopRoulSoundEnd?.();
    }
  };

  const [isBack, setIsBack] = useState(false);

  const BackgroundSound = (type: 'play' | 'stop'): void => {
    switch (type) {
      case 'play':
        if (menu.isMusicOn && !isBack) {
          playBackgroundSound();
          setIsBack(true);
        }
        break;
      case 'stop':
        stopBgSound?.();
        setIsBack(false);
        break;
      default:
        stopBgSound?.();
        setIsBack(false);
    }
  };

  const WinSound = (): void => {
    if (menu.isSoundOn) playWinSound();
  };

  const LoseSound = (): void => {
    if (menu.isSoundOn) playLoseSound();
  };

  const value = useMemo(
    () => ({
      InitAudioProvider,
      MuteSound,
      UnMuteSound,
      MuteBackground,
      UnMuteBackground,
      ClickSound,
      ChipSound,
      RouletteSound,
      RouletteSoundEnd,
      BackgroundSound,
      WinSound,
      LoseSound,
      CardSound,
    }),
    [
      InitAudioProvider,
      MuteSound,
      UnMuteSound,
      MuteBackground,
      UnMuteBackground,
      ClickSound,
      ChipSound,
      RouletteSound,
      RouletteSoundEnd,
      BackgroundSound,
      WinSound,
      LoseSound,
      CardSound,
    ],
  );

  return <SoundContext.Provider value={value}>{children}</SoundContext.Provider>;
};

export const useSoundContext = (): SoundControls => {
  const context = useContext(SoundContext);
  if (context === undefined) {
    throw new Error('useSoundContext must be used within a SoundProvider');
  }
  return context;
};
