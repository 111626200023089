import React from 'react';

import { ReactComponent as ExitIcon } from '../../assets/closeButton.svg';

import styles from './modalWrapper.module.scss';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  children: any;
  needCloseButton?: boolean;
};

export default function ModalWrapper(props: Props) {
  if (props.isOpen) {
    return (
      <div
        className={styles.background}
        onClick={(e) => {
          e.currentTarget === e.target ? props.needCloseButton && props.closeModal() : '';
        }}
      >
        <div className={styles.popUpWrapper}>
          <div className={styles.popUpHead}>
            {props.needCloseButton && (
              <ExitIcon
                onClick={() => {
                  props.closeModal();
                }}
              />
            )}
          </div>

          {props.children}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
