import { DefaultEventsMap } from '@socket.io/component-emitter';
import React from 'react';
import { Socket, io } from 'socket.io-client';

import * as Constants from '../Constants';

export let socket: Socket<DefaultEventsMap, DefaultEventsMap>;
export let isSocketConnected: boolean;

export default function InitSocket(auth: any) {
  socket = io(Constants.socket, {
    reconnection: true,
    autoConnect: false,
    reconnectionDelay: 100,
    reconnectionAttempts: 5000,
    reconnectionDelayMax: 10000,
    auth,
    // auth: {
    //   token: token,
    // },
    extraHeaders: {
      // 'ngrok-skip-browser-warning': 'true',
      // ngrokSkipBrowserWarning: 'true',
    },
    transports: ['websocket'],
    // transports: ['polling'],
    // transports: ['websocket', 'polling'],
  });
  socket.connect();
}

export function SocketListener() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const buildType = Constants.Select_build_types(process.env.REACT_APP_BUILD_ID);
  socket.on('connect', onConnect);

  function onConnect() {
    isSocketConnected = true;
  }

  socket.on('disconnect', onDisconnect);

  function onDisconnect() {
    isSocketConnected = false;
    // console.log('disconnected');
  }

  // const listener = (eventName: any, ...args: any[]) => {
  //   if (buildType === Constants.build_types.Aviator && eventName !== 'play') {
  //     console.log(eventName, args);
  //   }
  // };
  // socket.onAny(listener);

  return () => {
    socket.removeAllListeners();
  };
}

export function SocketDevButtons() {
  return (
    <div
      style={{
        left: 0,
        right: 0,
        justifyContent: 'center',
        display: 'inline-flex',
        position: 'absolute',
        zIndex: 999,
      }}
    >
      <button onClick={ConnectSocket}>Connect</button>
      <button onClick={DisconnectSocket}>Disconnect</button>
      <button onClick={sendTestRequest}>Send Test</button>
    </div>
  );
}

async function ConnectSocket() {
  socket.connect();
}

async function DisconnectSocket() {
  socket.disconnect();
}

async function sendTestRequest() {
  const a = { hello: 'WORLD' };
  socket.emit('echo', a);
}

export async function sendRequest(event: string, data: any) {
  socket.emit(event, JSON.stringify(data));
}
