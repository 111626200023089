/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Player } from '@lottiefiles/react-lottie-player';
import Decimal from 'decimal.js';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';

import animation from '../assets/animation.json';
import { ReactComponent as DeleteIcon } from '../assets/delete.svg';
import { ReactComponent as MinusIcon } from '../assets/minusIcon.svg';
import { ReactComponent as PlusIcon } from '../assets/plusIcon.svg';
import { usePlayerState } from '../Store';

// import { ClickSound } from './AudioHelper';
import styles from './BetCalculator.module.scss';
import { useSoundContext } from './SoundContext';
import Switch from './Switch';

let _min_bet: number;
let _max_bet: number;
let _step: number;
let _currency = 'INR';
let _prevOperationButton: any;

interface BetCalculatorParamsInterface {
  min_bet: number;
  max_bet: number;
  step: number;
  currency: string;
}

export function InitBetCalculator(params: BetCalculatorParamsInterface) {
  _min_bet = params.min_bet;
  _max_bet = params.max_bet;
  _step = params.step;
  _currency = params.currency;
}

interface DisplayParamsInterface {
  bet: number;
  setBet: any;
  balance: number;
  styles?: any;
  isButtonsHidden?: boolean;
  isFreeBet?: boolean;
  needNumPad?: boolean;
  needNumPadDot?: boolean;
  isNumPadOn?: boolean;
  setIsNumpadOn?: any;
}

export function Display(params: DisplayParamsInterface) {
  const { ClickSound } = useSoundContext();
  const classes = params.styles ? params.styles : styles;
  // const [isNumpadOn, setIsNumpadOn] = useState(false);
  const [displayStyle, setDisplayStyle] = useState<any>();

  // const NumpadChildMemo = React.memo(Numpad);

  function displayClick() {
    ClickSound();
    params.setIsNumpadOn(!params.isNumPadOn);
    setDisplayStyle({
      border: !params.isNumPadOn && '1px solid var(--accent-invertible-secondary)',
    });
  }

  function doneClick() {
    displayClick();
    changeBet({
      operation: 'numpad',
      value: params.bet,
      setBet: params.setBet,
      balance: params.balance,
      bet: params.bet,
    });
  }

  function btn(operation?: any) {
    if (params.isFreeBet) {
      return (
        <div className={`${classes.button} ${classes.animate}`}>
          <Player style={{ width: 30, height: 30 }} autoplay loop src={animation}></Player>
        </div>
      );
    } else {
      if (!params.isButtonsHidden) {
        return (
          <div
            className={`${classes.button} ${classes.minus}`}
            onClick={() => {
              changeBet({
                operation: operation,
                value: _step,
                bet: params.bet,
                setBet: params.setBet,
                balance: params.balance,
              });
              ClickSound();
            }}
          >
            {operation === '+' ? <PlusIcon /> : <MinusIcon />}
          </div>
        );
      }
    }
  }

  return (
    <div className={classes.betPanel} style={displayStyle}>
      {btn('-')}
      <div style={{ overflow: 'hidden', width: '100%' }}>
        <p className={`${classes.text} ${classes.betText}`} onClick={() => params.needNumPad && displayClick()}>
          <FormattedNumber value={params.bet} />
        </p>
      </div>

      {btn('+')}
      {params.isNumPadOn && params.needNumPad && (
        <Numpad
          // store={params}
          value={params.bet}
          setValue={params.setBet}
          doneClick={doneClick}
          needDot={params.needNumPadDot}
          position={{ left: '0px', bottom: '65px' }}
          setIsNumpadOn={params.setIsNumpadOn}
        />
      )}
    </div>
  );
}

interface BetButtonParamsInterface {
  text: string;
  betValue?: number;
  color?: string;
  styles?: any;
  onClick?: any;
}

export function BetButton(params: BetButtonParamsInterface) {
  const { ClickSound } = useSoundContext();
  const classes = params.styles ? params.styles : styles;
  const intl = useIntl();
  let CurrencyNumber;
  if (params.betValue) {
    CurrencyNumber = intl
      .formatNumberToParts(params.betValue, {
        style: 'currency',
        currency: _currency,
        minimumFractionDigits: 2,
      })
      .map(({ type, value }) => {
        switch (type) {
          case 'currency':
            return (
              <span style={{ fontWeight: '400' }} key={value}>
                {value}
              </span>
            );
          default:
            return value;
        }
      });
  }

  function betButtonClick() {
    ClickSound();
    params.onClick();
  }

  return (
    <div className={classes} style={{ background: params.color, overflow: 'hidden' }} onClick={betButtonClick}>
      <a>{params.text}</a>
      {params.betValue ? <p>{CurrencyNumber}</p> : ''}
    </div>
  );
}

interface ButtonWithValueParamsInterface {
  operation: '-' | '+' | 'min' | 'max' | 'x2' | 'clear' | 'clear_to_zero' | 'specific-bet';
  value: any;
  bet: number;
  setBet: any;
  balance: number;
  totalBet?: any;
  textColor?: any;
}

export function ButtonWithValue(params: ButtonWithValueParamsInterface) {
  const { ClickSound } = useSoundContext();
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (_prevOperationButton !== event.currentTarget) {
      _prevOperationButton = null;
    }
    changeBet({
      operation: params.operation,
      value: params.value,
      bet: params.bet,
      setBet: params.setBet,
      balance: params.balance,
      totalBet: params.totalBet,
    });
    ClickSound();
    //TODO: _prevOperationValue
    _prevOperationButton = event.currentTarget;
  };

  return (
    <div className={`${styles.button} ${styles.buttonItem}`} onClick={handleClick}>
      <a className={styles.text} style={{ color: params.textColor }}>
        {typeof params.value === 'number' ? (
          <>
            <FormattedNumber value={params.value} />
          </>
        ) : (
          params.value
        )}
      </a>
    </div>
  );
}

interface SwitchButtonParamsInterface {
  isOn: boolean;
  onSwitchChange: any;
  id: string;
  name: string;
  styles?: any;
  needDisplay?: boolean;
  displayValue?: number;
  setDisplayValue?: any;
  numPadNeedDot?: boolean;
  isNumPadOn?: boolean;
  setIsNumpadOn?: any;
}

export function SwitchButton(params: SwitchButtonParamsInterface) {
  const { ClickSound } = useSoundContext();
  const classes = params.styles ? params.styles : styles;
  // const [isNumpadOn, setIsNumpadOn] = useState(false);
  const [displayStyle, setDisplayStyle] = useState<any>();

  // const NumpadChildMemo = React.memo(Numpad);

  function switchClick() {
    ClickSound();
    params.onSwitchChange(!params.isOn);
  }

  function displayClick() {
    ClickSound();
    params.setIsNumpadOn(!params.isNumPadOn);
    setDisplayStyle({
      borderColor: !params.isNumPadOn ? 'var(--accent-invertible-secondary)' : 'var(--background-stroke-secondary)',
    });
    params.setDisplayValue(params.displayValue, true);
  }

  const animationVariant = {
    switch: {
      on: {
        x: -76,
      },
      off: {
        x: 0,
      },
    },
    display: {
      on: {
        opacity: 1,
        display: 'flex',
      },
      off: {
        opacity: 0,
        display: 'none',
      },
    },
  };
  return (
    <div className={classes.switchButtonNew}>
      <motion.div
        initial={animationVariant.switch.on}
        animate={params.needDisplay && params.isOn ? animationVariant.switch.on : animationVariant.switch.off}
        style={{ zIndex: 1 }}
        className={classes.flexDiv}
        transition={{ ease: 'easeIn' }}
      >
        <a className={classes.displayText}>{params.name}</a>
        <Switch value={params.isOn} onChange={switchClick} id={params.id} />
      </motion.div>
      {params.needDisplay && (
        <motion.div
          initial={animationVariant.display.off}
          animate={params.isOn ? animationVariant.display.on : animationVariant.display.off}
          style={{ position: 'absolute', right: 0 }}
          className={classes.flexDiv}
        >
          <a className={classes.boldText}>x</a>
          <div className={params.isNumPadOn ? classes.displayContainerOverlay : ''}>
            <div className={classes.display} onClick={displayClick} style={displayStyle}>
              <a className={classes.boldText}>{params.displayValue}</a>
            </div>
            {params.isNumPadOn && (
              <Numpad
                // store={params}
                value={params.displayValue}
                setValue={params.setDisplayValue}
                doneClick={displayClick}
                needDot={params.numPadNeedDot}
                position={{ right: '0px', bottom: '40px' }}
                setIsNumpadOn={params.setIsNumpadOn}
              />
            )}
          </div>
        </motion.div>
      )}
    </div>
  );
}

interface NumPadParamsInterface {
  // store: any;
  value: any;
  setValue: any;
  doneClick: any;
  position: { right?: any; left?: any; bottom: any };
  setIsNumpadOn: any;
  needDot?: boolean;
  charsLimit?: number;
}

export function Numpad(params: NumPadParamsInterface) {
  const { ClickSound } = useSoundContext();
  const ref = useRef<any>();
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert('You clicked outside of me!');
        params.doneClick();
      }
    }

    function closeNumpad() {
      params.setIsNumpadOn(false);
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchmove', closeNumpad);
    document.addEventListener('scroll', closeNumpad);
    document.addEventListener('wheel', closeNumpad);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchmove', closeNumpad);
      document.removeEventListener('scroll', closeNumpad);
      document.removeEventListener('wheel', closeNumpad);
    };
  }, [ref]);

  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const numbersButtonsList = numbers.map((button) => (
    <div
      key={button}
      className={styles.numPadButton}
      onClick={() => {
        ClickSound();
        if (
          params.charsLimit ? params.value.toString().length < params.charsLimit : params.value.toString().length < 9
        ) {
          params.setValue(Number(params.value.toString() + button));
        }
      }}
    >
      <a className={styles.numpadText}>{button}</a>
    </div>
  ));

  return (
    <div
      className={styles.numPad}
      style={{ right: params.position.right, left: params.position.left, bottom: params.position.bottom }}
      ref={ref}
    >
      {/*buttons 1 - 9*/}
      {numbersButtonsList}

      {/*dot button*/}
      {params.needDot && (
        <div
          className={styles.numPadButton}
          onClick={() => {
            ClickSound();
            if (
              params.charsLimit
                ? params.value.toString().length < params.charsLimit
                : params.value.toString().length < 9
            ) {
              if (!params.value.toString().includes('.')) {
                params.setValue(params.value.toString() + '.');
              }
            }
          }}
        >
          <a className={styles.numpadText}>.</a>
        </div>
      )}

      {/*button 0*/}
      <div
        className={styles.numPadButton}
        onClick={() => {
          ClickSound();
          if (
            params.charsLimit ? params.value.toString().length < params.charsLimit : params.value.toString().length < 9
          ) {
            if (params.value.toString().length > 1) {
              params.setValue(params.value.toString() + 0);
            } else {
              if (params.value.toString().charAt(0) !== '0') {
                params.setValue(params.value.toString() + 0);
              }
            }
          }
        }}
      >
        <a className={styles.numpadText}>0</a>
      </div>

      {/*delete button*/}
      <div
        className={styles.numPadButton}
        onClick={() => {
          ClickSound();
          if (params.value.toString().length <= 1) {
            params.setValue(0);
          } else {
            params.setValue(params.value.toString().slice(0, -1));
          }
        }}
      >
        <DeleteIcon />
      </div>

      {/*confirm button*/}
      <div
        className={styles.numPadButton}
        onClick={() => {
          // ClickSound();
          params.setValue(params.value);
          params.doneClick();
        }}
        style={{
          gridColumn: params.needDot ? 'span 3' : undefined,
          width: params.needDot ? 'auto' : undefined,
          color: 'var(--accent-system-light-green)',
        }}
      >
        {/*<CheckIcon fill={'var(--accent-system-light-green)'} width={22} height={16} />*/}
        <a
          className={styles.numpadText}
          style={{
            gridColumn: params.needDot ? 'span 3' : undefined,
            width: params.needDot ? 'auto' : undefined,
            color: 'var(--accent-system-light-green)',
          }}
        >
          Confirm
        </a>
      </div>
    </div>
  );
}

export function calculateMultiplyingCoefficient(max_bet: number) {
  return Math.pow(10, max_bet.toString().length - 3);
}

interface ChangeBetParamsInterface {
  operation: '-' | '+' | 'min' | 'max' | 'x2' | 'clear' | 'clear_to_zero' | 'specific-bet' | 'numpad';
  value?: number;
  bet: number;
  setBet: any;
  balance: number;
  totalBet?: number;
}

function changeBet(params: ChangeBetParamsInterface) {
  const multiplyingCoefficient = calculateMultiplyingCoefficient(_max_bet);
  let result: Decimal;

  if (params.bet < 1) {
    result = new Decimal(_min_bet);
  } else {
    result = new Decimal(params.bet);
  }

  const newTotal = params.totalBet !== undefined ? params.totalBet : 0;

  const balance = new Decimal(usePlayerState.getState().player.balance - newTotal);

  if (Number(_prevOperationButton?.firstChild.innerHTML.replace(',', '')) !== params.value) {
    _prevOperationButton = undefined;
  }

  if (balance.gte(_min_bet)) {
    switch (params.operation) {
      default:
        result = new Decimal(_min_bet);
        break;

      case '-':
        if (params.bet - params.value! >= _min_bet && balance.gte(params.bet - params.value!)) {
          result = result.minus(params.value!);
        }
        break;

      case '+':
        if (params.bet + params.value! <= _max_bet && balance.gte(params.bet + params.value!)) {
          result = result.plus(params.value!);
        }
        break;

      case 'min':
        result = new Decimal(_min_bet);
        break;

      case 'max':
        if (balance.gte(_max_bet)) {
          result = new Decimal(_max_bet);
        } else {
          result = balance;
        }
        break;

      case 'x2':
        if (params.bet * 2 <= _max_bet && balance.gte(params.bet * 2)) {
          result = new Decimal(params.bet * 2);
        }
        break;

      case 'clear':
        if (usePlayerState.getState().player.balance > 10 * multiplyingCoefficient) {
          result = new Decimal(10 * multiplyingCoefficient);
        } else {
          result = balance;
        }
        break;

      case 'clear_to_zero':
        result = new Decimal(0);
        break;

      case 'specific-bet':
        if (_prevOperationButton) {
          if (params.bet + params.value! <= _max_bet && balance.gte(params.bet + params.value!)) {
            result = result.plus(params.value!);
          }
        } else {
          if (balance.gte(params.value!)) {
            result = new Decimal(params.value!);
          }
        }
        break;

      case 'numpad':
        if (balance.gte(params.value!)) {
          result = new Decimal(params.value!);
        }
        if (balance.lte(params.value!)) {
          result = balance;
        }
        if (result.lte(_min_bet)) {
          result = new Decimal(_min_bet);
        }
        if (result.gte(_max_bet)) {
          result = new Decimal(_max_bet);
        }
        // if (balance.gte(params.value!)) {
        //   if (params.value! >= _min_bet && params.value! <= _max_bet) {
        //     result = new Decimal(params.value!);
        //   } else {
        //     // eslint-disable-next-line max-depth
        //     if (params.value! < _min_bet) {
        //       result = new Decimal(_min_bet);
        //     } else {
        //       // eslint-disable-next-line max-depth
        //       if (params.value! > _max_bet) {
        //         result = new Decimal(_max_bet);
        //       }
        //     }
        //   }
        // } else {
        //   if (params.value! >= _min_bet && params.value! <= _max_bet) {
        //     result = balance;
        //   } else {
        //     result = new Decimal(_max_bet);
        //   }
        // }
        break;
    }
  } else {
    if (newTotal > 0) {
      result = new Decimal(0);
    } else {
      result = new Decimal(_min_bet);
    }
  }
  params.setBet(result.toNumber());
}
