import React from 'react';

import { Row } from './Row';
import './CardContainer.scss';

const NUM_CARD_SLOTS = 3;

export const CardContainer = () => {
  return (
    <div className="card-container">
      <Row isTop={true} numSlots={NUM_CARD_SLOTS} />
      <Row isTop={false} numSlots={NUM_CARD_SLOTS} />
    </div>
  );
};
