import * as API from './API';
import * as Constants from './Constants';

function insertParam(url: URL, param: any, value: any) {
  if (url.searchParams.has(param)) {
    url.searchParams.set(param, value);
  } else {
    url.searchParams.append(param, value);
  }
  return url;
}

function insertParamsInUrl(
  url: URL,
  {
    menu,
    language,
    freebetId,
    uuid,
  }: {
    menu: any;
    language: any;
    freebetId?: any;
    uuid?: string;
  },
) {
  url = insertParam(url, 'isLightMode', menu.isLightMode);
  url = insertParam(url, 'isSoundOn', menu.isSoundOn);
  url = insertParam(url, 'isMusicOn', menu.isMusicOn);
  url = insertParam(url, 'locale', language);
  if (freebetId) url = insertParam(url, 'freebet_id', freebetId);
  if (uuid) url = insertParam(url, 'uuid', uuid);

  return url;
}

export async function generateTargetGameUrl({
  gameId,
  menu,
  player,
  language,
  freebetId,
}: {
  gameId: number;
  menu: any;
  player: any;
  language: any;
  freebetId?: number;
}) {
  let url;
  await API.post(Constants.run_next, { game_id: gameId }, player.token).then((json) => {
    url = new URL(json.game_url);
    url = insertParamsInUrl(url, {
      menu: menu,
      language: language,
      freebetId: freebetId,
    });

    return url;
  });
  return url;
}

export function generateTargetLobbyUrl(
  url: string,
  {
    player,
    menu,
    language,
    freebetId,
    uuid,
  }: {
    player: any;
    menu: any;
    language: any;
    freebetId?: number;
    uuid?: string;
  },
) {
  let new_url = new URL(url);
  new_url.searchParams.append('token', player);
  new_url = insertParamsInUrl(new_url, {
    menu: menu,
    language: language,
    freebetId: freebetId,
    uuid: uuid,
  });
  return new_url;
}

export function generateTargetDepositUrl(
  url: string,
  {
    isDepositOpen,
    operatorToken,
  }: {
    isDepositOpen: boolean;
    operatorToken?: string;
  },
) {
  const new_url = new URL(url);
  if (operatorToken) new_url.searchParams.set('operator_token', operatorToken);
  if (isDepositOpen) new_url.searchParams.set('modal', 'deposit');
  return new_url;
}
