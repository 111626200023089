import React, { useEffect, useRef, useState } from 'react';

// import { ReactComponent as SettingButton } from '../../../assets/settingButtonNew.svg';
import { ClickSound } from '../../../helpers/AudioHelper';
import { BetButton, ButtonWithValue, Display } from '../../../helpers/BetCalculator.new';
import { CurrencyNumberType } from '../../../helpers/CurrencyNumberShort';
import { activeFreeBetStore, stateStore, usePlayerState } from '../../../Store';
import {
  BaccaratStateStore,
  baccaratBetsStore,
  useBaccaratParams,
  useCalculatorStore,
  useColumnState,
} from '../BaccaratStore';

import * as Constants from './../../../helpers/Constants';
import styles from './calculator.module.scss';
import classesToUpdate from './classesToUpdate.module.scss';

interface Prop {
  bet: number;
  setBet: any;
  totalStake: any;
  isCalculatorShow?: boolean;
  isFreebetPlay?: boolean;
}

export default function Calculator(props: Prop) {
  const calculatorRef = useRef<HTMLDivElement>(null);
  const [baccaratParams] = useBaccaratParams((state: any) => [state.baccaratParams]);
  const [currentBet, setCurrentBet, setBaccaratBetStakes] = baccaratBetsStore((state: any) => [
    state.currentBet,
    state.setCurrentBet,
    state.setBaccaratBetStakes,
  ]);
  const [isCalculatorShow] = useCalculatorStore((state: any) => [state.isCalculatorShown]);

  const [activeColumn, activePair] = useColumnState((state: any) => [state.activeColumn, state.activePair]);

  const [currentButtonValue, setCurrentButtonValue] = useState(0);

  const [player] = usePlayerState((state: any) => [state.player]);

  const betButtonHandler = () => {
    ClickSound();
    setCurrentButtonValue(0);
    if (currentBet.stake === null || currentBet.stake === 0 || currentBet.stake === undefined) {
      setCurrentBet({ ...currentBet, stake: props.bet, side: currentBet.side });
      setBaccaratBetStakes(currentBet.side, props.bet, props.bet);
    }
  };

  const cancelButtonHandler = () => {
    ClickSound();
    setCurrentButtonValue(0);
    setCurrentBet({ ...currentBet, stake: 0, side: currentBet.side });
    setBaccaratBetStakes(currentBet.side, 0, -currentBet.stake);
  };

  const [betBgColor, setBetBgColor] = useState('var(--button-invertible-primary');
  // freebet block
  const [freeBetsManagement, setFreeBetsManagementOpen] = stateStore((state: any) => [
    state.freeBetsManagement,
    state.setFreeBetsManagementOpen,
  ]);

  const [isFreeBetPlay] = stateStore((state: any) => [state.freebetLobbyState]);
  const [currentFreeBet] = activeFreeBetStore((state: any) => [state.currentActiveFreeBet]);

  //
  // autobet

  const [baccaratGame, setBaccaratGame] = BaccaratStateStore((state: any) => [
    state.baccaratGame,
    state.setBaccaratGame,
  ]);

  const [baccaratBets] = baccaratBetsStore((state: any) => [state.baccaratBets]);

  const placedSideBets = baccaratBets.filter((el: any) => el.stake > 0);

  useEffect(() => {
    if (props.totalStake <= 0) {
      setBaccaratGame({
        ...baccaratGame,
        isAutoBet: false,
      });
    }
  }, [props.totalStake]);

  //

  useEffect(() => {
    if (currentBet.side !== null) {
      switch (true) {
        case currentBet.side === 0:
          setBetBgColor('var(--button-system-pink)');
          break;
        case currentBet.side === 1:
          setBetBgColor('var(--accent-system-orange)');
          break;
        case currentBet.side === 2:
          setBetBgColor('var(--button-system-blue');
          break;
        default:
          setBetBgColor('var(--button-invertible-primary');
      }
    } else {
      setBetBgColor('var(--button-invertible-primary');
    }
  }, [currentBet.side]);

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (!baccaratGame.isOpen) {
      if (isFreeBetPlay.status === Constants.freebetPlay.play) {
        setIsDisabled(true);
      } else if (currentBet.stake !== 0) {
        if (baccaratGame.isQuickMode) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  }, [isFreeBetPlay.status, currentBet, baccaratGame.isOpen]);

  const [isWidthSmall, setIsWidthSmall] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsWidthSmall(window.innerWidth <= 991);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {!baccaratGame.isQuickMode ? (
        <div
          ref={calculatorRef}
          className={
            isCalculatorShow
              ? styles.calculatorWrapper + ' ' + styles.startAnim
              : styles.calculatorWrapper + ' ' + styles.hideAnimation
          }
          // style={{
          //   display: activeColumn || activePair ? 'flex' : 'none',
          // }}
        >
          {isFreeBetPlay.status === Constants.freebetPlay.play ? (
            <div className={styles.calculatorRow + ' ' + styles.calculatorRowFreebets}>
              {currentFreeBet.freebets_amount - currentFreeBet.freebets_played ? (
                <div className={styles.freeBetsAvailable}>
                  Free Bets Available{' '}
                  <p>{currentFreeBet.freebets_amount - currentFreeBet.freebets_played - placedSideBets.length}</p>
                </div>
              ) : (
                ''
              )}

              <div className={styles.freeBetsAvailable} style={currentFreeBet.total_gain ? {} : { display: 'none' }}>
                • Total win
                <p>{CurrencyNumberType(currentFreeBet.total_gain, baccaratParams.currency)}</p>
              </div>

              <div
                className={styles.settingFreebets}
                onClick={() => {
                  ClickSound();
                  setFreeBetsManagementOpen({
                    ...freeBetsManagement,
                    isOpen: !freeBetsManagement.isOpen,
                  });
                }}
              >
                {/* <SettingButton /> */}
              </div>
            </div>
          ) : (
            <p style={{ display: 'none' }}>{CurrencyNumberType(currentFreeBet.total_gain, baccaratParams.currency)}</p>
          )}
          <div className={styles.calculatorRow + ' ' + styles.calculatorRowFirst}>
            <Display
              min_bet={baccaratParams.min_bet}
              max_bet={baccaratParams.max_bet}
              step={baccaratParams.step}
              currency={''}
              balance={player.balance}
              bet={props.bet}
              setBet={props.setBet}
              styles={classesToUpdate}
              totalBet={props.totalStake}
              disabled={isDisabled && isFreeBetPlay.status !== Constants.freebetPlay.play}
              setCurrentButtonValue={setCurrentButtonValue}
            />
            {isWidthSmall &&
              isCalculatorShow &&
              (currentBet.stake === 0 || currentBet.stake === null ? (
                <BetButton
                  text={'Bet'}
                  value={props.bet}
                  styles={classesToUpdate}
                  onClick={betButtonHandler}
                  color={betBgColor}
                />
              ) : (
                <BetButton text={'Cancel'} styles={classesToUpdate} onClick={cancelButtonHandler} color={betBgColor} />
              ))}
            {!isWidthSmall &&
              (activeColumn || activePair) &&
              (currentBet.stake === 0 || currentBet.stake === null ? (
                <BetButton
                  text={'Bet'}
                  value={props.bet}
                  styles={classesToUpdate}
                  onClick={betButtonHandler}
                  color={betBgColor}
                />
              ) : (
                <BetButton text={'Cancel'} styles={classesToUpdate} onClick={cancelButtonHandler} color={betBgColor} />
              ))}
          </div>

          <div
            className={styles.calculatorRow + ' ' + styles.calculatorRowSecond}
            style={isDisabled ? { opacity: '0.2' } : {}}
          >
            <div className={styles.row}>
              <ButtonWithValue
                operation="+"
                value={50}
                totalBet={props.totalStake}
                props={{
                  min_bet: baccaratParams.min_bet,
                  max_bet: baccaratParams.max_bet,
                  step: baccaratParams.step,
                  currency: baccaratParams.currency,
                  balance: player.balance,
                  bet: currentButtonValue === 50 ? props.bet : 0,
                  setBet: props.setBet,
                }}
                disabled={isDisabled}
                setCurrentButtonValue={setCurrentButtonValue}
              />
              <ButtonWithValue
                operation="+"
                value={200}
                totalBet={props.totalStake}
                props={{
                  min_bet: baccaratParams.min_bet,
                  max_bet: baccaratParams.max_bet,
                  step: baccaratParams.step,
                  currency: baccaratParams.currency,
                  balance: player.balance,
                  bet: currentButtonValue === 200 ? props.bet : 0,
                  setBet: props.setBet,
                }}
                disabled={isDisabled}
                setCurrentButtonValue={setCurrentButtonValue}
              />
              <ButtonWithValue
                operation="+"
                value={500}
                totalBet={props.totalStake}
                props={{
                  min_bet: baccaratParams.min_bet,
                  max_bet: baccaratParams.max_bet,
                  step: baccaratParams.step,
                  currency: baccaratParams.currency,
                  balance: player.balance,
                  bet: currentButtonValue === 500 ? props.bet : 0,
                  setBet: props.setBet,
                }}
                disabled={isDisabled}
                setCurrentButtonValue={setCurrentButtonValue}
              />
              <ButtonWithValue
                operation="+"
                value={1000}
                totalBet={props.totalStake}
                props={{
                  min_bet: baccaratParams.min_bet,
                  max_bet: baccaratParams.max_bet,
                  step: baccaratParams.step,
                  currency: baccaratParams.currency,
                  balance: player.balance,
                  bet: currentButtonValue === 1000 ? props.bet : 0,
                  setBet: props.setBet,
                }}
                disabled={isDisabled}
                setCurrentButtonValue={setCurrentButtonValue}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          ref={calculatorRef}
          className={styles.calculatorWrapper + ' ' + styles.calculatorWrapperQuick}
          // style={{
          //   display: activeColumn || activePair ? 'flex' : 'none',
          // }}
        >
          {isFreeBetPlay.status === Constants.freebetPlay.play ? (
            <div className={styles.calculatorRow + ' ' + styles.calculatorRowFreebets}>
              {currentFreeBet.freebets_amount - currentFreeBet.freebets_played ? (
                <div className={styles.freeBetsAvailable}>
                  Free Bets Available{' '}
                  <p>{currentFreeBet.freebets_amount - currentFreeBet.freebets_played - placedSideBets.length}</p>
                </div>
              ) : (
                ''
              )}

              <div className={styles.freeBetsAvailable} style={currentFreeBet.total_gain ? {} : { display: 'none' }}>
                • Total win
                <p>{CurrencyNumberType(currentFreeBet.total_gain, baccaratParams.currency)}</p>
              </div>

              <div
                className={styles.settingFreebets}
                onClick={() => {
                  ClickSound();
                  setFreeBetsManagementOpen({
                    ...freeBetsManagement,
                    isOpen: !freeBetsManagement.isOpen,
                  });
                }}
              >
                {/* <SettingButton /> */}
              </div>
            </div>
          ) : (
            <p style={{ display: 'none' }}>{CurrencyNumberType(currentFreeBet.total_gain, baccaratParams.currency)}</p>
          )}
          <div className={styles.calculatorRow + ' ' + styles.calculatorRowFirst}>
            <Display
              min_bet={baccaratParams.min_bet}
              max_bet={baccaratParams.max_bet}
              step={baccaratParams.step}
              currency={''}
              balance={player.balance}
              bet={props.bet}
              setBet={props.setBet}
              styles={classesToUpdate}
              totalBet={props.totalStake}
              disabled={isDisabled && isFreeBetPlay.status !== Constants.freebetPlay.play}
              setCurrentButtonValue={setCurrentButtonValue}
            />

            {!isWidthSmall &&
              (activeColumn || activePair) &&
              (currentBet.stake === 0 || currentBet.stake === null ? (
                <BetButton
                  text={'Bet'}
                  value={props.bet}
                  styles={classesToUpdate}
                  onClick={betButtonHandler}
                  color={betBgColor}
                />
              ) : (
                <BetButton text={'Cancel'} styles={classesToUpdate} onClick={cancelButtonHandler} color={betBgColor} />
              ))}
          </div>

          <div
            className={styles.calculatorRow + ' ' + styles.calculatorRowSecond}
            style={isDisabled ? { opacity: '0.2' } : {}}
          >
            <div className={styles.row}>
              <ButtonWithValue
                operation="+"
                value={50}
                totalBet={props.totalStake}
                props={{
                  min_bet: baccaratParams.min_bet,
                  max_bet: baccaratParams.max_bet,
                  step: baccaratParams.step,
                  currency: baccaratParams.currency,
                  balance: player.balance,
                  bet: currentButtonValue === 50 ? props.bet : 0,
                  setBet: props.setBet,
                }}
                disabled={isDisabled}
                setCurrentButtonValue={setCurrentButtonValue}
              />
              <ButtonWithValue
                operation="+"
                value={200}
                totalBet={props.totalStake}
                props={{
                  min_bet: baccaratParams.min_bet,
                  max_bet: baccaratParams.max_bet,
                  step: baccaratParams.step,
                  currency: baccaratParams.currency,
                  balance: player.balance,
                  bet: currentButtonValue === 200 ? props.bet : 0,
                  setBet: props.setBet,
                }}
                disabled={isDisabled}
                setCurrentButtonValue={setCurrentButtonValue}
              />
              <ButtonWithValue
                operation="+"
                value={500}
                totalBet={props.totalStake}
                props={{
                  min_bet: baccaratParams.min_bet,
                  max_bet: baccaratParams.max_bet,
                  step: baccaratParams.step,
                  currency: baccaratParams.currency,
                  balance: player.balance,
                  bet: currentButtonValue === 500 ? props.bet : 0,
                  setBet: props.setBet,
                }}
                disabled={isDisabled}
                setCurrentButtonValue={setCurrentButtonValue}
              />
              <ButtonWithValue
                operation="+"
                value={1000}
                totalBet={props.totalStake}
                props={{
                  min_bet: baccaratParams.min_bet,
                  max_bet: baccaratParams.max_bet,
                  step: baccaratParams.step,
                  currency: baccaratParams.currency,
                  balance: player.balance,
                  bet: currentButtonValue === 1000 ? props.bet : 0,
                  setBet: props.setBet,
                }}
                disabled={isDisabled}
                setCurrentButtonValue={setCurrentButtonValue}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
