import React from 'react';
import './CardsContainer.scss';

import { Row } from './Row';

type CardsContainer = {
  countTop?: number;
  countBottom?: number;
};

const NUM_CARD_SLOTS = 3;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CardsContainer = ({ countTop, countBottom }: CardsContainer) => {
  return (
    <div className="baccarat-card-container">
      <Row isTop={true} numSlots={NUM_CARD_SLOTS} countTop={countTop} countBottom={countBottom} />
      <Row isTop={false} numSlots={NUM_CARD_SLOTS} countTop={countTop} countBottom={countBottom} />
    </div>
  );
};
