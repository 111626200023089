import Decimal from 'decimal.js';
import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';

import * as API from './helpers/API';
import * as Constants from './helpers/Constants';
import { LOCALES } from './i18n/locales';

export const useBuildType = create(() => ({
  // TODO: -----------------------------change this line, to change build type--------------------------
  buildType: Constants.Select_build_types(process.env.REACT_APP_BUILD_ID),
}));

export const usePlayerState = create((set: any) => ({
  player: {
    token: GetParamsFromUrl('token'),
    operatorToken: GetParamsFromUrl('operator_token'),
    balance: 0,
    currency: null,
    session_uuid: GenerateUuid(),
  },
  playerState: async (params: any) => {
    await set((state: any) => {
      state.player = {
        token: params.token,
        operatorToken: params.operatorToken,
        balance: cutBalanceCoins(params.balance),
        currency: Constants.currency(params.currency),
        session_uuid: params.session_uuid,
      };

      return state.player;
    });
  },

  changeBalance: async (operation: string, value: any) => {
    switch (operation) {
      case '+':
        set((state: any) => ({
          player: {
            ...state.player,
            balance: cutBalanceCoins(new Decimal(state.player.balance).plus(new Decimal(value))),
          },
        }));
        break;
      case '-':
        set((state: any) => ({
          player: {
            ...state.player,
            balance: cutBalanceCoins(new Decimal(state.player.balance).minus(new Decimal(value))),
          },
        }));
        break;
      case 'set':
        set((state: any) => ({
          player: {
            ...state.player,
            balance: cutBalanceCoins(new Decimal(value)),
          },
        }));
        break;
    }
  },

  decreaseBalanceAsync: async (value: number) => {
    set((state: any) => ({
      player: {
        ...state.player,
        balance: new Decimal(state.player.balance).minus(new Decimal(value)),
      },
    }));
  },

  increaseBalanceAsync: async (value: any) => {
    set((state: any) => ({
      player: {
        ...state.player,
        balance: new Decimal(state.player.balance).plus(new Decimal(value)),
      },
    }));
  },
  initializeBalance: (value: any) => {
    set((state: any) => ({
      player: {
        ...state.player,
        balance: value,
      },
    }));
  },
}));

export const useGameParams = create((set: any) => ({
  andarParams: {
    id: 0,
    name: '',
    currency: '',
    min_bet: 1000,
    max_bet: 1000,
    step: 0,
    balance: 0,
    andar_coef: 0,
    bahar_coef: 0,
  },

  setAndarParams: (params: any) =>
    set((state: any) => {
      state.andarParams = {
        id: params.id,
        name: params.name,
        currency: Constants.currency(params.currency),
        min_bet: params.min_bet,
        max_bet: params.max_bet,
        step: params.step,
        balance: params.balance,
        andar_coef: params.andar_coef - 1,
        bahar_coef: (params.bahar_coef - 1).toFixed(1),
      };
      return state.andarParams;
    }),

  dragonParams: {
    id: 0,
    name: '',
    currency: '',
    min_bet: 1000,
    max_bet: 1000,
    step: 0,
    balance: 0,
    dragon_coef: 0,
    tiger_coef: 0,
    tie_coef: 0,
  },

  setDragonParams: (params: any) =>
    set((state: any) => {
      state.dragonParams = {
        id: params.id,
        name: params.name,
        currency: Constants.currency(params.currency),
        min_bet: params.min_bet,
        max_bet: params.max_bet,
        step: params.step,
        balance: params.balance,
        dragon_coef: params.dragon_coef - 1,
        tiger_coef: params.tiger_coef - 1,
        tie_coef: params.tie_coef - 1,
      };

      return state.dragonParams;
    }),

  luckyParams: {
    id: 0,
    name: '',
    currency: '',
    min_bet: 1000,
    max_bet: 1000,
    step: 0,
    balance: 0,
    seven_up_coef: 0,
    seven_down_coef: 0,
    seven_coef: 0,
  },

  setLuckyParams: (params: any) =>
    set((state: any) => {
      state.luckyParams = {
        id: params.id,
        name: params.name,
        currency: Constants.currency(params.currency),
        min_bet: params.min_bet,
        max_bet: params.max_bet,
        step: params.step,
        balance: params.balance,
        seven_up_coef: params.seven_up_coef - 1,
        seven_down_coef: params.seven_down_coef - 1,
        seven_coef: params.seven_coef - 1,
      };

      return state.luckyParams;
    }),

  teenParams: {
    id: 0,
    name: '',
    currency: '',
    min_bet: 100,
    max_bet: 1000,
    step: 1,
    balance: 0,
    coef: 0,
  },
  setTeenParams: (params: any) => {
    set((state: any) => {
      state.teenParams = {
        id: params.id,
        name: params.name,
        currency: Constants.currency(params.currency),
        min_bet: params.min_bet,
        max_bet: params.max_bet,
        step: params.step,
        balance: params.balance,
        coef: params.coef,
      };
      return state.teenParams;
    });
  },
}));

export const playRequestStore = create((set: any) => ({
  playAndarRequest: {
    demo: false,
    side: -1,
    stake: 0,
    sideBets: [],
    totalStake: 0,
  },

  setPlayAndarRequest: async (request: any) =>
    await set((state: any) => {
      state.playAndarRequest = {
        demo: request.demo,
        side: request.side,
        stake: request.stake,
        sideBets: request.sideBets,
        totalStake: request.totalStake,
      };
      return state.playAndarRequest;
    }),

  playAndarResponse: {
    bet_id: 0,
    win: false,
    win_side: 0,
    cards: [],
    balance: 0,
    profit: 0,
    mini_game: 0,
    mini_game_bet: 0,
    status: null,
    total_gain: null,
  },

  retrievePlayAndarResponse: async (data: any, token: any): Promise<any> => {
    const responseData = await API.post(Constants.play, data, token).then((json) => {
      return json;
    });

    await set((state: any) => {
      state.historyFreeBets = {
        bet_id: responseData.bet_id,
        win: responseData.win,
        win_side: responseData.win_side,
        cards: responseData.cards,
        balance: responseData.balance,
        profit: responseData.profit,
        mini_game: responseData.mini_game,
        mini_game_bet: responseData.mini_game_bet,
        status: responseData.status ? responseData.status : null,
        total_gain: responseData.total_gain ? responseData.total_gain : null,
      };
      return state.historyFreeBets;
    });
  },

  setPlayAndarResponse: async (response: any) =>
    await set((state: any) => {
      state.playAndarResponse = {
        bet_id: response.bet_id,
        win: response.win,
        win_side: response.win_side,
        cards: response.cards,
        balance: response.balance,
        profit: response.profit,
        mini_game: response.mini_game,
        mini_game_bet: response.mini_game_bet,
        status: response.status ? response.status : null,
        total_gain: response.total_gain ? response.total_gain : null,
      };
      return state.playAndarResponse;
    }),

  playDragonRequest: {
    demo: false,
    side: -1,
    stake: 0,
  },

  setPlayDragonRequest: async (request: any) =>
    await set((state: any) => {
      state.playDragonRequest = {
        demo: request.demo,
        side: request.side,
        stake: request.stake,
      };
      return state.playDragonRequest;
    }),

  playDragonResponse: {
    bet_id: 0,
    win: false,
    win_side: 0,
    cards: [],
    balance: 0,
    profit: 0,
    mini_game: 0,
    mini_game_bet: 0,
    status: null,
    total_gain: null,
  },

  retrievePlayDragonResponse: async (data: any, token: any): Promise<any> => {
    const responseData = await API.post(Constants.play, data, token).then((json) => {
      return json;
    });

    await set((state: any) => {
      state.historyFreeBets = {
        bet_id: responseData.bet_id,
        win: responseData.win,
        win_side: responseData.win_side,
        cards: responseData.cards,
        balance: responseData.balance,
        profit: responseData.profit,
        mini_game: responseData.mini_game,
        mini_game_bet: responseData.mini_game_bet,
        status: responseData.status ? responseData.status : null,
        total_gain: responseData.total_gain ? responseData.total_gain : null,
      };
      return state.historyFreeBets;
    });
  },

  setPlayDragonResponse: async (response: any) =>
    await set((state: any) => {
      state.playDragonResponse = {
        bet_id: response.bet_id,
        win: response.win,
        win_side: response.win_side,
        cards: response.cards,
        balance: response.balance,
        profit: response.profit,
        mini_game: response.mini_game,
        mini_game_bet: response.mini_game_bet,
        status: response.status ? response.status : null,
        total_gain: response.total_gain ? response.total_gain : null,
      };
      return state.playDragonResponse;
    }),

  playLuckyRequest: {
    demo: false,
    side: -1,
    stake: 0,
  },

  setPlayLuckyRequest: async (request: any) =>
    await set((state: any) => {
      state.playLuckyRequest = {
        demo: request.demo,
        side: request.side,
        stake: request.stake,
      };
      return state.playLuckyRequest;
    }),

  playLuckyResponse: {
    bet_id: 0,
    win: false,
    win_side: 0,
    cards: [],
    balance: 0,
    profit: 0,
    mini_game: 0,
    mini_game_bet: 0,
    status: null,
    total_gain: null,
  },

  retrievePlayLuckyResponse: async (data: any, token: any): Promise<any> => {
    const responseData = await API.post(Constants.play, data, token).then((json) => {
      return json;
    });

    await set((state: any) => {
      state.historyFreeBets = {
        bet_id: responseData.bet_id,
        win: responseData.win,
        win_side: responseData.win_side,
        cards: responseData.cards,
        balance: responseData.balance,
        profit: responseData.profit,
        mini_game: responseData.mini_game,
        mini_game_bet: responseData.mini_game_bet,
        status: responseData.status ? responseData.status : null,
        total_gain: responseData.total_gain ? responseData.total_gain : null,
      };
      return state.historyFreeBets;
    });
  },

  setPlayLuckyResponse: async (response: any) =>
    await set((state: any) => {
      state.playLuckyResponse = {
        bet_id: response.bet_id,
        win: response.win,
        win_side: response.win_side,
        cards: response.cards,
        balance: response.balance,
        profit: response.profit,
        mini_game: response.mini_game,
        mini_game_bet: response.mini_game_bet,
        status: response.status ? response.status : null,
        total_gain: response.total_gain ? response.total_gain : null,
      };
      return state.playLuckyResponse;
    }),

  playTeenPattiRequest: {
    demo: false,
    side: 0,
    stake: 0,
  },
  setPlayTeenPattiRequest: async (request: any) =>
    await set((state: any) => {
      state.playTeenPattiRequest = {
        demo: request.demo,
        side: request.side,
        stake: request.stake,
      };
      return state.playTeenPattiRequest;
    }),

  playTeenPattiResponse: {
    bet_id: 0,
    win: false,
    win_side: 0,
    cards: [],
    balance: 0,
    profit: 0,
    mini_game: 0,
    mini_game_bet: 0,
    status: null,
    total_gain: null,
  },
  retrievePlayTeenPattiResponse: async (data: any, token: any): Promise<any> => {
    const responseData = await API.post(Constants.play, data, token).then((json) => {
      return json;
    });

    await set((state: any) => {
      state.historyFreeBets = {
        bet_id: responseData.bet_id,
        win: responseData.win,
        win_side: responseData.win_side,
        cards: responseData.cards,
        balance: responseData.balance,
        profit: responseData.profit,
        mini_game: responseData.mini_game,
        mini_game_bet: responseData.mini_game_bet,
        status: responseData.status ? responseData.status : null,
        total_gain: responseData.total_gain ? responseData.total_gain : null,
      };
      return state.historyFreeBets;
    });
  },
  setPlayTeenPattiResponse: async (response: any) =>
    await set((state: any) => {
      state.playTeenPattiResponse = {
        bet_id: response.bet_id,
        win: response.win,
        win_side: response.win_side,
        cards: response.cards,
        balance: response.balance,
        profit: response.profit,
        mini_game: response.mini_game,
        mini_game_bet: response.mini_game_bet,
        status: response.status ? response.status : null,
        total_gain: response.total_gain ? response.total_gain : null,
      };
      return state.playTeenPattiResponse;
    }),
}));

export const stateStore = create((set: any) => ({
  modalWindow: {
    isOpen: false,
    reason: '',
    infoTitle: '',
    infoMessage: '',
    infoButtonText: '',
  },
  modalWindowState: async (params: any) =>
    set((state: any) => {
      state.modalWindow = {
        isOpen: params.isOpen,
        reason: params.reason,
        infoTitle: params.infoTitle,
        infoMessage: params.infoMessage,
        infoButtonText: params.infoButtonText,
      };
      return state.modalWindow;
    }),

  quit: {
    isOpen: false,
    toLobby: false,
  },
  quitState: async (params: { isOpen: boolean; toLobby: boolean }) =>
    await set((state: any) => {
      bodyOverflow(true);

      state.quit = {
        isOpen: params.isOpen,
        toLobby: params.toLobby,
      };
      return state.quit;
    }),

  error: {
    isOpen: false,
    messageId: 0,
    message: '',
  },
  errorState: async (params: { isOpen: boolean; messageId: number; message: string }) =>
    await set((state: any) => {
      bodyOverflow(true);

      state.error = {
        isOpen: params.isOpen,
        messageId: params.messageId,
        message: params.message,
      };
      return state.error;
    }),

  menu: {
    isOpen: false,
    isSoundOn: JSON.parse(getInitialSoundState()),
    isMusicOn: JSON.parse(getInitialBackSoundState()),
    isLightMode: JSON.parse(getInitialLightModeState()),
    lobby_url: getInitialLobbyUrl(),
    exit_url: getInitialExitUrl(),
    depositUrl: getDepositUrl(),
  },

  menuState: async (params: any) =>
    await set((state: any) => {
      bodyOverflow(true);

      state.menu = {
        isOpen: params.isOpen,
        isSoundOn: params.isSoundOn,
        isMusicOn: params.isMusicOn,
        isLightMode: params.isLightMode,
        lobby_url: params.lobby_url,
        exit_url: params.exit_url,
        depositUrl: params.depositUrl,
      };
      return state.menu;
    }),

  language: {
    isOpen: false,
    current: getInitialLocaleState(),
  },

  languageList: [],

  setLanguageList: async (response: any) =>
    await set((state: any) => {
      state.languageList = {
        languageList: [
          ...state.languageList,
          {
            id: response.id,
            name: response.name,
            code: response.code,
            is_active: response.is_active,
          },
        ],
      };
      return state.languageList;
    }),

  languageState: (params: any) =>
    set((state: any) => {
      localStorage.setItem('locale', params.current);
      state.language = {
        isOpen: params.isOpen,
        current: params.current,
      };
      return state.language;
    }),

  about: {
    isOpen: false,
    reason: '',
  },
  aboutState: (params: any) =>
    set((state: any) => {
      bodyOverflow(!params.isOpen);

      state.about = {
        isOpen: params.isOpen,
        reason: params.reason,
      };
      return state.about;
    }),

  playerHistory: {
    isOpen: false,
  },
  playerHistoryState: async (params: any) =>
    await set((state: any) => {
      bodyOverflow(!params.isOpen);

      state.playerHistory = {
        isOpen: params.isOpen,
      };
      return state.playerHistory;
    }),

  freeBetsManagement: {
    isOpen: false,
    selectedGameId: 0,
    activeFreeBetsGameId: [],
  },
  setFreeBetsManagementOpen: async (params: any) =>
    await set((state: any) => {
      bodyOverflow(!params.isOpen);
      state.freeBetsManagement = {
        ...state.freeBetsManagement,
        isOpen: params.isOpen,
      };
      return state.freeBetsManagement;
    }),

  setFreeBetsManagement: async (params: any) =>
    await set((state: any) => {
      state.freeBetsManagement = {
        ...state.freeBetsManagement,
        selectedGameId: params.selectedGameId,
        activeFreeBetsGameId: params.activeFreeBetsGameId,
      };
      return state.freeBetsManagement;
    }),

  freebetLobbyState: { status: 'notPlay' },

  setFreebetLobbyState: (params: any) =>
    set((state: any) => {
      state.freebetLobbyState = { status: params.status };
      return state.freebetLobbyState;
    }),

  andarBaharLobby: {
    isOpen: true,
    isStatisticsVisible: getInitialOnboardingState(),
    isStatisticsOpen: false,
  },
  andarBaharLobbyState: (params: any) =>
    set((state: any) => {
      state.andarBaharLobby = {
        isOpen: params.isOpen,
        isStatisticsVisible: params.isStatisticsVisible,
        isStatisticsOpen: params.isStatisticsOpen,
      };
      return state.andarBaharLobby;
    }),

  andarBaharGame: {
    isOpen: false,
    side: '',
  },
  andarBaharGameState: (params: any) =>
    set((state: any) => {
      state.andarBaharGame = {
        isOpen: params.isOpen,
        side: params.side,
      };
      return state.andarBaharGame;
    }),

  dragonLobby: {
    isOpen: true,
    isStatisticsVisible: getInitialOnboardingState(),
    isStatisticsOpen: false,
  },
  setDragonLobbyState: (params: any) =>
    set((state: any) => {
      state.dragonLobby = {
        isOpen: params.isOpen,
        isStatisticsVisible: params.isStatisticsVisible,
      };
      return state.dragonLobby;
    }),

  dragonGame: {
    isOpen: false,
    side: '',
  },
  dragonGameState: (params: any) =>
    set((state: any) => {
      state.dragonGame = {
        isOpen: params.isOpen,
        side: params.side,
      };
      return state.dragonGame;
    }),

  luckyLobby: {
    isOpen: true,
    isStatisticsVisible: getInitialOnboardingState(),
    isStatisticsOpen: false,
  },
  setLuckyLobbyState: (params: any) =>
    set((state: any) => {
      state.luckyLobby = {
        isOpen: params.isOpen,
        isStatisticsVisible: params.isStatisticsVisible,
      };
      return state.luckyLobby;
    }),

  luckyGame: {
    isOpen: false,
    side: '',
  },
  luckyGameState: (params: any) =>
    set((state: any) => {
      state.luckyGame = {
        isOpen: params.isOpen,
        side: params.side,
      };
      return state.luckyGame;
    }),

  teenPattiLobby: {
    isOpen: true,
  },
  teenPattiLobbyState: (params: any) =>
    set((state: any) => {
      state.teenPattiLobby = {
        isOpen: params.isOpen,
      };
      return state.teenPattiLobby;
    }),

  teenPattiGame: {
    isOpen: false,
    side: '',
  },
  teenPattiGameState: (params: any) =>
    set((state: any) => {
      state.teenPattiGame = {
        isOpen: params.isOpen,
        ante: params.ante,
      };
      return state.teenPattiGame;
    }),

  teenPattiModal: {
    isOpen: false,
    action: '',
  },
  teenPattiModalState: async (params: { isOpen: boolean; action: string }) =>
    await set((state: any) => {
      bodyOverflow(!params.isOpen);

      state.teenPattiModal = {
        isOpen: params.isOpen,
        action: params.action,
      };
      return state.teenPattiModal;
    }),
}));

export const andarStatisticsStore = create((set: any) => ({
  andarStats: {
    winner: [],
    last_winner: [],
    andar_percent: 0,
    bahar_percent: 0,
  },

  setAndarStats: (stats: any) =>
    set((state: any) => {
      state.andarStats = {
        winner: stats.winner,
        last_winner: stats.last_winner,
        andar_percent: stats.andar_percent,
        bahar_percent: stats.bahar_percent,
      };
      return state.andarStats;
    }),
  retrieveAndarStats: async (params: any): Promise<any> => {
    const data = await API.get(Constants.statistics + params.id, params.token).then((json) => {
      return json;
    });

    await set(async (state: any) => {
      state.andarStats = {
        ...state.andarStats,
        winner: data.winner,
        last_winner: data.last_winner,
        andar_percent: data.andar_percent,
        bahar_percent: data.bahar_percent,
      };

      return state.andarStats;
    });
  },
}));

export const dragonStatisticsStore = create((set: any) => ({
  dragonStats: {
    winners: getDragonStatisticState(),
  },

  setDragonStats: async (params: any) => {
    set((state: any) => {
      const winners = getDragonStatisticState();
      winners.unshift(params.winner);
      state.dragonStats = {
        winners: winners,
      };

      sessionStorage.setItem('getDragonStatisticState', JSON.stringify(state.dragonStats));
      return state.dragonStats;
    });
  },
}));

export const luckyStatisticsStore = create((set: any) => ({
  luckyStats: {
    winners: getLuckyStatisticState(),
  },

  setLuckyStats: async (params: any) => {
    set((state: any) => {
      const winners = getLuckyStatisticState();
      winners.unshift(params.winner);
      state.luckyStats = {
        winners: winners,
      };

      sessionStorage.setItem('getLuckyStatisticState', JSON.stringify(state.luckyStats));
      return state.luckyStats;
    });
  },
}));

export const gameHistoryStore = create((set: any) => ({
  history: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },

  setHistory: (params: any) =>
    set((state: any) => {
      state.history = {
        count: params.count,
        next: params.next,
        previous: params.npreviousext,
        results: params.results,
      };
      return state.history;
    }),
}));

// free bets

export const freeBetsHistoryStore = create((set: any) => ({
  historyFreeBets: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },

  setFreeBetsHistory: (params: any) =>
    set((state: any) => {
      state.history = {
        count: params.count,
        next: params.next,
        previous: params.previous,
        results: params.results,
      };
      return state.history;
    }),

  retrieveFreeBetsHistory: async (token: any): Promise<any> => {
    const historyData = await API.get(Constants.history_freebet + '?limit=100', token.token).then((json) => {
      return json;
    });
    const updatedData = {
      ...historyData,
      results: historyData.results.map((item: any) => {
        return { ...item, currency: Constants.currency(item.currency) };
      }),
    };

    await set((state: any) => {
      state.historyFreeBets = {
        count: updatedData.count,
        next: updatedData.next,
        previous: updatedData.previous,
        results: updatedData.results,
      };
      return state.historyFreeBets;
    });
  },
}));

export const activeFreeBetStore = create((set: any) => ({
  activeFreeBetsList: { data: [] },
  currentActiveFreeBet: {
    freebet_id: undefined,
    player_id: null,
    game_id: null,
    stake: null,
    freebets_amoun: null,
    freebets_played: null,
    total_gaint: null,
    currency: '',
    create_date: null,
    deposit_date: null,
    cancel_date: null,
    end_date: null,
    status: null,
  },

  setActiveFreeBetsList: async (params: any) => {
    const updatedData = params.data.map((item: any) => {
      if (item.end_date < new Date(Date.now()) && item.freebets_amount > item.freebets_played) {
        return { ...item, currency: Constants.currency(item.currency) };
      }
    });
    set(async (state: any) => {
      state.activeFreeBetsList = {
        ...state.activeFreeBetsList,
        data: updatedData,
      };
      return state.activeFreeBetsList;
    });
  },

  retrieveActiveFreeBets: async (token: any): Promise<any> => {
    const Data = await API.get(Constants.activeFreebets, token.token).then((json) => {
      return json.results;
    });
    const updatedData = Data.filter((item: any) => {
      if (new Date(item.end_date) > new Date(Date.now()) && item.freebets_amount > item.freebets_played) {
        return { ...item, currency: Constants.currency(item.currency) };
      }
    });

    await set((state: any) => {
      state.activeFreeBetsList = {
        data: updatedData,
      };
      return state.activeFreeBetsList;
    });
  },

  getCurrentActiveFreeBet: async (params: any) => {
    const Data = await API.get(Constants.activeFreebets + '?id=' + params.freebet_id, params.token).then((json) => {
      return json.results[0];
    });
    let updatedData = {};

    if (!Data || new Date(Data.end_date) < new Date(Date.now()) || Data.freebets_amount <= Data.freebets_played) {
      updatedData = { freebet_id: null };
    } else {
      updatedData = { ...Data, currency: Constants.currency(Data.currency) };
    }
    set(async (state: any) => {
      state.currentActiveFreeBet = {
        ...updatedData,
      };

      return await state.currentActiveFreeBet;
    });
    return updatedData;
  },
}));

export const lowBalanceStore = create((set: any) => ({
  isLowBalance: false,
  setIsLowBalance: (params: any) =>
    set((state: any) => {
      state.isLowBalance = {
        isLowBalance: params,
      };
      return state.isLowBalance;
    }),
}));

//change body overflow component
function bodyOverflow(flag: boolean) {
  if (flag) {
    const body = document.getElementById('body');
    if (body) body.style.overflow = 'hidden';
  } else {
    const body = document.getElementById('body');
    if (body) body.style.overflowY = 'unset';
  }
}

function getInitialSoundState() {
  try {
    return GetParamsFromUrl('isSoundOn') || localStorage.getItem('isSoundOn') || 'true';
  } catch (e) {
    return 'true';
  }
}

function getInitialBackSoundState() {
  try {
    return GetParamsFromUrl('isMusicOn') || localStorage.getItem('isMusicOn') || 'false';
  } catch (e) {
    return 'false';
  }
}

function getInitialLightModeState() {
  try {
    return GetParamsFromUrl('isLightMode') || localStorage.getItem('isLightMode') || 'false';
  } catch (e) {
    return 'false';
  }
}

function getInitialLocaleState() {
  try {
    return GetParamsFromUrl('locale') || localStorage.getItem('locale') || LOCALES.HINDI_EN;
  } catch (e) {
    return LOCALES.HINDI_EN;
  }
}

function getInitialLobbyUrl() {
  try {
    return localStorage.getItem('lobby_url') || '';
  } catch (e) {
    return '';
  }
}

function getInitialExitUrl() {
  try {
    return localStorage.getItem('lobby_exit_url') || '';
  } catch (e) {
    return '';
  }
}

function getDepositUrl() {
  try {
    return localStorage.getItem('deposit_url') || '';
  } catch (e) {
    return '';
  }
}

function getInitialOnboardingState() {
  try {
    const savedOnboardingState = sessionStorage.getItem('isStatisticsVisible');
    if (savedOnboardingState) {
      return savedOnboardingState.toLowerCase() === 'true';
    } else return false;
  } catch (e) {
    return false;
  }
}

function getDragonStatisticState() {
  try {
    const savedDragonStatistics = sessionStorage.getItem('getDragonStatisticState');
    if (savedDragonStatistics) return JSON.parse(savedDragonStatistics).winners;
    else return [];
  } catch (e) {
    return [];
  }
}

function getLuckyStatisticState() {
  try {
    const savedDragonStatistics = sessionStorage.getItem('getLuckyStatisticState');
    if (savedDragonStatistics) return JSON.parse(savedDragonStatistics).winners;
    else return [];
  } catch (e) {
    return [];
  }
}

function GetParamsFromUrl(param: string) {
  return new URLSearchParams(window.location.search).get(param);
}

function GenerateUuid() {
  try {
    return GetParamsFromUrl('uuid') || sessionStorage.getItem('uuid') || uuid();
  } catch (e) {
    return uuid();
  }
}

function uuid() {
  const new_uuid = uuidv4();
  sessionStorage.setItem('uuid', new_uuid);
  return new_uuid;
}

function cutBalanceCoins(balance: any) {
  const newBalance = balance.toString();
  if (newBalance.search(/\./) > 0) {
    return Number(newBalance.substring(0, newBalance.search(/\./) + 3));
  } else {
    return Number(newBalance);
  }
}
