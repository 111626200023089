export const build_types = {
  Lobby: 'lobby',
  Andar: 'andar-bahar',
  Dragon: 'dragon-tiger',
  Lucky: 'lucky-7',
  Teen: 'teen-patti',
  Baccarat: 'baccarat',
  Aviator: 'aviator',
};

export const Select_build_types = (id: any) => {
  switch (id) {
    case '1':
      return 'andar-bahar';
    case '2':
      return 'dragon-tiger';
    case '3':
      return 'lucky-7';
    case '4':
      return 'teen-patti';
    case '5':
      return 'baccarat';
    case '7':
      return 'aviator';
    default:
      return 'lobby';
  }
};
export const domain = String(process.env.REACT_APP_DOMAIN_BACKEND);
// export const domain = 'https://7b66-37-212-198-25.ngrok-free.app/';
export const socket = String(process.env.REACT_APP_SOCKET_BACKEND);
// export const socket = 'https://7b66-37-212-198-25.ngrok-free.app';
// export const domain = "https://hetzner.andarbahar.world/"; //stage
// export const domain = "https://bolobhai.gameswiss.com/"; //prod
// export const domain = "http://127.0.0.1:8000/" // local

export const amplitude_key = String(process.env.REACT_APP_AMPLITUDE_KEY);
// export const amplitude_key = "253291f45fe6f9a028207a8bf3c1f447"; //stage
// export const amplitude_key = "e2d4cd326699e2dc27e37aa939951382" //prod

export const clarity_key = String(process.env.REACT_APP_CLARITY);

export const games_list = domain + 'api/games/';
export const history_all = domain + 'api/history/all';
export const history = domain + 'api/history/';
export const play = domain + 'api/games/play';
export const run_next = domain + 'api/games/run-next';
export const game_params = domain + 'api/games/join-game';
export const statistics = domain + 'api/stats/';
export const lobby = domain + 'api/lobby';
export const languages = domain + 'api/languages';
export const latestWinner = domain + 'api/last-winner';

export const history_freebet = domain + 'api/freebets/history';
// export const playFreebet = domain + "api/freebets/play";
export const playFreebet = domain + 'api/freebets/play-and-claim';
export const claimFreebet = domain + 'api/freebets/claim';
export const activeFreebets = domain + 'api/freebets/active';
export const operatorInfo = domain + 'api/operators/info';
export const aviatorMatchmaking = domain + 'api/aviator/matchmaking';

export const history_limit = 100;

export const freebetPlay = {
  play: 'play',
  claim: 'claim',
  load: 'load',
  notPlay: 'notPlay',
};
export const freeBetStatus = {
  claimed: 'CLAIMED',
  active: 'ACTIVE',
  expired: 'EXPIRED',
  canceled: 'CANCELED',
};

export const errorWindowId = 'errWin';
export const andar_sides = {
  andar: 0,
  bahar: 1,
};

export const dragon_sides = {
  dragon: 0,
  tiger: 1,
  tie: 2,
};

export const lucky_sides = {
  seven_up: 0,
  seven_down: 1,
  seven: 2,
};

export const teenPatti_sides = {
  player: 0,
  dealer: 1,
  push: 2,
  tie: 3,
};

export const baccarat_side = {
  player: 0,
  banker: 1,
  tie: 2,
};

export const currency = (st: string) => {
  switch (st) {
    case 'USD':
      return 'USD';
    case 'INR':
      return 'INR';
    case 'EUR':
      return 'EUR';
    default:
      return 'INR';
  }
};

export const activity_status = {
  SENT: 'SENT',
  CREATED: 'CREATED',
  READ: 'READ',
};

export const aviator_state_machine_states = {
  WAITING_FOR_PLAYERS: 'WAITING_FOR_PLAYERS',
  START_MATCH: 'START_MATCH',
  START_ROUND: 'START_ROUND',
  PENDING_FOR_START: 'PENDING_FOR_START',
  START_FLYING: 'START_FLYING',
  IN_PLAY: 'IN_PLAY',
  END_FLYING: 'END_FLYING',
  FINAL: 'FINAL',
  END_ROUND: 'END_ROUND',
  END_MATCH: 'END_MATCH',
};

export const aviator_bet_panel_states = {
  default: 'default',
  bet_placed: 'bet_placed',
  cash_out: 'cash_out',
  pending: 'pending',
};

export const games_name = {
  andar_bahar: 'Andar Bahar',
  dragon_tiger: 'Dragon Tiger',
  lucky: 'Lucky 7',
  teen_patti: 'Teen Patti',
  baccarat: 'Baccarat',
};

export const Select_game_name = (id: any) => {
  switch (id) {
    case 1:
      return 'Andar Bahar';
    case 2:
      return 'Dragon Tiger';
    case 3:
      return 'Lucky 7';
    case 4:
      return 'teen-patti';
    case 5:
      return 'Baccarat';
    case 6:
      return 'Aviator';
    default:
      return 'lobby';
  }
};

export const socket_events_callback_statuses = {
  pending: 'pending',
  success: 'success',
  error: 'error',
};
