// import { ReactComponent as CloseButton } from "../assets/closeButton.svg";
import * as amplitude from '@amplitude/analytics-browser';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

// import { ClickSound } from '../helpers/AudioHelper';
import loader from '../helpers/dotsLoader.module.scss';
import { generateTargetDepositUrl } from '../helpers/GenerateTargetGameUrl';
import ModalWrapper from '../helpers/modalWrapper/ModalWrapper';
import { useSoundContext } from '../helpers/SoundContext';
import { stateStore, usePlayerState } from '../Store';

import modalStyle from './ModalWindow.module.scss';

export const ModalWindowReasons = {
  balance: 'balance',
  info: 'info',
  reconnect: 'reconnect',
};

export default function ModalWindow() {
  const { ClickSound } = useSoundContext();
  const { modalWindow, modalWindowState } = stateStore();
  const player = usePlayerState((state: any) => state.player);
  const menu = stateStore((state: any) => state.menu);
  const [headerContent, setHeaderContent] = useState<any>(null);
  const [bodyContent, setBodyContent] = useState<any>(null);
  const [footerContent, setFooterContent] = useState<any>(null);
  const [needCloseButton, setNeedCloseButton] = useState(true);

  function closeModalWindow() {
    ClickSound();
    modalWindowState({ ...modalWindow, isOpen: false });
  }

  function goToCashier() {
    ClickSound();
    amplitude.track('Open cashier');
    const target_url = generateTargetDepositUrl(menu.depositUrl + '&modal=deposit', {
      isDepositOpen: false,
      operatorToken: player.operatorToken,
    });
    window.open(target_url, '_top');
  }

  useEffect(() => {
    switch (modalWindow.reason) {
      case ModalWindowReasons.balance:
        balanceWindow();
        break;
      case ModalWindowReasons.info:
        infoWindow();
        break;
      case ModalWindowReasons.reconnect:
        reconnectWindow();
        break;
    }
  }, [modalWindow]);

  const intl = useIntl();

  function primaryButton(color: string, textId: string, onClick: any) {
    return (
      <div className={modalStyle.primaryButton} style={{ background: color }} onClick={onClick}>
        <a className={modalStyle.text}>
          <FormattedMessage id={textId} />
        </a>
      </div>
    );
  }

  function secondaryButton(textId: string, onClick: any) {
    return (
      <div className={modalStyle.secondaryButton} onClick={onClick}>
        <a className={modalStyle.text}>
          <FormattedMessage id={textId} />
        </a>
      </div>
    );
  }

  const CurrencyNumber = intl
    .formatNumberToParts(player.balance, {
      style: 'currency',
      currency: player.currency ? player.currency : 'INR',
    })
    .map(({ type, value }) => {
      switch (type) {
        case 'currency':
          return (
            <span key="45454key" style={{ fontWeight: '400' }}>
              {value}
            </span>
          );
        default:
          return value;
      }
    });

  function balanceWindow() {
    amplitude.track('Open balance pop-up');
    setNeedCloseButton(true);
    setHeaderContent(
      <>
        {/* {closeButton()} */}
        <p className={modalStyle.text}>
          <FormattedMessage id={'your_balance'} />
        </p>
        <p className={modalStyle.text}>{CurrencyNumber}</p>
      </>,
    );
    setBodyContent(<>{primaryButton('var(--button-system-light-green)', 'back_to_game', closeModalWindow)}</>);
    setFooterContent(<>{secondaryButton('go_to_cashier', goToCashier)}</>);
  }

  function infoWindow() {
    // amplitude.track('Open balance pop-up');
    setNeedCloseButton(true);
    setHeaderContent(
      <>
        {/* {closeButton()} */}
        <p className={modalStyle.text}>{modalWindow.infoTitle}</p>
      </>,
    );
    setBodyContent(
      <>
        <p className={modalStyle.text}>{modalWindow.infoMessage}</p>
      </>,
    );
    setFooterContent(
      <>
        <div
          className={modalStyle.primaryButton}
          style={{ background: 'var(--button-system-light-green)' }}
          onClick={closeModalWindow}
        >
          <a className={modalStyle.text}>{modalWindow.infoButtonText}</a>
        </div>
      </>,
    );
  }

  function reconnectWindow() {
    setNeedCloseButton(false);
    setHeaderContent(
      <>
        <p className={modalStyle.text}>
          <FormattedMessage id={'reconnect'} />
        </p>
      </>,
    );
    setBodyContent(<div className={loader.loader} />);
    setFooterContent(null);
  }

  return (
    // <div className={modalStyle.modal} style={{ display: modalWindow.isOpen ? "flex" : "none" }}>
    //     <div className={modalStyle.panel}>
    <ModalWrapper closeModal={closeModalWindow} isOpen={modalWindow.isOpen} needCloseButton={needCloseButton}>
      <div className={modalStyle.header}>{headerContent}</div>
      <div className={modalStyle.body}>{bodyContent}</div>
      <div className={modalStyle.footer}>{footerContent}</div>
    </ModalWrapper>
  );
}
