import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { LOCALES } from './../i18n/locales';

interface options {
  isWin: boolean;
  isExpired?: boolean;
}

export default function WinLoseLabel(options: options) {
  const locale = localStorage.getItem('locale');
  const [currentLocale, setCurrenLocales] = useState<string | null>(locale);

  useEffect(() => {
    setCurrenLocales(locale);
  }, [locale]);
  return (
    <div
      style={
        currentLocale === LOCALES.ENGLISH || currentLocale === LOCALES.HINDI_EN || currentLocale == null
          ? {
              display: 'flex',
              width: '64px',
              height: '24px',
              padding: '4px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              borderRadius: ' 4px',
              background: options.isWin
                ? 'rgba(48, 219, 91, 0.10)'
                : options.isExpired
                  ? 'rgba(255, 214, 10, 0.1)'
                  : 'rgba(255, 45, 85, 0.10)',
            }
          : {
              display: 'flex',
              width: '64px',
              height: '24px',
              padding: '4px 2px 4px 2px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              borderRadius: ' 4px',
              background: options.isWin
                ? 'rgba(48, 219, 91, 0.10)'
                : options.isExpired
                  ? 'rgba(255, 214, 10, 0.1)'
                  : 'rgba(255, 45, 85, 0.10)',
            }
      }
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '10px',
          overflow: 'hidden',
        }}
      >
        <p
          style={
            currentLocale === LOCALES.ENGLISH || currentLocale === LOCALES.HINDI_EN || currentLocale === null
              ? {
                  color: options.isWin ? '#1CDB2F' : options.isExpired ? '#FFD60A' : '#EB3333',
                  textAlign: 'center',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: '800',
                  lineHeight: '130%',
                  letterSpacing: '-0.12px',
                  textTransform: 'uppercase',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }
              : {
                  color: options.isWin ? '#1CDB2F' : options.isExpired ? '#FFD60A' : '#EB3333',
                  textAlign: 'center',
                  fontFamily: 'Inter',
                  fontSize: '9px',
                  fontStyle: 'normal',
                  fontWeight: '800',
                  lineHeight: '130%',
                  letterSpacing: '-0.12px',
                  textTransform: 'uppercase',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }
          }
        >
          {/*{options.isWin ? "WIN" : options.isExpired ? "MiSSED" : "LOSE"}*/}
          {options.isWin ? (
            <FormattedMessage id="win" />
          ) : options.isExpired ? (
            <FormattedMessage id="missed" />
          ) : (
            <FormattedMessage id="lose" />
          )}
        </p>
      </div>
    </div>
  );
}
