import { motion } from 'framer-motion';
import React from 'react';

import { ReactComponent as FilterArrowDown } from '../../../assets/filterArrowUp.svg';
import { BaccaratStateStore, useBaccaratParams } from '../BaccaratStore';
import styles2 from '../Game.module.scss';
import styles1 from '../Lobby.module.scss';

interface BankerColumnProps {
  activeColumn: any;
  activePair: any;
  bankerStake?: any;
  onClick?: () => void;
  totalStake: number;
}

export const BankerColumn: React.FC<BankerColumnProps> = ({
  activeColumn,
  activePair,
  bankerStake,
  onClick,
  totalStake,
}) => {
  const styles = { ...styles1, ...styles2 };
  const isActive = activeColumn === 'banker' || activePair === 'bankerTie';
  const isBetPlaced = bankerStake > 0;
  const baccaratGame = BaccaratStateStore((state: any) => state.baccaratGame);
  const isGameOpen = baccaratGame.isOpen;
  const [baccaratParams] = useBaccaratParams((state: any) => [state.baccaratParams]);
  return (
    <div className={styles.betColumn + ' ' + `${isActive ? styles.activeBanker : ''}`} onClick={onClick}>
      <div className={styles.betTitle}>Banker</div>
      {(isActive || isBetPlaced) && (
        <div
          className={styles.betValue}
          style={{
            color: !isActive && isBetPlaced ? 'var(--button-system-orange)' : 'white',
          }}
        >
          {bankerStake >= 0 && <div className={styles.placeBetWithStake}>PLACED BET</div>} ₹{bankerStake}{' '}
        </div>
      )}
      {!isActive && !isBetPlaced && (
        <div className={styles.placeBet + ' ' + `${bankerStake > 0 || isGameOpen ? styles.placeBetWithStak : ''}`}>
          {!totalStake &&
            !isActive &&
            !bankerStake &&
            !isBetPlaced &&
            activeColumn !== 'tie' &&
            activeColumn !== 'player' && (
              <motion.div
                className={styles.arrows}
                animate={{
                  x: [-5, 1, -5],
                  y: 0,
                  transition: {
                    duration: 1,
                    repeat: Infinity,
                  },
                }}
              >
                <FilterArrowDown
                  width={'14px'}
                  height={'14px'}
                  fill={'var(--button-system-orange)'}
                  style={{ transform: 'rotate(90deg)' }}
                />
              </motion.div>
            )}
          {isGameOpen ? 'No Bet' : 'Place Bet'}
          {!totalStake &&
            !isActive &&
            !bankerStake &&
            !isBetPlaced &&
            activeColumn !== 'tie' &&
            activeColumn !== 'player' && (
              <motion.div
                className={styles.arrows}
                animate={{
                  x: [5, -1, 5],
                  y: 0,
                  transition: {
                    duration: 1,
                    repeat: Infinity,
                  },
                }}
              >
                <FilterArrowDown
                  width={'14px'}
                  height={'14px'}
                  fill={'var(--button-system-orange)'}
                  style={{ transform: 'rotate(-90deg)' }}
                />
              </motion.div>
            )}
        </div>
      )}
      {/* <div
        className={`place-bet ${bankerStake > 0 ? 'place-bet-with-stake' : ''}`}
      >
        {!totalStake &&
          !isActive &&
          !bankerStake &&
          !isBetPlaced &&
          activeColumn !== 'tie' &&
          activeColumn !== 'player' && (
            <motion.div
              className="arrows"
              animate={{
                x: [-5, 1, -5],
                y: 0,
                transition: {
                  duration: 1,
                  repeat: Infinity,
                },
              }}
            >
              <FilterArrowDown
                width={'14px'}
                height={'14px'}
                fill={'var(--button-system-orange)'}
                style={{ transform: 'rotate(90deg)' }}
              />
            </motion.div>
          )}
        Place Bet
        {!totalStake &&
          !isActive &&
          !bankerStake &&
          !isBetPlaced &&
          activeColumn !== 'tie' &&
          activeColumn !== 'player' && (
            <motion.div
              className={'arrows'}
              animate={{
                x: [5, -1, 5],
                y: 0,
                transition: {
                  duration: 1,
                  repeat: Infinity,
                },
              }}
            >
              <FilterArrowDown
                width={'14px'}
                height={'14px'}
                fill={'var(--button-system-orange)'}
                style={{ transform: 'rotate(-90deg)' }}
              />
            </motion.div>
          )}
      </div> */}
      <div className={styles.odds}>{baccaratParams.banker_coef}:1</div>
    </div>
  );
};
