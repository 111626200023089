import { motion } from 'framer-motion';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CardsStore } from '../Game';

import { Card } from './Card';

import './Row.scss';

interface RowProps {
  isTop: boolean;
  numSlots: number;
}

export const Row = ({ isTop, numSlots }: RowProps) => {
  const cards = isTop ? CardsStore((state) => state.cardsTop) : CardsStore((state) => state.cardsBottom);

  const emptySlots = Array.from({ length: numSlots });

  return (
    <div>
      <a className="tp-position-player">
        <FormattedMessage id={isTop ? 'Dealer' : 'Player'} />
      </a>
      <div className="row">
        {cards.map((card: { id: number; card: string; name: string }, index: number) => (
          <Card key={card.id} card={card} isTop={isTop} index={index} />
        ))}
        {emptySlots.map((_, index) => (
          <motion.div key={index} className="teen-card-placeholder" />
        ))}
      </div>
    </div>
  );
};
