import * as amplitude from '@amplitude/analytics-browser';
// import { getAnalytics } from 'firebase/analytics';
// import { initializeApp } from 'firebase/app';
import React, { useEffect, useState } from 'react';
// import { Img } from 'react-image';
import { IntlProvider } from 'react-intl';
import { clarity } from 'react-microsoft-clarity';

import AppStyles from './App.module.scss';
import Caching from './CacheCleaner';
import FreeBetPopUp from './freebets/freebetsPopUp/FreebetsPopUp';
// import FreeBetsManagement from './freebets/management/FreeBetsManagement';
import AndarBaharNew from './games/andarNewVision/AndarBahar';
import Aviator from './games/aviator/Aviator';
import { Baccarat } from './games/baccarat/Baccarat';
import DragonTiger from './games/dragonTiger/DragonTiger';
import LuckySeven from './games/luckySeven/LuckySeven';
import { TeenPatti } from './games/teenPatti/TeenPatti';
import InitAPI, * as API from './helpers/API';
// import InitAudio from './helpers/AudioHelper';
// import { cardsList } from './helpers/CardsPreloader';
import * as Constants from './helpers/Constants';
import InitErrorWindow, { ErrorWindow, QuitWindow } from './helpers/ErrorWindow';
// import InitSocket, { SocketListener } from './helpers/socket/SocketManager';
import Splash from './helpers/SplashScreen';
import { LOCALES } from './i18n/locales';
import { messages } from './i18n/messages';
// import Activity from './panels/activity/Activity';
// import ActivitySplash from './panels/activity/ActivitySplash';
import Header from './panels/Header';
// import Language from './panels/Language';
import LobbyScene from './panels/LobbyScene';
import LowBalanceWindow from './panels/lowBalance/LowBalance';
import Menu from './panels/Menu';
import ModalWindow from './panels/ModalWindow';
import PlayerHistory from './panels/PlayerHistory';
import SettingsWarning from './panels/settingsWarning/SettingsWarning';
import { lowBalanceStore, stateStore, useBuildType, usePlayerState } from './Store';

// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: 'AIzaSyBVKKIQMrBxLuJD1-7vLEiu01BeQ3cr7AA',
//   authDomain: 'bolobhai-frontend.firebaseapp.com',
//   projectId: 'bolobhai-frontend',
//   storageBucket: 'bolobhai-frontend.appspot.com',
//   messagingSenderId: '428957783176',
//   appId: '1:428957783176:web:403b7baee89359f4d0fb3d',
//   measurementId: 'G-JKVTST4DN9',
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export default function App() {
  InitAPI();
  // InitAudio();
  InitErrorWindow();

  const [isStart, setIsStart] = useState(true);

  const [playerId, setPlayerId] = useState(null);

  useEffect(() => {
    Caching();
    if (isStart) {
      // analytics.app.automaticDataCollectionEnabled = true;

      // Start seeing data on the Clarity dashboard with your id
      clarity.init(Constants.clarity_key);
      clarity.identify('USER_ID', { userProperty: 'value' });
      clarity.consent();

      clarity.setTag('game', buildType);
      clarity.upgrade('upgradeReason');
      if (clarity.hasStarted()) {
        clarity.identify('USER_ID', { userProperty: 'value' });
      }
      setIsStart(false);
    }
  }, []);

  const buildType = useBuildType((state: any) => state.buildType);
  // find freebet id in url
  // const urlParams = new URLSearchParams(window.location.search);
  // const freebet_id = urlParams.get('freebet_id');
  // param to freebet play

  // const [activeFreeBetsList, retrieveActiveFreeBets, getCurrentActive] = activeFreeBetStore((state: any) => [
  //   state.activeFreeBetsList,
  //   state.retrieveActiveFreeBets,
  //   state.getCurrentActiveFreeBet,
  // ]);

  // const [freeBetsManagement, setFreeBetsManagement] = stateStore((state: any) => [
  //   state.freeBetsManagement,
  //   state.setFreeBetsManagement,
  // ]);

  // const setManageFreebet = (activeFreebets: any) => {
  //   setFreeBetsManagement({
  //     ...freeBetsManagement,
  //
  //     activeFreeBetsGameId: activeFreebets ? activeFreebets.map((item: any) => item.game_id) : '',
  //   });
  // };
  const [player, setPlayer] = usePlayerState((state: any) => [state.player, state.playerState]);
  const [menu, menuState] = stateStore((state: any) => [state.menu, state.menuState]);
  const language = stateStore((state: any) => state.language);
  // const setLanguageList = stateStore((state: any) => state.setLanguageList);
  const [isLowBalance] = lowBalanceStore((state: any) => [state.isLowBalance, state.setIsLowBalance]);

  // TODO: Disable for Aviator
  // useEffect(() => {
  //   retrieveActiveFreeBets({ token: player.token });
  //
  //   if (freebet_id) {
  //     getCurrentActive({ freebet_id: freebet_id, token: player.token });
  //   }
  //
  //   API.get(Constants.languages, '').then((json) => {
  //     for (let i = 0; i < json.length; i++) {
  //       setLanguageList(json[i]).then(() => null);
  //     }
  //   });
  // }, []);
  //
  // useEffect(() => {
  //   setManageFreebet(activeFreeBetsList.data);
  // }, [activeFreeBetsList]);

  useEffect(() => {
    if (menu.isLightMode) {
      document.body.setAttribute('light', '');
    } else {
      document.body.removeAttribute('light');
    }
  }, [menu.isLightMode]);

  async function getLobbyDataAndOperatorInfo() {
    let depositUrl = '';
    let amplitude_key = '';
    await API.get(Constants.operatorInfo, player.token).then(async (data) => {
      amplitude_key = data.amplitude_key;
      depositUrl = data.deposit_url;
      localStorage.setItem('deposit_url', data.deposit_url);
    });
    await API.get(Constants.lobby, player.token).then(async (json) => {
      amplitude.init(amplitude_key || Constants.amplitude_key, { defaultTracking: true });
      amplitude.track('Sign Up', {
        version: `${process.env.REACT_APP_VERSION}`,
        build_type: buildType,
        player_id: json.player_id,
      });

      setPlayerId(json.player_id);
      await menuState({
        ...menu,
        lobby_url: json.lobby_url,
        exit_url: json.lobby_exit_url,
        depositUrl: depositUrl,
      });
      await setPlayer({
        ...player,
        balance: Number(json.balance),
        currency: json.currency,
      });
      localStorage.setItem('lobby_url', json.lobby_url);
      localStorage.setItem('lobby_exit_url', json.lobby_exit_url);
      localStorage.setItem('operator_name', json.operator_name);
    });
  }

  useEffect(() => {
    if (player.token) {
      getLobbyDataAndOperatorInfo();
      // InitSocket(player.token);
      // SocketListener();
    }
  }, [player.token]);

  const [app, setApp] = useState<any>();
  useEffect(() => {
    const body = document.getElementById('body');
    switch (buildType) {
      case Constants.build_types.Lobby:
        if (body) body.style.overflow = 'unset';
        setApp(<LobbyScene key={'LobbyScene'} />);
        break;
      case Constants.build_types.Andar:
        setApp(<AndarBaharNew key={'AndarBahar'} />);
        break;
      case Constants.build_types.Dragon:
        setApp(<DragonTiger key={'DragonTiger'} />);
        break;
      case Constants.build_types.Lucky:
        setApp(<LuckySeven key={'LuckySeven'} />);
        break;
      case Constants.build_types.Teen:
        setApp(<TeenPatti key={'TeenPatti'} />);
        break;
      case Constants.build_types.Aviator:
        setApp(<Aviator key={'Aviator'} />);
        break;
      case Constants.build_types.Baccarat:
        setApp(<Baccarat key={'Baccarat'} />);
        break;
    }
  }, []);
  return (
    <IntlProvider messages={messages[language.current]} locale={language.current} defaultLocale={LOCALES.HINDI_EN}>
      <Splash buildType={buildType} />
      <QuitWindow />
      <ErrorWindow buildType={buildType} playerId={playerId} />
      <SettingsWarning />
      <div className={AppStyles.App}>
        {isLowBalance && <LowBalanceWindow />}
        <FreeBetPopUp />
        <ModalWindow />
        <Header />
        <Menu />
        {/*<Language /> TODO: Disable for Aviator */}
        <PlayerHistory />
        {/*<FreeBetsManagement /> TODO: Disable for Aviator */}
        {/*<Activity /> TODO: Disable for Aviator */}
        {/* <ActivitySplash /> */}
        {app}
      </div>
      {/*<div style={{ display: 'none' }} id={'cards_preloader'}> TODO: Disable for Aviator */}
      {/*  {cardsList.map((card: any) => {*/}
      {/*    return <Img src={card} key={card} />;*/}
      {/*  })}*/}
      {/*</div>*/}
    </IntlProvider>
  );
}
