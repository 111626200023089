import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as CloseButton } from '../../../assets/closeButton.svg';
import * as Constants from '../../../helpers/Constants';
import { useSoundContext } from '../../../helpers/SoundContext';
import { aviatorStore } from '../Store';

import { getCoefColor } from './BetsHistory';
import style from './CashOutPanel.module.scss';

interface CashOutPanelInterface {
  coef: number;
  profit: number;
  stake_id: any;
}

export default function CashOutPanel(data: CashOutPanelInterface) {
  const { ClickSound } = useSoundContext();
  const _ref = useRef<any>();
  const [isShow, setIsShow] = useState(true);
  const { aviatorStateMachine, betPanelOne, betPanelTwo } = aviatorStore();

  function destroyWindow() {
    setIsShow(false);
  }

  useEffect(() => {
    setInterval(() => {
      destroyWindow();
    }, 5000);

    if (aviatorStateMachine.state === Constants.aviator_state_machine_states.START_ROUND) {
      destroyWindow();
    }
  }, [aviatorStateMachine]);

  let winValue;

  if (Number(data.stake_id) === 1) {
    winValue = betPanelOne.bet * data.coef;
  } else if (Number(data.stake_id) === 2) {
    winValue = betPanelTwo.bet * data.coef;
  }

  // const winValue = data.profit / (data.coef - 1) + data.profit;
  const intl = useIntl();
  const CurrencyNumber = intl
    .formatNumberToParts(winValue!, {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
    })
    .map(({ type, value }) => {
      switch (type) {
        case 'currency':
          return (
            <span style={{ fontWeight: '400' }} key={value}>
              {value}
            </span>
          );
        default:
          return value;
      }
    });
  const _color = getCoefColor(data.coef);

  return (
    <>
      {isShow && (
        <div className={style.cashOutPanel} ref={_ref}>
          <div className={style.coef}>
            <a>You have cashed out!</a>
            <a className={style.coefText} style={{ color: _color }}>
              {data.coef}x
            </a>
          </div>
          <div className={style.amount}>
            <a>Win</a>
            <a className={style.amountText}>{CurrencyNumber}</a>
          </div>
          <div
            className={style.close}
            onClick={() => {
              ClickSound();
              destroyWindow();
            }}
          >
            <CloseButton />
          </div>
        </div>
      )}
    </>
  );
}
