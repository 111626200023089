import * as amplitude from '@amplitude/analytics-browser';
import React, { useEffect, useState } from 'react';

import { ReactComponent as BoxIcon } from '../../assets/iconBox.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ClickSound } from '../../helpers/AudioHelper';
import { generateTargetDepositUrl } from '../../helpers/GenerateTargetGameUrl';
import { activeFreeBetStore, lowBalanceStore, stateStore, usePlayerState } from '../../Store';

import styles from './LowBalance.module.scss';

export default function LowBalanceWindow() {
  const [menu] = stateStore((state: any) => [state.menu, state.menuState]);
  const [freeBetsManagement, setFreeBetsManagementOpen] = stateStore((state: any) => [
    state.freeBetsManagement,
    state.setFreeBetsManagementOpen,
  ]);
  const [player] = usePlayerState((state: any) => [state.player]);
  const [isShow, setIsShow] = useState(true);
  const [isLowBalance, setIsLowBalance] = lowBalanceStore((state: any) => [state.isLowBalance, state.setIsLowBalance]);

  const [activeFreeBetsList] = activeFreeBetStore((state: any) => [state.activeFreeBetsList]);

  // function getLobbyData() {
  //     API.get(Constants.lobby, player.token).then((json) => {
  //         menuState({ ...menu, lobby_url: json.lobby_url, exit_url: json.lobby_exit_url });
  //     });
  // }

  useEffect(() => {
    setIsShow(isLowBalance);
    if (isLowBalance) {
      amplitude.track('Low balance', { balance: player.balance });
    }
    // getLobbyData();
  }, [isLowBalance]);

  function exit(isDepositOpen: boolean) {
    const target_url = generateTargetDepositUrl(menu.depositUrl, {
      isDepositOpen: isDepositOpen,
      operatorToken: player.operatorToken,
    });
    isDepositOpen ? amplitude.track('Open cashier') : amplitude.track('Exit');
    window.open(target_url, '_top');
  }

  const exitFunc = () => {
    // ClickSound();
    // setIsShow(false);
  };

  useEffect(() => {
    setIsLowBalance(isShow);
  }, [isShow]);

  if (isShow) {
    return (
      <div
        className={styles.background}
        onClick={(e) => {
          e.currentTarget === e.target ? exitFunc() : '';
        }}
      >
        <div className={styles.lowBalanceWindowWrapper}>
          <div className={styles.lowBalanceHead}>Your balance is low, please visit cashier</div>
          <div onClick={() => exit(true)} className={styles.lowBalanceExitButton}>
            Make Deposit
          </div>
          {activeFreeBetsList.data.length > 0 && (
            <div
              onClick={() =>
                setFreeBetsManagementOpen({
                  ...freeBetsManagement,
                  isOpen: true,
                })
              }
              className={styles.lowBalanceFreebetsButton}
              id={'button' + activeFreeBetsList.data.length}
            >
              Play with free bet <BoxIcon />
            </div>
          )}
          <div onClick={() => exit(false)} className={styles.lowBalanceCloseButton}>
            Exit
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
