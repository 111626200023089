import React, { useEffect, useState } from 'react';

import * as API from '../../helpers/API';
import * as Constants from '../../helpers/Constants';
import InitSocket, { SocketListener, socket } from '../../helpers/socket/SocketManager';
import { useSoundContext } from '../../helpers/SoundContext';
import { lowBalanceStore, stateStore, usePlayerState } from '../../Store';

import About from './About';
import Game from './Game';
import { aviatorStore } from './Store';

export default function Aviator() {
  const { BackgroundSound } = useSoundContext();
  useEffect(() => {
    function playBg() {
      BackgroundSound('play');
    }
    document.body.addEventListener('click', playBg);
    return () => {
      document.body.removeEventListener('click', playBg);
    };
  }, [BackgroundSound]);

  aviatorConnection();

  return (
    <div style={{ width: '100%' }}>
      {/*<StateMachineDevButtons />*/}
      {/*<SocketDevButtons />*/}
      <About />
      <Game />
    </div>
  );
}

export function aviatorConnection() {
  const { setAviatorParams, setLastIndexes, setSocketParams, setAviatorStateMachine } = aviatorStore();
  const { player, playerState } = usePlayerState();
  const { freeBetsManagement, setFreeBetsManagement, error, errorState } = stateStore();
  const { betPanelOne, setBetPanelOne, betPanelTwo, setBetPanelTwo } = aviatorStore();
  const { setIsLowBalance } = lowBalanceStore();
  const [is_matchmaking, setIs_matchmaking] = useState(false);
  const { modalWindowState } = stateStore();

  useEffect(() => {
    if (player.token != null && !is_matchmaking) {
      API.get(Constants.aviatorMatchmaking, player.token).then((json) => {
        if (json.player_id) {
          setIs_matchmaking(true);

          const arr = [];
          for (let i = 0; i < json.coefs.length; i++) {
            arr.push(Number(json.coefs[i]).toFixed(2));
          }
          setLastIndexes(arr);

          setSocketParams({ player_id: json.player_id, room: json.room, name: json.name });
          setAviatorStateMachine({ state: json.room_state });

          // TODO: Split socket connection for another socket server
          InitSocket({ token: player.token, room: json.room, old_sid: json.old_sid });
          SocketListener();
        } else {
          errorState({ ...error, isOpen: true, messageId: 0, message: 'Matchmaking error' });
        }
      });
      API.post(Constants.game_params, { demo: false }, player.token).then((json) => {
        setAviatorParams(json);
        playerState({ ...player, balance: json.balance });
        setFreeBetsManagement({
          ...freeBetsManagement,
          selectedGameId: json.id,
        });
        setBetPanelOne({ ...betPanelOne, bet: json.default_bet, autoCashOut: { value: json.min_coef.toFixed(2) } });
        setBetPanelTwo({ ...betPanelTwo, bet: json.default_bet, autoCashOut: { value: json.min_coef.toFixed(2) } });
        if (json.balance < json.min_bet) {
          setIsLowBalance(true);
        }
      });
    }
  }, [player.token, is_matchmaking]);

  useEffect(() => {
    if (socket) {
      const onDisconnect = () => {
        modalWindowState({ reason: 'reconnect', isOpen: true });
      };
      socket.on('disconnect', onDisconnect);

      const onConnect = () => {
        modalWindowState({ reason: 'reconnect', isOpen: false });
      };
      socket.on('connect', onConnect);
    }
  }, [socket]);

  // useEffect(() => {
  //   if (isSocketConnected) {
  //     modalWindowState({ reason: 'reconnect', isOpen: false });
  //   } else {
  //     modalWindowState({ reason: 'reconnect', isOpen: true });
  //   }
  // }, [isSocketConnected]);

  useEffect(() => {
    const reconnect = () => {
      console.log('Reconnect');
      setIs_matchmaking(false);
      modalWindowState({ reason: 'reconnect', isOpen: false });
    };

    window.addEventListener('online', reconnect);

    return () => {
      window.removeEventListener('online', reconnect);
    };
  }, []);
}
