/* eslint-disable eqeqeq */
import { motion } from 'framer-motion';
import React from 'react';

import { teenPatti_sides } from '../../../helpers/Constants';
import Firework from '../../../helpers/Fireworks.module.scss';
import { playRequestStore } from '../../../Store';

import { CardContainer } from './Cards/CardContainer';

type ResultAnimationProps = {
  winBannerColor: string;
  winAnimationState: boolean;
  firstWinText: any;
  secondWinText: any;
  win: boolean;
};

export const ResultAnimation = ({
  winBannerColor,
  winAnimationState,
  firstWinText,
  secondWinText,
}: ResultAnimationProps) => {
  const [playTeenPattiResponse] = playRequestStore((state: any) => [state.playTeenPattiResponse]);
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <motion.div
          className={'winBanner'}
          style={{ background: winBannerColor }}
          initial={{ opacity: 0 }}
          animate={
            winAnimationState
              ? {
                  opacity: 1,
                  transition: { duration: 0.4 },
                }
              : undefined
          }
        >
          {playTeenPattiResponse.win_side == teenPatti_sides.player ? (
            <div className={Firework.pyro} style={{ width: '100%' }}>
              <div className={Firework.before}></div>
              <div className={Firework.after}></div>
            </div>
          ) : (
            ''
          )}
          <a className={'winText'}>{firstWinText}</a>
          <a className={'winTextSecond'}>{secondWinText}</a>
        </motion.div>
        <motion.div
          animate={
            winAnimationState
              ? {
                  opacity: 0.4,
                  transition: { duration: 0.4 },
                }
              : undefined
          }
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        ></motion.div>
        <CardContainer />
      </div>
    </>
  );
};
