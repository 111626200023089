import { create } from 'zustand';

import * as Constants from '../../helpers/Constants';

interface stakesListInterface {
  previousRound: {
    roundCoef: number;
    allBets: {
      id: number;
      name: string;
      stake: number;
      coef: number;
    }[];
  };
  allBets: {
    id: number;
    name: string;
    stake: number;
    coef: number;
  }[];
  myBets: {
    id: number;
    date: string;
    stake: number;
    coef: number;
    isFreeBet: boolean;
  }[];
}

interface cashOutMessagesInterface {
  coef: number;
  profit: number;
  stake_id: number;
}

export const aviatorStore = create((set: any) => ({
  aviatorParams: {
    id: 0,
    name: '',
    currency: 'INR',
    min_bet: 50,
    max_bet: 100,
    min_coef: 1.01,
    max_coef: 100,
    default_bet: 50,
    step: 1,
    balance: 0,
  },

  setAviatorParams: (params: any) =>
    set((state: any) => {
      state.aviatorParams = {
        id: params.id,
        name: params.name,
        currency: Constants.currency(params.currency),
        min_bet: params.min_bet,
        max_bet: params.max_bet,
        min_coef: params.min_coef,
        max_coef: params.max_coef,
        default_bet: params.default_bet,
        step: params.step,
        balance: params.balance,
      };
      return state.aviatorParams;
    }),

  socketParams: {
    player_id: 0,
    room: '',
    name: '',
  },

  setSocketParams: (params: any) =>
    set((state: any) => {
      state.socketParams = {
        player_id: params.player_id,
        room: params.room,
        name: params.name.slice(0, 1) + '***' + params.name.slice(-1),
      };
      return state.socketParams;
    }),

  aviatorStateMachine: {
    state: '',
  },

  setAviatorStateMachine: (params: any) =>
    set((state: any) => {
      state.aviatorStateMachine = {
        state: params.state,
      };
      return state.aviatorStateMachine;
    }),

  playState: {
    coef: null,
  },

  setPlayState: (params: any) =>
    set((state: any) => {
      state.playState = {
        coef: params.coef,
      };
      return state.playState;
    }),

  planeAnimation: {
    visible: false,
    width: 0,
    height: 0,
    isEndFlying: false,
  },

  setPlaneAnimation: (params: any) =>
    set((state: any) => {
      state.planeAnimation = {
        visible: params.visible,
        width: params.width,
        height: params.height,
        isEndFlying: params.isEndFlying,
      };
      return state.planeAnimation;
    }),

  setPlaneAnimationSize: (params: any) =>
    set((state: any) => {
      state.planeAnimation.width = params.width;
      state.planeAnimation.height = params.height;
      return state.planeAnimation;
    }),

  lastIndexes: [],

  setLastIndexes: (params: any) =>
    set((state: any) => {
      state.lastIndexes = params;
      return state.lastIndexes;
    }),

  addLastIndex: (index: any) =>
    set((state: any) => {
      state.lastIndexes = [index, ...state.lastIndexes];
      return state.lastIndexes;
    }),

  displayState: {
    obj: '',
  },

  setDisplayState: (params: any) =>
    set((state: any) => {
      state.displayState = {
        obj: params.obj,
      };
      return state.displayState;
    }),

  cashOutMessage: {} as cashOutMessagesInterface,

  setCashOutMessage: (message: any) =>
    set((state: any) => {
      state.cashOutMessage = {
        coef: message.coef,
        profit: message.profit,
        stake_id: message.stake_id,
      };
      return state.cashOutMessage;
    }),

  stakesList: {
    previousRound: {
      roundCoef: 0,
      allBets: [],
    },
    allBets: [],
    myBets: [],
  },

  setStakesList: (stakesList: stakesListInterface) =>
    set((state: any) => {
      state.stakesList = stakesList;
      return state.stakesList;
    }),

  addToAllBets: (bet: any) =>
    set((state: any) => {
      if (!state.stakesList.allBets.find((element: any) => element === bet)) {
        const _allBets = [...state.stakesList.allBets, bet];
        _allBets.sort((a, b) => a.stake - b.stake).reverse();
        state.stakesList = {
          ...state.stakesList,
          allBets: _allBets,
        };
      }
      return state.stakesList;
    }),

  removeFromAllBets: (bet: any) =>
    set((state: any) => {
      const _allBets = state.stakesList.allBets;
      const _bet = _allBets.find(
        (element: any) =>
          String(element.player_id) === String(bet.player_id) && Number(element.stake_id) === Number(bet.stake_id),
      );
      const _index = _allBets.indexOf(_bet);
      if (_index > -1) {
        _allBets.splice(_index, 1);
      }
      state.stakesList = {
        ...state.stakesList,
        allBets: _allBets,
      };
      return state.stakesList;
    }),

  changeBetFromAllBets: (bet: any) =>
    set((state: any) => {
      const _allBets = state.stakesList.allBets;
      const _bet = _allBets.find(
        (element: any) =>
          String(element.player_id) === String(bet.player_id) && Number(element.stake_id) === Number(bet.stake_id),
      );
      const _index = _allBets.indexOf(_bet);
      if (_index > -1) {
        _allBets[_index] = bet;
      }
      state.stakesList = {
        ...state.stakesList,
        allBets: _allBets,
      };
      return state.stakesList;
    }),

  moveAllBetsToPreviousRound: (roundCoef: number) =>
    set((state: any) => {
      state.stakesList = {
        ...state.stakesList,
        previousRound: {
          roundCoef: roundCoef,
          allBets: state.stakesList.allBets,
        },
        allBets: [],
      };
      return state.stakesList;
    }),

  setMyBets: (betsList: any) =>
    set((state: any) => {
      state.stakesList = {
        ...state.stakesList,
        myBets: betsList,
      };
      return state.stakesList;
    }),

  betPanelOne: {
    panelId: 1,
    bet: 0,
    autoBet: false,
    autoCashOut: {
      enable: false,
      value: '1.10',
    },
    status: Constants.aviator_bet_panel_states.default,
    loader: false,
  },

  setBetPanelOne: async (params: any) =>
    await set((state: any) => {
      state.betPanelOne = {
        panelId: 1,
        bet: params.bet,
        autoBet: params.autoBet,
        autoCashOut: {
          enable: params.autoCashOut.enable,
          value: params.autoCashOut.value,
        },
        status: params.status,
        loader: params.loader,
      };
      return state.betPanelOne;
    }),

  setBetPanelOneStatus: (params: any) =>
    set((state: any) => {
      state.betPanelOne = {
        ...state.betPanelOne,
        status: params.status,
      };
      return state.betPanelOne;
    }),

  betPanelTwo: {
    panelId: 2,
    bet: 0,
    autoBet: false,
    autoCashOut: {
      enable: false,
      value: '1.10',
    },
    status: Constants.aviator_bet_panel_states.default,
    loader: false,
  },

  setBetPanelTwo: async (params: any) =>
    await set((state: any) => {
      state.betPanelTwo = {
        panelId: 2,
        bet: params.bet,
        autoBet: params.autoBet,
        autoCashOut: {
          enable: params.autoCashOut.enable,
          value: params.autoCashOut.value,
        },
        status: params.status,
        loader: params.loader,
      };
      return state.betPanelTwo;
    }),

  setBetPanelTwoStatus: (params: any) =>
    set((state: any) => {
      state.betPanelTwo = {
        ...state.betPanelTwo,
        status: params.status,
      };
      return state.betPanelTwo;
    }),
}));
