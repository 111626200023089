export function DrawCurveLine(canvas: any, context: any, endX: any, endY: any) {
  const start = { x: 10, y: canvas.current.height - 10 };
  const cp1 = { x: endX / 2, y: canvas.current.height - 10 };
  const cp2 = { x: endX, y: endY };
  const end = { x: endX, y: endY };

  context.lineWidth = 5;
  const grad = context.createLinearGradient(0, canvas.current.height, canvas.current.width, 0);
  grad.addColorStop(0, 'rgba(255,55,95,0.2)');
  grad.addColorStop(1, 'rgb(255,55,95)');
  context.strokeStyle = grad;
  context.fillStyle = grad;

  context.beginPath();
  context.moveTo(start.x, start.y);
  context.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, end.x, end.y);
  context.stroke();
  context.lineTo(end.x, start.y);
  context.lineTo(start.x, start.y);
  context.fill();
}
