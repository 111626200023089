import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as OnboardingFingerButtonAuto } from '../../../assets/onboardingFinger.svg';

import styles from './TooltipOverlay.module.scss';

interface Tooltip {
  text: string;
  targetSelectors: string[];
}

const tooltips: Tooltip[] = [
  {
    text: 'Welcome to the game! To get started, choose your side: Andar or Bahar',
    targetSelectors: ['#andarBtn', '#baharBtn'],
  },
  { text: 'Tap to Bet', targetSelectors: ['#betBtn'] },
  { text: 'Press  Start Game to play!', targetSelectors: ['#startBtn'] },
];

type TooltipOverlayProps = {
  setIsTooltipShown: Dispatch<SetStateAction<boolean>>;
};

const TooltipOverlay: React.FC<TooltipOverlayProps> = ({ setIsTooltipShown }) => {
  const [activeTooltipIndex, setActiveTooltipIndex] = useState<number | null>(0);
  const [highlightedButtonIndex, setHighlightedButtonIndex] = useState<number | null>(null);
  const sideBetsId = document.querySelector('#betsWrapper') as Element | null;
  // const rect = sideBetsId?.getBoundingClientRect() as DOMRect;

  const handleButtonClick = useCallback(() => {
    setTimeout(() => {
      setActiveTooltipIndex((prevIndex) =>
        prevIndex !== null && prevIndex + 1 < tooltips.length ? prevIndex + 1 : null,
      );
    }, 150);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (sideBetsId !== null) {
        sideBetsId.classList.add(styles['changeLayer']);
      }
    }, 150);
  }, [sideBetsId]);

  const handleOverlayClick = useCallback(() => {
    if (activeTooltipIndex !== null && activeTooltipIndex > 0) {
      setActiveTooltipIndex(null);
      setIsTooltipShown(false);
    }
  }, [activeTooltipIndex]);

  useEffect(() => {
    const overlay = document.querySelector(`.${styles['tooltip-overlay']}`);
    overlay?.addEventListener('click', handleOverlayClick);

    return () => {
      overlay?.removeEventListener('click', handleOverlayClick);
    };
  }, [handleOverlayClick]);

  useEffect(() => {
    const updateTargets = () => {
      tooltips.forEach((tooltip, index) => {
        tooltip.targetSelectors.forEach((selector) => {
          const target = document.querySelector(selector) as HTMLElement;
          if (target) {
            if (index === activeTooltipIndex) {
              target.classList.add(styles['highlighted-button']);
              target.addEventListener('click', handleButtonClick);
            } else {
              target.classList.remove(styles['highlighted-button']);
              target.removeEventListener('click', handleButtonClick);
            }
          }
        });
      });
    };

    updateTargets();

    return () => {
      tooltips.forEach((tooltip) => {
        tooltip.targetSelectors.forEach((selector) => {
          const target = document.querySelector(selector) as HTMLElement;
          target?.removeEventListener('click', handleButtonClick);
        });
      });
    };
  }, [activeTooltipIndex, handleButtonClick]);

  useEffect(() => {
    let intervalId: any;
    if (activeTooltipIndex === 0) {
      intervalId = setInterval(() => {
        setHighlightedButtonIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
      }, 2000);
    } else if (activeTooltipIndex === 1) {
      setHighlightedButtonIndex(2);
    } else if (activeTooltipIndex === 2) {
      setHighlightedButtonIndex(null);
    } else {
      setHighlightedButtonIndex(null);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [activeTooltipIndex]);

  useEffect(() => {
    if (activeTooltipIndex !== 0) {
      if (sideBetsId !== null) {
        sideBetsId.classList.remove(styles['changeLayer']);
      }
    }
    if (activeTooltipIndex === 0) {
      const targets = tooltips[0].targetSelectors
        .map((selector) => document.querySelector(selector))
        .filter((target) => target !== null) as Element[];

      if (targets.length > 0) {
        targets[0].classList.add(styles['light-button']);
        if (sideBetsId !== null) {
          sideBetsId.classList.add(styles['changeLayer']);
        }
        let currentIndex = 0;

        const animateButtons = () => {
          targets[currentIndex].classList.remove(styles['light-button']);
          currentIndex = (currentIndex + 1) % targets.length;
          targets[currentIndex].classList.add(styles['light-button']);
        };

        const intervalId = setInterval(animateButtons, 2000);
        return () => clearInterval(intervalId);
      }
    }
  }, [activeTooltipIndex, tooltips]);

  interface TooltipTextProps {
    text: string;
    highlightedText: string;
  }

  const TooltipText: React.FC<TooltipTextProps> = ({ text, highlightedText }) => {
    return (
      <div>
        {text.split(highlightedText).map((part, index, array) => (
          <React.Fragment key={index}>
            {part}
            {index < array.length - 1 && <span style={{ fontWeight: 600 }}>{highlightedText}</span>}
          </React.Fragment>
        ))}
      </div>
    );
  };

  const [style, setStyle] = useState<React.CSSProperties>({});
  const [tool, setTool] = useState<React.CSSProperties>({});

  const updatePosition = () => {
    const betsWrapper = document.getElementById('betsWrapper');
    const startWrapper = document.getElementById('startBtn');
    if (betsWrapper && startWrapper) {
      const rect = betsWrapper.getBoundingClientRect();
      const right = window.innerWidth - rect.right;
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const isBigScreen = screenWidth > 1024 && screenHeight > 1200;
      const isSmallScreen = screenWidth < 900;
      const isSmallHeight = screenHeight < 750 && screenWidth < 900;
      setStyle({
        position: 'fixed',
        right: !isBigScreen
          ? isSmallScreen
            ? activeTooltipIndex === 0
              ? `${window.innerWidth / 2 - 305}px`
              : activeTooltipIndex === 1
                ? `${right - 40}px`
                : `${window.innerWidth / 2 - 240}px`
            : activeTooltipIndex === 0
              ? `${window.innerWidth / 14 - 130}px`
              : activeTooltipIndex === 1
                ? `${right - 15}%`
                : `${window.innerWidth / 2 - 250}px`
          : activeTooltipIndex === 0
            ? `${window.innerWidth / 14 - 30}px`
            : activeTooltipIndex === 1
              ? `${right - 7}%`
              : `${window.innerWidth / 2 - 250}px`,
        top: !isSmallHeight
          ? isSmallScreen
            ? activeTooltipIndex === 0
              ? `${rect.top + 200}px`
              : activeTooltipIndex === 1
                ? `${rect.top + 210}px`
                : `${rect.top + 240}px`
            : activeTooltipIndex === 0
              ? `${rect.top - 100}px`
              : activeTooltipIndex === 1
                ? `${rect.top + 100}px`
                : `${rect.top + 120}px`
          : activeTooltipIndex === 0
            ? `${rect.top - 150}px`
            : activeTooltipIndex === 1
              ? `${rect.top + 210}px`
              : `${rect.top / 5 + 5}%`,
        transform: 'translateX(-50%)',
      });
      setTool({
        position: 'fixed',
        right: !isBigScreen
          ? isSmallScreen
            ? highlightedButtonIndex === 0
              ? `${window.innerWidth / 2 + 30}px`
              : highlightedButtonIndex === 1
                ? `${right - 25}px`
                : `${right + 10}px`
            : highlightedButtonIndex === 0
              ? `${window.innerWidth / 4 - 40}px`
              : highlightedButtonIndex === 1
                ? `${right - 80}px`
                : `${right - 9}%`
          : highlightedButtonIndex === 0
            ? `${window.innerWidth / 4 - 40}px`
            : highlightedButtonIndex === 1
              ? `${right - 80}px`
              : `${right - 5}%`,
        top: !isSmallHeight
          ? isSmallScreen
            ? activeTooltipIndex === 0
              ? `${rect.top + 120}px`
              : activeTooltipIndex === 1
                ? `${rect.top + 135}px`
                : `${rect.top - 10}%`
            : activeTooltipIndex === 0
              ? `${rect.top + 150}px`
              : activeTooltipIndex === 1
                ? `${rect.top + 10}px`
                : `${rect.top - 10}%`
          : activeTooltipIndex === 0
            ? `${rect.top + 20}px`
            : activeTooltipIndex === 1
              ? `${rect.top + 130}px`
              : `${rect.top - 10}%`,
        transform: 'translateX(-50%)',
      });
    }
  };

  useEffect(() => {
    updatePosition();
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition);

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition);
    };
  }, [highlightedButtonIndex, activeTooltipIndex]);

  return ReactDOM.createPortal(
    <>
      <div className={`${styles['tooltip-overlay']} ${activeTooltipIndex !== null ? styles['active'] : ''}`}>
        {activeTooltipIndex !== null && (
          <div className={styles['tooltip']} style={style}>
            <TooltipText text={tooltips[activeTooltipIndex].text} highlightedText="Andar or Bahar" />
          </div>
        )}
      </div>
      {activeTooltipIndex !== null && highlightedButtonIndex !== null && (
        <OnboardingFingerButtonAuto
          className={activeTooltipIndex === 0 ? styles['finger'] : styles['finger2']}
          style={tool}
        />
      )}
    </>,
    document.body,
  );
};

export default TooltipOverlay;
