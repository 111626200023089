import { useEffect } from 'react';

const speed = 0.3;

export function DrawCircle(context: any, x: number, y: number, isWhiteColor: boolean) {
  context.beginPath();
  context.arc(x, y, 1.5, 0, 2 * Math.PI);
  context.fillStyle = 'rgba(255,255,255,0.3)';
  if (isWhiteColor) {
    context.fillStyle = 'rgba(255,255,255)';
  }
  context.fill();
}

export function DrawCircles(canvas: any, context: any, startPointX: any, startPointY: any, isWhiteColor: boolean) {
  useEffect(() => {
    if (context != null) {
      animate();
    }
  }, [context]);

  const movePointY = () => {
    startPointY.y = startPointY.y + speed;
    if (startPointY.y > context.canvas.height - 10) startPointY.y = 0;
  };
  const movePointX = () => {
    startPointX.x = startPointX.x - speed;
    if (startPointX.x < 10) startPointX.x = context.canvas.width;
  };

  const animate = () => {
    movePointY();
    movePointX();
    DrawCircle(context, startPointY.x, startPointY.y, isWhiteColor);
    DrawCircle(context, startPointX.x, startPointX.y, isWhiteColor);

    requestAnimationFrame(animate);
  };
}

export function DrawCircleX(context: any, startPointX: any, isWhiteColor: boolean) {
  useEffect(() => {
    if (context != null) {
      animate();
    }
  }, [context]);

  const movePointX = () => {
    startPointX.x = startPointX.x - speed;
    if (startPointX.x < 0) startPointX.x = context.canvas.width;
  };

  const animate = () => {
    if (context != null) {
      movePointX();
      DrawCircle(context, startPointX.x, startPointX.y, isWhiteColor);
      requestAnimationFrame(animate);
    }
  };
}

export function DrawCircleY(context: any, startPointY: any, isWhiteColor: boolean) {
  useEffect(() => {
    if (context != null) {
      animate();
    }
  }, [context]);

  const movePointY = () => {
    startPointY.y = startPointY.y + speed;
    if (startPointY.y > context.canvas.height) startPointY.y = 0;
  };

  const animate = () => {
    if (context != null) {
      movePointY();
      DrawCircle(context, startPointY.x, startPointY.y, isWhiteColor);
      requestAnimationFrame(animate);
    }
  };
}

// usage below
// generateCircles();
//
// function generateCircles() {
//   for (let i = 1; i < planeAnimation.width / 20; i++) {
//     DrawCircleX(ctx, { x: i * 20, y: planeAnimation.height - 10 }, !(i % 5));
//   }
//   for (let i = 1; i < planeAnimation.height / 20; i++) {
//     DrawCircleY(ctx, { x: 10, y: i * 20 }, !(i % 5));
//   }
// }
