import React, { useEffect } from 'react';

import * as API from '../../helpers/API';
import * as Constants from '../../helpers/Constants';
import { stateStore, useGameParams, usePlayerState } from '../../Store';

import { About } from './About';
import { Game } from './Game/Game';
import { Lobby } from './Lobby';

export const TeenPatti = () => {
  const [setTeenParams] = useGameParams((state: any) => [state.setTeenParams]);

  const [player, setPlayer] = usePlayerState((state: any) => [state.player, state.playerState]);

  // const [freeBetsManagement, setFreeBetsManagement] = stateStore(
  //   (state: any) => [state.freeBetsManagement, state.setFreeBetsManagement],
  // );
  const [error, errorState] = stateStore((state: any) => [state.error, state.errorState]);
  useEffect(() => {
    if (player.token != null) {
      API.post(Constants.game_params, { demo: false }, player.token).then((json) => {
        if (json.id !== Number(process.env.REACT_APP_BUILD_ID)) {
          errorState({ ...error, isOpen: true, messageId: 0, message: 'Wrong token' });
        }
        setTeenParams(json);
        setPlayer({ ...player, balance: json.balance });
      });
    }
  }, [player.token]);

  return (
    <div key={'teen'} id={'teen'} style={{ width: '100%' }}>
      <About />
      <Lobby />
      <Game />
    </div>
  );
};
