import * as amplitude from '@amplitude/analytics-browser';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as BoxFreeBets } from '../assets/iconBox.svg';
import * as API from '../helpers/API';
// import { ClickSound } from '../helpers/AudioHelper';
import * as Constants from '../helpers/Constants';
import { generateTargetGameUrl } from '../helpers/GenerateTargetGameUrl';
import { useSoundContext } from '../helpers/SoundContext';
import { stateStore, usePlayerState } from '../Store';

import LatestWinner from './LatestWinner';
import styles from './LobbyScene.module.scss';

function LobbyScene() {
  const { ClickSound } = useSoundContext();
  const [player] = usePlayerState((state: any) => [state.player, state.playerState]);
  const [gamesList, setGamesList] = useState<any>([]);
  const menu = stateStore((state: any) => state.menu);
  const language = stateStore((state: any) => state.language);
  const [freeBetsManagement, setFreeBetsManagement] = stateStore((state: any) => [
    state.freeBetsManagement,
    state.setFreeBetsManagement,
  ]);

  useEffect(() => {
    // function handleWindowLoader() {

    // TODO: Games type filter
    // setGamesTypeFilterCarouselWidth(filter.current.scrollWidth - window.innerWidth)

    API.get(Constants.games_list, '').then((json) => {
      setGamesList(json);
      setFreeBetsManagement({
        ...freeBetsManagement,
        selectedGameId: json[0].id,
      });
    });
  }, []);

  async function openGame(gameId: number) {
    const url = await generateTargetGameUrl({
      gameId: gameId,
      menu: menu,
      player: player,
      language: language.current,
    });
    window.open(url, '_self');
  }

  return (
    <div className={styles['lobbyScene']}>
      <div className={styles['container']}>
        {/*TODO: Header text */}
        {/*<a className={"lobbyHeaderText"}>24 Games Lobby</a>*/}

        {/*TODO: Games type filter */}
        {/*<motion.div ref={filter} className={"gamesTypeFilterCarousel"}>*/}
        {/*    <motion.div*/}
        {/*        drag={"x"}*/}
        {/*        dragConstraints={{right: 0, left: -gamesTypeFilterCarouselWidth}}*/}
        {/*        dragElastic={0.1}*/}
        {/*        className={"inner-gamesTypeFilterCarousel"}*/}
        {/*    >*/}
        {/*        {gameTypesArray.map(type => {*/}
        {/*            return (*/}
        {/*                <motion.div className={"gamesTypeFilterItem"} key={type}>*/}
        {/*                    <a>{type}</a>*/}
        {/*                </motion.div>*/}
        {/*            )*/}
        {/*        })}*/}
        {/*    </motion.div>*/}
        {/*</motion.div>*/}
        {gamesList.length > 0 ? <LatestWinner gamesList={gamesList} /> : ''}
        <div className={styles.gamesWrapper}>
          <div className={styles['games']}>
            {(() => {
              const displayedGamesList = [];
              for (let i = 0; i < gamesList.length; i++) {
                if (gamesList[i].is_active) {
                  displayedGamesList.push(
                    <div
                      className={styles['gamePanel']}
                      key={gamesList[i].name}
                      onClick={async () => {
                        ClickSound();
                        amplitude.track('Open game', {
                          game: gamesList[i].name,
                          from: 'lobby',
                        });
                        await openGame(gamesList[i].id);
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img className={styles['gamePanelImage']} src={gamesList[i].logo_url} alt={gamesList[i].name} />
                        {/*TODO: Favorites icons */}
                        {/*<div className={'starIcon'}>*/}
                        {/*    <StarIcon*/}
                        {/*        fill={true ? "rgba(255, 159, 10, 1)" : "rgba(174, 174, 178, 1)"}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {freeBetsManagement.activeFreeBetsGameId.includes(gamesList[i].id) ? (
                          <div className={styles.freebetBox}>
                            <BoxFreeBets width={'14px'} height={'14px'} />
                            <FormattedMessage id="free_bets" />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      {/*<div className={styles["itemTextContainer"]}>*/}
                      {/*    <p className={styles.speedText}>Speed</p>*/}
                      {/*    <a className={styles["gameNameText"]}>*/}
                      {/*        /!*{gamesList[i].name}*!/*/}
                      {/*        {(() => {*/}
                      {/*            let displayedText = [];*/}
                      {/*            const words = gamesList[i].name.toLowerCase().split(" ");*/}
                      {/*            for (let j = 0; j < words.length; j++) {*/}
                      {/*                displayedText.push(*/}
                      {/*                    <FormattedMessage id={words[j]} key={j} />,*/}
                      {/*                );*/}
                      {/*            }*/}
                      {/*            return displayedText;*/}
                      {/*        })()}*/}
                      {/*    </a>*/}
                      {/*    /!* <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>*/}
                      {/*        <div style={{ display: "flex" }}>*/}
                      {/*            <img*/}
                      {/*                src={avatar1}*/}
                      {/*                className={styles["playersCountIcons"]}*/}
                      {/*                style={{ zIndex: 3, marginLeft: "unset" }}*/}
                      {/*                alt={"avatar1"}*/}
                      {/*            />*/}
                      {/*            <img*/}
                      {/*                src={avatar2}*/}
                      {/*                className={styles["playersCountIcons"]}*/}
                      {/*                style={{ zIndex: 2 }}*/}
                      {/*                alt={"avatar2"}*/}
                      {/*            />*/}
                      {/*            <img*/}
                      {/*                src={avatar3}*/}
                      {/*                className={styles["playersCountIcons"]}*/}
                      {/*                style={{ zIndex: 1 }}*/}
                      {/*                alt={"avatar3"}*/}
                      {/*            />*/}
                      {/*        </div>*/}
                      {/*        <a className={styles["playersCountText"]}>*/}
                      {/*            {gamesList[i].active_players} <FormattedMessage id="players" />*/}
                      {/*        </a>*/}
                      {/*    </div>*!/*/}
                      {/*</div>*/}
                    </div>,
                  );
                }
              }
              return displayedGamesList;
            })()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LobbyScene;
