/* eslint-disable eqeqeq */
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import './Game.scss';
import { FormattedMessage } from 'react-intl';
import { create } from 'zustand';

import { ReactComponent as StarsSvg } from '../../../assets/stars.svg';
import { BackgroundSound, CardSound, LoseSound, WinSound } from '../../../helpers/AudioHelper';
import { cardMap } from '../../../helpers/CardsPreloader';
import { teenPatti_sides } from '../../../helpers/Constants';
import { playRequestStore, stateStore, usePlayerState } from '../../../Store';

import { ResultAnimation } from './ResultAnimation';
import { TeenPattiModal } from './TeenPattiModal';

interface card {
  id: number;
  card: string;
  name: string;
}
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const CardsStore = create((set: any) => ({
  currentCard: null as card | null,
  setCard: (card: card) => set({ currentCard: card }),
  clearCard: () => set({ currentCard: null }),

  cardsTop: [],
  addTopCard: (card: card) =>
    set((state: any) => ({
      cardsTop: [...state.cardsTop, { id: card.id, card: card.card }],
    })),

  cardsBottom: [],
  addBottomCard: (card: card) =>
    set((state: any) => ({
      cardsBottom: [...state.cardsBottom, { id: card.id, card: card.card }],
    })),

  clearCards: () =>
    set(() => ({
      cardsTop: [],
      cardsBottom: [],
    })),

  cardsTopDistribution: async (cards: any) => {
    // await sleep(500);
    for (let i = 3; i < cards.length; i++) {
      set((state: any) => ({
        cardsTop: [...state.cardsTop, { id: i, card: cardMap().get(cards[i]) }],
      }));
      CardSound();
      await sleep(600);
    }
  },

  cardsBottomDistribution: async (cards: any) => {
    await sleep(500);
    for (let i = 0; i < cards.length / 2; i++) {
      set((state: any) => ({
        cardsBottom: [...state.cardsBottom, { id: i, card: cardMap().get(cards[i]) }],
      }));
      CardSound();
      await sleep(600);
    }
  },
}));

// eslint-disable-next-line react/display-name
export const Game = React.memo(() => {
  const [clearCards, cardsBottomDistribution, cardsTopDistribution] = CardsStore((state: any) => [
    state.clearCards,
    state.cardsBottomDistribution,
    state.cardsTopDistribution,
  ]);

  const [playTeenPattiRequest, setPlayTeenPattiRequest] = playRequestStore((state: any) => [
    state.playTeenPattiRequest,
    state.setPlayTeenPattiRequest,
  ]);

  const [playTeenPattiResponse, setPlayTeenPattiResponse] = playRequestStore((state: any) => [
    state.playTeenPattiResponse,
    state.setPlayTeenPattiResponse,
  ]);
  const [teenPattiLobby, teenPattiLobbyState] = stateStore((state: any) => [
    state.teenPattiLobby,
    state.teenPattiLobbyState,
  ]);
  const [teenPattiGame, teenPattiGameState] = stateStore((state: any) => [
    state.teenPattiGame,
    state.teenPattiGameState,
  ]);

  const [showTeenPattiModal, setTeenPattiModal] = stateStore((state: any) => [
    state.teenPattiModal,
    state.teenPattiModalState,
  ]);
  const [player, setPlayer] = usePlayerState((state: any) => [state.player, state.playerState]);
  const [winAnimationState, setWinAnimationState] = useState(false);
  const [firstWinText, setFirstWinText] = useState<any>();
  const [secondWinText, setSecondWinText] = useState<any>();
  const [winBannerColor, setWinBannerColor] = useState('');

  const win = playTeenPattiResponse.win;
  const balance = playTeenPattiResponse.balance;

  async function cardsDistributionAnimation(cards: any) {
    await cardsBottomDistribution(cards);
    await cardsTopDistribution(cards);
    await sleep(2800);
    await winAnimation();
    await setPlayer({ ...player, balance: balance });
    await sleep(3000);
    moveToLobby();
  }

  async function handleYes() {
    await setTeenPattiModal({
      ...showTeenPattiModal,
      isOpen: true,
      action: 'yes',
    });
    await setTeenPattiModal({
      ...showTeenPattiModal,
      isOpen: false,
      action: '',
    });
    await cardsTopDistribution(playTeenPattiResponse.cards);
    await sleep(1000);
    await winAnimation();
    await sleep(5000);
    moveToLobby();
  }

  async function handleNo() {
    await setTeenPattiModal({
      ...showTeenPattiModal,
      isOpen: false,
      action: 'no',
    });
    await sleep(1000);
    moveToLobby();
  }

  // async function cardsDistributionAnimation(cards: any) {
  //   await cardsBottomDistribution(cards);
  //   await setTeenPattiModal({ ...showTeenPattiModal, isOpen: true, action: '' });
  // }

  useEffect(() => {
    if (teenPattiGame.isOpen) {
      BackgroundSound('play');
      cardsDistributionAnimation(playTeenPattiResponse.cards).then(() => {
        return;
      });
    }
  }, [teenPattiGame.isOpen]);

  async function winAnimation() {
    const totalAmount = parseInt(playTeenPattiResponse.profit);

    if (playTeenPattiResponse.win_side == teenPatti_sides.player) {
      setFirstWinText(
        <>
          <StarsSvg />
          <FormattedMessage id={'you_win'} />{' '}
          {/* {intl.formatNumber(Math.trunc(totalAmount), {
            style: 'currency',
            currency: currency ? currency : 'INR',
            minimumFractionDigits: 2,
          })}{' '} */}
          {totalAmount}
          ! <StarsSvg />
        </>,
      );
      WinSound();
    } else {
      setSecondWinText('');
      LoseSound();
    }

    if (playTeenPattiResponse.win_side == teenPatti_sides.player) {
      setSecondWinText(
        <>
          Player <FormattedMessage id={'won'} />
        </>,
      );
      if (win) {
        setWinBannerColor(
          'linear-gradient(90deg, color-mix(in srgb,var(--button-system-blue),#0000 100%) 0%, var(--button-system-blue) 50.52%, color-mix(in srgb,var(--button-system-blue),#0000 100%) 100%)',
        );
      }
    } else if (playTeenPattiResponse.win_side == teenPatti_sides.push) {
      setFirstWinText(<>Push</>);
      setWinBannerColor(
        'linear-gradient(90deg, rgba(26, 26, 26, 0.00) 0%, #1A1A1A 50.52%, rgba(26, 26, 26, 0.00) 100%)',
      );
    } else if (playTeenPattiResponse.win_side == teenPatti_sides.tie) {
      setFirstWinText(
        <>
          <FormattedMessage id={'Tie'} />
        </>,
      );
      setWinBannerColor(
        'linear-gradient(90deg, rgba(26, 26, 26, 0.00) 0%, #1A1A1A 50.52%, rgba(26, 26, 26, 0.00) 100%)',
      );
    } else {
      setFirstWinText(
        <>
          Dealer <FormattedMessage id={'won'} />
        </>,
      );
      setWinBannerColor(
        'linear-gradient(90deg, rgba(26, 26, 26, 0.00) 0%, #1A1A1A 50.52%, rgba(26, 26, 26, 0.00) 100%)',
      );
    }
    setWinAnimationState(true);
    await setPlayer({ ...player });
  }

  function moveToLobby() {
    setWinAnimationState(false);
    clearCards();
    setPlayTeenPattiRequest({
      stake: (playTeenPattiRequest.stake = 0),
    });
    setPlayTeenPattiResponse({
      profit: (playTeenPattiResponse.profit = 0),
    }).then(() => {
      teenPattiLobbyState({
        ...teenPattiLobby,
        isOpen: true,
      });
    });
    teenPattiGameState({ ...teenPattiGame, isOpen: false });
  }
  return (
    <motion.div className={'game'}>
      <div className="game-container">
        <div className="player-dealer-container">
          <a className="tp-position-dealer">
            <FormattedMessage id={'Dealer'} />
          </a>
          <a className="tp-player2">
            <FormattedMessage id={'Player'} />
          </a>
        </div>
        <ResultAnimation
          winBannerColor={winBannerColor}
          winAnimationState={winAnimationState}
          firstWinText={firstWinText}
          secondWinText={secondWinText}
          win={win}
        />
        <a className="tp-position">
          <FormattedMessage id={'Player'} />
        </a>
        <TeenPattiModal onYes={handleYes} onNo={handleNo} />
      </div>
      <div className="footer-game">
        <div className="total-bet-amount">
          Your Bet Amount: ₹<span className="stake-value">{playTeenPattiRequest.stake}</span>
        </div>
      </div>
    </motion.div>
  );
});
