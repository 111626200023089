import * as amplitude from '@amplitude/analytics-browser';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as CloseButton } from '../../assets/closeButton.svg';
import '../../panels/About.scss';
// import { ClickSound } from '../../helpers/AudioHelper';
import { useSoundContext } from '../../helpers/SoundContext';
import { stateStore } from '../../Store';

export default function About() {
  const { ClickSound } = useSoundContext();
  const [about, aboutState] = stateStore((state: any) => [state.about, state.aboutState]);
  useEffect(() => {
    if (about.isOpen) amplitude.track('Open about game Aviator');
  }, [about.isOpen]);

  return (
    <div className={'about'} style={{ display: about.isOpen ? 'flex' : 'none' }}>
      <div className={'background'}></div>
      <div className={'aboutHeader'}>
        <div
          className={'close'}
          onClick={() => {
            ClickSound();
            aboutState({ ...about, isOpen: false });
          }}
        >
          <CloseButton />
        </div>

        <p className={'text headerText'}>
          <FormattedMessage id={'welcome_to'} /> <FormattedMessage id={'aviator'} /> !
        </p>
      </div>
      <div className={'aboutContent'}>
        <ol>
          <li className={'text aboutText'}>
            <p>CRAZY TAKE OFF: A high-stakes betting adventure</p>
          </li>
          <li className={'text aboutText'}>
            <p>
              In the exhilarating world of &quot;CRAZY TAKE OFF&quot;, players are invited to take part in a thrilling
              game of risk and reward. This unique betting experience centers around the concept of a Lucky Plane, whose
              departure dictates the outcome of each round. Players must harness their instincts and strategic prowess
              to place bets, time cash-outs, and maximize their winnings before the Lucky Plane takes off.
            </p>
          </li>
          <li className={'text aboutText'}>
            <p>Here&apos;s a breakdown of the game&apos;s key features and functions:</p>
          </li>
          <li className={'text aboutText'}>
            <p>
              <b>Win multiplier:</b> The excitement begins as players watch the win multiplier steadily increase with
              each passing moment, mirroring the ascent of the Lucky Plane. The higher the multiplier climbs, the
              greater the potential winnings become.
            </p>
          </li>
          <li className={'text aboutText'}>
            <p>
              <b>Bet & cash out:</b> Players can place their bets and decide when to cash out by pressing the
              corresponding button. It&apos;s a race against time as players must be vigilant to cash out before the
              Lucky Plane departs, or risk losing their bet.
            </p>
          </li>
          <li className={'text aboutText'}>
            <p>
              <b>Auto play & Auto cash out:</b> For added convenience, players can activate Auto Play to automatically
              place bets while retaining control over when to cash out. The Auto Cash Out feature ensures that winnings
              are automatically claimed once the multiplier reaches a specified threshold.
            </p>
          </li>
          <li className={'text aboutText'}>
            <p>
              <b>Live bets & statistics:</b> Stay informed with the Live Bets panel, which displays all bets made in the
              current round. Track your own bets and cash-out information in the My Bets panel, while also exploring
              game statistics in the Top panel.
            </p>
          </li>
          <li className={'text aboutText'}>
            <p>
              <b>Randomization:</b> Rest assured knowing that each round is governed by a Provably Fair algorithm,
              ensuring transparency and fairness in gameplay. Players can verify the fairness of each round and adjust
              Provably Fair settings as needed.
            </p>
          </li>
          <li className={'text aboutText'}>
            <p>
              <b>Other features:</b> In the event of an interrupted internet connection, the game automatically cashes
              out with the current multiplier, ensuring that players winnings are safeguarded. Additionally, any
              malfunction of the game hardware/software results in affected bets being refunded.
            </p>
          </li>
          <li className={'text aboutText'}>
            <p>
              &quot;CRAZY TAKE OFF&quot; offers an adrenaline-fueled blend of risk, strategy, and excitement, where
              every decision could lead to thrilling victories or heart-pounding defeats. With its dynamic gameplay
              mechanics and immersive features, it&apos;s a game that promises endless thrills for players seeking the
              ultimate betting adventure.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
}
