import { LOCALES } from './locales';

const english = {
  null: ' ',
  32: '32',
  cards: 'cards',
  baccarat: 'baccarat',

  andar: 'Andar ',
  bahar: 'Bahar ',
  dragon: 'Dragon ',
  tiger: 'Tiger ',
  lucky7: 'Lucky 7 ',
  players: 'players',
  teen: 'Teen ',
  patti: 'Patti ',
  tie: 'Tie ',
  up: 'up',
  down: 'down',
  latest_winners: 'Last Winners',
  user: 'User',
  dragon_letter: 'D',
  tiger_letter: 'T',
  tie_letter: 'T',
  aviator: 'CRAZY TAKE OFF',

  dark_mode: 'dark mode',
  light_mode: 'light mode',
  choose_language: 'choose language',
  about_game: 'about game',
  player_history: 'player history',
  mute: 'mute',
  unmute: 'unmute',
  free_bets: 'free bets',
  exit: 'exit',
  lobby: 'lobby',
  welcome_to: 'welcome to',

  date: 'date',
  time: 'time',
  bets: 'bets',
  payout: 'payout',
  status: 'status',
  lose: 'lose',
  win: 'win',
  missed: 'missed',

  statistics: 'Statistics',
  show_all: 'Show All',
  on_boarding_header_andar: 'Welcome to Andar Bahar!',
  on_boarding_header_dragon: 'Welcome to Dragon Tiger!',
  on_boarding_header_lucky: 'Welcome to Lucky 7!',
  on_boarding_semi_header: 'Place Bets and Play',
  on_boarding_footer: 'Make Your Bet Below',
  on_boarding_footer_next: 'Make Your Next Bet',

  history: 'history',
  freebetsmanagement: 'Free bets management',
  playwithcash: 'Play with cash',
  playfreebet: 'Play with Free Bet',
  freebets_available: 'Free Bets Available',
  total_win: 'Total WIN',

  claim: 'Claim',
  freebets_spent: 'All Free Bets Spent',
  freebets_spent_win_0: 'All free bets spent Total win 0',
  play: 'Play',
  d: 'd',
  h: 'h',
  m: 'm',
  s: 's',

  change_bet: 'Change Bet',
  placed_bet: 'Placed Bet',
  you_bet_on: 'You Bet On',
  amount: 'Amount:',
  to_win: 'to Win',
  you_win: 'You WIN',
  won: 'WON',
  available_now: 'Available Now',
  start: 'Start',
  game: 'Game',
  player: 'Player',
  banker: 'Banker',

  andar_about_setup:
    'Setup: The game is played with a standard deck of 52 playing cards without the jokers. One player is designated as the dealer.',
  andar_about_initial_bet:
    'Initial Bet: Each player places their bet before the game begins. The bets are typically placed on either "Andar" or "Bahar".',
  andar_about_dealing_card:
    'Dealing Cards: The dealer starts by placing a single card face-up in the center of the table This card is called the "Joker" or "Middle Card." The remaining deck of cards is shuffled and held face-down by the dealer.',
  andar_about_andar_or_bahar:
    'Andar or Bahar: The objective of the game is to predict on which side (Andar or Bahar) a card of the same rank as the Joker card will appear.',
  andar_about_turns:
    'Turns: The dealer begins dealing cards one by one alternately to the Andar and Bahar sides until a card of the same rank as the Joker card is dealt.',
  andar_about_winning_condition:
    'Winning Condition: If the card of the same rank as the Joker card is dealt on the same side as the Joker card (Andar or Bahar), the players who placed their bets on that side win. The dealer pays out the winning bets based on the predetermined odds.',
  andar_about_multiple_rounds:
    'Multiple Rounds: The game can continue with multiple rounds, and players can place bets on either side (Andar or Bahar) for each round.',
  andar_about_changing_joker:
    'Changing Joker: After each round, the dealer can change the Joker card by replacing it with another card from the shuffled deck.',

  objective: 'Objective',
  gameplay: 'Gameplay',
  card_rankings: 'Card Rankings',
  card_value: 'Card Values',
  hand_rankings: 'Hand Rankings',
  dealing: 'Dealing',
  determining_the_winner: 'Determining the Winner',
  payouts: 'Payouts',

  dragon_objective:
    'The objective of Dragon Tiger is to bet on which hand, Dragon or Tiger, will have the highest-ranking card.',
  card_rankings_AK: 'Cards are ranked from lowest to highest: A (Ace) is the lowest, and K (King) is the highest.',
  suits_dont_matter: 'Suits do not matter in this game.',
  standard_deck: 'A standard deck of 52 playing cards is used.',
  deck_count: 'The game is played with 8 decks shuffled together.',
  dragon_gameplay_3:
    "Players place their bets on one of three possible outcomes: Dragon wins, Tiger wins, or it's a Tie.",
  dragon_dealing: 'Two cards are dealt face-up on the Dragon and Tiger positions on the table.',
  dragon_determining_the_winner_1: 'The hand with the highest-ranking card wins.',
  dragon_determining_the_winner_2:
    'If both hands have the same-ranking card, the game is a Tie, and bets on Dragon and Tiger push (are returned to the player).',
  dragon_determining_the_winner_3: 'Suits do not matter when determining the winner; only the card rank matters.',
  dragon_payouts_1:
    'If you bet on Dragon or Tiger and your chosen hand wins, you receive even money (1:1) on your bet.',
  dragon_payouts_2: "If there's a Tie and you bet on Tie, you usually receive an (11:1) payout.",

  lucky_objective: 'Predict whether the next card dealt will be above 7 (7 Up), below 7 (7 Down) or 7.',
  lucky_gameplay_1:
    'In Lucky 7, the objective of the game is for players to predict if the next card dealt will be above 7 (7 Up), below 7 (7 Down) or 7.',
  lucky_gameplay_2:
    'Deals the card for this round, only one card is dealt per round. Once the card is drawn this is the result state and the round ends.',
  lucky_payouts_1: 'If you bet on 7 Up or 7 Down and your chosen hand wins, you receive even money (1:1) on your bet.',
  lucky_payouts_2: "If there's a 7 and you bet on 7, you usually receive an (11:1) payout.",

  teen_objective:
    "The objective of Teen Patti is to get a better 3-card Poker hand than the dealer's using the player's 3 dealt cards against dealer's 3 dealt cards.",
  teen_card_rankings:
    'The game is played with a single 52-card deck (excluding Jokers), cards are shuffled after each game round.',
  teen_card_rankings_no_limit: 'Any number of players can participate in a single game simultaneously.',
  teen_card_hand_ranking: 'Winning hands for Teen Patti:',
  teen_card_royal_flesh: 'Royal Flush is a suited Ace, King and Queen.',
  teen_card_straight_flesh:
    'Straight Flush is a hand that contains three cards in sequence, all of the same suit. For example: Ten, Nine and Eight of clubs.',
  teen_card_three_of_kind: 'Three of a Kind is a hand that contains three cards of the same rank.',
  teen_card_straight:
    'Straight is a hand that contains three cards of sequential rank in at least two different suits. E.g. Two, Three and Four on at least two different suits. Two straights are ranked by comparing the highest card of each.',
  teen_card_flush:
    'Flush is a hand where all three cards are of the same suit, but not in a sequence. E.g. three cards that are all clubs.',
  teen_card_pair:
    'Pair is a hand that contains two cards of one rank plus one card that is not on this rank. E.g. two Fives and a Two. If two hands have the same pair then the kickers are compared to determine the winner.',
  teen_card_high_card:
    'High Card is a poker hand of any three cards not meeting any of the above requirements. No Hand is made and the hand rank is according to the highest.',
  teen_card_hand_ranking_two:
    'Individual cards are ranked down from Ace, which has the highest value, through face cards (King, Queen, then Jack), and finally from 10 down to 2.',
  teen_card_hand_ranking_three:
    'For completing a Straight, the Ace may represent either a one or the next card above a King.',
  teen_card_hand_ranking_four:
    'The weakest hand in Poker is the High Card - a poker hand made of any three cards not meeting any of the above mentioned requirements.',
  teen_card_hand_ranking_five: "The decisive factor is the highest card in a player's hand.",
  teen_patti_gameplay_one: "You will receive three cards. The dealer's three cards will be dealt face down.",
  teen_patti_gameplay_two:
    'A Push is a draw - where you and the dealer have exactly the same hand. In this case. the player gets back his stake.',
  teen_patti_gameplay_three:
    'The dealer must have a Queen high or better to qualify and initiate the comparison of hands, determining the outcome of the game.',
  teen_patti_payout_one: "You win if the dealer's hand qualifies and is lower than yours. The stake pays 1:1",
  teen_patti_payout_two: "You will lose if the dealer's hand qualifies and is higher than yours.",
  teen_patti_payout_three:
    'A tie is a draw where the dealer and you have exactly the same hand. In this case, the player get back his stake.',
  teen_patti_payout_four:
    "Where the dealer doesn't have a qualifying hand (at least Queen high), you will get back your stake.",

  baccarat_objective:
    'Predict whether the Player or Banker will win by having their hand value closest to 9 or the hand will be a Tie.',
  baccarat_gameplay1: 'In Baccarat, two hands are dealt: one for the Player and another for the Banker.',
  baccarat_gameplay2:
    'The player bets on whether they think the Player or Banker will win the round by having a hand value closest to 9.',
  baccarat_gameplay3:
    "If the player thinks that both the Player's and Banker's hands will have the same value, he can bet on a Tie.",
  baccarat_gameplay4: 'This version of Baccarat is played with eight standard decks of 52 cards',
  baccarat_card_values1:
    'If the cards in a hand total more than 10 points, simply subtract 10 to obtain the Baccarat point value of the hand. (Examples: 8+8=16, but counts as 6. Jack+7=17 but counts as 7.)',
  baccarat_card_values2:
    "A two-card total of 9 is the best hand and is called a 'Natural'. The second-best hand is a 'Natural' 8. If both the Player and Banker are dealt identical hands, the game ends in a Tie.",
  baccarat_card_values3:
    "The dealer will deal two cards for the Player's hand and two for the Banker's hand. If the value of either hand is 8 or 9 (a Natural) the hand is complete, and no further cards are dealt. Otherwise, the Player gets dealt another card for a hand value of 5 or less. If the Player did not draw a third card, then the Banker follows the rules mentioned below.",
  baccarat_card_values4: 'If the Player did draw a third card, then the Banker follows these rules:',
  baccarat_player_rules: 'Rules for Player',
  baccarat_player_rule1:
    'If the Player has a score of 6 or 7, the Player does not get the third card. If the Player has a score of 5 or less, a third card is dealt.',
  baccarat_banker_rules: 'Rules for Banker',
  baccarat_banker_rule1:
    'If the Player has not been dealt the third card, the Banker draws a card if they have a score of 5 or less.',
  baccarat_banker_rule2:
    "If the Player has been dealt the third card, the value of the Player's third card and the value of the Banker's hand will determine if the Banker gets the third card using the following rules:",
  baccarat_banker_rule3:
    "If the Player's third card is a 2 or 3, the Banker will draw if they have a score of 0-4 and stand with a score of 5-7",
  baccarat_banker_rule4:
    "If the Player's third card is a 4 or 5, the Banker will draw if they have a score of 0-5 and stand with a score 6 or 7.",
  baccarat_banker_rule5:
    " If the Player's third card is a 6 or 7, the Banker will draw if they have a score of 0-6 and stand with a score of 7.",
  baccarat_banker_rule6:
    " If the Player's third card is an 8, then the Banker will draw if they have a score of 0-2-4-5 and stand with  a score of 3-7.",
  baccarat_banker_rule7:
    "If the Player's third card is a 9, 10, face card or Ace, the Banker will draw if they have a score of 0-3 and stand with a score of 4-7.",
  exit_to_lobby: 'Exit to lobby',
  quit: 'quit',
  cancel: 'cancel',
  are_your_sure_want_to_exit_to_lobby: 'Are you sure you want \nto go to the lobby?',
  are_you_sure_want_to_quit: 'Are you sure \nwant to quit?',
  ooops_something_went_wrong: 'Ooops... Something went wrong!',
  gotolobby: 'Go to lobby',
  refreshPage: 'Refresh page',
  your_balance: 'Your balance',
  go_to_cashier: 'Make deposit',
  back_to_game: 'Back to game',
  balance: '{n, number, ::currency/INR}',
  continue: 'Continue',
  deposit: 'Deposit',
  please_refresh_page: 'Please, refresh the page',
  sounds: 'Sounds',
  music: 'Music',

  // price_display:
  //     'How {n, number, ::currency/USD} is displayed in your selected language',
  // number_display:
  //     'This is how {n, number} is formatted in the selected locale',
  // start_today: 'Start Today: {d, date}',
  // // меню
  // about_project: 'About the project',
  // contact_us: 'Contact us'
  select_amount: 'Select amount',
  play_for_free: 'Play for free',
  continue_with_cash: 'Continue with cash',
  limited_time_offer: "Limited time offer, don't miss out!",
  reconnect: 'Trying to reconnect...',
};

export const messages = {
  [LOCALES.ENGLISH]: english,
  [LOCALES.HINDI_EN]: english,
  [LOCALES.RUSSIAN]: {
    null: ' ',
    32: '32',
    cards: 'cards',
    baccarat: 'baccarat',

    andar: 'Андар ',
    bahar: 'Бахар ',
    dragon: 'Дракон ',
    tiger: 'Тигр ',
    lucky7: 'Счастливая 7',
    teen: 'Teen ',
    patti: 'Patti ',
    players: 'игроков',
    tie: 'Ничья ',
    user: 'Игрок',
    up: 'вверх',
    down: 'вниз',
    dragon_letter: 'Д',
    tiger_letter: 'Т',
    tie_letter: 'Н',
    aviator: 'CRAZY TAKE OFF',

    dark_mode: 'тёмная тема',
    light_mode: 'светлая тема',
    choose_language: 'выбрать язык',
    about_game: 'об игре',
    player_history: 'история ставок',
    mute: 'без звука',
    unmute: 'со звуком',
    free_bets: 'бесплатные ставки',
    exit: 'выйти',
    lobby: 'лобби',
    welcome_to: 'добро пожаловать в',
    latest_winners: 'последний победитель',

    date: 'дата',
    time: 'время',
    bets: 'ставки',
    payout: 'выигрыш',
    status: 'статус',
    lose: 'проиграл',
    win: 'выиграл',
    missed: 'пропустил',

    statistics: 'Статистика',
    show_all: 'Показать все',
    on_boarding_header_andar: 'Добро пожаловать в Андар Бахар!',
    on_boarding_header_dragon: 'Добро пожаловать в Дракон Тигр!',
    on_boarding_header_lucky: 'Добро пожаловать в Счастливая 7!',
    on_boarding_semi_header: 'Сделайте ставку и Играйте',
    on_boarding_footer: 'Make Your Bet Below',
    on_boarding_footer_next: 'Make Your Next Bet',

    freebetsmanagement: 'управление бесплатными ставками',
    history: 'история',
    playwithcash: 'Игра на деньги',
    playfreebet: 'Играйте с фрибетом',
    freebets_available: 'Доступны бесплатные ставки',
    total_win: 'Выйгрыш',
    freebets_spent: 'Все фрибеты потрачены',
    claim: 'Требовать',

    freebets_spent_win_0: 'Все бесплатные ставки потрачены. Общий выигрыш 0',
    play: 'Играть',
    d: 'д',
    h: 'ч',
    m: 'м',
    s: 'с',

    change_bet: 'Изменить Ставку',
    placed_bet: 'Размещённая Ставка',
    you_bet_on: 'You bet on',
    amount: 'Amount:',
    to_win: 'to Win:',
    you_win: 'вы ВЫИГРАЛИ',
    won: 'выиграл',

    andar_about_setup:
      'Расстановка: В игре используется стандартная колода из 52 игральных карт без джокеров. Один игрок назначается дилером.',
    andar_about_initial_bet:
      'Начальная ставка: каждый игрок делает ставку до начала игры. Обычно ставки делаются на "Андар" или "Бахар".',
    andar_about_dealing_card:
      'Раздача карт: дилер начинает с размещения одной карты лицевой стороной вверх в центре стола. Эта карта называется «Джокер» или «Средняя карта». Оставшаяся колода карт перемешивается и удерживается. дилер лицом вниз.',
    andar_about_andar_or_bahar:
      'Андар или Бахар: Цель игры — предсказать, на какой стороне (Андар или Бахар) появится карта того же ранга, что и карта Джокера.',
    andar_about_turns:
      'Ходы: дилер начинает раздавать карты одну за другой поочередно сторонам Андара и Бахара, пока не будет сдана карта того же ранга, что и карта Джокера.',
    andar_about_winning_condition:
      'Условие победы: если карта того же ранга, что и карта Джокера, раздается на той же стороне, что и карта Джокера (Андар или Бахар), выигрывают игроки, сделавшие ставки на эту сторону. Дилер выплачивает выигрыш. ставки на основе заранее определенных коэффициентов.',
    andar_about_multiple_rounds:
      'Несколько раундов: игра может продолжаться в несколько раундов, и игроки могут делать ставки на любую сторону (Андар или Бахар) в каждом раунде.',
    andar_about_changing_joker:
      'Смена джокера: после каждого раунда дилер может сменить карту джокера, заменив ее другой картой из перетасованной колоды.',

    objective: 'Цель',
    gameplay: 'Геймплей',
    card_rankings: 'Рейтинг карт',
    dealing: 'Раздача',
    determining_the_winner: 'Определение победителя',
    payouts: 'Выплаты',

    dragon_objective:
      'Цель игры Dragon Tiger — сделать ставку на то, в какой руке, Дракон или Тигр, будет карта самого высокого ранга.',
    dragon_card_rankings_1:
      'Карты ранжируются от низшего к высшему: А (туз) — самый низкий, а К (король) — самый высокий.',
    dragon_card_rankings_2: 'Масти не имеют значения в этой игре.',
    dragon_gameplay_1: 'Используется стандартная колода из 52 игральных карт.',
    dragon_gameplay_2: 'В игре используются 8 колод, перетасованных вместе.',
    dragon_gameplay_3:
      'Игроки делают ставки на один из трех возможных исходов: победа Дракона, победа Тигра или ничья.',
    dragon_dealing: 'Две карты раздаются лицом вверх на позициях Дракона и Тигра на столе.',
    dragon_determining_the_winner_1: 'Выигрывает рука с картой наивысшего достоинства.',
    dragon_determining_the_winner_2:
      'Если в обеих руках карты одного ранга, игра заканчивается вничью, и ставки на Дракона и Тигра возвращаются игроку.',
    dragon_determining_the_winner_3:
      'Масти не имеют значения при определении победителя; имеет значение только ранг карты.',
    dragon_payouts_1:
      'Если вы сделаете ставку на Дракона или Тигра и выбранная вами рука выиграет, вы получите равные деньги (1:1) по своей ставке.',
    dragon_payouts_2: 'Если есть ничья и вы делаете ставку на ничью, вы получаете выплату (11:1).',

    lucky_objective: 'Предскажите, будет ли следующая сданная карта выше 7 (7 вверх), ниже 7 (7 вниз) или 7.',
    lucky_gameplay_1:
      'В Lucky 7 цель игры состоит в том, чтобы игроки предсказали, будет ли следующая сданная карта выше 7 (7 вверх), ниже 7 (7 вниз) или 7.',
    lucky_gameplay_2:
      'Раздает карту в этом раунде, за раунд раздается только одна карта. Как только карта вытянута, это состояние результата, и раунд заканчивается.',
    lucky_payouts_1:
      'Если вы сделаете ставку на 7 Up или 7 Down и выбранная вами рука выиграет, вы получите равные деньги (1:1) по своей ставке.',
    lucky_payouts_2: 'Если выпадает 7 и вы делаете ставку на 7, вы получаете выплату (11:1).',
    exit_to_lobby: 'выйти в лобби',
    quit: 'выйти',
    cancel: 'отмена',
    are_your_sure_want_to_exit_to_lobby: 'Вы действительно хотите выйти в лобби?',
    are_you_sure_want_to_quit: 'Вы уверены что хотите выйти?',
    ooops_something_went_wrong: 'Ой... Что-то пошло не так',
    gotolobby: 'выйти в лобби',
    refreshPage: 'обновить',
    your_balance: 'ваш баланс',
    // your_balance: "ваш баланс \n{n, number, ::currency/INR}",
    go_to_cashier: 'выйти в кассу',
    back_to_game: 'вернуться в игру',
    balance: '{n, number, ::currency/INR}',
    continue: 'продолжить',
    select_amount: 'Select Amount',
    sounds: 'sounds',
    music: 'music',
    available_now: 'Available Now',
    play_for_free: 'Play For free',
    continue_with_cash: 'Continue with cash',
    limited_time_offer: "Limited time offer, don't miss out!",
  },
  [LOCALES.HINDI_IN]: {
    null: ' ',
    32: '32',
    cards: 'cards',
    baccarat: 'baccarat',

    andar: 'अंदर ',
    bahar: 'बहार ',
    dragon: 'अजगर ',
    tiger: 'चीता ',
    lucky7: 'भाग्यशाली 7 ',
    teen: 'Teen ',
    patti: 'Patti ',
    players: 'खिलाड़ी',
    tie: 'खींचना ',
    up: 'ऊपर',
    down: 'नीचे',
    user: 'उपयोगकर्ता',
    dragon_letter: 'डी',
    tiger_letter: 'टी',
    tie_letter: 'एन',
    aviator: 'CRAZY TAKE OFF',

    dark_mode: 'डार्क मोड',
    light_mode: 'लाइट मोड',
    choose_language: 'भाषा चुनें',
    about_game: 'खेल के बारे में',
    player_history: 'खिलाड़ी का इतिहास',
    mute: 'म्यूट',
    unmute: 'अनम्यूट',
    free_bets: 'मुफ़्त दांव',
    exit: 'बाहर निकलें',
    lobby: 'लॉबी',
    welcome_to: 'आपका स्वागत है',
    latest_winners: 'अंतिम विजेता',

    date: 'तारीख',
    time: 'समय',
    bets: 'दांव',
    payout: 'भुगतान',
    status: 'स्थिति',
    lose: 'खोना',
    win: 'जीतना',
    missed: 'चुक होना',

    statistics: 'सांख्यिकी',
    show_all: 'सभी दिखाएँ',
    on_boarding_header_andar: 'अंदर बहार में आपका स्वागत है!',
    on_boarding_header_dragon: 'ड्रैगन टाइगर में आपका स्वागत है!',
    on_boarding_header_lucky: 'लकी 7 में आपका स्वागत है!',
    on_boarding_semi_header: 'दांव लगाएं और खेलें',
    on_boarding_footer: 'Make Your Bet Below',
    on_boarding_footer_next: 'Make Your Next Bet',

    freebetsmanagement: 'निःशुल्क दांव प्रबंधन',
    history: 'खिलाड़ी',
    playwithcash: 'नकदी के साथ खेलें',
    playfreebet: 'निःशुल्क शर्त के साथ खेलें',
    freebets_available: 'मुफ़्त दांव उपलब्ध हैं',
    total_win: 'कुल जीत',

    claim: 'दावा',
    freebets_spent: 'All Free Bets Spent',
    freebets_spent_win_0: 'सभी निःशुल्क दांव खर्च किए गए, कुल जीत 0',
    play: 'खेल',
    d: 'd',
    h: 'h',
    m: 'm',
    s: 's',

    change_bet: 'शर्त बदलें',
    placed_bet: 'दांव लगाया',
    you_bet_on: 'You bet on',
    amount: 'Amount:',
    to_win: 'to win:',
    you_win: 'आप जीतते हैं',
    won: 'जीत गया',

    andar_about_setup:
      'सेटअप: गेम को जोकरों के बिना 52 ताश के पत्तों के एक मानक डेक के साथ खेला जाता है। एक खिलाड़ी को डीलर के रूप में नामित किया जाता है।',
    andar_about_initial_bet:
      'प्रारंभिक दांव: प्रत्येक खिलाड़ी खेल शुरू होने से पहले अपना दांव लगाता है। दांव आम तौर पर "अंदर" या "बहार" पर लगाए जाते हैं।',
    andar_about_dealing_card:
      'डील कार्ड: डीलर टेबल के केंद्र में एक कार्ड को ऊपर की ओर रखकर शुरू करता है। इस कार्ड को "जोकर" या "मिडिल कार्ड" कहा जाता है। कार्ड के शेष डेक को मिलाया जाता है और रखा जाता है डीलर द्वारा फेस-डाउन।',
    andar_about_andar_or_bahar:
      'अंदर या बहार: खेल का उद्देश्य यह अनुमान लगाना है कि जोकर कार्ड के समान रैंक का कार्ड किस तरफ (अंदर या बहार) दिखाई देगा।',
    andar_about_turns:
      'बदलता है: डीलर बारी-बारी से अंदर और बहार पक्षों को एक-एक करके कार्ड बांटना शुरू कर देता है, जब तक कि जोकर कार्ड के समान रैंक का कार्ड नहीं बांट दिया जाता।',
    andar_about_winning_condition:
      'जीतने की स्थिति: यदि जोकर कार्ड के समान रैंक का कार्ड जोकर कार्ड (अंदर या बहार) के समान ही बांटा जाता है, तो उस तरफ दांव लगाने वाले खिलाड़ी जीत जाते हैं। डीलर जीत का भुगतान करता है पूर्व निर्धारित बाधाओं के आधार पर दांव।',
    andar_about_multiple_rounds:
      'एकाधिक राउंड: खेल कई राउंड के साथ जारी रह सकता है, और खिलाड़ी प्रत्येक राउंड के लिए दोनों तरफ (अंदर या बहार) दांव लगा सकते हैं।',
    andar_about_changing_joker:
      'चेंजिंग जोकर: प्रत्येक राउंड के बाद, डीलर जोकर कार्ड को शफ़ल डेक से दूसरे कार्ड से बदलकर बदल सकता है।',

    objective: 'उद्देश्य',
    gameplay: 'गेमप्ले',
    card_rankings: 'कार्ड रैंकिंग',
    dealing: 'व्यवहार करना:',
    determining_the_winner: 'विजेता का निर्धारण करना',
    payouts: 'भुगतान:',

    dragon_objective:
      'ड्रैगन टाइगर का उद्देश्य इस बात पर दांव लगाना है कि ड्रैगन या टाइगर में से किस हाथ में उच्चतम रैंकिंग वाला कार्ड होगा।',
    dragon_card_rankings_1:
      'कार्डों को निम्नतम से उच्चतम तक क्रमबद्ध किया गया है: ए (ऐस) सबसे निचला है, और के (किंग) उच्चतम है।',
    dragon_card_rankings_2: 'इस खेल में सूट कोई मायने नहीं रखता.',
    dragon_gameplay_1: '52 ताश के पत्तों के एक मानक डेक का उपयोग किया जाता है।',
    dragon_gameplay_2: 'खेल 8 डेक को एक साथ घुमाकर खेला जाता है।',
    dragon_gameplay_3:
      'खिलाड़ी तीन संभावित परिणामों में से एक पर अपना दांव लगाते हैं: ड्रैगन जीतता है, टाइगर जीतता है, या यह टाई है',
    dragon_dealing: 'टेबल पर ड्रैगन और टाइगर की स्थिति पर दो कार्ड आमने-सामने बांटे जाते हैं।',
    dragon_determining_the_winner_1: 'उच्चतम रैंकिंग कार्ड वाला हाथ जीतता है।',
    dragon_determining_the_winner_2:
      'यदि दोनों हाथों में समान रैंकिंग कार्ड है, तो गेम टाई है, और ड्रैगन और टाइगर पुश पर दांव खिलाड़ी को वापस कर दिए जाते हैं।',
    dragon_determining_the_winner_3:
      'विजेता का निर्धारण करते समय सूट कोई मायने नहीं रखता; केवल कार्ड रैंक मायने रखता है.',
    dragon_payouts_1:
      'यदि आप ड्रैगन या टाइगर पर दांव लगाते हैं और आपका चुना हुआ हाथ जीत जाता है, तो आपको अपने दांव पर सम राशि (1:1) मिलती है।',
    dragon_payouts_2: 'यदि कोई टाई है और आप टाई पर दांव लगाते हैं, तो आपको आमतौर पर (11:1) भुगतान प्राप्त होता है।',

    lucky_objective: 'भविष्यवाणी करें कि अगला कार्ड 7 से ऊपर (7 ऊपर), 7 से नीचे (7 नीचे) या 7 होगा।',
    lucky_gameplay_1:
      'लकी 7 में, खेल का उद्देश्य खिलाड़ियों के लिए यह अनुमान लगाना है कि बांटा गया अगला कार्ड 7 से ऊपर (7 ऊपर), 7 से नीचे (7 नीचे) या 7 होगा।',
    lucky_gameplay_2:
      'इस राउंड के लिए कार्ड बांटे जाते हैं, प्रति राउंड केवल एक कार्ड बांटा जाता है। एक बार कार्ड निकल जाने के बाद यह परिणाम की स्थिति होती है और राउंड समाप्त हो जाता है।',
    lucky_payouts_1:
      'यदि आप 7 अप या 7 डाउन पर दांव लगाते हैं और आपका चुना हुआ हाथ जीत जाता है, तो आपको अपने दांव पर सम राशि (1:1) मिलती है।',
    lucky_payouts_2: 'यदि 7 है और आप 7 पर दांव लगाते हैं, तो आपको आमतौर पर (11:1) भुगतान प्राप्त होता है।',
    exit_to_lobby: 'पैरवी के लिए बाहर निकलें',
    quit: 'बाहर जाओ',
    cancel: 'रद्द',
    are_your_sure_want_to_exit_to_lobby: 'क्या आप वाकई लॉबी से बाहर निकलना चाहते हैं?',
    are_you_sure_want_to_quit: 'क्या आप वाकई बाहर जाना चाहते हैं?',
    ooops_something_went_wrong: 'ओह! कुछ गलत हो गया है',
    gotolobby: 'लॉबी में जाओ',
    refreshPage: 'पृष्ठ ताज़ा करें',
    // your_balance: "your balance \n{n, number, ::currency/INR}",
    your_balance: 'your balance',
    go_to_cashier: 'खजांची के पास जाओ',
    back_to_game: 'खेल में वापस',
    balance: '{n, number, ::currency/INR}',
    continue: 'जारी रखना',
    select_amount: 'Select Amount',
    sounds: 'sounds',
    music: 'music',
    available_now: 'Available Now',
    play_for_free: 'Play For free',
    continue_with_cash: 'Continue with cash',
    limited_time_offer: "Limited time offer, don't miss out!",
  },
  [LOCALES.PORTUGAL]: {
    null: ' ',
    32: '32',
    cards: 'cards',
    baccarat: 'baccarat',

    andar: 'Andar ',
    bahar: 'Bahar ',
    dragon: 'Dragão ',
    tiger: 'Tigre ',
    lucky7: 'Sorte 7 ',
    teen: 'Teen ',
    patti: 'Patti ',
    players: 'jogadores',
    tie: 'Empate ',
    user: 'do utilizador',
    up: 'acima',
    down: 'abaixo',
    dragon_letter: 'D',
    tiger_letter: 'T',
    tie_letter: 'N',
    aviator: 'CRAZY TAKE OFF',

    dark_mode: 'modo escuro',
    light_mode: 'modo luz',
    choose_language: 'escolher idioma',
    about_game: 'sobre o jogo',
    player_history: 'histórico do jogador',
    mute: 'mudo',
    unmute: 'ativar o som',
    free_bets: 'apostas grátis',
    exit: 'sair',
    lobby: 'lobby',
    latest_winners: 'último vencedor',
    welcome_to: 'bem-vindo ao',

    date: 'data',
    time: 'tempo',
    bets: 'apostas',
    payout: 'pagamento',
    status: 'status',
    lose: 'perder',
    win: 'ganhar',
    missed: 'perdida',

    statistics: 'Estatísticas',
    show_all: 'Mostrar tudo',
    on_boarding_header_andar: 'Bem-vindo ao Andar Bahar!',
    on_boarding_header_dragon: 'Bem-vindo ao Dragão Tigre!',
    on_boarding_header_lucky: 'Bem-vindo ao Lucky 7!',
    on_boarding_semi_header: 'Faça apostas e jogue',
    on_boarding_footer: 'Make your bet below',
    on_boarding_footer_next: 'Make your next bet',

    freebetsmanagement: 'gestão de apostas grátis',
    history: 'histórico',
    playwithcash: 'Jogue com dinheiro',
    playfreebet: 'Jogue com aposta grátis',
    freebets_available: 'Apostas grátis disponíveis',
    total_win: 'Vitória total',
    freebets_spent: 'All free bets spent',
    claim: 'Alegar',

    freebets_spent_win_0: 'Todas as apostas grátis gastas Vitória total 0',
    play: 'Jogar',
    d: 'd',
    h: 'h',
    m: 'm',
    s: 's',

    change_bet: 'Alterar aposta',
    placed_bet: 'Aposta colocada',
    you_bet_on: 'You bet on',
    amount: 'Amount:',
    to_win: 'to win:',
    you_win: 'Você GANHA',
    won: 'ganho',

    andar_about_setup:
      'Preparação: O jogo é jogado com um baralho padrão de 52 cartas sem curingas. Um jogador é designado como dealer.',
    andar_about_initial_bet:
      'Aposta Inicial: Cada jogador faz sua aposta antes do jogo começar. As apostas normalmente são feitas em "Andar" ou "Bahar".',
    andar_about_dealing_card:
      'Distribuir cartas: O dealer começa colocando uma única carta virada para cima no centro da mesa. Esta carta é chamada de "Joker" ou "Carta do Meio." O baralho de cartas restante é embaralhado e guardado virado para baixo pelo dealer.',
    andar_about_andar_or_bahar:
      'Andar ou Bahar: O objetivo do jogo é prever de que lado (Andar ou Bahar) aparecerá uma carta do mesmo valor da carta Joker.',
    andar_about_turns:
      'Turnos: O dealer começa a distribuir as cartas uma a uma alternadamente para os lados Andar e Bahar até que uma carta do mesmo valor da carta Joker seja distribuída.',
    andar_about_winning_condition:
      'Condição de vitória: Se a carta do mesmo valor da carta Joker for distribuída no mesmo lado da carta Joker (Andar ou Bahar), os jogadores que fizeram as suas apostas nesse lado ganham. O dealer paga o vencedor apostas baseadas nas probabilidades predeterminadas.',
    andar_about_multiple_rounds:
      'Múltiplas Rodadas: O jogo pode continuar com múltiplas rodadas, e os jogadores podem fazer apostas em qualquer um dos lados (Andar ou Bahar) para cada rodada.',
    andar_about_changing_joker:
      'Trocando o Joker: Após cada rodada, o dealer pode trocar a carta do Joker substituindo-a por outra carta do baralho embaralhado.',

    objective: 'Objetivo',
    gameplay: 'Jogabilidade',
    card_rankings: 'Classificações de cartas',
    dealing: 'Negociação',
    determining_the_winner: 'Determinando o Vencedor',
    payouts: 'Pagamentos',

    dragon_objective: 'O objetivo do Dragon Tiger é apostar em qual mão, Dragão ou Tigre, terá a carta de maior valor.',
    dragon_card_rankings_1:
      'As cartas são classificadas do menor para o maior: A (Ás) é o mais baixo e K (Rei) é o mais alto.',
    dragon_card_rankings_2: 'Os ternos não importam neste jogo.',
    dragon_gameplay_1: 'É usado um baralho padrão de 52 cartas.',
    dragon_gameplay_2: 'O jogo é jogado com 8 baralhos embaralhados.',
    dragon_gameplay_3:
      'Os jogadores fazem suas apostas em um dos três resultados possíveis: vitória do Dragão, vitória do Tigre ou empate.',
    dragon_dealing: 'Duas cartas são distribuídas viradas para cima nas posições Dragão e Tigre na mesa.',
    dragon_determining_the_winner_1: 'A mão com a carta de maior valor vence.',
    dragon_determining_the_winner_2:
      'Se ambas as mãos tiverem a mesma carta, o jogo termina empatado e as apostas no push do Dragão e do Tigre (são devolvidas ao jogador).',
    dragon_determining_the_winner_3:
      'Os naipes não importam na determinação do vencedor; apenas a classificação da carta é importante.',
    dragon_payouts_1:
      'Se você apostar no Dragão ou no Tigre e a mão escolhida vencer, você receberá o mesmo dinheiro (1:1) na sua aposta.',
    dragon_payouts_2: 'Se houver um empate e você apostar no empate, normalmente receberá um pagamento (11:1).',

    lucky_objective:
      'Preveja se a próxima carta distribuída será acima de 7 (7 para cima), abaixo de 7 (7 para baixo) ou 7.',
    lucky_gameplay_1:
      'No Lucky 7, o objetivo do jogo é que os jogadores prevejam se a próxima carta distribuída será acima de 7 (7 Up), abaixo de 7 (7 Down) ou 7.',
    lucky_gameplay_2:
      'Distribui a carta para esta rodada, apenas uma carta é distribuída por rodada. Assim que a carta for comprada, este é o estado do resultado e a rodada termina.',
    lucky_payouts_1:
      'Se você apostar em 7 Up ou 7 Down e a mão escolhida vencer, você receberá o mesmo dinheiro (1:1) em sua aposta.',
    lucky_payouts_2: 'Se houver um 7 e você apostar nele, normalmente receberá um pagamento (11:1).',
    exit_to_lobby: 'Sair para o lobby',
    quit: 'sair',
    cancel: 'cancelamento',
    are_your_sure_want_to_exit_to_lobby: 'Tem certeza de que deseja sair para o lobby?',
    are_you_sure_want_to_quit: 'tem certeza que quer sair?',
    ooops_something_went_wrong: 'Ops... Algo deu errado',
    gotolobby: 'vá para o saguão',
    refreshPage: 'ATUALIZAR A PÁGINA',
    // your_balance: "your balance \n{n, number, ::currency/INR}",
    your_balance: 'your balance',
    go_to_cashier: 'ir ao caixa',
    back_to_game: 'de volta ao jogo',
    balance: '{n, number, ::currency/INR}',
    continue: 'continuar',
    select_amount: 'Select Amount',
    sounds: 'sounds',
    music: 'music',
    available_now: 'Available Now',
    play_for_free: 'Play For free',
    continue_with_cash: 'Continue with cash',
    limited_time_offer: "Limited time offer, don't miss out!",
  },
};
