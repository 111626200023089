import * as amplitude from '@amplitude/analytics-browser';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as FilterArrowDown } from '../../assets/filterArrowDown.svg';
import ClaimWindow from '../../freebets/freeBetsOnGame/claimWindow';
import FreeBetsLobby from '../../freebets/freeBetsOnGame/FreeBetsLobby';
import * as API from '../../helpers/API';
import { ClickSound } from '../../helpers/AudioHelper';
import BetCalculator, { calculateMultiplyingCoefficient } from '../../helpers/BetCalculator';
import { lucky_sides } from '../../helpers/Constants';
import * as Constants from '../../helpers/Constants';
import loader from '../../helpers/loaderSpinner.module.scss';
import { useWindowSize } from '../../helpers/WindowSize';
import {
  activeFreeBetStore,
  lowBalanceStore,
  playRequestStore,
  stateStore,
  useGameParams,
  usePlayerState,
} from '../../Store';

import styles from './Lobby.module.scss';
import Statistics from './Statistics';

export default function Lobby() {
  // find freebet id in url
  const urlParams = new URLSearchParams(window.location.search);
  const freebet_id = urlParams.get('freebet_id');
  // param to freebet playluckyLobby
  const [isFreeBetPlay, setIsFreeBetPlay] = stateStore((state: any) => [
    state.freebetLobbyState,
    state.setFreebetLobbyState,
  ]);

  const intl = useIntl();

  const luckyParams = useGameParams((state: any) => state.luckyParams);
  const [player, setPlayer] = usePlayerState((state: any) => [state.player, state.playerState]);

  const [luckyLobby, setLuckyLobbyState] = stateStore((state: any) => [state.luckyLobby, state.setLuckyLobbyState]);

  const [luckyGame, luckyGameState] = stateStore((state: any) => [state.luckyGame, state.luckyGameState]);
  const [playRequest, setPlayRequest] = playRequestStore((state: any) => [
    state.playLuckyRequest,
    state.setPlayLuckyRequest,
  ]);
  const [playResponse, setPlayResponse] = playRequestStore((state: any) => [
    state.playLuckyResponse,
    state.setPlayLuckyResponse,
    state.retrievePlayLuckyResponse,
  ]);

  const [currentFreeBet, getCurrentActive] = activeFreeBetStore((state: any) => [
    state.currentActiveFreeBet,
    state.getCurrentActiveFreeBet,
  ]);
  const [bet, setBet] = useState(100);
  const [setIsLowBalance] = lowBalanceStore((state: any) => [state.setIsLowBalance]);

  const isFreeBetPlayHandler = async () => {
    const freeBet = await currentFreeBet;
    const hasBet = Number(freeBet.freebets_amount) - Number(freeBet.freebets_played);
    if (freeBet.freebet_id && hasBet > 0) {
      setIsFreeBetPlay({ status: Constants.freebetPlay.play });
      setBet(Number(freeBet.stake));
    } else if (freeBet.freebet_id && hasBet === 0) {
      setIsFreeBetPlay({ status: Constants.freebetPlay.claim });
      setBet(10 * Math.pow(10, luckyParams.max_bet.toString().length - 3));
    } else if (freeBet.freebet_id === undefined) {
      setIsFreeBetPlay({ status: Constants.freebetPlay.load });
      setBet(10 * Math.pow(10, luckyParams.max_bet.toString().length - 3));
    } else {
      setIsFreeBetPlay({ status: Constants.freebetPlay.notPlay });
      setBet(10 * Math.pow(10, luckyParams.max_bet.toString().length - 3));
    }
  };

  useEffect(() => {
    if (freebet_id) {
      isFreeBetPlayHandler();
    }
  }, []);

  useEffect(() => {
    if (isFreeBetPlay.status === Constants.freebetPlay.play) {
      isFreeBetPlayHandler();
    } else {
      const defaultStake = 2 * calculateMultiplyingCoefficient(luckyParams.max_bet);
      if (player.balance > defaultStake) {
        setBet(defaultStake);
      } else {
        if (player.balance > luckyParams.min_bet) {
          setBet(player.balance);
        } else {
          setBet(luckyParams.min_bet);
        }
      }
    }
  }, [luckyParams, isFreeBetPlay.status]);

  const [numberOfPlayedGames, setNumberOfPlayedGames] = useState(1);

  async function startGame(params: any) {
    setNumberOfPlayedGames(numberOfPlayedGames + 1);
    amplitude.track('Play', {
      side: params.side,
      stake: bet,
      game: 'Lucky 7',
      number_of_played_games: numberOfPlayedGames,
    });

    if (player.balance >= bet && player.balance >= luckyParams.min_bet) {
      setIsLowBalance(false);
      const data = {
        demo: 'False',
        side: params.side,
        stake: bet,
      };
      await setPlayRequest(data);

      API.post(Constants.play, data, player.token).then((json) => {
        if (json) {
          amplitude.track('Play response', {
            ...json,
            game_name: luckyParams.name,
          });
          setPlayResponse(json);
          setPlayer({ ...player, balance: player.balance - data.stake });

          setLuckyLobbyState({
            ...luckyLobby,
            isOpen: false,
            isStatisticsVisible: true,
          });
          luckyGameState({ ...luckyGame, isOpen: true, side: params.side });

          sessionStorage.setItem('isStatisticsVisible', String(true));
          setSideButtonsDisabled(false);
        }
      });
    } else {
      setIsLowBalance(true);
    }
  }

  async function startFreeBetGame(params: any) {
    const data = {
      demo: false,
      freebet_id: currentFreeBet.freebet_id,
      side: params.side,
    };
    await setPlayRequest({
      ...data,
      stake: bet,
    });

    await setPlayer({ ...player, balance: player.balance });
    API.post(Constants.playFreebet, data, player.token).then((json) => {
      setPlayResponse({
        freebet_id: json.freebet_id,
        win: json.win,
        win_side: json.win_side,
        cards: json.cards,
        total_gain: json.total_gain,
        freebets_amount: json.freebets_amount,
        freebets_played: json.freebet_played,
        stake: json.stake,
        currency: json.currency,
        profit: json.last_freebet_gain,
        balance: json.balance,
        status: json.status,
      });
      getCurrentActive({ freebet_id: json.freebet_id, token: player.token });
      setLuckyLobbyState({
        ...luckyLobby,
        isOpen: false,
        isStatisticsVisible: true,
      });
      luckyGameState({ ...luckyGame, isOpen: true, side: params.side });

      sessionStorage.setItem('isStatisticsVisible', String(true));
      setSideButtonsDisabled(false);
    });
  }

  const [sideButtonsDisabled, setSideButtonsDisabled] = useState(false);
  const sideButtonClick = (side: number) => {
    ClickSound();
    setSideButtonsDisabled(true);
    isFreeBetPlay.status === Constants.freebetPlay.play ? startFreeBetGame({ side: side }) : startGame({ side: side });
  };

  const size = useWindowSize();

  return (
    <motion.div
      key={size.width}
      className={styles.lobby}
      // style={{ display: luckyLobby.isOpen ? "flex" : "none" }}
      initial={{ y: 0 }}
      animate={luckyGame.isOpen ? { y: window.innerHeight } : { y: 0 }}
      transition={{ duration: 0.4 }}
    >
      <div className={styles.container}>
        <div
          className={
            styles['panel'] +
            ' ' +
            styles['stakePanel'] +
            ' ' +
            (isFreeBetPlay.status === Constants.freebetPlay.play ? styles.freebet : '')
          }
        >
          <div className={styles.content}>
            {/* {isFreeBetPlay.status != Constants.freebetPlay.play &&
                        isFreeBetPlay.status != Constants.freebetPlay.claim ? ( */}
            <div
              className={styles.onBoardingHeader}
              style={{
                display: luckyLobby.isStatisticsVisible ? 'none' : 'flex',
              }}
            >
              <a className={styles.text + ' ' + styles.onBoardingHeaderText}>
                <FormattedMessage id="on_boarding_header_lucky" />
              </a>
              {/* <a className={styles.text + " " + styles.onBoardingHeaderSemiText}>
                                    <FormattedMessage id="on_boarding_semi_header" />
                                </a> */}
            </div>
            {/* ) : (
                            ""
                        )} */}
            <Statistics />

            <div className={styles.betContent}>
              {isFreeBetPlay.status === Constants.freebetPlay.play ||
              isFreeBetPlay.status === Constants.freebetPlay.claim ? (
                <FreeBetsLobby currentFreeBet={currentFreeBet} openLobby={() => null} style={'lobbyStyle'} />
              ) : isFreeBetPlay.status === Constants.freebetPlay.load ? (
                <span className={loader.loader}></span>
              ) : (
                <BetCalculator
                  min_bet={luckyParams.min_bet}
                  max_bet={luckyParams.max_bet}
                  step={luckyParams.step}
                  currency={luckyParams.currency}
                  balance={player.balance}
                  bet={bet}
                  setBet={setBet}
                />
              )}
            </div>

            <div
              className={styles.onBoardingFooter}
              // style={{ display: luckyLobby.isStatisticsVisible ? "none" : "flex" }}
            >
              <a className={`${styles.text} ${styles.onBoardingHeaderSemiText} ${styles.onBoardingFooterSemiText}`}>
                <motion.div
                  animate={{
                    y: [5, -5, 5],
                    transition: {
                      duration: 2.5,
                      repeat: Infinity,
                    },
                  }}
                >
                  {luckyLobby.isStatisticsVisible ? (
                    <span>
                      <FormattedMessage id="on_boarding_footer_next" />{' '}
                      {isFreeBetPlay.status !== Constants.freebetPlay.notPlay
                        ? intl.formatNumber(Math.trunc(bet * 100) / 100, {
                            style: 'currency',
                            currency: luckyParams.currency ? luckyParams.currency : 'INR',
                            minimumFractionDigits: 0,
                          })
                        : ''}
                    </span>
                  ) : (
                    <span>
                      <FormattedMessage id="on_boarding_footer" />{' '}
                      {isFreeBetPlay.status !== Constants.freebetPlay.notPlay
                        ? intl.formatNumber(Math.trunc(bet * 100) / 100, {
                            style: 'currency',
                            currency: luckyParams.currency ? luckyParams.currency : 'INR',
                            minimumFractionDigits: 0,
                          })
                        : ''}
                    </span>
                  )}
                </motion.div>
              </a>
              <motion.div
                className={styles.arrows}
                animate={{
                  y: [5, -5, 5],
                  transition: {
                    duration: 1,
                    repeat: Infinity,
                  },
                }}
              >
                <FilterArrowDown width={'24px'} height={'24px'} fill={'var(--button-system-pink)'} />
              </motion.div>
              <motion.div
                className={styles.arrows}
                animate={{
                  y: [0, 5, 0, -5, 0],
                  transition: {
                    duration: 1,
                    repeat: Infinity,
                  },
                }}
              >
                {' '}
                <FilterArrowDown width={'24px'} height={'24px'} fill={'var(--button-system-blue)'} />
              </motion.div>
              <motion.div
                className={styles.arrows}
                animate={{
                  y: [-5, 5, -5],
                  transition: {
                    duration: 1,
                    repeat: Infinity,
                  },
                }}
              >
                {' '}
                <FilterArrowDown width={'24px'} height={'24px'} fill={'var(--button-system-orange)'} />
              </motion.div>
            </div>
          </div>
        </div>
        {isFreeBetPlay.status !== Constants.freebetPlay.load ? (
          <div className={styles.sideButtons}>
            <button
              className={styles.sideButton}
              style={{ background: 'var(--button-system-pink)' }}
              onClick={() => {
                sideButtonClick(lucky_sides.seven_up);
              }}
              disabled={sideButtonsDisabled}
            >
              <a className={`${styles['text']} ${styles['coef']}`}>{luckyParams.seven_up_coef}:1</a>
              <a className={styles.text}>
                7 <FormattedMessage id="up" />
              </a>
              <div
                className={styles.sideButtonLoader}
                style={{
                  display: playRequest.side === lucky_sides.seven_up ? 'block' : 'none',
                }}
              >
                <span className={'loader'}></span>
              </div>
            </button>

            <button
              className={styles.sideButton}
              style={{ background: 'var(--button-system-blue)' }}
              onClick={() => {
                sideButtonClick(lucky_sides.seven);
              }}
              disabled={sideButtonsDisabled}
            >
              <a className={`${styles['text']} ${styles['coef']}`}>{luckyParams.seven_coef}:1</a>
              <a className={styles.text}>7</a>
              <div
                className={styles.sideButtonLoader}
                style={{
                  display: playRequest.side === lucky_sides.seven ? 'block' : 'none',
                }}
              >
                <span className={'loader'}></span>
              </div>
            </button>
            <button
              className={styles.sideButton}
              style={{ background: 'var(--button-system-orange)' }}
              onClick={() => {
                sideButtonClick(lucky_sides.seven_down);
              }}
              disabled={sideButtonsDisabled}
            >
              <a className={`${styles['text']} ${styles['coef']}`}>{luckyParams.seven_down_coef}:1</a>
              <a className={styles.text}>
                7 <FormattedMessage id="down" />
              </a>

              <div
                className={styles.sideButtonLoader}
                style={{
                  display: playRequest.side === lucky_sides.seven_down ? 'block' : 'none',
                }}
              >
                <span className={styles.loader}></span>
              </div>
            </button>
          </div>
        ) : (
          ''
        )}
      </div>

      <ClaimWindow
        totalGain={playResponse.total_gain}
        moveToLobby={() => {
          setIsFreeBetPlay({
            ...isFreeBetPlay,
            status: Constants.freebetPlay.notPlay,
          });
        }}
      />
    </motion.div>
  );
}
