import React, { useEffect, useState } from 'react';

import { ReactComponent as CloseButton } from '../../assets/closeButton.svg';
import { ClickSound } from '../../helpers/AudioHelper';
import ModalWrapper from '../../helpers/modalWrapper/ModalWrapper';
import ModalWindowWrapper from '../../helpers/modalWrapperNew/ModalWindowWrapper';
import { usePlayerState } from '../../Store';
import { baccaratStatisticsStore, useBaccaratParams } from '../baccarat/BaccaratStore';

import styles from './Statistics.module.scss';

type Prop = {
  isOpen: boolean;
  setExtendedWindow: any;
  reason?: string;
};

export default function Statistic(props: Prop) {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const player = usePlayerState((state: any) => state.player);
  const baccaratParams = useBaccaratParams((state: any) => state.baccaratParams);
  const [baccaratStats, retrieveBaccaratStats] = baccaratStatisticsStore((state: any) => [
    state.baccaratStats,
    state.retrieveBaccaratStats,
  ]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) {
      props.setExtendedWindow(0);
    }
  }, [isOpen]);

  useEffect(() => {
    if (baccaratParams.id) {
      retrieveBaccaratStats({ id: baccaratParams.id, token: player.token });
    }
  }, [baccaratParams.id]);

  const historyElementColors = [
    'var(--button-system-blue)',
    'var(--button-system-pink)',
    'var(--button-system-orange)',
  ];

  function ProgressBar() {
    return (
      <div className={styles.progress}>
        <a className={styles.text + ' ' + styles.progressText}>{baccaratStats.player_percent}%</a>
        <div className={styles.progressBar}>
          <div
            className={styles.andarPercents}
            // style={{ width: baccaratStats.player_percent.toString() + '%' }}
          ></div>
        </div>
        <a className={styles.text + ' ' + styles.progressText}>{baccaratStats.banker_percent}%</a>
      </div>
    );
  }

  function MainStatInfo() {
    return (
      <div className={styles.lastHistory + ' ' + styles.mainStatInfo}>
        {(() => {
          const lastHistory = [];
          const lastHistoryCount = 32;
          for (let i = 0; i <= lastHistoryCount - 1; i++) {
            let color = 'var(--background-on-surface)';
            let name = '';
            if (baccaratStats.winner[i]) {
              if (baccaratStats.winner[i] === 'B') {
                color = historyElementColors[2];
              } else if (baccaratStats.winner[i] === 'P') {
                color = historyElementColors[1];
              } else if (baccaratStats.winner[i] === 'T') {
                color = historyElementColors[0];
              }
            }
            name = baccaratStats.winner[i];

            lastHistory.push(
              <div
                className={styles.element}
                style={{
                  background: color,
                  opacity: i === 0 ? 1 : 0.65,
                  border: i === 0 ? '1px solid #FFF' : undefined,
                }}
                key={i}
              >
                <a className={styles.text + ' ' + styles.elementText}>{name}</a>
              </div>,
            );
          }
          retrieveBaccaratStats({ id: baccaratParams.id, token: player.token });
          return lastHistory;
        })()}
      </div>
    );
  }

  if (props.reason === 'ProgressBar') {
    return (
      <div className={styles.statisticBody}>
        <ProgressBar />
      </div>
    );
  } else if (props.reason === 'Desk') {
    return (
      <ModalWrapper
        isOpen={isOpen}
        closeModal={() => {
          ClickSound();
          setIsOpen(false);
        }}
      >
        <div className={styles.statisticWrapper} style={{ marginTop: '-40px' }}>
          <div className={styles.statisticHead}>
            <div className={styles.title}>Statistics</div>
          </div>
          <div className={styles.statisticBody}>
            <MainStatInfo />
          </div>
        </div>
      </ModalWrapper>
    );
  } else {
    return (
      <ModalWindowWrapper isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className={styles.statisticWrapper}>
          <div className={styles.statisticHead}>
            <div
              className={styles.closeBtn}
              onClick={() => {
                ClickSound();
                setIsOpen(!isOpen);
              }}
            >
              <CloseButton />
            </div>
            <div className={styles.title}>Statistics</div>
          </div>
          <div className={styles.statisticBody}>
            <MainStatInfo />
          </div>
        </div>
      </ModalWindowWrapper>
    );
  }
}
