/* eslint-disable eqeqeq */
import { motion } from 'framer-motion';
import React from 'react';

import FireworkComponent from '../../helpers/FireWorkComponet';
import Firework from '../../helpers/Fireworks.module.scss';

import { baccaratPlayRequestStore, useFireworkStore } from './BaccaratStore';
import { CardsContainer } from './Cards/CardsContainer';
import styles from './ResultAnimation.module.scss';

type ResultAnimationProps = {
  winBannerColor: string;
  winAnimationState: boolean;
  firstWinText: any;
  secondWinText: any;
  win: boolean;
  totalStake?: any;
  countTop?: number;
  countBottom?: number;
};

export const ResultAnimation = ({
  winBannerColor,
  winAnimationState,
  firstWinText,
  secondWinText,
  countTop,
  countBottom,
}: ResultAnimationProps) => {
  const [playBaccaratResponse] = baccaratPlayRequestStore((state: any) => [state.playBaccaratResponse]);
  const [isFireworkShown] = useFireworkStore((state: any) => [state.isFireworkShown]);

  return (
    <>
      <div className={styles.bannerContainer}>
        <div style={{ zIndex: '5' }}>{isFireworkShown && <FireworkComponent />}</div>
        <motion.div
          className={styles.winBanner}
          style={{ background: winBannerColor }}
          initial={{ opacity: 0 }}
          animate={
            winAnimationState
              ? {
                  opacity: 1,
                  transition: { duration: 0.4 },
                }
              : undefined
          }
        >
          {playBaccaratResponse.total_win ? (
            <div className={Firework.pyro} style={{ width: '100%' }}>
              {/* <div className={Firework.before}></div>
              <div className={Firework.after}></div> */}
            </div>
          ) : (
            ''
          )}
          <a className={styles.winText}>{firstWinText}</a>
          <a className={styles.winTextSecond}>{secondWinText}</a>
        </motion.div>
        <motion.div
          animate={
            winAnimationState
              ? {
                  opacity: 0.4,
                  transition: { duration: 0.4 },
                }
              : undefined
          }
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        ></motion.div>
        <CardsContainer countTop={countTop} countBottom={countBottom} />
      </div>
    </>
  );
};
