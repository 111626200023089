import * as amplitude from '@amplitude/analytics-browser';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as CloseButton } from '../assets/closeButton.svg';
import { ReactComponent as ExitIcon } from '../assets/exitIcon.svg';
import { ReactComponent as HistoryIcon } from '../assets/historyIcon.svg';
import { ReactComponent as InfoIcon } from '../assets/infoIcon.svg';
import { ReactComponent as LobbyIcon } from '../assets/lobbyIcon.svg';
import { ReactComponent as SettingButton } from '../assets/settingButton.svg';
import { AndarStateStore } from '../games/andarNewVision/AndarStore';
// import { ClickSound, MuteBackground, MuteSound, UnMuteBackground, UnMuteSound } from '../helpers/AudioHelper';
import * as Constants from '../helpers/Constants';
import { generateTargetDepositUrl, generateTargetLobbyUrl } from '../helpers/GenerateTargetGameUrl';
import Switch from '../helpers/Switch';
import { stateStore, useBuildType, usePlayerState } from '../Store';
import './Menu.css';
// eslint-disable-next-line import/order
import { useSoundContext } from '../helpers/SoundContext';

export default function Menu() {
  const { ClickSound } = useSoundContext();
  const buildType = useBuildType((state: any) => state.buildType);

  const player = usePlayerState((state: any) => state.player);
  const [menu, menuState] = stateStore((state: any) => [state.menu, state.menuState]);
  const [about, aboutState] = stateStore((state: any) => [state.about, state.aboutState]);
  const [playerHistory, playerHistoryState] = stateStore((state: any) => [
    state.playerHistory,
    state.playerHistoryState,
  ]);

  const operatorName =
    localStorage.getItem('operator_name') !== 'undefined' && localStorage.getItem('operator_name') !== null
      ? localStorage.getItem('operator_name')
      : 'Casino';

  const [isFreeBetPlay, freeBetsManagement, setFreeBetsManagementOpen] = stateStore((state: any) => [
    state.freebetLobbyState,
    state.freeBetsManagement,
    state.setFreeBetsManagementOpen,
  ]);

  const [quit, quitState] = stateStore((state: any) => [state.quit, state.quitState]);
  const [teenPattiGame, luckyGame, dragonGame] = stateStore((state: any) => [
    state.teenPattiGame,
    state.luckyGame,
    state.dragonGame,
  ]);
  const { andarBaharGame } = AndarStateStore();
  const language = stateStore((state: any) => state.language);

  const muteButtonClick = useCallback(
    async (e: { preventDefault: () => void; stopPropagation: () => void }) => {
      e.preventDefault();
      e.stopPropagation();
      ClickSound();
      await menuState({ ...menu, isSoundOn: !menu.isSoundOn });
      localStorage.setItem('isSoundOn', String(!menu.isSoundOn));
    },
    [menu, ClickSound, menuState],
  );

  const musicButtonClick = useCallback(
    async (e: { preventDefault: () => void; stopPropagation: () => void }) => {
      e.preventDefault();
      e.stopPropagation();
      ClickSound();
      await menuState({ ...menu, isMusicOn: !menu.isMusicOn });
      localStorage.setItem('isMusicOn', String(!menu.isMusicOn));
    },
    [menu, ClickSound, menuState],
  );

  const goToCashier = useCallback(() => {
    amplitude.track('Open cashier');
    const target_url = generateTargetDepositUrl(menu.depositUrl, {
      isDepositOpen: true,
      operatorToken: player.operatorToken,
    });
    window.open(target_url, '_top');
  }, [menu.depositUrl, player.operatorToken]);

  const closeMenuHandler = useCallback(
    (e: { currentTarget: any; target: any }) => {
      if (e.currentTarget === e.target) {
        ClickSound();
        menuState({ ...menu, isOpen: false });
      }
    },
    [menu, ClickSound, menuState],
  );

  const handleClick = useCallback(
    (action: any) => (e: any) => {
      e.stopPropagation();
      ClickSound();
      action(e); // Передача события e в действие
    },
    [ClickSound],
  );

  return (
    <div
      onClick={closeMenuHandler}
      className={'menu'}
      style={{ display: menu.isOpen && !quit.isOpen ? 'flex' : 'none' }}
    >
      <div className={'headerBlock'}>
        <div className={'providerBlock'}>
          <a className={'providerText'}>Gameswiss</a>
        </div>
        <div className={'cachierBlock'}>
          <div
            className={'cachierButton'}
            onClick={async () => {
              goToCashier();
            }}
          >
            <FormattedMessage id="deposit" />
          </div>
        </div>
      </div>
      <div onClick={closeMenuHandler} className={'menuItems'} style={{ display: quit.isOpen ? 'none' : 'flex' }}>
        <div className={'menuButtons'}>
          {/*TODO: Enable if need light theme*/}
          {/*<div className={"menuButtonsGroup"}>*/}
          {/*----Dark\Light mode----*/}
          {/*<div*/}
          {/*    className={"menuButton button"}*/}
          {/*    style={{*/}
          {/*        display: buildType == Constants.build_types.Lobby ? "flex" : "none",*/}
          {/*        background: "none",*/}
          {/*    }}*/}
          {/*>*/}
          {/*    <a*/}
          {/*        className={"text menuToggleText"}*/}
          {/*        style={{color: menu.isLightMode ? "#8D8D8E" : "#FFF"}}*/}
          {/*    >*/}
          {/*        <FormattedMessage id="dark_mode"/>*/}
          {/*    </a>*/}
          {/*    /!*<!-- Rounded switch -->*!/*/}
          {/*    <label className="switch">*/}
          {/*        <input*/}
          {/*            type="checkbox"*/}
          {/*            checked={menu.isLightMode}*/}
          {/*            onChange={async () => {*/}
          {/*                ClickSound();*/}
          {/*                await menuState({...menu, isLightMode: !menu.isLightMode});*/}
          {/*            }}*/}
          {/*        />*/}
          {/*        <span className="slider round"/>*/}
          {/*    </label>*/}
          {/*    <a*/}
          {/*        className={"text menuToggleText"}*/}
          {/*        style={{color: menu.isLightMode ? "#FFF" : "#8D8D8E"}}*/}
          {/*    >*/}
          {/*        <FormattedMessage id="light_mode"/>*/}
          {/*    </a>*/}
          {/*</div>*/}
          {/*</div>*/}
          <div className={'menuButtonsGroup'}>
            {/*----exit to lobby----*/}
            {/*<div*/}
            {/*  className={'menuButton'}*/}
            {/*  onClick={() => {*/}
            {/*    ClickSound();*/}
            {/*    quitState({ isOpen: true, toLobby: true });*/}
            {/*  }}*/}
            {/*  style={{*/}
            {/*    display: buildType === Constants.build_types.Lobby ? 'none' : 'flex',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <LobbyIcon className={'icon'} />*/}
            {/*  <a className={'text menuText'}>*/}
            {/*    <FormattedMessage id="lobby" />*/}
            {/*  </a>*/}
            {/*</div>*/}
            {/*----exit to betting----*/}
            <div
              className={'menuButton'}
              onClick={handleClick(() => quitState({ isOpen: true, toLobby: false }))}
              // style={{
              //   display: buildType === Constants.build_types.Lobby ? 'flex' : 'none',
              // }}
            >
              <ExitIcon className={'icon'} />
              <a className={'text menuText'}>Back to {operatorName}</a>
            </div>
            {teenPattiGame.isOpen || luckyGame.isOpen || dragonGame.isOpen || andarBaharGame.isOpen ? (
              <div
                className={'menuButton'}
                onClick={handleClick(() => quitState({ isOpen: true, toLobby: true }))}
                style={{
                  display: buildType === Constants.build_types.Lobby ? 'none' : 'flex',
                }}
              >
                <LobbyIcon className={'icon'} />
                <a className={'text menuText'}>
                  <FormattedMessage id="lobby" />
                </a>
              </div>
            ) : (
              <div
                className={'menuButton'}
                onClick={handleClick(() => {
                  const target_url = generateTargetLobbyUrl(menu.lobby_url, {
                    player: player.token,
                    menu: menu,
                    language: language.current,
                    freebetId: 0,
                    uuid: player.session_uuid,
                  });
                  amplitude.track('Go to lobby');
                  window.open(target_url, '_self');
                })}
                style={{
                  display: buildType === Constants.build_types.Lobby ? 'none' : 'flex',
                }}
              >
                <LobbyIcon className={'icon'} />
                <a className={'text menuText'}>
                  <FormattedMessage id="lobby" />
                </a>
              </div>
            )}
          </div>

          <div className={'menuButtonsGroup' + ' ' + 'specificGroup'}>
            {/*----Sound----*/}
            <div className={'menuButton'} onClick={muteButtonClick}>
              <a className={'text menuText'}>
                <FormattedMessage id="sounds" />
              </a>
              <Switch value={menu.isSoundOn} onChange={muteButtonClick} id="isSoundOn" />
            </div>
            <div className={'menuButton'} onClick={musicButtonClick}>
              <a className={'text menuText'}>
                <FormattedMessage id="music" />
              </a>
              <Switch value={menu.isMusicOn} onChange={musicButtonClick} id="isMusicOn" />
            </div>
          </div>
          <div className={'menuButtonsGroup'}>
            {/*----PLayer history----*/}
            <div
              className={'menuButton'}
              onClick={handleClick(async () => {
                await menuState({ ...menu, isOpen: false });
                await playerHistoryState({ ...playerHistory, isOpen: true });
              })}
              style={{
                display: buildType === Constants.build_types.Aviator ? 'none' : 'flex',
              }}
            >
              <HistoryIcon className={'icon'} />

              <a className={'text menuText'}>
                <FormattedMessage id="player_history" />
              </a>
            </div>

            {/*----About game----*/}
            <div
              className={'menuButton'}
              onClick={handleClick(async () => {
                await menuState({ ...menu, isOpen: false });
                await aboutState({ ...about, isOpen: true, reason: '' });
              })}
              style={{
                display: buildType === Constants.build_types.Lobby ? 'none' : 'flex',
              }}
            >
              <InfoIcon className={'icon'} />
              <a className={'text menuText'}>
                <FormattedMessage id="about_game" />
              </a>
            </div>
          </div>
          {/* free bet management */}
          {isFreeBetPlay.status === Constants.freebetPlay.play ? (
            <div className={'menuButtonsGroup'}>
              <div
                className={'menuButton'}
                onClick={handleClick(async () => {
                  await menuState({ ...menu, isOpen: false });
                  await setFreeBetsManagementOpen({
                    ...freeBetsManagement,
                    isOpen: !freeBetsManagement.isOpen,
                  });
                })}
              >
                <SettingButton className={'icon'} />
                <a className={'text menuText'}>Free bets management</a>
              </div>
            </div>
          ) : (
            ''
          )}

          {/*<div className={"menuButtonsGroup"}>*/}
          {/*----Language----*/}
          {/*<div*/}
          {/*    className={"menuButton"}*/}
          {/*    onClick={() => {*/}
          {/*        ClickSound();*/}
          {/*        // menuState({...menu, isOpen: false})*/}
          {/*        languageState({ ...language, isOpen: true });*/}
          {/*    }}*/}
          {/*>*/}
          {/*    <LanguageIcon className={"icon"} />*/}
          {/*    <a className={"text menuText"}>*/}
          {/*        <FormattedMessage id="choose_language" />*/}
          {/*    </a>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<div className={"menuButtonsGroup"}>*/}
          {/*    */}
          {/*</div>*/}
        </div>
        <div
          className={'closeMenuButton'}
          onClick={handleClick(() => {
            menuState({ ...menu, isOpen: false });
          })}
        >
          <CloseButton className={'icon'} />
        </div>
      </div>
    </div>
  );
}
