import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { CurrencyNumberType } from '../../helpers/CurrencyNumberShort';

import { BaccaratStateStore } from './BaccaratStore';
import styles2 from './Lobby.module.scss';
import styles1 from './startButton.module.scss';

interface Prop {
  totalStake: number;
  currency: any;
  playButtonDisabled?: boolean;
  isAutoBet?: boolean;
  totalGames?: number;
  lostLimit?: number;
  baccaratBetss?: any;
  startGame?: (params: any) => void;
  startAutoGame?: (params: any) => void;
}

export const StartBtn = ({ totalStake, currency, playButtonDisabled, startGame }: Prop) => {
  const styles = { ...styles1, ...styles2 };
  const sleep = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));
  const [baccaratGame] = BaccaratStateStore((state: any) => [state.baccaratGame]);

  const [isShow, setIsShow] = useState(true);
  const [isWidthSmall, setIsWidthSmall] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsWidthSmall(window.innerWidth <= 991);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  async function showingHandler() {
    if (!baccaratGame.isOpen || !isWidthSmall) {
      await sleep(150);
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  }

  useEffect(() => {
    showingHandler();
  }, [baccaratGame.isOpen]);

  return isShow ? (
    <div
      className={totalStake > 0 && !baccaratGame.isOpen ? styles.startGameContainer : styles.inactiveStartGameContainer}
    >
      <button
        disabled={playButtonDisabled}
        className={totalStake > 0 && !baccaratGame.isOpen ? styles.startGameBtn : styles.inactiveStartGameBtn}
        onClick={
          totalStake > 0
            ? startGame
            : () => {
                return;
              }
        }
      >
        <FormattedMessage id={'start'} /> <FormattedMessage id={'game'} />
        <p className="startTextAmount" style={totalStake > 0 && !baccaratGame.isOpen ? {} : { display: 'none' }}>
          <span className={styles.amountWord}>Amount:</span>
          <span className={styles.amountNum}> {CurrencyNumberType(totalStake, currency)}</span>
        </p>
      </button>
    </div>
  ) : null;
};
