import * as amplitude from '@amplitude/analytics-browser';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import './Lobby.scss';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as FilterArrowDown } from '../../assets/filterArrowDown.svg';
import * as API from '../../helpers/API';
import { ClickSound } from '../../helpers/AudioHelper';
import BetCalculator from '../../helpers/BetCalculator';
import * as Constants from '../../helpers/Constants';
import { teenPatti_sides } from '../../helpers/Constants';
import { useWindowSize } from '../../helpers/WindowSize';
import { lowBalanceStore, playRequestStore, stateStore, useGameParams, usePlayerState } from '../../Store';

export const Lobby = () => {
  const size = useWindowSize();
  const [activeTab] = useState<string>('main-bets');
  const [bet, setBet] = useState(20);
  const teenParams = useGameParams((state: any) => state.teenParams);
  const [player, setPlayer] = usePlayerState((state: any) => [state.player, state.playerState]);
  const [anteButtonDisabled, setAnteButtonDisabled] = useState(false);
  const [setIsLowBalance] = lowBalanceStore((state: any) => [state.setIsLowBalance]);

  const [setPlayTeenPattiResponse] = playRequestStore((state: any) => [state.setPlayTeenPattiResponse]);

  const [setPlayTeenPattiRequest] = playRequestStore((state: any) => [state.setPlayTeenPattiRequest]);

  const [teenPattiLobby, teenPattiLobbyState] = stateStore((state: any) => [
    state.teenPattiLobby,
    state.teenPattiLobbyState,
  ]);
  const [teenPattiGame, teenPattiGameState] = stateStore((state: any) => [
    state.teenPattiGame,
    state.teenPattiGameState,
  ]);

  const [numberOfPlayedGames, setNumberOfPlayedGames] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  async function startGame(params: any) {
    setNumberOfPlayedGames(numberOfPlayedGames + 1);
    amplitude.track('Play', {
      side: params.side,
      stake: bet,
      game: 'Teen Patti',
      number_of_played_games: numberOfPlayedGames,
    });
    if (player.balance >= bet && player.balance >= teenParams.min_bet) {
      setIsLowBalance(false);
      setIsLoading(true);

      const data = {
        demo: 'False',
        side: params.side,
        stake: bet,
      };
      await setPlayTeenPattiRequest(data);

      API.post(Constants.play, data, player.token).then((json) => {
        if (json) {
          amplitude.track('Play response', {
            ...json,
            game_name: teenParams.name,
          });
          setPlayTeenPattiResponse(json);
          setPlayer({ ...player, balance: player.balance - data.stake });
          teenPattiLobbyState({ ...teenPattiLobby, isOpen: false });
          teenPattiGameState({
            ...teenPattiGame,
            isOpen: true,
            side: params.side,
          });
          setAnteButtonDisabled(false);
          setIsLoading(false);
        }
      });
    } else {
      setIsLowBalance(true);
    }
  }

  const anteButtonClick = (side: number) => {
    ClickSound();
    setAnteButtonDisabled(true);
    startGame({ side: side });
  };
  return (
    <>
      <motion.div
        key={size.width}
        className="lobby"
        animate={teenPattiGame.isOpen ? { y: window.innerHeight } : { y: 0 }}
        transition={{ duration: 0.3 }}
        initial={{ y: 0 }}
      >
        <div className="lobby-container">
          <div className="lobby-text">
            <FormattedMessage id={'Welcome to Teen Patti!'} />
          </div>
          <div className="wrapper-content">
            <div className="lobby-content">
              {/* <div className='tab-buttons'>
                                <div className='tab-button-container'>
                                    <div id="btn" className={position}></div>
                                    <button
                                        className="toggle-btn"
                                        onClick={() => handleTabClickLelt('main-bets', 'left')}
                                    >
                                        <FormattedMessage id={"Main Bets"}/>
                                    </button>
                                    <button
                                        className="toggle-btn"
                                        onClick={() => handleTabClickRight('side-bets', 'right')}
                                    >
                                        <FormattedMessage id={"Side Bets"}/>
                                    </button>
                                </div>
                            </div> */}
              <div className="tab-content">
                {activeTab === 'main-bets' && (
                  <div className="main-bets-content">
                    <BetCalculator
                      min_bet={teenParams.min_bet}
                      max_bet={teenParams.max_bet}
                      step={teenParams.step}
                      currency={teenParams.currency}
                      balance={player.balance}
                      bet={bet}
                      setBet={setBet}
                    />
                  </div>
                )}
                {/* {activeTab === 'side-bets' && (
                  <div className="side-bets-buttons-container">
                    <div className="top-buttons">
                      <div className="button-group">
                        <button
                          className={`side-bet-button ${isPairBetAnteSelected ? 'selected' : ''}`}
                          onClick={() => {
                            handleBetterClick(
                              playTeenPattiRequest.better,
                              'pair_better',
                            );
                            setIsPairBetAnteSelected(true);
                          }}
                        >
                          <a className="sidebet-text">
                            <FormattedMessage id={'PAIR OR BETTER'} />
                          </a>
                          {isPairBetAnteSelected && lastSelectedStake && (
                            <>
                              <div className="last-selected-stake">
                                {lastSelectedStake.img}
                              </div>
                              <div className="selected-bet">
                                {playTeenPattiRequest.better}
                              </div>
                            </>
                          )}
                        </button>
                        <button
                          className="trashcan"
                          onClick={() => {
                            clearSelectedAnte();
                          }}
                        >
                          <Trash />
                        </button>
                      </div>
                      <div className="button-group">
                        <button
                          className={`side-bet-button ${isThreeAndThreeAnteSelected ? 'selected' : ''}`}
                          onClick={() => {
                            handleThreePlusThreeClick(
                              playTeenPattiRequest.three_plus_three,
                              'three_and_three',
                            );
                            setIsThreeAndThreeAnteSelected(true);
                          }}
                        >
                          <a className="sidebet-text">
                            <FormattedMessage id={'3 + 3 BONUS'} />
                          </a>
                          {isThreeAndThreeAnteSelected &&
                            lastSelectedStake2 && (
                              <>
                                <div className="last-selected-stake">
                                  {lastSelectedStake2.img}
                                </div>
                                <div className="selected-bet">
                                  {playTeenPattiRequest.three_plus_three}
                                </div>
                              </>
                            )}
                        </button>
                        <button
                          className="trashcan"
                          onClick={() => {
                            clearSelectedAnte();
                          }}
                        >
                          <Trash />
                        </button>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </div>
          {activeTab === 'main-bets' && (
            <div className="looby-footer">
              <a className="footer-text">
                <motion.div
                  animate={{
                    y: [5, -5, 5],
                    transition: {
                      duration: 2.5,
                      repeat: Infinity,
                    },
                  }}
                >
                  <FormattedMessage id={'Make your bet'} />
                </motion.div>
              </a>
              <div className="arrow">
                <motion.div
                  animate={{
                    y: [5, -5, 5],
                    transition: {
                      duration: 1,
                      repeat: Infinity,
                    },
                  }}
                >
                  <FilterArrowDown width={'24px'} height={'24px'} fill={'var(--button-system-blue)'} />
                </motion.div>
              </div>
            </div>
          )}
          {activeTab === 'side-bets'}
        </div>
        <div className="ante-btn-container">
          <button
            className="ante-btn"
            onClick={() => anteButtonClick(teenPatti_sides.player)}
            disabled={anteButtonDisabled}
          >
            <FormattedMessage id={'play'} />
            {isLoading && (
              <div className="ante-btn-loader ">
                <span className={'loader'}></span>
              </div>
            )}
          </button>
        </div>
      </motion.div>
    </>
  );
};
