import { stateStore } from '../Store';

import * as Constants from './Constants';

const buildType = Constants.Select_build_types(process.env.REACT_APP_BUILD_ID);
let clickSound = new Audio(require('../assets/sounds/click.mp3'));
let backgroundSound = new Audio(require('../assets/sounds/background.mp3'));
let cardSound = new Audio(require('../assets/sounds/card.mp3'));
let winSound = new Audio(require('../assets/sounds/win.mp3'));
let loseSound = new Audio(require('../assets/sounds/lose.mp3'));

switch (buildType) {
  case Constants.build_types.Andar:
    clickSound = new Audio(require('../assets/sounds/click2.mp3'));
    backgroundSound = new Audio(require('../assets/sounds/background2.mp3'));
    cardSound = new Audio(require('../assets/sounds/card.mp3'));
    winSound = new Audio(require('../assets/sounds/winUpBalance.mp3'));
    loseSound = new Audio(require('../assets/sounds/lose.new.mp3'));
    break;

  case Constants.build_types.Baccarat:
    clickSound = new Audio(require('../assets/sounds/click2.mp3'));
    backgroundSound = new Audio(require('../assets/sounds/background2.mp3'));
    cardSound = new Audio(require('../assets/sounds/card.mp3'));
    winSound = new Audio(require('../assets/sounds/winUpBalance.mp3'));
    loseSound = new Audio(require('../assets/sounds/lose.new.mp3'));
    break;

  case Constants.build_types.Aviator:
    clickSound = new Audio(require('../assets/sounds/click.mp3'));
    backgroundSound = new Audio(require('../assets/sounds/aviatorBackgroundNew.mp3'));
    break;

  default:
    clickSound = new Audio(require('../assets/sounds/click.mp3'));
    backgroundSound = new Audio(require('../assets/sounds/background.mp3'));
    cardSound = new Audio(require('../assets/sounds/card.mp3'));
    winSound = new Audio(require('../assets/sounds/win.mp3'));
    loseSound = new Audio(require('../assets/sounds/lose.mp3'));
    break;
}

export const click_sound = clickSound;
export const background_sound = backgroundSound;
export const card_sound = cardSound;
export const win_sound = winSound;
export const lose_sound = loseSound;

let menu: {
  isSoundOn: boolean;
  isMusicOn: boolean;
};

export default async function InitAudio() {
  menu = await stateStore((state: any) => state.menu);
  background_sound.loop = true;
  background_sound.volume = 0.2;
  background_sound.playbackRate = 1.05;
  if (buildType === Constants.build_types.Aviator) {
    background_sound.playbackRate = 1;
  }
  click_sound.volume = 0.3;
  click_sound.playbackRate = 1.2;
  !menu.isSoundOn ? MuteSound() : UnMuteSound();
  !menu.isMusicOn ? MuteBackground() : UnMuteBackground();
}

export function ClickSound() {
  if (menu.isSoundOn) click_sound.play();
}

export function BackgroundSound(type: string) {
  switch (type) {
    case 'play':
      background_sound.play();
      break;
    case 'stop':
      background_sound.pause();
      break;
    default:
      background_sound.pause();
  }
}

export async function CardSound() {
  if (menu.isSoundOn) {
    await card_sound.play();
  }
}

export function WinSound() {
  if (menu.isSoundOn) win_sound.play();
}

export function LoseSound() {
  if (menu.isSoundOn) lose_sound.play();
}

export function MuteSound() {
  win_sound.muted = true;
  lose_sound.muted = true;
}

export function UnMuteSound() {
  win_sound.muted = false;
  lose_sound.muted = false;
}

export function MuteBackground() {
  background_sound.muted = true;
}

export function UnMuteBackground() {
  background_sound.muted = false;
}
