import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as SplashBgAviator } from '../assets/splashBgAviator.svg';
import { ReactComponent as SplashScreen3 } from '../assets/splashLogo7.svg';
import { ReactComponent as SplashScreen1 } from '../assets/splashLogoAndar.svg';
import { ReactComponent as SplashScreen4 } from '../assets/splashLogoBaccaratNew.svg';
import { ReactComponent as SplashScreen2 } from '../assets/splashLogoDragon.svg';
import { PlaneImageWithAnimation } from '../games/aviator/modules/planeAnimation/PlaneImageWithAnimation';
import { usePlayerState } from '../Store';

import * as Constants from './Constants';
import loader from './dotsLoader.module.scss';
import style from './SplashScreen.module.scss';

export default function Splash(props: any) {
  const div: any = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [gameName, setGameName] = useState('');
  const player = usePlayerState((state) => state.player);
  window.addEventListener('load', () => {
    setLoaded(true);
  });
  useEffect(() => {
    if (div.current != null) {
      switch (props.buildType) {
        case Constants.build_types.Lobby:
          setGameName('');
          break;
        case Constants.build_types.Andar:
          setGameName('Andar Bahar');
          break;
        case Constants.build_types.Dragon:
          setGameName('Dragon Tiger');
          break;
        case Constants.build_types.Lucky:
          setGameName('Lucky 7');
          break;
        case Constants.build_types.Baccarat:
          setGameName('Baccarat');
          break;
        case Constants.build_types.Aviator:
          setGameName('Aviator');
          break;
      }
      if (!player.balance && player.balance !== 0) {
        setLoaded(true);
      } else {
        if (loaded && !completed) {
          setCompleted(true);
          setTimeout(() => {
            div.current.remove();
            // amplitude.track("Load complete");
          }, 2000);
        }
      }
    }
  }, [loaded, player.balance]);

  const classesList = [];
  classesList.push(style.splashBackground, style.show);
  const classes = classesList.reduce((prev: string, curr: string) => {
    return prev.concat(' '.concat(curr));
  });

  function splash(param: any) {
    switch (param) {
      case Constants.build_types.Lobby:
        return (
          <div className={classes}>
            <div className={style.gameName}></div>

            <div className={style.bottomBlock}>
              <div className={loader.loader} />
              <Logo className={style.splashSVG} />
            </div>
          </div>
        );

      case Constants.build_types.Andar:
        return (
          <div className={classes}>
            <div className={style.gameName}>
              <p className={style.textHead}>Speed</p>
              <p className={style.textName} style={{ color: '#1B88FF' }}>
                {gameName}
              </p>
            </div>
            <SplashScreen1 className={style.splashSVG} />
            <div className={style.bottomBlock}>
              <div className={loader.loader} />
              <Logo className={style.splashSVG} />
            </div>
          </div>
        );

      case Constants.build_types.Dragon:
        return (
          <div className={classes}>
            <div className={style.gameName}>
              <p className={style.textHead}>Speed</p>
              <p className={style.textName} style={{ color: '#FF375F' }}>
                {gameName}
              </p>
            </div>
            <SplashScreen2 className={style.splashSVG} />
            <div className={style.bottomBlock}>
              <div className={loader.loader} />
              <Logo className={style.splashSVG} />
            </div>
          </div>
        );

      case Constants.build_types.Lucky:
        return (
          <div className={classes}>
            <div className={style.gameName}>
              <p className={style.textHead}>Speed</p>
              <p className={style.textName} style={{ color: '#FF9F0A' }}>
                {gameName}
              </p>
            </div>
            <SplashScreen3 className={style.splashSVG} />
            <div className={style.bottomBlock}>
              <div className={loader.loader} />
              <Logo className={style.splashSVG} />
            </div>
          </div>
        );

      case Constants.build_types.Baccarat:
        return (
          <div className={classes}>
            <div className={style.gameName}>
              <p className={style.textHead}>Speed</p>
              <p className={style.textName} style={{ color: '#FF9F0A' }}>
                {gameName}
              </p>
            </div>
            <SplashScreen4 className={style.splashSVG} />
            <div className={style.bottomBlock}>
              <div className={loader.loader} />
              <Logo className={style.splashSVG} />
            </div>
          </div>
        );

      case Constants.build_types.Aviator:
        return (
          <div className={classes}>
            {/*<div className={style.gameName}>*/}
            {/*  <p className={style.textHead}>Speed</p>*/}
            {/*  <p className={style.textName} style={{ color: '#FF9F0A' }}>*/}
            {/*    {gameName}*/}
            {/*  </p>*/}
            {/*</div>*/}
            <div className={style.bodyBlock}>
              <div style={{ width: '40%', position: 'absolute' }}>
                <PlaneImageWithAnimation width={'100%'} />
              </div>
              <SplashBgAviator className={style.bg} />
            </div>

            <div className={style.bottomBlock}>
              <div className={loader.loader} />
              <Logo className={style.splashSVG} />
            </div>
          </div>
        );
    }
  }

  return <div ref={div}>{splash(props.buildType)}</div>;
}
