import * as amplitude from '@amplitude/analytics-browser';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as CloseButton } from '../../../assets/closeButton.svg';
import { ClickSound } from '../../../helpers/AudioHelper';
import { stateStore } from '../../../Store';

import styles from './about.module.scss';

export default function About() {
  const [about, aboutState] = stateStore((state: any) => [state.about, state.aboutState]);
  useEffect(() => {
    if (about.isOpen) amplitude.track('Open about game Andar Bahar');
  }, [about.isOpen]);

  useEffect(() => {
    if (about.reason === 'sideBets') {
      window.scrollBy(0, 600);
    }
  }, [about.isOpen]);

  return (
    <div className={styles.about} style={{ display: about.isOpen ? 'flex' : 'none' }}>
      <div className={styles.background}> </div>
      <div className={styles.aboutHeader}>
        <div
          className={styles.close}
          onClick={() => {
            ClickSound();
            aboutState({ ...about, isOpen: false });
          }}
        >
          <CloseButton />
        </div>

        <p className={styles.text + ' ' + styles.headerText}>
          <FormattedMessage id={'welcome_to'} /> <FormattedMessage id={'andar'} /> <FormattedMessage id={'bahar'} /> !
        </p>
      </div>
      <div className={styles.aboutContent}>
        <div className={styles.aboutBlock}>
          <div className={styles.aboutSubHead + ' ' + styles.text}>Objective</div>
          <div className={styles.aboutText + ' ' + styles.text}>
            Objective: The objective of the game is to predict whether the randomly selected card (the
            &quot;Joker&quot;) will appear on the Andar (left) or Bahar (right) side of the table.{' '}
          </div>
        </div>
        <div className={styles.aboutBlock}>
          <div className={styles.aboutSubHead + ' ' + styles.text}>Deck</div>
          <div className={styles.aboutText + ' ' + styles.text}>
            The game is played with a standard deck of 52 playing cards without the jokers. One player is designated as
            the dealer.
          </div>
        </div>
        <div className={styles.aboutBlock}>
          <div className={styles.aboutSubHead + ' ' + styles.text}>Gameplay</div>
          <div className={styles.aboutText + ' ' + styles.text}>
            <p>
              Once all bets have been placed the player presses the Start Game button, one card known as the
              &quot;Joker&quot; is drawn from the deck and placed face up in the centre of the table.
            </p>{' '}
            <p>
              {' '}
              The dealer continues to draw cards from the deck one by one and place them alternately on the Andar and
              Bahar sides of the table.{' '}
            </p>{' '}
            <p>Play continues until a card of the same rank as the Joker card is drawn. </p>{' '}
            <p>
              If the matching card falls on the side where the player placed his bet, he wins. Otherwise, the player
              loses his bet.
            </p>{' '}
            <p>
              Players that bet on the winning side will win the bet based on the payout table below. The game is played
              with one standard deck of 52 cards that are shuffled/changed before each round by the dealer. After the
              deck is shuffled/changed, the dealer will reveal the Joker for the new round.
            </p>
          </div>
        </div>
        <div className={styles.aboutBlock}>
          <div className={styles.aboutSubHead + ' ' + styles.text}>Payout Table</div>
          <div className={styles.aboutText + ' ' + styles.text}>
            <table>
              <tbody>
                <tr>
                  <td>Andar</td>
                  <td>1:1</td>
                </tr>
                <tr>
                  <td>Bahar</td>
                  <td>0.9:1</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.aboutBlock}>
          <div className={styles.aboutSubHead + ' ' + styles.text}>Side Bets</div>
          <div className={styles.aboutText + ' ' + styles.text}>
            <p>
              Placing a bet on the side bet does not require placing a bet on Andar or Bahar main bet (but can be done
              in addition to the main bet).
            </p>{' '}
            <p>
              The side bet allows the player to predict the number of cards that will be dealt after the Joker card
              until a card sharing the same value as the Joker card will be drawn for either Andar or Bahar. For
              example, if a player believes the Joker card will be dealt in the first five cards then he can place a
              side bet on 1-5 (1 to 5). If the card sharing the same value as the Joker card is drawn within the first 5
              cards, the player will win 2.5 times their bet amount.
            </p>{' '}
          </div>
        </div>
        <div className={styles.aboutBlock}>
          <div className={styles.aboutSubHead + ' ' + styles.text}>Payout Table</div>
          <div className={styles.aboutText + ' ' + styles.text}>
            <table>
              <tbody>
                <tr>
                  <td>1-5 (1 to 5)</td>
                  <td>2.5:1</td>
                </tr>
                <tr>
                  <td>6-10 (6 to 10)</td>
                  <td>3.5:1</td>
                </tr>
                <tr>
                  <td>11-15 (11 to 15)</td>
                  <td>4.5:1</td>
                </tr>
                <tr>
                  <td>16-25 (16 to 25)</td>
                  <td>3.5:1</td>
                </tr>
                <tr>
                  <td>26-30 (26 to 30)</td>
                  <td>14:1</td>
                </tr>
                <tr>
                  <td>31-35 (31 to 35)</td>
                  <td>24:1</td>
                </tr>
                <tr>
                  <td>36-40 (36 to 40)</td>
                  <td>49:1</td>
                </tr>
                <tr>
                  <td>41-49 (41 to 49)</td>
                  <td>119:1</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.aboutBlock}>
          <div className={styles.aboutSubHead + ' ' + styles.text}>AutoPlay </div>
          <div className={styles.aboutText + ' ' + styles.text}>
            <p>
              AutoPlay feature allows users to automate gameplay by selecting the number of rounds and a loss limit.
            </p>{' '}
            <p>Player can choose the desired number of consecutive rounds to be played automatically.</p>{' '}
            <p>
              Player can set a predetermined loss limit, specifying the maximum amount they are willing to lose before
              AutoPlay stops.
            </p>{' '}
            <p>
              AutoPlay will halt immediately when the loss limit is reached or when the specified number of rounds is
              completed.
            </p>{' '}
            <p>Player have the option to manually interrupt AutoPlay at any time during the automated gameplay.</p>{' '}
          </div>
        </div>
      </div>
    </div>
  );
}
