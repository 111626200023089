import * as amplitude from '@amplitude/analytics-browser';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as CloseSVG } from '../assets/closeButton.svg';
import { stateStore, usePlayerState } from '../Store';

// import { ClickSound } from './AudioHelper';
import * as Constants from './Constants';
import style from './ErrorWindow.module.scss';
import { generateTargetDepositUrl, generateTargetLobbyUrl } from './GenerateTargetGameUrl';
import { useSoundContext } from './SoundContext';

let setErrorWindow: any;

export default function InitErrorWindow() {
  [setErrorWindow] = stateStore((state) => [state.errorState]);
}

export function displayError(code = 0, message = '') {
  setErrorWindow({ isOpen: true, messageId: code, message: message });
}

type ErrorProp = {
  buildType: any;
  playerId: any;
};

export function ErrorWindow(props: ErrorProp) {
  const { ClickSound } = useSoundContext();

  // const errorRef: any = useRef(null);
  const [quit] = stateStore((state: any) => [state.quit, state.quitState]);
  const [error] = stateStore((state: any) => [state.error, state.errorState]);

  const player = usePlayerState((state: any) => state.player);
  const menu = stateStore((state: any) => state.menu);

  useEffect(() => {
    if (error.isOpen) {
      amplitude.track('Error', {
        messageId: error.messageId,
        message: error.message,
        buildType: props.buildType,
        playerId: player.playerId,
      });
    }
  }, [error.isOpen]);

  // useEffect(() => {
  //     if (errorRef === null) {
  //         return;
  //     }
  //     errorRef.current.style.display = error.isOpen && !quit.isOpen ? "flex" : "none";
  // }, [error.isOpen, quit.isOpen]);
  // const [message, setMessage] = useState("");
  // switch (error.messageId) {
  //     default:
  //         setMessage("ooops_something_went_wrong");
  //         break;
  // }

  function goToExit() {
    ClickSound();
    amplitude.track('Exit');
    let target_url;
    try {
      target_url = generateTargetDepositUrl(menu.exit_url, {
        isDepositOpen: false,
        operatorToken: player.operatorToken,
      });
    } catch (Exception) {
      target_url = new URL('https://24betting.com/casino');
    }

    window.open(target_url, '_top');
  }
  return (
    <div
      id={Constants.errorWindowId}
      className={style.errorScreen}
      style={{ display: error.isOpen && !quit.isOpen ? 'flex' : 'none' }}
    >
      <div className={style.errorBackground}></div>
      <div className={style.errorWindow}>
        <div className={style.errorGif}></div>
        <div className={style.errorText}>
          <FormattedMessage id="ooops_something_went_wrong" />
          {/* <br></br> */}
          {/* <FormattedMessage id="please_refresh_page" /> */}
          <br></br>
        </div>
        <div className={style.primaryButton} onClick={() => goToExit()}>
          <a className={style.text}>Home</a>
        </div>
        {/*<div*/}
        {/*    className={style.goToLobbyButton}*/}
        {/*    onClick={() => {*/}
        {/*        ClickSound();*/}
        {/*        window.location.reload();*/}
        {/*    }}*/}
        {/*>*/}
        {/*    <div className={style.goToLobbyButtonText}>*/}
        {/*        <FormattedMessage id="please_refresh_page"/>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<RefreshButton/>*/}
        {/*<div*/}
        {/*    className={style.goToLobbyButton}*/}
        {/*    onClick={() => {*/}
        {/*        quitState({isOpen: true});*/}
        {/*    }}*/}
        {/*>*/}
        {/*    <div className={style.goToLobbyButtonText}>*/}
        {/*        <FormattedMessage id="exit"/>*/}
        {/*    </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export function QuitWindow() {
  const { ClickSound } = useSoundContext();

  const quitRef: any = useRef(null);
  const menu = stateStore((state: any) => state.menu);
  const player = usePlayerState((state: any) => state.player);
  const language = stateStore((state: any) => state.language);
  const [quit, quitState] = stateStore((state: any) => [state.quit, state.quitState]);
  const [error, errorState] = stateStore((state: any) => [state.error, state.errorState]);

  useEffect(() => {
    if (quitRef === null) {
      return;
    }
    if (quit.isOpen) {
      if (document.getElementById(Constants.errorWindowId)?.style.display === 'flex' && !error.isOpen) {
        errorState({ isOpen: true });
      }
    }
    quitRef.current.style.display = quit.isOpen ? 'flex' : 'none';
  }, [quit.isOpen]);

  return (
    <div id="quitWin" className={style.quitScreen} ref={quitRef}>
      <div className={style.errorBackground}></div>
      <div className={style.goToLobbyWindow}>
        <div
          className={style.closeSVGPanel}
          onClick={() => {
            ClickSound();
            quitState({ isOpen: false });
          }}
        >
          <CloseSVG className={style.closeIcon} />
        </div>
        <div className={style.goToLobbyMessage}>
          <p className={style.messageText}>
            {quit.toLobby ? (
              <FormattedMessage id="are_your_sure_want_to_exit_to_lobby" />
            ) : (
              <FormattedMessage id="are_you_sure_want_to_quit" />
            )}
            {/*<FormattedMessage id="are_you_sure_want_to_quit"/>*/}
          </p>
        </div>

        <div className={style.buttonsPanel}>
          <div
            className={style.quitButton}
            onClick={() => {
              ClickSound();
              if (quit.toLobby) {
                const target_url = generateTargetLobbyUrl(menu.lobby_url, {
                  player: player.token,
                  menu: menu,
                  language: language.current,
                  freebetId: 0,
                  uuid: player.session_uuid,
                });
                amplitude.track('Go to lobby');
                window.open(target_url, '_self');
              } else {
                const target_url = generateTargetDepositUrl(menu.depositUrl, {
                  isDepositOpen: false,
                  operatorToken: player.operatorToken,
                });
                amplitude.track('Exit');
                window.open(target_url, '_top');
              }
            }}
          >
            <span className={style.buttonsText}>
              {quit.toLobby ? <FormattedMessage id="gotolobby" /> : <FormattedMessage id="quit" />}
            </span>
          </div>
          <div
            className={style.cancelButton}
            onClick={() => {
              ClickSound();
              quitState({ isOpen: false });
            }}
          >
            <span className={style.buttonsText}>
              <FormattedMessage id="cancel" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
