import React, { useEffect, useState } from 'react';

import { ReactComponent as AvatarPlaceholder } from '../../../assets/avatarPlaceholder.svg';
import * as API from '../../../helpers/API';
import * as Constants from '../../../helpers/Constants';
import { useSoundContext } from '../../../helpers/SoundContext';
import { usePlayerState } from '../../../Store';
import { aviatorStore } from '../Store';

import style from './BetsHistory.module.scss';

export default function BetsHistory() {
  const { ClickSound } = useSoundContext();
  const [historyType, setHistoryType] = useState<any>(0);
  const { stakesList, setMyBets } = aviatorStore();
  const { player } = usePlayerState();

  const [tableItems, setTableItems] = useState<any>([]);
  const [dataArray, setDataArray] = useState<any>(stakesList.allBets);
  useEffect(() => {
    switch (historyType) {
      case 0:
        setDataArray(stakesList.allBets);
        break;
      case 1:
        setDataArray(stakesList.myBets);
        break;
      case 2:
        setDataArray(stakesList.previousRound.allBets);
        break;
    }
  }, [historyType, stakesList]);

  useEffect(() => {
    const _items = [];
    for (let i = 0; i < dataArray.length; i++) {
      let _coef = 0;
      if (dataArray[i].profit && dataArray[i].stake) {
        _coef = dataArray[i].profit / dataArray[i].stake + 1;
      }
      if (dataArray[i].coef) {
        _coef = dataArray[i].coef;
      }
      const _color = getCoefColor(_coef);

      _items.push(
        <div
          key={i}
          className={`${style.tableItem} + ${(dataArray[i].play_status === 'WIN' || dataArray[i].coef) && style.win}`}
        >
          <div className={style.column}>
            {historyType === 1 ? (
              <>
                <a className={style.name}>{new Date(dataArray[i].created).toLocaleDateString()}</a>
              </>
            ) : (
              <>
                <AvatarPlaceholder />
                <a className={style.name}>{dataArray[i].name}</a>
              </>
            )}
          </div>
          <div className={`${style.column} + ${dataArray[i].isFreeBet && style.twoColumnElements}`}>
            {dataArray[i].isFreeBet && <a className={style.freeBet}>Free Bet</a>}
            <a>₹{Number(dataArray[i].stake).toFixed()}</a>
          </div>
          <div className={style.column}>
            {(dataArray[i].play_status === 'WIN' || dataArray[i].coef) && (
              <div className={style.coef} style={{ background: _color + '10' }}>
                <a style={{ color: _color }}>{Number(_coef).toFixed(2) + 'X'}</a>
              </div>
            )}
          </div>
          <div style={{ justifyContent: 'flex-end' }} className={style.column}>
            <a>
              {dataArray[i].play_status === 'WIN' &&
                '₹' + (Number(dataArray[i].stake) + Number(dataArray[i].profit)).toFixed(2)}
              {dataArray[i].coef && '₹' + (dataArray[i].coef * dataArray[i].stake).toFixed(2)}
            </a>
          </div>
        </div>,
      );
    }
    setTableItems(_items);
  }, [dataArray, stakesList]);

  return (
    <>
      <div className={style.betsHistory}>
        <div className={style.header}>
          <div className={style.tabs}>
            <div
              className={`${style.tab} + ${historyType === 0 ? style.selected : ''}`}
              onClick={() => {
                ClickSound();
                setHistoryType(0);
              }}
            >
              <a className={style.text}>All bets • {stakesList.allBets.length}</a>
            </div>
            <div
              className={`${style.tab} + ${historyType === 1 ? style.selected : ''}`}
              onClick={() => {
                ClickSound();
                setHistoryType(1);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                fetchBets(player.token!, setMyBets);
              }}
            >
              <a className={style.text}>My bets</a>
            </div>
            <div
              className={`${style.tab} + ${historyType === 2 ? style.selected : ''}`}
              onClick={() => {
                ClickSound();
                setHistoryType(2);
              }}
            >
              <a className={style.text}>Previous round</a>
            </div>
          </div>
          <div className={style.filter}>
            <div className={style.filterItem}>
              <a className={style.text}>{historyType === 1 ? 'Date' : 'User'}</a>
            </div>
            <div className={style.filterItem}>
              <a className={style.text}>Bet, INR</a>
            </div>
            <div className={style.filterItem}>
              {historyType === 2 ? (
                <div
                  className={style.coef}
                  style={{ background: getCoefColor(stakesList.previousRound.roundCoef) + '10' }}
                >
                  <a style={{ color: getCoefColor(stakesList.previousRound.roundCoef) }}>
                    {stakesList.previousRound.roundCoef}X
                  </a>
                </div>
              ) : (
                <a className={style.text}>X</a>
              )}
            </div>
            <div className={style.filterItem} style={{ textAlign: 'right' }}>
              <a className={style.text}>Cash Out, INR</a>
            </div>
          </div>
        </div>
        <div className={style.table}>{tableItems}</div>
      </div>
    </>
  );
}

export async function fetchBets(playerToken: string, setMyBets: any) {
  if (playerToken != null) {
    API.get(Constants.history + '?limit=' + Constants.history_limit, playerToken).then((json) => {
      setMyBets(json.results);
    });
  }
}

export function getCoefColor(coef: number) {
  let _color = '#64D2FF';
  if (coef >= 3 && coef < 10) {
    _color = '#BF5AF2';
  } else {
    if (coef >= 10) _color = '#FF2D55';
  }
  return _color;
}
