import Decimal from 'decimal.js';
import React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

import { ReactComponent as MinusIcon } from '../assets/minusIcon.svg';
import { ReactComponent as PlusIcon } from '../assets/plusIcon.svg';
import { stateStore } from '../Store';

import { ClickSound } from './AudioHelper';
import styles from './BetCalculator.module.scss';
import * as Constants from './Constants';

interface paramsInterface {
  min_bet: number;
  max_bet: number;
  step: number;
  currency: string;
  balance: number;
  bet: number;
  setBet: any;
  reason?: any;
  totalBet?: any;
}

export function calculateMultiplyingCoefficient(max_bet: number) {
  return Math.pow(10, max_bet.toString().length - 3);
}

export default function BetCalculator(params: paramsInterface) {
  const isFreeBetPlay = stateStore((state: any) => state.freebetLobbyState);
  const intl = useIntl();

  const totalBet = params.totalBet !== undefined ? params.totalBet - params.bet : 0;
  const multiplyingCoefficient = calculateMultiplyingCoefficient(params.max_bet);

  function changeBet(operation: string, value?: number) {
    let result = new Decimal(params.bet);
    const balance = new Decimal(params.balance - totalBet);
    if (balance.gte(params.min_bet)) {
      switch (operation) {
        default:
          result = new Decimal(params.min_bet);
          break;
        case '-':
          if (params.bet - (value as any) >= params?.min_bet && balance.gte(params.bet - (value as any))) {
            result = result.minus(value as any);
          }
          break;
        case '+':
          if (params.bet + (value as any) <= params?.max_bet && balance.gte(params.bet + (value as any))) {
            result = result.plus(value as any);
          }
          break;
        case 'min':
          result = new Decimal(params?.min_bet);
          break;
        case 'max':
          if (balance.gte(params?.max_bet)) {
            result = new Decimal(params?.max_bet);
          } else {
            result = balance;
          }
          break;
        case 'x2':
          if (params.bet * 2 <= params?.max_bet && balance.gte(params.bet * 2)) {
            result = new Decimal(params.bet * 2);
          }
          break;
        case 'clear':
          if (params?.balance - totalBet > 10 * multiplyingCoefficient) {
            result = new Decimal(10 * multiplyingCoefficient);
          } else {
            result = balance;
          }
          break;
        case 'clear-to-zero':
          result = new Decimal(0);
          break;
      }
    } else if (balance.lte(params.min_bet) && params.reason === 'sideBets') {
      if (params.bet > 0) {
        result = new Decimal(params.bet);
      } else {
        result = new Decimal(0);
      }
    } else {
      result = new Decimal(params.min_bet);
    }
    params.setBet(result.toNumber());
    ClickSound();
  }

  const CurrencyNumber = intl
    .formatNumberToParts(params.bet, {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
    })
    .map(({ type, value }) => {
      switch (type) {
        case 'currency':
          return (
            <span style={{ fontWeight: '400' }} key={'rndkey'}>
              {value}
            </span>
          );
        default:
          return value;
      }
    });

  function Display() {
    if (isFreeBetPlay.status !== Constants.freebetPlay.play) {
      return (
        <div className={styles.betPanel}>
          <div className={`${styles.button} ${styles.minus}`} onClick={() => changeBet('-', params?.step)}>
            <MinusIcon />
          </div>
          <p className={`${styles.text} ${styles.betText}`}>
            {CurrencyNumber}
            {/* {isFreeBetPlay.status != Constants.freebetPlay.play
                            ? CurrencyNumber
                            : `FreeBet: ${intl.formatNumber(params.bet)}`} */}
          </p>
          <div className={`${styles.button} ${styles.plus}`} onClick={() => changeBet('+', params?.step)}>
            <PlusIcon />
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.betPanel}>
          <a className={`${styles.text} ${styles.betText}`}>
            {isFreeBetPlay.status !== Constants.freebetPlay.play ? `${params.bet}` : `FreeBet: ${params.bet}`}
          </a>
        </div>
      );
    }
  }

  function Buttons() {
    if (isFreeBetPlay.status !== Constants.freebetPlay.play) {
      return (
        <div className={styles.betButtons}>
          <div
            className={`${styles.button} ${styles.buttonItem}`}
            onClick={() => changeBet('+', 1 * multiplyingCoefficient)}
          >
            <a className={styles.text}>
              +<FormattedNumber value={1 * multiplyingCoefficient} />
            </a>
          </div>
          <div
            className={`${styles.button} ${styles.buttonItem}`}
            onClick={() => changeBet('+', 5 * multiplyingCoefficient)}
          >
            <a className={styles.text}>
              +<FormattedNumber value={5 * multiplyingCoefficient} />
            </a>
          </div>
          <div
            className={`${styles.button} ${styles.buttonItem}`}
            onClick={() => changeBet('+', 10 * multiplyingCoefficient)}
          >
            <a className={styles.text}>
              +
              <FormattedNumber value={10 * multiplyingCoefficient} />
            </a>
          </div>
          <div
            className={`${styles.button} ${styles.buttonItem}`}
            onClick={() => changeBet('+', 50 * multiplyingCoefficient)}
          >
            <a className={styles.text}>
              +
              <FormattedNumber value={50 * multiplyingCoefficient} />
            </a>
          </div>
          <div className={`${styles.button} ${styles.buttonItem}`} onClick={() => changeBet('min')}>
            <a className={styles.text}>Min</a>
          </div>
          <div className={`${styles.button} ${styles.buttonItem}`} onClick={() => changeBet('x2')}>
            <a className={styles.text} style={{ color: 'var(--accent-system-orange)' }}>
              x2
            </a>
          </div>
          <div className={`${styles.button} ${styles.buttonItem}`} onClick={() => changeBet('max')}>
            <a className={styles.text} style={{ color: 'var(--accent-system-light-green)' }}>
              Max
            </a>
          </div>
          {params.reason !== 'sideBets' ? (
            <div className={`${styles.button} ${styles.buttonItem}`} onClick={() => changeBet('clear')}>
              <a className={styles.text} style={{ color: 'var(--accent-system-pink)' }}>
                Reset
              </a>
            </div>
          ) : (
            <div className={`${styles.button} ${styles.buttonItem}`} onClick={() => changeBet('clear-to-zero')}>
              <a className={styles.text} style={{ color: 'var(--accent-system-pink)' }}>
                Clear
              </a>
            </div>
          )}
        </div>
      );
    } else return <></>;
  }

  return (
    <>
      {params.reason !== 'sideBets' ? (
        <>
          <p className={styles.selectAmount}>
            <FormattedMessage id="select_amount" />
          </p>
          <Display />
        </>
      ) : (
        ''
      )}
      <Buttons />
    </>
  );
}
