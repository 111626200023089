import * as amplitude from '@amplitude/analytics-browser';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import card_one from '../../assets/cards/2C.png';
import ThreeC from '../../assets/cards/3H.png';
import FourD from '../../assets/cards/4D.png';
import FiveD from '../../assets/cards/5D.png';
import FiveH from '../../assets/cards/5H.png';
import SixC from '../../assets/cards/6C.png';
import SixD from '../../assets/cards/6D.png';
import SixH from '../../assets/cards/6H.png';
import SevenC from '../../assets/cards/7C.png';
import EC from '../../assets/cards/8C.png';
import NC from '../../assets/cards/9C.png';
import AD from '../../assets/cards/AD.png';
import JC from '../../assets/cards/JC.png';
import KD from '../../assets/cards/KD.png';
import QD from '../../assets/cards/QD.png';
import TC from '../../assets/cards/TC.png';
import TH from '../../assets/cards/TH.png';
import { ReactComponent as CloseButton } from '../../assets/closeButton.svg';
import { ClickSound } from '../../helpers/AudioHelper';
import { stateStore } from '../../Store';
import '../../panels/About.scss';

export const About = () => {
  const [aboutState, setAboutState] = stateStore((state: any) => [state.about, state.aboutState]);
  useEffect(() => {
    if (aboutState.isOpen) amplitude.track('Open about game Teen Patti');
  }, [aboutState.isOpen]);
  return (
    <div className={'about'} style={{ display: aboutState.isOpen ? 'flex' : 'none' }}>
      <div className={'background'}></div>
      <div className={'aboutHeader'}>
        <div
          className={'close'}
          onClick={() => {
            ClickSound();
            setAboutState({ ...aboutState, isOpen: false });
          }}
        >
          <CloseButton />
        </div>

        <p className={'text headerText'}>
          <FormattedMessage id={'welcome_to'} /> <FormattedMessage id={'teen'} />
          <FormattedMessage id={'patti'} /> !
        </p>
      </div>
      <div className={'aboutContent newAboutContent'}>
        <div className={'rulesBlock'}>
          <a className={'text rulesBlockHeader'}>
            <FormattedMessage id={'objective'} />:
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'teen_objective'} />
          </a>
        </div>
        <div className={'rulesBlock'}>
          <a className={'text rulesBlockHeader'}>
            <FormattedMessage id={'card_rankings'} />:
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'teen_card_rankings'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'teen_card_rankings_no_limit'} />
          </a>
        </div>
        <div className={'rulesBlock'}>
          <a className={'text rulesBlockHeader'}>
            <FormattedMessage id={'hand_rankings'} />:
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'teen_card_hand_ranking'} />
          </a>
          <div className={'twoColumnContainer'}>
            <div className={'combinedRow'}>
              <div className={'leftColumn'}>
                <img className="teenCard" src={AD} alt={''} />
                <img className="teenCard" src={KD} alt={''} />
                <img className="teenCard" src={QD} alt={''} />
              </div>
              <div className={'rightColumn'}>
                <a className={'teenCardText'}>
                  <FormattedMessage id={'teen_card_royal_flesh'} />
                </a>{' '}
              </div>
            </div>
            <div className={'combinedRow'}>
              <div className={'leftColumn'}>
                <img className="teenCard" src={TC} alt={''} />
                <img className="teenCard" src={NC} alt={''} />
                <img className="teenCard" src={EC} alt={''} />
              </div>
              <div className={'rightColumn'}>
                <a className={'teenCardText'}>
                  <FormattedMessage id={'teen_card_straight_flesh'} />
                </a>{' '}
              </div>
            </div>
            <div className={'combinedRow'}>
              <div className={'leftColumn'}>
                <img className="teenCard" src={SixD} alt={''} />
                <img className="teenCard" src={SixC} alt={''} />
                <img className="teenCard" src={SixH} alt={''} />
              </div>
              <div className={'rightColumn'}>
                <a className={'teenCardText'}>
                  <FormattedMessage id={'teen_card_three_of_kind'} />
                </a>{' '}
              </div>
            </div>
            <div className={'combinedRow'}>
              <div className={'leftColumn'}>
                <img className="teenCard" src={card_one} alt={''} />
                <img className="teenCard" src={ThreeC} alt={''} />
                <img className="teenCard" src={FourD} alt={''} />
              </div>
              <div className={'rightColumn'}>
                <a className={'teenCardText'}>
                  <FormattedMessage id={'teen_card_straight'} />
                </a>{' '}
              </div>
            </div>
            <div className={'combinedRow'}>
              <div className={'leftColumn'}>
                <img className="teenCard" src={SixC} alt={''} />
                <img className="teenCard" src={JC} alt={''} />
                <img className="teenCard" src={NC} alt={''} />
              </div>
              <div className={'rightColumn'}>
                <a className={'teenCardText'}>
                  <FormattedMessage id={'teen_card_flush'} />
                </a>{' '}
              </div>
            </div>
            <div className={'combinedRow'}>
              <div className={'leftColumn'}>
                <img className="teenCard" src={FiveD} alt={''} />
                <img className="teenCard" src={FiveH} alt={''} />
                <img className="teenCard" src={card_one} alt={''} />
              </div>
              <div className={'rightColumn'}>
                <a className={'teenCardText'}>
                  <FormattedMessage id={'teen_card_pair'} />
                </a>{' '}
              </div>
            </div>
            <div className={'combinedRow'}>
              <div className={'leftColumn'}>
                <img className="teenCard" src={SevenC} alt={''} />
                <img className="teenCard" src={KD} alt={''} />
                <img className="teenCard" src={TH} alt={''} />
              </div>
              <div className={'rightColumn'}>
                <a className={'teenCardText'}>
                  <FormattedMessage id={'teen_card_high_card'} />
                </a>{' '}
              </div>
            </div>
          </div>
          <div className={'rulesBlock'}>
            <a className={'text aboutText'}>
              <FormattedMessage id={'teen_card_hand_ranking_two'} />
            </a>
            <a className={'text aboutText'}>
              <FormattedMessage id={'teen_card_hand_ranking_three'} />
            </a>
            <a className={'text aboutText'}>
              <FormattedMessage id={'teen_card_hand_ranking_four'} />
            </a>
            <a className={'text aboutText'}>
              <FormattedMessage id={'teen_card_hand_ranking_five'} />
            </a>
          </div>
        </div>
        <div className={'rulesBlock'}>
          <a className={'text rulesBlockHeader'}>
            <FormattedMessage id={'gameplay'} />:
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'teen_patti_gameplay_one'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'teen_patti_gameplay_three'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'teen_patti_payout_four'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'teen_patti_payout_one'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'teen_patti_payout_two'} />
          </a>
          <a className={'text aboutText'}>
            <FormattedMessage id={'teen_patti_payout_three'} />
          </a>
        </div>
      </div>
    </div>
  );
};
