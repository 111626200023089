import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import './Card.scss';

type CardProps = {
  card: {
    // id: number;
    card: string;
    name: string;
  };
  isTop: boolean;
  index: number;
};

export const Card = ({ card, isTop, index }: CardProps) => {
  const xPositionValues = [-95, 8, 95];
  const xPositionValuesForBtm = [-95, -8, 95];
  const xSmallPositionValue = [-80, 8, 80];
  const xSmallPositionValueForBtm = [-80, -8, 80];
  const [isWidthSmall, setIsWidthSmall] = useState(false);
  const [isHeightSmall, setIsHeightSmall] = useState(false);
  const xPosition = isWidthSmall && isHeightSmall ? xSmallPositionValue : xPositionValues;
  const xPositionForBtm = isWidthSmall && isHeightSmall ? xSmallPositionValueForBtm : xPositionValuesForBtm;

  // const calculateXPosition = isTop
  //   ? xPosition[index] || 0
  //   : xPositionValuesForBtm[index] || 0;

  const calculateXPosition = () => {
    if (isTop) {
      if (index === 0) return xPosition[2] || 0;
      else if (index === 1) return xPosition[1] || 0;
      else return xPosition[0] || 0;
    } else {
      return xPositionForBtm[index] || 0;
    }
  };
  const calculateRotation = () => {
    if ((isTop && index === 2) || (!isTop && index === 2)) return 90;
    else return 0;
  };

  const topPosition = () => {
    if (!isTop && index === 2) return 15;
    else if (isTop && index === 2) return 15;
    else return 0;
  };

  // const calculateRotation =
  //   isTop && index === 2 ? 90 : !isTop && index === 2 ? 90 : 0;

  // const topPosition =
  //   !isTop && index === 2 ? 15 : isTop && index === 0 ? 15 : 0;

  useEffect(() => {
    const handleResize = () => {
      setIsWidthSmall(window.innerWidth <= 556);
      setIsHeightSmall(window.innerHeight <= 400);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <motion.img
        initial={{ opacity: 1, x: -1000, rotate: -25, y: 100 }}
        animate={{
          y: topPosition(),
          rotate: calculateRotation(),
          opacity: 1,
          x: calculateXPosition(),
        }}
        exit={{ opacity: 0, x: isTop ? 300 : 100 }}
        transition={{ duration: 0.4, ease: 'easeOut' }}
        className="baccarat-card"
        src={card.card}
        alt={card.name}
      />
    </>
  );
};
